<script lang="ts" setup>
import { ref, watch, onMounted, nextTick, reactive } from "vue";
import type { FormInstance } from "element-plus";
import { useRouter } from "vue-router";
import { useMainStore } from "../store/main";
import { usePGRESTAPIStore } from "../store/pgrest_api";
import PGRESTResult from "../classes/PGRESTResult";
import { ElMessage } from "element-plus";
import { Edit, Delete, Notebook, Plus, MoreFilled, Files } from "@element-plus/icons-vue";
import InterestDetails from "../components/ConfigInterestDetails.vue";
import UserType from "../enums/UserType";
import { useGenericMethodsVariables } from "../composables/genericMethodsVariables";
const { showError} = useGenericMethodsVariables();
const router = useRouter();
const mainStore = useMainStore();
const pgrestapi = usePGRESTAPIStore();
const formRef = ref<FormInstance>();
const tabPosition = ref("left");
const DeleteDialogVisible = ref(false);
const currencies = ref<any>([]);
let detailsVisible = ref(false);
let deleteVisible = ref(false);
let selectedRow = ref(0);
let DeleteSelectedRow = ref(0);
let InterestCategory = ref(0);
let dataList = ref([]);
let isLoading = ref(false);
let lastPage = ref(0);
let dataCount = ref(0);

const emits = defineEmits(["save", "close", "fail-save"]);

onMounted(async () => {
  loadPage(1);
  await loadCurrencies();
});

const deleteHandleClick = (row_id: number) => {
  DeleteSelectedRow.value = row_id;
  console.log(InterestCategory);
  deleteVisible.value = true;
  DeleteInterest(row_id);
};

const handleClick = (row_id: number) => {
  selectedRow.value = row_id;
  detailsVisible.value = true;
};

const DeleteInterest = async (row_id: number) => {
  let result1: PGRESTResult = new PGRESTResult();
  let result2: PGRESTResult = new PGRESTResult();

  let id = JSON.stringify(row_id);
  result2 = await pgrestapi.delete_data("interest_rates?interest_category=eq." + id);

  if (result2.error) {
    ElMessage({
      showClose: true,
      message: result2.error.message,
      type: "error",
    });

    return;
  }

  result1 = await pgrestapi.delete_data("config_interest?id=eq." + id);

  if (result1.error) {
    ElMessage({
      showClose: true,
      message: result1.error.message,
      type: "error",
    });

    DeleteDialogVisible.value = false;
    return;
  }

  onDelete();
};

const onSave = async () => {
  selectedRow.value = -1;
  ElMessage({
    showClose: true,
    message: "Interest Updated Successfully",
    type: "success",
    duration: 1000,
  });

  detailsVisible.value = false;
  loadPage(lastPage.value);
};

const onInsert = async () => {
  selectedRow.value = -1;
  ElMessage({
    showClose: true,
    message: "Interest Saved Successfully",
    type: "success",
    duration: 1000,
  });

  detailsVisible.value = false;
  loadPage(lastPage.value);
};

const onDelete = async () => {
  ElMessage({
    showClose: true,
    message: "Interest Deleted Successfully",
    type: "success",
    duration: 1000,
  });

  DeleteDialogVisible.value = false;
  loadPage(lastPage.value);
};

const onFailSave = async () => {
  selectedRow.value = -1;
  ElMessage({
    showClose: true,
    message: "Interest failed to update",
    type: "error",
    duration: 2000,
  });

  detailsVisible.value = false;
};

const dynamicValidateForm = reactive<{
  interests: InterestItem[];
}>({
  interests: [],
});

interface InterestItem {
  interest: string;
  currency: string;
  automato_epitokio:boolean;
}

const removeInterest = (item: InterestItem) => {
  const index = dynamicValidateForm.interests.indexOf(item);
  if (index !== -1) {
    dynamicValidateForm.interests.splice(index, 1);
  }
};

const addInterest = () => {
  dynamicValidateForm.interests.push({
    interest: "",
    currency: "",
    automato_epitokio:false,
  });
};

const submitForm = (formEl: FormInstance | undefined) => {
  if (!formEl) return;
  formEl.validate((valid) => {
    if (valid) {
      console.log("submit!");
      save();
    } else {
      console.log("error submit!");
    }
  });
};

const save = async () => {
  let result: PGRESTResult = new PGRESTResult();

  console.log(dynamicValidateForm.interests);

  result = await pgrestapi.post_data("config_interest", dynamicValidateForm.interests);

  if (result.error) {
    onFailSave();
    return;
  }
  dynamicValidateForm.interests = [];
  onInsert();
  loadPage(1);
};

const loadPage = async (page: number) => {
  isLoading.value = true;

  lastPage.value = page;
  page = page - 1;
  
  let result: PGRESTResult = await pgrestapi.getPaginated(
    "view_mosinterests_view1?order=interest",
    page
  );

  if (result.error) {
    showError(result.error.message);

    return;
  }

  if (result.headers)
    dataCount.value = parseInt(result.headers["content-range"].split("/")[1]);

  dataList.value = result.data;

  isLoading.value = false;
};

const loadCurrencies = async () => {
  let result: PGRESTResult = new PGRESTResult();

  result = await pgrestapi.get("config_currency?order=currency");

  if (result.error) {
    showError(result.error.message);

    return;
  }

  currencies.value = result.data;
};

const handleClickAdd = (row_id: number) => {
  router.push({
    name: "Interest Data",
    params: { catg_id: row_id as number },
  });
};
</script>

<template>
  <div style="height: 100%" v-loading="isLoading">
    <el-row :gutter="10" style="margin: 20px">
      <el-col :span="24">
        <el-table :data="dataList" stripe border table-layout="fixed" size="small">
          <el-table-column fixed sortable prop="interest" :label="$t('PlatformSettingsView.interest_categories')"
            type="text" />
          <el-table-column fixed sortable prop="currency_code" :label="$t('PlatformSettingsView.currencies')"
            type="text" />
          <el-table-column fixed="right" :label="$t('global.operations')">
            <template #default="scope">
              <el-button link type="primary" size="small" class="btn-add" @click="handleClickAdd(scope.row.id)">
                <el-icon>
                  <Files />
                </el-icon>
              </el-button>
              <el-button link type="primary" size="small" class="btn-detail" @click="handleClick(scope.row.id)">
                <el-icon>
                  <Edit />
                </el-icon>
              </el-button>
              <el-button v-if="mainStore.loggedUser.user_type === UserType.Administrator" link type="primary"
                size="small" class="btn-delete" @click="DeleteDialogVisible = true; DeleteSelectedRow = scope.row.id;"
                :icon="Delete" />
            </template>
          </el-table-column>
        </el-table>
        <div>
          <el-pagination small layout=" prev, pager, next" :page-size="10" :total="dataCount"
            @current-change="loadPage" />
        </div>

        <el-dialog v-model="DeleteDialogVisible" title="Interests Delete" width="50%">
          <span>Are you sure you want to delete this interest category?</span>
          <template #footer #default="scope">
            <span class="dialog-footer">
              <el-button @click="DeleteDialogVisible = false">{{$t('generic.cancel')}}</el-button>
              <el-button type="primary" @click="deleteHandleClick(DeleteSelectedRow)">{{$t('generic.delete')}}
              </el-button>
            </span>
          </template>
        </el-dialog>

        <el-dialog v-model="detailsVisible" title="Interest Details" width="50%">
          <InterestDetails :interestid="selectedRow" @save="onSave" @close="detailsVisible = false; selectedRow = -1;"
            @fail-save="onFailSave" />
        </el-dialog>

        <el-row :gutter="10">
          <el-col :span="24">
            <el-form ref="formRef" :model="dynamicValidateForm" label-width="220px">
              <el-row :gutter="10">
                <el-col :span="24">
                  <el-form-item v-for="(interest, index) in dynamicValidateForm.interests"
                    :label="$t('PlatformSettingsView.interest_categories') + ' ' + index"
                    :prop="'interests.' + index + '.value'"
                    style="border-bottom: 0px; margin-top: 30px; padding-bottom: 30px">
                    <el-col :span="7">
                      <el-input :placeholder="
                        $t('PlatformSettingsView.interest_categories_details')
                      " v-model="interest.interest" autocomplete="off" style="border-bottom: 3px solid #e3e3e3" />
                    </el-col>
                    <el-col :span="7" style="margin-top: 0px">
                      <el-form-item style="margin-top: 0px">
                        <el-select v-model="interest.currency" :value="interest.currency"
                          :placeholder="$t('PlatformSettingsView.currencies_details')" style="margin-top: 0px">
                          <el-option v-for="currency in currencies" :label="currency.currency" :value="currency.id" />
                        </el-select>
                      </el-form-item>
                    </el-col>
                    <el-col :span="6" style="margin-top: 0px">
                      <el-form-item style="margin-top: 0px">{{$t('PlatformSettingsView.automato_epitokio')}} &nbsp;
                        <el-switch v-model="interest.automato_epitokio" :value="interest.automato_epitokio"
                          :placeholder="$t('PlatformSettingsView.automato_epitokio')" style="margin-top: 0px">                         
                        </el-switch>
                      </el-form-item>
                    </el-col>
                    <el-col :span="4">
                      <el-button style="float: left" @click.prevent="removeInterest(interest)">
                        Delete
                      </el-button>
                    </el-col>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="10">
                <el-col :span="24">
                  <el-form-item style="border-bottom: 0px; float: right; margin-left: 0px; margin-top: 30px;">
                    <el-button v-if="dynamicValidateForm.interests.length != 0" type="primary"
                      @click="submitForm(formRef)">
                      {{ $t('generic.save') }}
                    </el-button>
                    <el-button @click="addInterest">{{
                        $t("PlatformSettingsView.new_interest_category")
                    }}</el-button>
                    <!-- <el-button @click="resetForm(formRef)">Reset</el-button> -->
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
  </div>
</template>

<style scoped>
.el-row {
  margin-bottom: 20px;
}

.el-row:last-child {
  margin-bottom: 0;
}

.el-col {
  border-radius: 4px;
}

.grid-content {
  border-radius: 4px;
  min-height: 36px;
}

.info {
  margin: auto;
  width: 50%;
  padding: 10px;
  text-align: center;
}
</style>
