<script lang="ts" setup>
import { ref, watch, onMounted, nextTick } from "vue";
import { useRoute } from "vue-router";
import { storeToRefs } from "pinia";
import { useMainStore } from "../store/main";
import { usePGRESTAPIStore } from "../store/pgrest_api";
import PGRESTResult from "../classes/PGRESTResult";
import { Edit, Delete, Plus, User, RefreshLeft, Lock } from "@element-plus/icons-vue";
import AddEdit_Portfolio from "../components/AddEdit_Portfolio.vue";
import Client from "../classes/DB_Entities/Client";
import Option from "../interfaces/Option";
import UserType from "../enums/UserType";
import { useSort } from "../composables/sort";
import { useI18n } from "vue-i18n";
import { useGenericMethodsVariables } from "../composables/genericMethodsVariables";
const { showError, showSuccess } = useGenericMethodsVariables();
const { t } = useI18n({ useScope: "global" });
const route = useRoute();
const mainStore = useMainStore();
const pgrestapi = usePGRESTAPIStore();

let filterClient = ref<any>();

let timer = ref<any>(null);
const { onSortChange, sortColumn, sortOrder } = useSort();
let clientsList = ref<Client[]>([]);
let dataList = ref([]);
let dataCount = ref(0);
let isLoading = ref(false);
let lastPage = ref(0);
let supervisorSelectionVisible = ref(false);
let addEditVisible = ref(false);
let LockedRow = ref(false);
let UnlockAllRowsVisible = ref(false);
let selectedRow = ref(0);
let selectedRowForSupervisors = ref(0);
const deleteDialogVisible = ref(false);

let availableSupervisors = ref<Option[]>([]);
let assignedSupervisors = ref([]);

const { globalPortfolioID } = storeToRefs(mainStore);
const { globalDebtorID } = storeToRefs(mainStore);
const { globalLoanAgreementID } = storeToRefs(mainStore);

const handleClickToUnlock = async (row_id: number) => {
  let result = await pgrestapi.patch("portfolio?id=eq." + row_id, { lockedrow: 'false' });
  await loadPage(lastPage.value);
}

const UnlockAllLockedRows = async () => {
  let result = await pgrestapi.patch("portfolio?" , { lockedrow: 'false' });
  await loadPage(lastPage.value);
  UnlockAllRowsVisible.value = false;
}

onMounted(async () => {
  const ResultLockedRow = await pgrestapi.get("portfolio?select=*&lockedrow=eq.true");

  if (ResultLockedRow.data.length > 0) {
    UnlockAllRowsVisible.value = true;
    UnlockAllRowsVisible.value = false;//να κρυφτει για να παιξει***********************
  } else {
    UnlockAllRowsVisible.value = false;
  }
});

watch(globalPortfolioID, async () => {
  if (mainStore.loggedUser.username !== '') // Do not load if user logged out (changes the value of globalPortfolioID to "")
  {
    await loadClients();
    await loadPage(1);
  }
});
watch(globalDebtorID, async () => {
  if (mainStore.loggedUser.username !== '') // Do not load if user logged out (changes the value of globalDebtorID to "")
  {
    await loadClients();
    await loadPage(1);
  }
});

watch(globalLoanAgreementID, async () => {
  if (mainStore.loggedUser.username !== '') // Do not load if user logged out (changes the value of globalLoanAgreementID to "")
  {
    await loadClients();
    await loadPage(1);
  }
});

const handleClickEdit = async (row_id: number) => {

  selectedRow.value = row_id;
  /*let ResultLockedRow = await pgrestapi.get("portfolio?select=*&id=eq." + row_id);
  LockedRow.value = ResultLockedRow.data[0].lockedrow;
  console.log(LockedRow);
  LockedRow.value=false;
  //να κρυφτει για να παιξει****************************************

  if(LockedRow.value){
    await loadPage(lastPage.value);
    return
  } else if (LockedRow.value == false){
    let result = await pgrestapi.patch("portfolio?id=eq." + row_id, { lockedrow: 'true' });
    await loadPage(lastPage.value);*/
    addEditVisible.value = true;
 /*}*/

};

const handleClickAdd = () => {
  addEditVisible.value = true;
  selectedRow.value = -1;
};

const handleClickDelete = (row_id: number) => {
  selectedRow.value = row_id;
  deleteDialogVisible.value = true;
};

const loadSupervisors = async (row_id: number) => {
  let result = await pgrestapi.get("users?user_type=eq." + UserType.Supervisor);

  availableSupervisors.value = [];

  for (let agent of result.data) {
    availableSupervisors.value.push({
      key: agent.id,
      label: agent.username,
      disabled: false,
    });
  }

  result = await pgrestapi.get("portfolio?id=eq." + row_id);
  assignedSupervisors.value = result.data[0].allowed_users;
};

const closeSupervisorPicking = async () => {
  supervisorSelectionVisible.value = false;

  let result = pgrestapi.patch("portfolio?id=eq." + selectedRowForSupervisors.value, {
    allowed_users: assignedSupervisors.value,
  });
};

const handleSupervisors = (row_id: number) => {
  selectedRowForSupervisors.value = row_id;
  supervisorSelectionVisible.value = true;
  loadSupervisors(row_id);
};

const deleteSelectedPortfolio = async () => {
  let result = await pgrestapi.delete_data("portfolio?id=eq." + selectedRow.value);

  if (result.error) {
    showError(result.error.message);
    deleteDialogVisible.value = false;
    return;
  }
};

onMounted(async () => {
  isLoading.value = true;
  await loadClients();

  if (route.query.client_name) {
    nextTick(async () => {
      let filterObj = {
        key: route.query.client_id,
        label: route.query.client_name,
        id: route.query.client_id,
      };

      filterClient.value = filterObj;
      await loadPage(1);
    });

    return;
  }
  await loadPage(1);
});

const loadClients = async () => {
  isLoading.value = true;

  let addedFilter = "";
  if (mainStore.globalPortfolioID !== "") {
    addedFilter = ",portfolio!inner(*)&portfolio.id=eq." + mainStore.globalPortfolioID;
  }

  /*Το φίλτρο αν μπει πρέπει να μπει βάσει portfolio*/
  let result: PGRESTResult = await pgrestapi.get(
    `mosclients?select=id,clientname` +
    addedFilter
  );


  if (result.error) {
    showError("Failed retrieving clients list:" + result.error.message, 2500);
    isLoading.value = false;
    return;
  }

  clientsList.value = result.data;

  isLoading.value = false;
};

const loadPage = async (page: number) => {
  isLoading.value = true;

  lastPage.value = page;
  page = page - 1;

  let filterURL = "";

  if (sortOrder.value !== "") {
    filterURL += `&order=${sortColumn.value}.${sortOrder.value}`;
  }

  if (filterClient.value) {
    filterURL += `&mosclient_id=eq.${filterClient.value.id}`;
  }

  if (mainStore.globalPortfolioID !== "") {
    filterURL += "&id=eq." + mainStore.globalPortfolioID;
  }

  if (mainStore.globalDebtorID !== "") {
    filterURL += "&debtor_ids=cs.{" + mainStore.globalDebtorID + "}";
  }

  if (mainStore.globalLoanAgreementID !== "") {
    filterURL += "&loan_agreement_ids=cs.{" + mainStore.globalLoanAgreementID + "}";
  }

  if (mainStore.loggedUser.user_type == UserType.Agent) {
    filterURL += `&debtor_allowed_users=cs.{${mainStore.loggedUser.id}}`;
  } else if (mainStore.loggedUser.user_type == UserType.Supervisor) {
    filterURL += `&allowed_users=cs.{${mainStore.loggedUser.id}}`;
  }

  let result: PGRESTResult = await pgrestapi.getPaginated(
    "view_mosportfolios_view1?select=*" + filterURL + "&order=portf_name.asc",
    page
  );

  if (result.error) {
    showError(result.error.message);
    isLoading.value = false;
    return;
  }

  if (result.headers)
    dataCount.value = parseInt(result.headers["content-range"].split("/")[1]);

  dataList.value = result.data;

  const ResultLockedRow = await pgrestapi.get("portfolio?select=*&lockedrow=eq.true");
  if (ResultLockedRow.data.length > 0) {
    UnlockAllRowsVisible.value = true;
  } else {
    UnlockAllRowsVisible.value = false;
  }

  isLoading.value = false;
};

const onDelete = async () => {
  showSuccess("Portfolio deleted Successfully");

  deleteDialogVisible.value = false;
  loadPage(lastPage.value);
};

const onSave = async () => {
  showSuccess("Portfolio updated");
  addEditVisible.value = false;
  selectedRow.value = -1;

  loadPage(lastPage.value);
};

const onAdd = async () => {
  showSuccess("Portfolio added");
  addEditVisible.value = false;
  loadPage(lastPage.value);
};

const onFailSave = async () => {
  selectedRow.value = -1;
  addEditVisible.value = false;
  showError("Portfolio failed to update", 2000);
};

const filterChanged = async (value: string) => {
  if (timer.value !== 0) {
    clearTimeout(timer.value);
  }

  timer.value = setTimeout(() => {
    loadPage(1);

    timer.value = 0;
  }, 100);
};

const localSortChange = async (sortObj: any) => {
  onSortChange(sortObj);
  await loadPage(lastPage.value);
};

const onCloseAddEdit = async () => {
  let result = await pgrestapi.patch("portfolio?id=eq." + selectedRow.value, { lockedrow: 'false' });
  await loadPage(lastPage.value);
  addEditVisible.value = false;
  selectedRow.value = -1;
};
</script>

<template>
  <div class="content-wrapper" v-loading="isLoading">
    <el-row :gutter="10">
      <el-col :span="12">
        <h3 class="heading-page">
          {{ $t("PortfolioView.portfolios") }} ({{ dataCount }})
        </h3>
      </el-col>
    </el-row>
    <div style="margin: 30px">
      <el-row :gutter="20">
        <el-col :span="6">
          <el-select clearable v-model="filterClient" class="m-2 client-input" placeholder="Client" size="large"
            value-key="id" @change="filterChanged">
            <el-option v-for="item in clientsList" :key="item.id" :label="item.clientname" :value="item" />
          </el-select>
        </el-col>
      </el-row>
    </div>
    <div>
      <el-row :gutter="10" style="margin: 10px; margin-top: 15px">
        <el-col :span="12" class="realestate-add-new-btn" />

        <el-col :span="12" class="realestate-add-new-btn">
          <el-button link type="primary" size="small" class="btn-add" @click="loadPage(lastPage)"
            style="float: right; margin: 2.5px" :icon="RefreshLeft" />
          <el-button v-if="mainStore.loggedUser.user_type === UserType.Administrator" link type="primary" size="small"
            class="btn-add" style="float: right; margin: 2.5px" @click="handleClickAdd()" :icon="Plus" />
          <el-tooltip :content="$t('global.unlockalllockedrow')" v-if="UnlockAllRowsVisible && false">
            <el-button link type="primary" size="small"
            class="btn-add" style="float: right; margin: 2.5px; background-color: #f56c6c !important;" @click="UnlockAllLockedRows" :icon="Lock" />
          </el-tooltip>
        </el-col>
      </el-row>
    </div>
    <el-table :data="dataList" stripe border table-layout="fixed" sortable="custom" @sort-change="localSortChange"
      size="small">
      <el-table-column sortable fixed prop="portf_name" :label="$t('DebtorDetailsView.portfolio')" />
      <el-table-column sortable fixed prop="asset_owner" label="Asset Owner" />
      <el-table-column fixed="right" :label="$t('global.operations')">
        <template #default="scope">
          <el-button v-if="mainStore.loggedUser.user_type === UserType.Administrator" link type="primary" size="small"
            class="btn-portfolios" @click="handleSupervisors(scope.row.id)" :icon="User" />
          <el-button link type="primary" size="small" class="btn-detail" @click="handleClickEdit(scope.row.id)"
            :icon="Edit" :disabled="scope.row.lockedrow&&false" style="background-color: #d6d6d6 !important;" />
          <el-button v-if="mainStore.loggedUser.user_type === UserType.Administrator" link type="primary" size="small"
            class="btn-delete" @click="handleClickDelete(scope.row.id)" :icon="Delete" />
          <el-tooltip :content="$t('global.lockedrow')" v-if="scope.row.lockedrow == true && false">
          <el-button type="danger" :icon="Lock" circle @click="handleClickToUnlock(scope.row.id)"/>
         </el-tooltip>
        </template>
      </el-table-column>
    </el-table>

    <div>
      <el-pagination small layout="prev, pager, next" :page-size="10" :total="dataCount" @current-change="loadPage" />
    </div>

    <el-dialog v-if="addEditVisible" v-model="addEditVisible" @update:model-value="onCloseAddEdit"
      :title="selectedRow === -1 ? 'Add Portfolio' : 'Portfolio Details'">
      <AddEdit_Portfolio :row_id="selectedRow" @close="onCloseAddEdit" @save="onAdd"
        @fail-save="onFailSave" />
    </el-dialog>

    <el-dialog v-model="deleteDialogVisible" title="Portfolio Delete" width="50%">
      <span>Are you sure you want to delete this portfolio?</span>
      <template #footer #default="scope">
        <span class="dialog-footer">
          <el-button @click="deleteDialogVisible = false">{{ $t('generic.cancel') }}</el-button>
          <el-button type="primary" @click="deleteSelectedPortfolio()">{{ $t('generic.delete') }}
          </el-button>
        </span>
      </template>
    </el-dialog>

    <el-dialog v-model="supervisorSelectionVisible" title="Supervisor assignment" width="60%">
      <el-transfer v-model="assignedSupervisors" :data="availableSupervisors" :titles="['Available', 'Assigned']" />

      <template #footer #default="scope">
        <span class="dialog-footer">
          <el-button @click="closeSupervisorPicking">{{ $t('generic.close') }}</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<style scoped></style>
