<script lang="ts" setup>
import { ref, watch, onMounted } from "vue";
import { usePGRESTAPIStore } from "../store/pgrest_api";
import PGRESTResult from "../classes/PGRESTResult";
import { Avatar, Edit, Delete, Document, Plus, RefreshLeft, View } from "@element-plus/icons-vue";
import { ElMessage } from "element-plus";
import { storeToRefs } from "pinia";
import { useMainStore } from "../store/main";
import AddLiksiario from "../components/Add_Liksiario.vue";
import LiksiarioDetails from "../components/LiksiarioDetails.vue"
import UserType from "../enums/UserType";
import { useSort } from "../composables/sort";
import { useI18n } from "vue-i18n";
import { DateTime } from "luxon";
import { useGenericMethodsVariables } from "../composables/genericMethodsVariables";
const { showError } = useGenericMethodsVariables();

const { locale } = useI18n({ useScope: "global" });
const { t } = useI18n({ useScope: "global" });
const pgrestapi = usePGRESTAPIStore();
const mainStore = useMainStore();
const { onSortChange, sortColumn, sortOrder } = useSort();
const { globalPortfolioID } = storeToRefs(mainStore);
const { globalDebtorID } = storeToRefs(mainStore);
const { globalLoanAgreementID } = storeToRefs(mainStore);
const statusOptions = [
  {
    value: "denounced",
    label: t("loan_agreement.denounced"),
  },
  {
    value: "non_performing",
    label: t("loan_agreement.non_performing"),
  },
  {
    value: "performing",
    label: t("loan_agreement.performing"),
  },
];
let filterPortfolio = ref<string>("");
let dataList = ref([]);
let dataCount = ref(0);
let isLoading = ref(false);
let lastPage = ref(0);
let detailsVisible = ref(false);
let selectedRow = ref(0);
let selectedLoanAgreement = ref(0);
let SelectedGlobalLa = ref<string>("");
let activeLang = ref<string>("en");
const DeleteDialogVisible = ref(false);
let DeleteSelectedRow = ref(0);
let deleteVisible = ref(false);
let ΑddLiksiarioVisible = ref(false);
let filterContractNumber = ref<string>("");
let filterFullName = ref<string>("");
let filterTaxRegistration = ref<string>("");
let filterProduct = ref<string>("");
let filterStatus = ref<string>("");
let timer = ref<any>(null);
let filterPendingNew = ref(false);
let filterHideRejected = ref(true);
let viewOnly = ref(false);

watch(globalPortfolioID, async () => {
  if (mainStore.loggedUser.username !== '') // Do not load if user logged out (changes the value of globalLoanAgreementID to "")
    await loadPage(1);
});

watch(globalDebtorID, async () => {
  if (mainStore.loggedUser.username !== '') // Do not load if user logged out (changes the value of globalLoanAgreementID to "")
    await loadPage(1);
});

watch(globalLoanAgreementID, async () => {
  if (mainStore.loggedUser.username !== '') // Do not load if user logged out (changes the value of globalLoanAgreementID to "")
    await loadPage(1);
});

watch(locale, async () => {
  await loadPage(1);
});

const handleClickAdd = () => {
  ΑddLiksiarioVisible.value = true;
};

const handleClick = (row: any) => {
  viewOnly.value = !isEditable(row);
  console.log('viewOnly->', viewOnly.value)
  selectedRow.value = row.id;
  detailsVisible.value = true;
};

const deleteHandleClick = (row_id: number) => {
  DeleteSelectedRow.value = row_id;
  deleteVisible.value = true;
  DeleteLiksiario(row_id);
};

const onAdd = async () => {
  ElMessage({
    showClose: true,
    message: "Row added Successfully",
    type: "success",
    duration: 1000,
  });

  ΑddLiksiarioVisible.value = false;

  loadPage(lastPage.value);
};

const DeleteLiksiario = async (row_id: number) => {
  let result: PGRESTResult = new PGRESTResult();
  console.log(row_id);
  let id = JSON.stringify(row_id);
  result = await pgrestapi.delete_data("la_liksiario?id=eq." + id);

  if (result.error) {
    showError(result.error.message);

    DeleteDialogVisible.value = false;
    return;
  }

  onDelete();
};

onMounted(async () => {
  loadPage(1);
});

const loadPage = async (page: number) => {
  activeLang.value = locale.value as string;
  isLoading.value = true;

  lastPage.value = page;
  page = page - 1;

  let filterUrl = "";

  if (filterFullName.value !== "") {
    filterUrl += `&fullname=ilike.*${filterFullName.value}*`;
  }

  if (filterPortfolio.value) {
    filterUrl += `&portf_name=ilike.*${filterPortfolio.value}*`;
  }

  let addedFilter = "";

  if (mainStore.globalLoanAgreementID !== "") {
    SelectedGlobalLa.value = globalLoanAgreementID.value;
    addedFilter += "&loan_agreement_id=eq." + mainStore.globalLoanAgreementID;
  }
  if (mainStore.loggedUser.user_type == UserType.Agent) {
    addedFilter += `&allowed_users=cs.{${mainStore.loggedUser.id}}`;
  } else if (mainStore.loggedUser.user_type == UserType.Supervisor) {
    addedFilter += `&portfolio_allowed_users=cs.{${mainStore.loggedUser.id}}`;
  }

  let result: PGRESTResult = await pgrestapi.getPaginated(
    "view_laliksiario_view1?select=*" +
    addedFilter +
    filterUrl, +
  page
  );

  if (result.error) {
    showError(result.error.message);

    return;
  }

  if (result.headers)
    dataCount.value = parseInt(result.headers["content-range"].split("/")[1]);

  for (let item of result.data) {
    if (item.repayment_date) {
      item.repayment_date = DateTime.fromFormat((item.repayment_date) as string, "yyyy-MM-dd").setLocale('en-gb').toLocaleString();
    } if (item.start_date) {
      item.start_date = DateTime.fromFormat((item.start_date) as string, "yyyy-MM-dd").setLocale('en-gb').toLocaleString();
    } if (item.end_date) {
      item.end_date = DateTime.fromFormat((item.end_date) as string, "yyyy-MM-dd").setLocale('en-gb').toLocaleString();
    }
  }

  dataList.value = result.data;
  isLoading.value = false;
};

const onSave = async () => {
  selectedRow.value = -1;
  ElMessage({
    showClose: true,
    message: "Row updated",
    type: "success",
    duration: 1000,
  });

  detailsVisible.value = false;
  loadPage(lastPage.value);
};

const onDelete = async () => {
  ElMessage({
    showClose: true,
    message: "Row Deleted Successfully",
    type: "success",
    duration: 1000,
  });

  DeleteDialogVisible.value = false;
  loadPage(lastPage.value);
};

const onFailSave = async () => {
  selectedRow.value = -1;
  ElMessage({
    showClose: true,
    message: "Row failed to update",
    type: "error",
    duration: 2000,
  });

  detailsVisible.value = false;
};

const filterChanged = async (value: string) => {
  if (timer.value !== 0) {
    clearTimeout(timer.value);
  }

  timer.value = setTimeout(() => {
    loadPage(1);

    timer.value = 0;
  }, 500);
};

const localSortChange = async (sortObj: any) => {
  onSortChange(sortObj);
  await loadPage(lastPage.value);
};

const onClose = () => {
  detailsVisible.value = false;
  selectedRow.value = -1;
};

watch(globalLoanAgreementID, async () => {
  if (mainStore.loggedUser.username !== '') // Do not load if user logged out (changes the value of globalLoanAgreementID to "")
    SelectedGlobalLa.value = globalLoanAgreementID.value;
});

const isEditable = (row: any) => {
  return (row.debtor_approve_status === 1 && row.loan_agreement_approve_status === 1);
}
</script>

<template>
  <div class="content-wrapper" v-loading="isLoading">
    <el-row :gutter="10">
      <el-col :span="12">
        <h3 class="heading-page">
          {{ $t('LiksiarioView.diary') }}
        </h3>
      </el-col>
    </el-row>

    <!-- <div style="margin: 30px">
        <el-row :gutter="20">
          <el-col :span="6">
            <el-input v-model="filterPortfolio" class="client-input"
              :placeholder="$t('SummaryChecks.portfolio')" @input="filterChanged" />
          </el-col>
          <el-col :span="6">
            <el-input v-model="filterFullName" class="client-input"
              :placeholder="$t('DebtorDetailsView.company_fullname')" @input="filterChanged" />
          </el-col>
        </el-row>
      </div> -->

    <div>
      <el-row :gutter="10" style="margin: 10px; margin-top: 15px">
        <el-col :span="24" class="realestate-add-new-btn">
          <el-button link type="primary" size="small" class="btn-add" @click="loadPage(lastPage)"
            style="float: right; margin: 2.5px" :icon="RefreshLeft" />
          <el-button link type="primary" size="small" class="btn-add" style="float: right; margin: 2.5px"
            @click="handleClickAdd()" :icon="Plus" />
        </el-col>
      </el-row>
    </div>

    <el-table :data="dataList" stripe border table-layout="fixed" sortable="custom" @sort-change="localSortChange"
      size="small">
      <el-table-column sortable fixed prop="ar_sumbashs" :label="$t('LoanAgreementView.loan_agreement')" />
      <el-table-column sortable fixed prop="total_remaining_balance" :label="$t('LiksiarioView.remaining_balanc')" />
      <el-table-column sortable fixed prop="repayment_date" :label="$t('LiksiarioView.repayment_date')" />
      <el-table-column sortable fixed prop="margin" :label="$t('LiksiarioView.margin')" />
      <el-table-column sortable fixed prop="start_date" :label="$t('LoanAgreementEktokismosView.start_date')" />
      <el-table-column sortable fixed prop="end_date" :label="$t('LoanAgreementEktokismosView.end_date')" />
      <el-table-column fixed="right" :label="$t('global.operations')" width="280">
        <template #default="scope">
          <el-button type="primary" circle size="small" :icon="isEditable(scope.row) ? Edit : View"
            @click="handleClick(scope.row)" />
          <el-button v-if="mainStore.loggedUser.user_type === UserType.Administrator" type="danger" size="small"
            :icon="Delete" circle @click="DeleteDialogVisible = true; DeleteSelectedRow = scope.row.id;" />
          <el-popover v-if="scope.row.debtor_approve_status !== 1" placement="top-start" title="" :width="205"
            trigger="hover" :content="$t('global.debtor_not_approved')">
            <template #reference>
              <el-button :icon="Avatar" circle size="small"
                style="background-color: blueviolet; border: 0px; color: white" />
            </template>
          </el-popover>
          <el-popover v-if="scope.row.loan_agreement_approve_status !== 1" placement="top-start" title="" :width="265"
            trigger="hover" :content="$t('global.loan_agreement_not_approved')">
            <template #reference>
              <el-button :icon="Document" circle size="small"
                style="background-color: coral; border: 0px; color: white" />
            </template>
          </el-popover>

        </template>
      </el-table-column>
    </el-table>
    <div>
      <el-pagination small layout="prev, pager, next" :page-size="10" :total="dataCount" @current-change="loadPage" />
    </div>

    <el-dialog v-if="detailsVisible" v-model="detailsVisible" @update:model-value="onClose" title="Diary Details" width="70%">
      <LiksiarioDetails :rowid="selectedRow" :loan_agreement_id="selectedLoanAgreement" @save="onSave" @close="onClose"
        @fail-save="onFailSave" :view_only="viewOnly" />
    </el-dialog>

    <el-dialog v-if="ΑddLiksiarioVisible" v-model="ΑddLiksiarioVisible" title="Add diary row">
      <AddLiksiario :globalla="SelectedGlobalLa" @close="ΑddLiksiarioVisible = false" @save="onAdd" />
    </el-dialog>

    <el-dialog v-model="DeleteDialogVisible" title="Diary Delete" width="50%">
      <span>Are you sure you want to delete this row?</span>
      <template #footer #default="scope">
        <span class="dialog-footer">
          <el-button @click="DeleteDialogVisible = false">{{ $t('generic.cancel') }}</el-button>
          <el-button type="primary" @click="deleteHandleClick(DeleteSelectedRow)">{{ $t('generic.delete') }}
          </el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<style scoped></style>
