<script lang="ts" setup>
import { ref, watch, onMounted } from "vue";
import Interest_Rates from "../classes/DB_Entities/InterestRates";
import PGRESTResult from "../classes/PGRESTResult";
import { ElMessage } from "element-plus";
import { usePGRESTAPIStore } from "../store/pgrest_api";
import { useRoute } from "vue-router";
import { useRouter } from "vue-router";
import { Edit, Delete, Notebook, Plus, RefreshLeft } from "@element-plus/icons-vue";
import Add_InterestRate from "./Add_InterestRate.vue";
import Edit_InterestRate from "./Edit_InterestRate.vue";
import { useGenericMethodsVariables } from "../composables/genericMethodsVariables";
const emits = defineEmits(["save", "close", "fail-save"]);

const { showError } = useGenericMethodsVariables();

let isLoading = ref(false);
let dataList = ref([]);
let dataCount = ref(0);
let lastPage = ref(0);
const DeleteDialogVisible = ref(false);
let detailsVisible = ref(false);
let deleteVisible = ref(false);
let DeleteSelectedRow = ref(0);
let selectedRow = ref(0);
let interest_category = ref<string>("");
let newEntity = ref<boolean>(false);
let ΑddInterestVisible = ref(false);
let EditInterestVisible = ref(false);
let interest_row_id = ref();
const route = useRoute();
const router = useRouter();
let interests = ref<Array<Interest_Rates>>([]);
let interest_categ_name = ref<string>();
const pgrestapi = usePGRESTAPIStore();
let StoreInterestValues = ref<any>([]);
let row_id = ref<string>();
const props = defineProps<{
  catg_id: Number;
}>();

onMounted(async () => {
  await loadInterestRates();
  row_id.value = route.params.catg_id as string;
  loadPage(1);
});

const loadInterestRates = async () => {
  isLoading.value = true;
  let result: PGRESTResult = new PGRESTResult();

  result = await pgrestapi.get(
    "interest_rates?select=*,config_interest!inner(*)&interest_category=eq." +
      route.params.catg_id
  );

  if (result.error) {
    showError(result.error.message);

    return;
  }

  StoreInterestValues.value = result.data;
  let result2 = await pgrestapi.get(
    "config_interest?select=interest&id=eq." + route.params.catg_id
  );

  if (result2.data) {
    interest_categ_name.value = result2.data[0].interest;
  }

  console.log(StoreInterestValues.value);
  isLoading.value = false;
};

const goBack = () => {
  router.push({
    name: "Platform Settings",
  });
};

const handleClickAdd = (interest_categ: string) => {
  console.log('interest_category '+interest_categ)
  interest_category.value = interest_categ;
  newEntity.value = false;
  ΑddInterestVisible.value = true;
  selectedRow.value = -1;
 
};

const handleClickEdit = (row_id: number, interest_categ: string) => {
  interest_row_id.value = row_id;
  interest_category.value = interest_categ;
  newEntity.value = false;
  EditInterestVisible.value = true;
};

const loadPage = async (page: number) => {
  isLoading.value = true;
  //if (page === 0) { page = 1 }
  lastPage.value = page;
  page = page - 1;
  //lastPage.value = page;
  let filterURL = "";

  let result: PGRESTResult = await pgrestapi.getPaginated(
    "interest_rates?select=*,config_interest!inner(*)&interest_category=eq." +
      route.params.catg_id +
      filterURL,
    page
  );
  // console.log(JSON.stringify(result.data));
  if (result.error) {
    showError(result.error.message);

    return;
  }

  if (result.headers)
    dataCount.value = parseInt(result.headers["content-range"].split("/")[1]);

  StoreInterestValues.value = result.data;

  isLoading.value = false;
};

const onSave = async () => {
  emits("save");
  console.log("qqqqqqqqqqqqqqqqqqqqq");
  selectedRow.value = 0;
  ElMessage({
    showClose: true,
    message: "Interest updated successfully",
    type: "success",
    duration: 1000,
  });

  ΑddInterestVisible.value = false;
  selectedRow.value = -1;
  console.log('aaa'+row_id);
  //row_id
  
  await loadPage(lastPage.value);
};
const onSaveEdit = async () => {
  selectedRow.value = -1;
  ElMessage({
    showClose: true,
    message: "Interest updated Successfully",
    type: "success",
    duration: 1000,
  });

  EditInterestVisible.value = false;
  selectedRow.value = -1;
  await loadPage(lastPage.value);
};

const deleteHandleClick = (row_id: number) => {
  DeleteSelectedRow.value = row_id;
  deleteVisible.value = true;
  DeleteInterest(row_id);
};

const DeleteInterest = async (row_id: number) => {
  let result: PGRESTResult = new PGRESTResult();

  let id = JSON.stringify(row_id);

  result = await pgrestapi.delete_data("interest_rates?id=eq." + id);

  if (result.error) {
    showError(result.error.message);

    DeleteDialogVisible.value = false;
    return;
  }

  onDelete();
};

const onDelete = async () => {
  selectedRow.value = 0;
  ElMessage({
    showClose: true,
    message: "Interest Deleted Successfully",
    type: "success",
    duration: 1000,
  });

  DeleteDialogVisible.value = false;
  await loadPage(lastPage.value);
};
</script>
<template>
  <div class="content-wrapper" style="height: 100%" v-loading="isLoading">
    <el-row>
      <div style="margin-bottom: 10px">
        <h3 class="heading-page">Platform Settings / {{ interest_categ_name }}</h3>
      </div>
    </el-row>
    <el-row justify="end">
      <el-button
        link
        type="primary"
        size="small"
        class="btn-add"
        @click="handleClickAdd(row_id as string)"
      >
        <el-icon>
          <Plus />
        </el-icon>
      </el-button>
    </el-row>
    <el-row :gutter="10">
      <el-col :span="24">
        <el-table
          :data="StoreInterestValues"
          stripe
          border
          table-layout="fixed"
          size="small"
        >
          <el-table-column
            fixed
            sortable
            prop="interest_value"
            :label="$t('PlatformSettingsView.interest_rate')"
            type="text"
          />
          <el-table-column
            fixed
            sortable
            prop="interest_value_date"
            :label="$t('PlatformSettingsView.interest_value_date')"
            type="text"
          />
          <el-table-column fixed="right" :label="$t('global.operations')">
            <template #default="scope">
              <el-button
                link
                type="primary"
                size="small"
                class="btn-detail"
                @click="handleClickEdit(scope.row.id, scope.row.config_interest.id)"
              >
                <el-icon>
                  <Edit />
                </el-icon>
              </el-button>
              <el-button
                link
                type="primary"
                size="small"
                class="btn-delete"
                @click="
                  DeleteDialogVisible = true;
                  DeleteSelectedRow = scope.row.id;
                "
              >
                <el-icon>
                  <Delete />
                </el-icon>
              </el-button>
            </template>
          </el-table-column>
        </el-table>

        <div>
          <el-pagination
            small
            layout="prev, pager, next"
            :page-size="10"
            :total="dataCount"
            @current-change="loadPage"
          />
        </div>
      </el-col>
    </el-row>
    <el-row justify="end">
      <el-col :span="24">
        <el-button @click="goBack" type="primary" style="float: right">Back</el-button>
      </el-col>
    </el-row>
  </div>

  <el-dialog v-if="ΑddInterestVisible" v-model="ΑddInterestVisible" title="Add Interest Values">
    <Add_InterestRate 
      :interestCategory="interest_category"
      @close="ΑddInterestVisible = false"
      @save="onSave"
    />
  </el-dialog>
  <el-dialog v-if="EditInterestVisible" v-model="EditInterestVisible" title="Edit Interest Values">
    <Edit_InterestRate
      :interestRowID="interest_row_id"
      :interestCategory="interest_category"
      @close="EditInterestVisible = false"
      @save="onSaveEdit"
    />
  </el-dialog>

  <el-dialog v-model="DeleteDialogVisible" title="Client Delete" width="50%">
    <span>Are you sure you want to delete this Interest Rate?</span>
    <template #footer #default="scope">
      <span class="dialog-footer">
        <el-button @click="DeleteDialogVisible = false">{{$t('generic.cancel')}}</el-button>
        <el-button type="primary" @click="deleteHandleClick(DeleteSelectedRow)"
          >Delete
        </el-button>
      </span>
    </template>
  </el-dialog>
</template>

<style scoped>
.el-row {
  margin-bottom: 20px;
}

.el-row:last-child {
  margin-bottom: 0;
}

.el-col {
  border-radius: 4px;
}

.grid-content {
  border-radius: 4px;
  min-height: 36px;
}

.info {
  margin: auto;
  width: 50%;
  padding: 10px;
  text-align: center;
}

.demo-tabs > .el-tabs__content {
  padding: 32px;
  color: #6b778c;
  font-size: 32px;
  font-weight: 600;
}

.el-tabs--right .el-tabs__content,
.el-tabs--left .el-tabs__content {
  height: 100%;
}
</style>
