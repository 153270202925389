<script lang="ts" setup>
import { ref, watch, onMounted, nextTick, reactive } from "vue";
import type { FormInstance } from "element-plus";
import { useMainStore } from "../store/main";
import { usePGRESTAPIStore } from "../store/pgrest_api";
import PGRESTResult from "../classes/PGRESTResult";
import { ElMessage } from "element-plus";
import { Edit, Delete, Check, Close } from "@element-plus/icons-vue";
import LoanAccountDetails from "../components/ConfigLoanAccountDetails.vue";
import UserType from "../enums/UserType";
import { useGenericMethodsVariables } from "../composables/genericMethodsVariables";
const { showError } = useGenericMethodsVariables();
const klados = ref<any>([]);
const mainStore = useMainStore();
const pgrestapi = usePGRESTAPIStore();
const formRef = ref<FormInstance>();
const DeleteDialogVisible = ref(false);
let detailsVisible = ref(false);
let deleteVisible = ref(false);
let selectedRow = ref(0);
let DeleteSelectedRow = ref(0);
let dataList = ref([]);
let isLoading = ref(false);
let lastPage = ref(0);
let dataCount = ref(0);

const emits = defineEmits(["save", "close", "fail-save"]);

onMounted(async () => {
  loadPage(1);
});

const deleteHandleClick = (row_id: number) => {
  DeleteSelectedRow.value = row_id;
  deleteVisible.value = true;
  DeleteLoanAccountProduct(row_id);
};

const handleClick = (row_id: number) => {
  selectedRow.value = row_id;
  detailsVisible.value = true;
};

const DeleteLoanAccountProduct = async (row_id: number) => {
  let result: PGRESTResult = new PGRESTResult();

  let id = JSON.stringify(row_id);

  result = await pgrestapi.delete_data("config_loan_account_product?id=eq." + id);

  if (result.error) {
    showError(result.error.message);

    DeleteDialogVisible.value = false;
    return;
  }

  onDelete();
};

const onSave = async () => {
  selectedRow.value = -1;
  ElMessage({
    showClose: true,
    message: "Loan Account Product updated Successfully",
    type: "success",
    duration: 1000,
  });

  detailsVisible.value = false;
  loadPage(lastPage.value);
};

const onInsert = async () => {
  selectedRow.value = -1;
  ElMessage({
    showClose: true,
    message: "Loan Account Product saved Successfully",
    type: "success",
    duration: 1000,
  });

  detailsVisible.value = false;
  loadPage(lastPage.value);
};

const onDelete = async () => {
  ElMessage({
    showClose: true,
    message: "Loan Account Product Deleted Successfully",
    type: "success",
    duration: 1000,
  });

  DeleteDialogVisible.value = false;
  loadPage(lastPage.value);
};

const onFailSave = async () => {
  selectedRow.value = -1;
  ElMessage({
    showClose: true,
    message: "Loan Account Product failed to update",
    type: "error",
    duration: 2000,
  });

  detailsVisible.value = false;
};

const dynamicValidateForm = reactive<{
  LAproduct: AgentItem[];
}>({
  LAproduct: [],
});

interface AgentItem {
  product: string;
  greek_trans: string;
  english_trans: string;
  calculate_argies: boolean;
  omologiako: boolean;
  allhloxreo: boolean;
}

const removeLoanAccountProduct = (item: AgentItem) => {
  const index = dynamicValidateForm.LAproduct.indexOf(item);
  if (index !== -1) {
    dynamicValidateForm.LAproduct.splice(index, 1);
  }
};

const addLoanAccountProduct = () => {
  dynamicValidateForm.LAproduct.push({
    product: "",
    greek_trans: "",
    english_trans: "",
    calculate_argies: false,
    omologiako: false,
    allhloxreo: false
  });
};

const submitForm = (formEl: FormInstance | undefined) => {
  if (!formEl) return;
  formEl.validate((valid) => {
    if (valid) {
      console.log("submit!");
      save();
    } else {
      console.log("error submit!");
    }
  });
};

const save = async () => {
  let result: PGRESTResult = new PGRESTResult();

  result = await pgrestapi.post_data(
    "config_loan_account_product",
    dynamicValidateForm.LAproduct
  );

  if (result.error) {
    onFailSave();
    return;
  }
  dynamicValidateForm.LAproduct = [];
  onInsert();
  loadPage(1);
};

const loadPage = async (page: number) => {
  isLoading.value = true;
  if (page <= 0) {
    page = 1;
  }
  lastPage.value = page;
  page = page - 1;
  
  let result: PGRESTResult = await pgrestapi.getPaginated(
    "config_loan_account_product?order=product",
    page
  );

  if (result.error) {
    showError(result.error.message);

    return;
  }

  if (result.headers)
    dataCount.value = parseInt(result.headers["content-range"].split("/")[1]);

  dataList.value = result.data;

  isLoading.value = false;
};
</script>

<template>
  <div style="height: 100%" v-loading="isLoading">
    <el-row :gutter="10" style="margin: 20px">
      <el-col :span="24">
        <el-table :data="dataList" stripe border table-layout="fixed" size="small">
          <el-table-column fixed sortable prop="product" :label="$t('PlatformSettingsView.loan_agreement_product')"
            type="text" />
            <el-table-column fixed sortable prop="calculate_argies" :label="$t('PlatformSettingsView.calculateArgies')"
            type="text" >
            <template #default="scope">
              <el-icon v-if="scope.row.calculate_argies == true">
                  <Check class="argiesYES" />
                </el-icon>
                <el-icon v-if="scope.row.calculate_argies == false">
                  <Close class="argiesNO" />
                </el-icon>
              </template>
          </el-table-column>
            <el-table-column fixed sortable prop="omologiako" :label="$t('PlatformSettingsView.omologiako')"
            type="text" >
            <template #default="scope">
              <el-icon v-if="scope.row.omologiako == true">
                  <Check class="argiesYES" />
                </el-icon>
                <el-icon v-if="scope.row.omologiako == false">
                  <Close class="argiesNO" />
                </el-icon>
              </template>
          </el-table-column>
            <el-table-column fixed sortable prop="allhloxreo" :label="$t('PlatformSettingsView.allhloxreo')"
            type="text" >
            <template #default="scope">
              <el-icon v-if="scope.row.allhloxreo == true">
                  <Check class="argiesYES" />
                </el-icon>
                <el-icon v-if="scope.row.allhloxreo == false">
                  <Close class="argiesNO" />
                </el-icon>
              </template>
          </el-table-column>
          <el-table-column fixed="right" :label="$t('global.operations')">
            <template #default="scope">
              <el-button link type="primary" size="small" class="btn-detail" @click="handleClick(scope.row.id)">
                <el-icon>
                  <Edit />
                </el-icon>
              </el-button>
              <el-button v-if="mainStore.loggedUser.user_type === UserType.Administrator" link type="primary"
                size="small" class="btn-delete" @click="DeleteDialogVisible = true; DeleteSelectedRow = scope.row.id;" :icon="Delete" />
            </template>
          </el-table-column>
        </el-table>
        <div>
          <el-pagination small layout=" prev, pager, next" :page-size="10" :total="dataCount"
            @current-change="loadPage" />
        </div>

        <el-dialog v-model="DeleteDialogVisible" title="Loan Account Product Delete" width="50%">
          <span>Are you sure you want to delete this Loan Account Product ?</span>
          <template #footer #default="scope">
            <span class="dialog-footer">
              <el-button @click="DeleteDialogVisible = false">{{$t('generic.cancel')}}</el-button>
              <el-button type="primary" @click="deleteHandleClick(DeleteSelectedRow)">{{$t('generic.delete')}}
              </el-button>
            </span>
          </template>
        </el-dialog>

        <el-dialog v-model="detailsVisible" title="Loan Account Product Details" width="50%">
          <LoanAccountDetails :larowid="selectedRow" @save="onSave" @close="
            detailsVisible = false;
          selectedRow = -1;
          " @fail-save="onFailSave" />
        </el-dialog>

        <el-row :gutter="10">
          <el-col :span="24">
            <el-form ref="formRef" :model="dynamicValidateForm" label-width="320px">
              <el-row :gutter="10">
                <el-col :span="24">
                  <el-form-item v-for="(product, index) in dynamicValidateForm.LAproduct" :label="
                    $t('PlatformSettingsView.loan_account_product_details') +
                    ' ' +
                    index
                  " :prop="'Kad.' + index + '.value'"
                    style="border-bottom: 0px; margin-top: 30px; padding-bottom: 30px">
                    <el-col :span="8">
                      <el-input :placeholder="
                        $t('PlatformSettingsView.loan_account_product_details')
                      " v-model="product.product" autocomplete="off" style="border-bottom: 3px solid #e3e3e3" />
                    </el-col>
                    <el-col :span="8">
                      <el-input :placeholder="$t('PlatformSettingsView.la_greek_trans')" v-model="product.greek_trans"
                        autocomplete="off" style="border-bottom: 3px solid #e3e3e3" />
                    </el-col>
                    <el-col :span="8">
                      <el-input :placeholder="$t('PlatformSettingsView.la_english_trans')"
                        v-model="product.english_trans" autocomplete="off" style="border-bottom: 3px solid #e3e3e3" />
                    </el-col>
                    <el-col :span="6" style="margin-top: 0px">
                      <el-form-item style="margin-top: 0px">{{$t('PlatformSettingsView.calculate_argies')}} &nbsp;
                        <el-switch v-model="product.calculate_argies" :value="product.calculate_argies"
                          :placeholder="$t('PlatformSettingsView.calculate_argies')" style="margin-top: 0px">                         
                        </el-switch>
                      </el-form-item>
                    </el-col>
                    <el-col :span="6" style="margin-top: 0px">
                      <el-form-item style="margin-top: 0px">{{$t('PlatformSettingsView.omologiako')}} &nbsp;
                        <el-switch v-model="product.omologiako" :value="product.omologiako"
                          :placeholder="$t('PlatformSettingsView.automato_epitokio')" style="margin-top: 0px">                         
                        </el-switch>
                      </el-form-item>
                    </el-col>
                    <el-col :span="6" style="margin-top: 0px">
                      <el-form-item style="margin-top: 0px">{{$t('PlatformSettingsView.allhloxreo')}} &nbsp;
                        <el-switch v-model="product.allhloxreo" :value="product.allhloxreo"
                          :placeholder="$t('PlatformSettingsView.automato_epitokio')" style="margin-top: 0px">                         
                        </el-switch>
                      </el-form-item>
                    </el-col>
                    <el-col :span="6">
                      <el-button style="float: left" @click.prevent="removeLoanAccountProduct(product)">{{$t('generic.delete')}}
                      </el-button>
                    </el-col>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="10">
                <el-col :span="24">
                  <el-form-item style="border-bottom: 0px; float: right; margin-left: 0px; margin-top: 30px;">
                    <el-button v-if="dynamicValidateForm.LAproduct.length != 0" type="primary"
                      @click="submitForm(formRef)">
                      {{ $t('generic.save') }}
                    </el-button>
                    <el-button @click="addLoanAccountProduct">{{ $t("PlatformSettingsView.new_loan_account_product") }}
                    </el-button>
                    <!-- <el-button @click="resetForm(formRef)">Reset</el-button> -->
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
  </div>
</template>

<style scoped>
.el-row {
  margin-bottom: 20px;
}

.el-row:last-child {
  margin-bottom: 0;
}

.el-col {
  border-radius: 4px;
}

.grid-content {
  border-radius: 4px;
  min-height: 36px;
}

.info {
  margin: auto;
  width: 50%;
  padding: 10px;
  text-align: center;
}
.argiesYES {
color:rgb(0, 64, 0); 
}
.argiesNO {
color:rgb(225, 123, 123);
}
</style>
