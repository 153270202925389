<script lang="ts" setup>
import { ref, watch, onMounted } from "vue";
import { storeToRefs } from "pinia";
import { useRoute } from "vue-router";
import { useMainStore } from "../store/main";
import { usePGRESTAPIStore } from "../store/pgrest_api";
import PGRESTResult from "../classes/PGRESTResult";
import { ElMessage } from "element-plus";
import Portfolio from "../classes/DB_Entities/Portfolio";
import LoanAgreement from "../classes/DB_Entities/LoanAgreement";
import Debtor from "../classes/DB_Entities/Debtor";
import RegisterOfBond from "../classes/DB_Entities/RegisterOfBond";
import SummaryCheck from "../classes/DB_Entities/SummaryCheck";
import RestOfCollaterals from "../classes/DB_Entities/RestOfCollaterals";
import Decision from "../classes/Decision";
import ChangeApprovalStatus from "../enums/ChangeApprovalStatus";
import ChangesetHelper from "changeset-helper";
import EntityField from "../components/EntityField.vue";
import { DateTime } from "luxon";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import { useGenericMethodsVariables } from "../composables/genericMethodsVariables";

const { showError } = useGenericMethodsVariables();

const { t } = useI18n({ useScope: "global" });
const router = useRouter();
const route = useRoute();
const mainStore = useMainStore();
const pgrestapi = usePGRESTAPIStore();
let product_type = ref<any>();
const { globalLoanAgreementID } = storeToRefs(mainStore);
let SummaryCheckLoanAgreementID = ref<string>("");
let selectedLoanAgreementID = ref<string>("");
let isLoading = ref(false);
let Epitages = ref<string>("");
let Metoxes = ref<string>("");
let active_loan_account_id: number;
const product = ref<any>([]);
const LoanAccountProduct = ref<any>([]);
const currency = ref<any>([]);
const interest = ref<any>([]);
const emits = defineEmits(["save", "close", "fail-save"]);
let StoreSummaryCheckDebtor = ref<any>([]);
let StoreSummaryCheckLoanAgreement = ref(new LoanAgreement());
let StoreSummaryCheckLoanAccount = ref<any>([]);
let StoreSummaryCheckPortfolio = ref(new Portfolio());
let StoreSummaryCheckRegisterOfBond = ref(new RegisterOfBond());
let StoreSummaryCheckRegisterOfBondPartBanks = ref<any>([]);
let StoreSummaryCheckGreekStateGuarantees = ref<any>([]);
let StoreSummaryRobBonds = ref<any>([]);
let StoreSummaryCheckDebtorsGuarantees = ref<any>([]);
let StoreSummaryCheckRealEstateCollaterals = ref<any>([]);
let StoreSummaryCheckDebtorGuaranteesIndividual = ref(new Array<Debtor>()); //ref<any>([]);
let StoreSummaryCheckDebtorGuaranteesLegalEntity = ref(new Array<Debtor>()); //ref<any>([]);
let StoreSummaryCheckRestOfCollaterals = ref(new RestOfCollaterals());
let SaveSummaryCheck = ref(new SummaryCheck());
let fullObj = ref<any>({});
let originalObj = ref<any>({});

let has_listed_shares = ref<string>('');
let has_unlisted_shares = ref<string>('');
let has_deposits = ref<string>('');
let has_pledged_claims = ref<string>('');
let has_warehouse_receipt = ref<string>('');
let has_checks = ref<string>('');
let has_bill_of_exchange = ref<string>('');
let has_movable_collaterals = ref<string>('');

let isDenounced = ref<string>('');
let has_ekdosi_diatagis = ref<string>('');
let hmer_ekd_datag_pliromis = ref<string[]>([]);
let hmeromhnies_tropopoihshs = ref<string[]>([]);

const loadLoanAgreement = async (loan_agreement_id: string) => {
  if (loan_agreement_id === "") return;

  console.log(loan_agreement_id);
  isLoading.value = true;

  //empty object
  StoreSummaryCheckRegisterOfBondPartBanks.value = [];
  StoreSummaryCheckDebtorsGuarantees.value = [];
  StoreSummaryCheckDebtorGuaranteesIndividual.value = [];
  StoreSummaryCheckDebtorGuaranteesLegalEntity.value = [];

  let result: PGRESTResult = await pgrestapi.get(
    `loan_agreement?id=eq.` + loan_agreement_id
  );
  StoreSummaryCheckLoanAgreement.value = result.data[0];
  await loadInterest(StoreSummaryCheckLoanAgreement.value.kathgor_epitok_bashs);
  await loadDebtor(StoreSummaryCheckLoanAgreement.value.debtor_id);
  await loadActiveLoanAccount(loan_agreement_id);
  await loadRegisterOfBonds(loan_agreement_id);
  await loadGuarantees(loan_agreement_id);
  await loadGuaranteesIndividual(loan_agreement_id);
  await loadGuaranteesLegalEntity(loan_agreement_id);
  await loadProduct(StoreSummaryCheckLoanAgreement.value.product);

  if (StoreSummaryCheckLoanAgreement.value.currency !== undefined && StoreSummaryCheckLoanAgreement.value.currency !== null)
    await loadCurrency(StoreSummaryCheckLoanAgreement.value.currency);

  await loadRealEstateCollaterals(StoreSummaryCheckLoanAgreement.value.id as number);
  await loadGreekStateGuarantees(loan_agreement_id);
  await loadRestOfCollaterals(loan_agreement_id);
  await loadSummaryCheck(loan_agreement_id);

  isLoading.value = false;
  if (StoreSummaryCheckLoanAgreement.value.katast_sumbashs === 'denounced') { isDenounced.value = 'YES'; } else { isDenounced.value = 'NO'; }
  if (StoreSummaryCheckLoanAgreement.value.ekdosi_diatagis === true) { has_ekdosi_diatagis.value = 'YES'; } else { has_ekdosi_diatagis.value = 'NO'; }
  hmer_ekd_datag_pliromis.value = [];

  if (StoreSummaryCheckLoanAgreement.value.diatagi_pl_hmek_hmep_ex_per !== null) {
    for (let diatagi_pliromis of StoreSummaryCheckLoanAgreement.value.diatagi_pl_hmek_hmep_ex_per) {
      hmer_ekd_datag_pliromis.value.push((diatagi_pliromis as any).hmek);
    }
  }

  hmeromhnies_tropopoihshs.value = [];

  if (StoreSummaryCheckLoanAgreement.value.hmer_tropop_sumbashs !== null) {
    for (let hmeromhnia_trop of StoreSummaryCheckLoanAgreement.value.hmer_tropop_sumbashs) {
      hmeromhnies_tropopoihshs.value.push((hmeromhnia_trop as any).hmer_tropop);
    }
  }
};

const loadSummaryCheck = async (loan_agreement_id: string) => {
  let result: PGRESTResult = await pgrestapi.get(
    `summary_check_xtra?loan_agreement_id=eq.` + loan_agreement_id
  );

  if (result.error) {
    showError(result.error.message);

    return;
  }

  originalObj.value = JSON.parse(JSON.stringify(result.data[0]));
  fullObj.value = JSON.parse(JSON.stringify(result.data[0]));

  // Θα συμπληρώνεται τιμή ΝΑΙ αν στο φύλλο Loan Aggreement υπάρχει διαφορετική τιμή στα πεδία Αρχική ημερομηνία λήξης και Νέα ημερομηνία λήξης* Το πεδίο θα πρέπει να μπορεί να τροποποιείται και από τον χρήστη. 
  // if (StoreSummaryCheckLoanAccount.value.arxikh_hmer_lukshs !== StoreSummaryCheckLoanAccount.value.nea_hmer_lukshs) {
  //   fullObj.value.paratasi_diarkeias = 'YES'
  // } else {
  //   fullObj.value.paratasi_diarkeias = 'NO'
  // }

  // if (StoreSummaryCheckLoanAccount.value.hmer_ektam !== undefined) {
  //   hmer_ektam.value = DateTime.fromFormat((StoreSummaryCheckLoanAccount.value.hmer_ektam as string), 'yyyy-MM-dd').toString();

  //   originalObj.value.hmer_ektam = hmer_ektam.value;
  //   fullObj.value.hmer_ektam = hmer_ektam.value;
  // }

  await loadPendingChanges(result.data[0].id);
  SaveSummaryCheck.value = result.data[0];
};

const loadPendingChanges = async (summary_id: number) => {
  let result: PGRESTResult = new PGRESTResult();
  let lacresult: PGRESTResult = new PGRESTResult();
  result = await pgrestapi.get(
    `entity_summary_check_xtra_changes?entity_id=eq.${summary_id}&approval_status=eq.0&select=id,field,value,insert_timestamp,editor:users!fk_user_editor(username)&order=id`
  );

  for (let field of Object.keys(fullObj.value)) {
    fullObj.value[field] = { field, originalValue: fullObj.value[field] };
  }
  // console.log(JSON.stringify(fullObj.value));
  for (let pending of result.data) {
    pending["originalValue"] = originalObj.value[pending.field];
    fullObj.value[pending.field].pendingValue = pending;
  }

  if (active_loan_account_id !== undefined) {

    lacresult = await pgrestapi.get(
      `entity_loan_accounts_changes?entity_id=eq.${active_loan_account_id}&approval_status=eq.0&field=in.("hmer_ektam")&select=id,field,value,insert_timestamp,editor:users!fk_user_editor(username)&order=id`
    );

    for (let pending of lacresult.data) {
      pending["originalValue"] = originalObj.value[pending.field];
      fullObj.value[pending.field].pendingValue = pending;
    }
  }
};

const loadDebtor = async (debtor_id: number) => {
  let result: PGRESTResult = await pgrestapi.get(`debtor?select=*,config_klados(*),config_kad(*),config_prefecture!debtor_fk_7(*),config_postalcode!debtor_fk_8(*),config_country!debtor_fk_9(*),postcode(*),district(*),country(*)&approve_status=eq.1&id=eq.` + debtor_id);

  if (result.data[0]) {
    StoreSummaryCheckDebtor.value = result.data[0];
  } else {
    ElMessage({
      showClose: true,
      message: 'Ο οφειλέτης που συνδέετε με αυτό το Summary check έχει κατάσταση έγκρισης σε εκκρεμότητα.',
      type: "error",
      duration: 0
    });
  }

  await loadPortfolio(parseInt(StoreSummaryCheckDebtor.value.portfolio as string));
};

const loadGuaranteesIndividual = async (loan_agreement_id: string) => {
  StoreSummaryCheckDebtorGuaranteesIndividual.value = [];
  let result: PGRESTResult = await pgrestapi.get(
    `guarantees?approve_status=eq.1&loan_agreement_id=eq.${loan_agreement_id}`
  );

  for (let i = 0; i < result.data.length; i++) {
    let result2: PGRESTResult = await pgrestapi.get(
      `debtor?approve_status=eq.1&customer_type=eq.individual&cid=eq.${result.data[i].debtor_cid}`
    );

    if (result2.data[0] === undefined) {
      continue;
    } else {
      StoreSummaryCheckDebtorGuaranteesIndividual.value.push(result2.data[0]);
    }
  }
};

const loadGuaranteesLegalEntity = async (loan_agreement_id: string) => {
  StoreSummaryCheckDebtorGuaranteesLegalEntity.value = [];
  let result: PGRESTResult = await pgrestapi.get(
    `guarantees?approve_status=eq.1&loan_agreement_id=eq.${loan_agreement_id}`
  );

  for (let i = 0; i < result.data.length; i++) {
    let result2: PGRESTResult = await pgrestapi.get(
      `debtor?approve_status=eq.1&customer_type=eq.legal_entity&cid=eq.${result.data[i].debtor_cid}`
    );

    if (result2.data[0] === undefined) {
      continue;
    } else {
      StoreSummaryCheckDebtorGuaranteesLegalEntity.value.push(result2.data[0]);
    }
  }
};

const loadPortfolio = async (portfolio_id: number | undefined) => {
  let result: PGRESTResult = await pgrestapi.get(`portfolio?id=eq.` + portfolio_id);
  StoreSummaryCheckPortfolio.value = result.data[0];
};

const loadRealEstateCollaterals = async (loan_agreement_id: number) => {
  let result: PGRESTResult = await pgrestapi.get(
    `re_collaterals?select=*,real_estate!inner(*)&approve_status=eq.1&real_estate.approve_status=eq.1&loan_agreement_id=eq.` +
    loan_agreement_id
  );
  StoreSummaryCheckRealEstateCollaterals.value = result.data;
};

const loadRestOfCollaterals = async (loan_agreement_id: string) => {
  let result: PGRESTResult = await pgrestapi.get(
    `rest_of_collaterals?approve_status=eq.1&loan_agreement_id=eq.` + loan_agreement_id
  );


  /*
  
  has_listed_shares
  has_unlisted_shares
  has_deposits
  has_pledged_claims
  has_warehouse_receipt
  has_checks
  has_bill_of_exchange
  has_movable_collaterals
  */

  if (result.error) {
    showError(result.error.message);
    return;
  }



  if (result.data.length !== 0) {
    StoreSummaryCheckRestOfCollaterals.value = result.data[0];

    for (let i = 0; i < result.data.length; i++) {
      if (result.data[i].e_sumbatika_eggrafa || result.data[i].s_ypog_sumbash) {
        Epitages.value = t('components.switch.yes');
      } else {
        Epitages.value = t('components.switch.no');
      }
      if (result.data[i].em_sumbash_enexurou || result.data[i].mem_sumbash_enexurou) {
        Metoxes.value = t('components.switch.yes');
      } else {
        Metoxes.value = t('components.switch.no');
      }

    }

  }


  for (let rest of result.data) {
    if (rest.type == 'listed_shares') { has_listed_shares.value = 'YES'; }
    if (rest.type === 'unlisted_shares') { has_unlisted_shares.value = 'YES'; }
    if (rest.type === 'deposits') { has_deposits.value = 'YES'; }
    if (rest.type === 'pledged_claims') { has_pledged_claims.value = 'YES'; }
    if (rest.type === 'warehouse_receipt') { has_warehouse_receipt.value = 'YES'; }
    if (rest.type === 'checks') { has_checks.value = 'YES'; }
    if (rest.type === 'bill_of_exchange') { has_bill_of_exchange.value = 'YES'; }
    if (rest.type === 'movable_collaterals') { has_movable_collaterals.value = 'YES'; }
  }
  if (has_listed_shares.value !== 'YES') { has_listed_shares.value = 'NO'; }
  if (has_unlisted_shares.value !== 'YES') { has_unlisted_shares.value = 'NO'; }
  if (has_deposits.value !== 'YES') { has_deposits.value = 'NO'; }
  if (has_pledged_claims.value !== 'YES') { has_pledged_claims.value = 'NO'; }
  if (has_warehouse_receipt.value !== 'YES') { has_warehouse_receipt.value = 'NO'; }
  if (has_checks.value !== 'YES') { has_checks.value = 'NO'; }
  if (has_bill_of_exchange.value !== 'YES') { has_bill_of_exchange.value = 'NO'; }
  if (has_movable_collaterals.value !== 'YES') { has_movable_collaterals.value = 'NO'; }
  // console.log('has_listed_shares ' + has_listed_shares.value);
};



const loadGreekStateGuarantees = async (loan_agreement_id: string) => {
  let result: PGRESTResult = await pgrestapi.get(
    `greek_state_guarantees?approve_status=eq.1&loan_agreement_id=eq.${loan_agreement_id}`
  );

  if (result.error) {
    showError(result.error.message);
    return;
  }

  if (result.data.length === 0) return;

  StoreSummaryCheckGreekStateGuarantees.value = result.data;
};

const loadRegisterOfBonds = async (loan_agreement_id: string) => {
  let result: PGRESTResult = await pgrestapi.get(
    `register_of_bonds?approve_status=eq.1&loan_agreement_id=eq.${loan_agreement_id}`
  );

  if (result.error) {
    showError(result.error.message);
    return;
  }

  if (result.data.length === 0) return;

  StoreSummaryCheckRegisterOfBond.value = result.data[0];
  await loadRegisterOfBondsPartBanks(StoreSummaryCheckRegisterOfBond.value.id as number);
  await loadBondsPartBanks(
    loan_agreement_id
  );
};

const loadRegisterOfBondsPartBanks = async (register_of_bond_id: number) => {
  let result: PGRESTResult = await pgrestapi.get(
    `register_of_bonds?select=id,rob_participating_banks!inner(*)&approve_status=eq.1&rob_participating_banks.register_of_bond_id=eq.` +
    register_of_bond_id
  );

  if (result.error) {
    showError(result.error.message);
    return;
  }

  if (result.data.length === 0) return;

  StoreSummaryCheckRegisterOfBondPartBanks.value = result.data[0];
};

const loadBondsPartBanks = async (loan_agreement_id: string) => {
  let result: PGRESTResult = await pgrestapi.get(
    `view_mosbonds_view1?loan_agreement_id=eq.` + loan_agreement_id
  );

  // console.log(`view_mosbonds_view1?loan_agreement_id=eq.` + loan_agreement_id)

  if (result.error) {
    showError(result.error.message);
    return;
  }

  if (result.data.length === 0) return;

  StoreSummaryRobBonds.value = result.data;
};

const loadActiveLoanAccount = async (loan_agreement_id: string) => {
  let result: PGRESTResult = await pgrestapi.get(
    `loan_accounts?select=*,config_loan_account_product(*),config_interest(*)&loan_agreement_id=eq.${loan_agreement_id}&status=eq.active`
  );
  StoreSummaryCheckLoanAccount.value = result.data;
};

watch(
  () => selectedLoanAgreementID.value,
  async (newVal) => {
    console.log("selected loan agreement changed to " + newVal);
  }
);

let saveData = async () => {
  isLoading.value = true;
  await save();
  // isLoading.value = false;
};

const loadGuarantees = async (loan_agreement_id: string) => {
  let result: PGRESTResult = await pgrestapi.get(
    `guarantees?approve_status=eq.1&loan_agreement_id=eq.${loan_agreement_id}`
  );

  for (let i = 0; i < result.data.length; i++) {
    let result2: PGRESTResult = await pgrestapi.get(
      `debtor?select=*,config_kad(*),config_prefecture!debtor_fk_7(*),config_postalcode!debtor_fk_8(*),config_country!debtor_fk_9(*),postcode(*),country(*),district(*)&approve_status=eq.1&cid=eq.${result.data[i].debtor_cid}`
    );
    if (result2.data.length > 0) {
      // console.log(JSON.stringify(result2.data[0]))
      StoreSummaryCheckDebtorsGuarantees.value.push(result2.data[0]);
    }
  }
};

const loadProduct = async (product_id: number) => {
  let result: PGRESTResult = new PGRESTResult();

  result = await pgrestapi.get("config_product?order=product&id=eq." + product_id);
  product.value = result.data[0];
};

const loadInterest = async (interest_id: number) => {
  let result: PGRESTResult = new PGRESTResult();

  if (interest_id === null) return { interest: '' };

  result = await pgrestapi.get("config_interest?id=eq." + interest_id);
  interest.value = result.data[0];
};

const LoadLoanAccountProduct = async (LoanAccountproduct_id: number) => {
  let result: PGRESTResult = new PGRESTResult();

  result = await pgrestapi.get(
    "config_loan_account_product?order=product&id=eq." + LoanAccountproduct_id
  );
  LoanAccountProduct.value = result.data[0];
};

const loadCurrency = async (currency_id: number) => {
  let result: PGRESTResult = new PGRESTResult();

  result = await pgrestapi.get("config_currency?id=eq." + currency_id);
  currency.value = result.data[0];
};

watch(globalLoanAgreementID, async () => {
  if (mainStore.loggedUser.username === '') return;

  if (globalLoanAgreementID.value !== "") {

    let result: PGRESTResult = await pgrestapi.get(
      "view_summary_check_xtra_view1?select=product_type&loan_agreement_id=eq." +
      globalLoanAgreementID.value
    );

    if (result) {
      product_type.value = result.data[0].product_type;
    }

    if (product_type.value == "summary_check_KOD") {
      router.push({
        name: "Summary Check KOD",
        params: { loan_agreement_id: globalLoanAgreementID.value },
      });
    } else if (product_type.value == "summary_check_OD") {
      router.push({
        name: "Summary Check OD",
        params: { loan_agreement_id: globalLoanAgreementID.value },
      });
    } else if (product_type.value == "summary_check_another_loan") {
      router.push({
        name: "Summary Check AL",
        params: { loan_agreement_id: globalLoanAgreementID.value },
      });
    }

    await loadLoanAgreement(globalLoanAgreementID.value.toString());
  }
  selectedLoanAgreementID.value = globalLoanAgreementID.value;
});

// route.params.loan_agreement_id --> will be always set
onMounted(async () => {
  if (route.params.loan_agreement_id && globalLoanAgreementID.value.toString()) {
    SummaryCheckLoanAgreementID.value = route.params.loan_agreement_id as string;
    await loadLoanAgreement(route.params.loan_agreement_id as string);
  }
  if (route.params.loan_agreement_id && !globalLoanAgreementID.value.toString()) {
    SummaryCheckLoanAgreementID.value = route.params.loan_agreement_id as string;
    await loadLoanAgreement(route.params.loan_agreement_id as string);
    return;
  } else if (!route.params.loan_agreement_id && globalLoanAgreementID.value.toString()) {
    SummaryCheckLoanAgreementID.value = route.params.loan_agreement_id as string;
    await loadLoanAgreement(globalLoanAgreementID.value.toString());
  }
});

const loadData = async () => {
  console.log('reload data')
  await loadLoanAgreement(route.params.loan_agreement_id as string);
}

const save = async () => {
  let result: PGRESTResult = new PGRESTResult();
  let obj: any = {};

  if (mainStore.loggedUser.user_type === 2) {

    await saveSummaryCheckChanges();
    //globalLoanAgreementID.value = "";
    emits("save");
    await loadData();
    return;
  }

  let newFull: any = {};
  for (let field of Object.keys(fullObj.value)) {
    if (field !== 'hmer_ektam') {
      newFull[field] = (fullObj as any).value[field].originalValue;
    }
    // else if (field === 'hmer_ektam') {
    //   obj[field] = (fullObj as any).value[field].originalValue;
    //   result = await pgrestapi.patch(
    //     "loan_accounts?id=eq." + active_loan_account_id,
    //     obj
    //   );
    //   await loadData();
    // }
  }

  result = await pgrestapi.patch(
    "summary_check_xtra?loan_agreement_id=eq." + SummaryCheckLoanAgreementID.value,
    newFull
  );

  //globalLoanAgreementID.value = "";
  emits("save");
  await loadData();
};

const saveSummaryCheckChanges = async () => {
  let result: PGRESTResult = new PGRESTResult();
  let timestamp: string = await (await pgrestapi.get("rpc/customnew")).data;

  let newFull: any = {};
  for (let field of Object.keys(fullObj.value)) {
    if ((originalObj as any).value[field] === null) {
      (originalObj as any).value[field] = '';

    }
    if ((fullObj as any).value[field].originalValue === null) {
      (fullObj as any).value[field].originalValue = '';
    }
    newFull[field] = fullObj.value[field].originalValue;

  }

  // console.log(JSON.stringify(originalObj.value));
  const report = ChangesetHelper.compare(originalObj.value, newFull);
  for (let changedField of report.changes) {
    if (changedField !== 'hmer_ektam') {
      if (fullObj.value[changedField].pendingValue === null || fullObj.value[changedField].pendingValue === undefined) {
        result = await pgrestapi.post_data("entity_summary_check_xtra_changes", {
          entity_id: originalObj.value.id,
          editor_user_id: mainStore.loggedUser.id,
          field: changedField,
          value: newFull[changedField],
        });
      }
      else {
        result = await pgrestapi.patch("entity_summary_check_xtra_changes?id=eq." + fullObj.value[changedField].pendingValue.id, {
          entity_id: originalObj.value.id,
          editor_user_id: mainStore.loggedUser.id,
          field: changedField,
          value: newFull[changedField],
          decision_timestamp: timestamp,
        });
      }
    }
    // else if (changedField === 'hmer_ektam') {
    //   if (fullObj.value[changedField].pendingValue === null || fullObj.value[changedField].pendingValue === undefined) {
    //     result = await pgrestapi.post_data("entity_loan_accounts_changes", {
    //       entity_id: active_loan_account_id,
    //       editor_user_id: mainStore.loggedUser.id,
    //       field: changedField,
    //       value: newFull[changedField],
    //     });
    //   }
    //   else {
    //     // console.log(fullObj.value[changedField].pendingValue.id)
    //     result = await pgrestapi.patch("entity_loan_accounts_changes?id=eq." + fullObj.value[changedField].pendingValue.id, {
    //       entity_id: active_loan_account_id,
    //       editor_user_id: mainStore.loggedUser.id,
    //       field: changedField,
    //       value: newFull[changedField],
    //       decision_timestamp: timestamp,
    //     });
    //   }
    // }

  }

};

const goBack = async () => {
  router.push({
    name: "Summary Check"
  });
}

const onDecision = async (decision: Decision) => {
  let result: PGRESTResult = new PGRESTResult();

  let timestamp: string = await (await pgrestapi.get("rpc/customnew")).data;
  let obj: any = {};
  let field = decision.field as string;
  obj[field] = decision.value;
  // console.log('field:' + field);
  // console.log(obj[field]);
  if (field !== 'hmer_ektam') {
    //if ((field !== 'hmer_ektam') && (field !=='kapoio_allo_pedio')) {
    if (decision.accepted) {
      result = await pgrestapi.patch(
        "entity_summary_check_xtra_changes?id=eq." + decision.db_change_id,
        {
          approval_status: ChangeApprovalStatus.Approved,
          approver_user_id: mainStore.loggedUser.id,
          decision_timestamp: timestamp,
        }
      );
      result = await pgrestapi.patch(
        "summary_check_xtra?id=eq." + originalObj.value.id,
        obj
      );
      loadData();
    } else {
      result = await pgrestapi.patch(
        "entity_summary_check_xtra_changes?id=eq." + decision.db_change_id,
        {
          approval_status: ChangeApprovalStatus.Rejected,
          approver_user_id: mainStore.loggedUser.id,
          decision_timestamp: timestamp,
        }
      );
    }
  }
  else if (field === 'hmer_ektam') {
    //LOAN ACCOUNT hmer_ektam
    if (decision.accepted) {
      result = await pgrestapi.patch(
        "entity_loan_accounts_changes?id=eq." + decision.db_change_id,
        {
          approval_status: ChangeApprovalStatus.Approved,
          approver_user_id: mainStore.loggedUser.id,
          decision_timestamp: timestamp,
        }
      );
      result = await pgrestapi.patch(
        "loan_accounts?id=eq." + active_loan_account_id,
        obj
      );
      loadData();
    }
    else {
      result = await pgrestapi.patch(
        "entity_loan_accounts_changes?id=eq." + decision.db_change_id,
        {
          approval_status: ChangeApprovalStatus.Rejected,
          approver_user_id: mainStore.loggedUser.id,
          decision_timestamp: timestamp,
        }
      );
    }
  }
  loadSummaryCheck(globalLoanAgreementID.value.toString());
};

const hasDebtorContactDetails = (): boolean => {
  if (StoreSummaryCheckDebtor.value.address !== null || StoreSummaryCheckDebtor.value.address_number !== null
  || StoreSummaryCheckDebtor.value.region !== null || StoreSummaryCheckDebtor.value.city !== null
  || StoreSummaryCheckDebtor.value.district !== null || StoreSummaryCheckDebtor.value.config_postalcode !== null
  || StoreSummaryCheckDebtor.value.country !== null)
    return true;
  return false;
};
</script>

<template>
  <div class="content-wrapper">
    <div>
      <el-row :gutter="10">
        <el-col :span="20">
          <h3 class="heading-page">Summary Check</h3>
        </el-col>

        <el-col :span="2">
          <el-button @click="goBack()" type="primary" style="float: right">Back</el-button>
        </el-col>

        <el-col :span="2">
          <el-button type="primary" @click="saveData()">{{ $t('generic.save') }}</el-button>
        </el-col>

      </el-row>
    </div>
  </div>

  <div v-loading="isLoading" class="content-wrapper summary-check-wrapper" style="margin-top: 20px; height: 100%">

    <el-form v-if="SummaryCheckLoanAgreementID" :model="SaveSummaryCheck">
      <div>
        <div class="editable">
          <EntityField type="datepicker" label="Summary Check Date" :object="fullObj.summary_check_date"
            @decision="onDecision" style="padding: 8px; font-weight: bold;" />
        </div>

        <table style="width: 100%" class="basic-elements-table">
          <tr>
            <th colspan="2" class="table-heading">
              <p>{{ t('SummaryChecks.basic_data') }}</p>
            </th>
          </tr>
          <tr>
            <th>{{ t('SummaryChecks.portfolio') }}</th>
            <td>
              {{ StoreSummaryCheckPortfolio?.portf_name }}
            </td>
          </tr>
          <tr>
            <th>{{ t('PlatformSettingsView.group_name') }}</th>
            <td>
              {{ StoreSummaryCheckDebtor?.group_name }}
            </td>
          </tr>
          <tr>
            <th>{{ t('SummaryChecks.cid') }}</th>
            <td>
              {{ StoreSummaryCheckDebtor?.cid }}
            </td>
          </tr>
          <tr>
            <th>{{ t('SummaryChecks.name') }}</th>
            <td>
              {{ StoreSummaryCheckDebtor?.fullname }}
            </td>
          </tr>
          <tr>
            <th>{{ t('SummaryChecks.afm') }}</th>
            <td>
              {{ StoreSummaryCheckDebtor?.vat_number }}
            </td>
          </tr>
          <tr>
            <th>{{ t('SummaryChecks.gemi') }}</th>
            <td v-if="StoreSummaryCheckDebtor?.gemh != -1">
              {{ StoreSummaryCheckDebtor?.gemh }}
            </td>
            <td v-else>
            </td>
          </tr>
          <tr>
            <th>{{ t('SummaryChecks.klados') }}</th>
            <td>
              {{ StoreSummaryCheckDebtor?.config_klados?.perigrafi }}
            </td>
          </tr>
          <tr>
            <th>{{ t('SummaryChecks.lixi_nom') }}</th>
            <td v-if="StoreSummaryCheckDebtor?.hmer_liks_isx_nomim">
              {{ StoreSummaryCheckDebtor?.hmer_liks_isx_nomim }}
              {{ DateTime.fromFormat((StoreSummaryCheckDebtor?.hmer_liks_isx_nomim as string),
                'yyyy-MM-dd').toFormat("dd/MM/yyyy") }}
            </td>
            <td v-else>
            </td>
          </tr>
          <tr>
            <th>{{ t('SummaryChecks.phone') }}</th>
            <td>
              {{ StoreSummaryCheckDebtor?.phone_number }}
            </td>
          </tr>
          <tr>
            <th>{{ t('SummaryChecks.email') }}</th>
            <td>
              {{ StoreSummaryCheckDebtor?.email }}
            </td>
          </tr>
          <tr>
            <th>{{ t('SummaryChecks.postal_street') }}</th>
            <td>
              {{ hasDebtorContactDetails() ? StoreSummaryCheckDebtor?.address : StoreSummaryCheckDebtor?.postal_address }}
            </td>
          </tr>
          <tr>
            <th>{{ t('SummaryChecks.postal_nbr') }}</th>
            <td>
              {{ hasDebtorContactDetails() ? StoreSummaryCheckDebtor?.address_number : StoreSummaryCheckDebtor?.postal_address_number }}
            </td>
          </tr>
          <tr>
            <th>{{ t('SummaryChecks.postal_region') }}</th>
            <td>
              {{ hasDebtorContactDetails() ? StoreSummaryCheckDebtor?.region : StoreSummaryCheckDebtor?.postal_region }}
            </td>
          </tr>
          <tr>
            <th>{{ t('SummaryChecks.postal_city') }}</th>
            <td>
              {{ hasDebtorContactDetails() ? StoreSummaryCheckDebtor.city : StoreSummaryCheckDebtor?.postal_city }}
            </td>
          </tr>
          <tr>
            <th>{{ t('SummaryChecks.postal_zip_code') }}</th>
            <td>
              {{ hasDebtorContactDetails() ? StoreSummaryCheckDebtor?.postcode?.postalcode : StoreSummaryCheckDebtor?.config_postalcode?.postalcode }}
            </td>
          </tr>
          <tr>
            <th>{{ t('SummaryChecks.postal_prefecture') }}</th>
            <td>
              {{ hasDebtorContactDetails() ? StoreSummaryCheckDebtor?.district?.prefecture : StoreSummaryCheckDebtor?.config_prefecture?.prefecture }}
            </td>
          </tr>
          <tr>
            <th>{{ t('SummaryChecks.country') }}</th>
            <td>
              {{ hasDebtorContactDetails() ? StoreSummaryCheckDebtor?.country?.country : StoreSummaryCheckDebtor?.config_country?.country }}
            </td>
          </tr>
          <tr>
            <th>{{ t('SummaryChecks.account_manager') }}</th>
            <td>
              {{ StoreSummaryCheckDebtor?.mos_clients_acc_off }}
            </td>
          </tr>

          <tr></tr>
        </table>

        <div class="responsive-table">
          <table style="width: 100%; margin-top: 20px" class="basic-note-table">
            <tr>
              <th colspan="2" class="table-heading">
                <p>{{ t('SummaryChecks.basic_check_note') }}</p>
              </th>
            </tr>
            <tr>
              <th colspan="2" class="sub-heading-table">
                <p>{{ t('SummaryChecks.details_of_contract') }}</p>
              </th>
            </tr>
            <tr>
              <th style="width: 20%;">{{ t('SummaryChecks.type_of_financing') }}</th>
              <td>
                {{ product?.product }}
              </td>
            </tr>
            <tr>
              <th>{{ t('SummaryChecks.loan_agreement') }}</th>
              <td>
                {{ StoreSummaryCheckLoanAgreement?.ar_sumbashs }}
              </td>
            </tr>
            <tr>
              <th>{{ t('SummaryChecks.guarantees') }}</th>
              <td>
                <table style="width: 100%" class="inner-table-basic-note">
                  <tr>
                    <td>
                      <h6>{{ t('SummaryChecks.onomateponimo') }}</h6>
                    </td>
                    <td>
                      <h6>{{ t('SummaryChecks.phone') }}</h6>
                    </td>
                    <td>
                      <h6>{{ t('SummaryChecks.email') }}</h6>
                    </td>
                    <td>
                      <h6>{{ t('SummaryChecks.street') }}</h6>
                    </td>
                    <td>
                      <h6>{{ t('SummaryChecks.street_number') }}</h6>
                    </td>
                    <td>
                      <h6>{{ t('SummaryChecks.region') }}</h6>
                    </td>
                    <td>
                      <h6>{{ t('SummaryChecks.postal_zip_code') }}</h6>
                    </td>
                    <td>
                      <h6>{{ t('SummaryChecks.city') }}</h6>
                    </td>
                    <td>
                      <h6>{{ t('SummaryChecks.prefecture') }} </h6>
                    </td>
                    <td>
                      <h6>{{ t('SummaryChecks.country') }}</h6>
                    </td>
                  </tr>
                  <tr v-if="StoreSummaryCheckDebtorsGuarantees.length > 0"
                    v-for="guarantees in StoreSummaryCheckDebtorsGuarantees">
                    <td>
                      <span class="inner-table-content">{{ guarantees?.fullname }}</span>
                    </td>
                    <td>
                      <span class="inner-table-content">{{
                        guarantees?.phone_number
                      }}</span>
                    </td>
                    <td>
                      <span class="inner-table-content">{{ guarantees?.email }}</span>
                    </td>
                    <td>
                      <span class="inner-table-content">{{
                        guarantees?.postal_address !== null
                        ? guarantees?.postal_address
                        : guarantees?.address
                      }}</span>
                    </td>
                    <td>
                      <span class="inner-table-content">{{
                        guarantees?.postal_address_number !== null
                        ? guarantees?.postal_address_number
                        : guarantees?.address_number
                      }}</span>
                    </td>
                    <td>
                      <span class="inner-table-content">{{
                        guarantees?.postal_region !== null
                        ? guarantees?.postal_region
                        : guarantees?.region
                      }}</span>
                    </td>
                    <td>
                      <span class="inner-table-content">{{
                        guarantees?.postal_city !== null
                        ? guarantees?.postal_city
                        : guarantees?.city
                      }}</span>
                    </td>
                    <td>
                      <span class="inner-table-content">{{
                        guarantees?.config_postalcode !== null
                        ? guarantees?.config_postalcode?.postalcode
                        : guarantees?.postcode.postalcode
                      }}</span>
                    </td>
                    <td>
                      <span class="inner-table-content">{{
                        guarantees?.config_prefecture !== null
                        ? guarantees?.config_prefecture?.prefecture
                        : guarantees?.district?.prefecture
                      }}</span>
                    </td>
                    <td>
                      <span class="inner-table-content">{{ guarantees?.config_country !== null ?
                        guarantees?.config_country?.country : guarantees?.country?.country }}</span>
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
            <tr>
              <th>{{ t('SummaryChecks.payment_representative') }}</th>
              <td>{{ StoreSummaryCheckRegisterOfBond?.payment_representative }}</td>
            </tr>
            <tr>
              <th>{{ t('SummaryChecks.replacement_mechanism') }}</th>
              <td>{{ StoreSummaryCheckRegisterOfBond?.replacement_mechanism }}</td>
            </tr>
            <tr>
              <th>{{ t('SummaryChecks.summet_trapezes') }}</th>
              <td>
                <table style="width: 100%" class="inner-table-basic-note">
                  <tr>
                    <td>
                      <h6>{{ t('SummaryChecks.bank') }}</h6>
                    </td>
                    <td>
                      <h6>{{ t('SummaryChecks.pososto') }}</h6>
                    </td>
                    <td>
                      <h6>{{ t('SummaryChecks.contact_details') }}</h6>
                    </td>
                  </tr>
                  <tr v-for="bank in StoreSummaryCheckRegisterOfBondPartBanks?.rob_participating_banks">
                    <td>
                      <span class="inner-table-content">
                        {{ bank?.bank_name }}
                      </span>
                    </td>
                    <td>
                      <span class="inner-table-content">
                        {{ bank?.participation_percent }}
                      </span>
                    </td>
                    <td>
                      <span class="inner-table-content">
                        {{ bank?.contact_details }}
                      </span>
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
          </table>
          <table style="width: 100%;">
            <tr>
              <th colspan="2" class="table-heading">
                <p>{{ t('SummaryChecks.orth_plirot_ypografon') }}</p>
              </th>
            </tr>
          </table>

          <table style="width: 100%; margin-bottom: 0px; padding: 0px" class="inner-table-basic-note editable">
            <tr>
              <td>
                <EntityField type="datepicker" :label="t('global.date')" :object="fullObj.plhr_sumb_hmerom"
                  @decision="onDecision" style="padding: 8px; font-weight: bold;" />
              </td>
            </tr>
            <tr>
              <td>
                <EntityField type="input" :label="t('SummaryChecks.bank_representative')"
                  :object="fullObj.plhr_sumb_ekpr_trapezas" @decision="onDecision"
                  style="padding: 8px; font-weight: bold;" />
              </td>
            </tr>
            <tr>
              <td>
                <EntityField type="input" :label="t('SummaryChecks.creditor_signatures')"
                  :object="fullObj.plhr_sumb_ypogr_pistouxou" @decision="onDecision"
                  style="padding: 8px; font-weight: bold;" />
              </td>
            </tr>
            <tr>
              <td>
                <EntityField type="input" :label="t('SummaryChecks.signatures_of_guarantors')"
                  :object="fullObj.plhr_sumb_ypogr_egg" @decision="onDecision" style="padding: 8px; font-weight: bold;" />
              </td>
            </tr>

          </table>


          <table style="width: 100%" class="inner-table-basic-note">
            <tr>
              <th style="width: 20%;">{{ t('SummaryChecks.total_amount') }}</th>
              <td>
                {{ StoreSummaryCheckLoanAgreement?.synol_poso_sumbashs
                }}<span v-if="StoreSummaryCheckLoanAgreement?.synol_poso_sumbashs">{{ currency.symbol }}</span>
              </td>
            </tr>

            <tr>
              <th>{{ t('SummaryChecks.skopos_ekdosis') }}</th>
              <td>
                <el-row class="display_table editable">
                  <el-col :span="20">
                    <EntityField type="input" label="" :object="fullObj.skopos_ekdosis" @decision="onDecision" />
                  </el-col>
                </el-row>
              </td>
            </tr>

            <tr>
              <th>{{ t('LoanAgreementDetails.date_of_agreement') }}</th>
              <td v-if="StoreSummaryCheckLoanAgreement?.hmer_sumbashs">
                {{ DateTime.fromFormat((StoreSummaryCheckLoanAgreement?.hmer_sumbashs),
                  'yyyy-MM-dd').toFormat("dd/MM/yyyy") }}
              </td>
            </tr>

            <tr>
              <th>{{ t('SummaryChecks.tropopoihseis') }}</th>
              <td>
                <ul v-if="hmeromhnies_tropopoihshs.length != 0" v-for="hmer_trop in hmeromhnies_tropopoihshs">
                  <li>{{ DateTime.fromFormat((hmer_trop), 'yyyy-MM-dd').toFormat("dd/MM/yyyy") }} </li>
                </ul>
              </td>
            </tr>
            <tr>
              <th>{{ t('SummaryChecks.paratasi_diarkeias') }}</th>
              <td>
                {{ StoreSummaryCheckLoanAgreement?.nea_hmer_lukshs !== null ?
                  DateTime.fromFormat(StoreSummaryCheckLoanAgreement?.nea_hmer_lukshs, 'yyyy-MM-dd').toFormat("dd/MM/yyyy")
                  : StoreSummaryCheckLoanAgreement?.arxikh_hmer_lukshs !== null ?
                    DateTime.fromFormat(StoreSummaryCheckLoanAgreement?.arxikh_hmer_lukshs,
                      'yyyy-MM-dd').toFormat('dd/MM/yyyy') : '-' }}
              </td>
            </tr>
            <tr>
              <th>{{ t('LoanAgreementView.legal_status') }}</th>
              <td v-if="StoreSummaryCheckLoanAgreement?.katast_sumbashs === 'non_performing'">
                <p>Non Performing</p>
              </td>
              <td v-if="StoreSummaryCheckLoanAgreement?.katast_sumbashs === 'performing'">
                <p>Performing</p>
              </td>
              <td v-if="StoreSummaryCheckLoanAgreement?.katast_sumbashs === 'denounced'">
                <p>Denounced</p>
              </td>
                <td v-else>
                  <p></p>
                </td>
            </tr>
          </table>

          <table style="width: 100%; margin-top: 20px;">
            <tr>
              <th colspan="2" class="table-heading">
                <p>Ομολογίες</p>
              </th>
            </tr>
          </table>
          <div style="overflow-y: auto; height: 255px" v-if="StoreSummaryRobBonds.length != 0">
            <table style="width: 100%;" class="inner-table-basic-note">
              <tr v-for="(robBonds, index) in StoreSummaryRobBonds">
                <td>{{ (index + 1) }}</td>
                <td>
                  <p>
                    {{ robBonds?.end_bond_number - robBonds?.start_bond_number + 1 }} ΟΜΟΛΟΓΙΕΣ ΣΤΟΝ ΚΟΜΙΣΤΗ '
                    {{ robBonds?.bank_name }}', ΟΝΟΜΑΣΤΙΚΗΣ ΑΞΙΑΣ {{ robBonds?.nominal_value }}{{ currency.symbol }}
                  </p>
                </td>
              </tr>
            </table>
          </div>
          <div v-if="StoreSummaryRobBonds.length == 0" style="width: 100%;">
            <el-row justify="center" style="background-color: #DDDDDD;">
              <el-col :sm="12" :lg="6">
                <el-result icon="info" title="Empty" sub-title="This table is empty">

                </el-result>
              </el-col>
            </el-row>
          </div>
          <!-- LOAN ACCOUNT DETAILS -->
          <div v-if="StoreSummaryCheckLoanAccount.length != 0" v-for="item in StoreSummaryCheckLoanAccount">
            <table style="width: 100%; margin-top: 20px;" class="basic-note-table">
              <tr>
                <th colspan="2" class="table-heading">
                  <p>{{ t('SummaryChecks.account_details') }}: {{ item?.ar_trexontos_logariasmou }}</p>
                </th>
              </tr>
            </table>
            <table style="width: 100%;margin-bottom: 20px;" class="basic-note-table">
              <tr>
                <th style="width: 20%;">{{ t('SummaryChecks.imer_kalipsis_ektam') }}</th>
                <td v-if="item?.hmer_ektam">
                  {{ DateTime.fromFormat((item?.hmer_ektam), 'yyyy-MM-dd').toFormat("dd/MM/yyyy") }}
                </td>
              </tr>
              <tr>
                <th>{{ t('SummaryChecks.ektam_poso') }}</th>
                <td>{{ item?.poso_ektam }}<span v-if="item?.poso_ektam">{{ currency.symbol }}</span></td>
              </tr>
              <tr>
                <th>{{ t('SummaryChecks.product_type') }}</th>
                <td>
                  {{ item?.config_loan_account_product?.product }}
                </td>
              </tr>
              <tr>
                <th>{{ t('SummaryChecks.loan_account_number') }}</th>
                <td>
                  {{ item?.ar_trexontos_logariasmou }}
                </td>
              </tr>
              <tr>
                <th>{{ t('SummaryChecks.repayment') }}</th>
                <td>
                  <table style="width: 100%" class="inner-table-basic-note">
                    <tr>
                      <td>
                        <h6>{{ t('SummaryChecks.capital') }}</h6>
                      </td>
                      <td>
                        <h6>{{ t('SummaryChecks.interests') }}</h6>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span class="inner-table-content">
                          {{ StoreSummaryCheckLoanAgreement?.program_apopl_kefal }}
                        </span>
                      </td>
                      <td>
                        <span class="inner-table-content">
                          {{ StoreSummaryCheckLoanAgreement?.program_apopl_tokon }}
                        </span>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
              <tr>
                <th>{{ t('SummaryChecks.propliromi') }}</th>
                <td v-if="StoreSummaryCheckLoanAgreement?.dikaioma_propl == 'false'">
                  {{ t('components.switch.no') }}
                </td>
                <td v-else>{{ t('components.switch.yes') }}</td>
              </tr>
              <tr>
                <th>{{ t('LoanAccountDetails.current_balance') }}</th>
                <td>
                  <table style="width: 100%" class="inner-table-basic-note">
                    <tr>
                      <td>
                        <h6>{{ t('SummaryChecks.total_amount') }}</h6>
                      </td>
                      <td>
                        <h6>{{ t('SummaryChecks.capital') }}</h6>
                      </td>
                      <td>
                        <h6>{{ t('LoanAccountDetails.on_balance_sheet_interest') }}</h6>
                      </td>
                      <td>
                        <h6>{{ t('LoanAccountDetails.off_balance_sheet_interest') }}</h6>
                      </td>
                      <td>
                        <h6>{{ t('LoanAccountDetails.commissions_expenses') }}</h6>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span class="inner-table-content">
                          {{ item?.trexon_upol
                          }}<span v-if="item?.trexon_upol">{{ currency.symbol }}</span>
                        </span>
                      </td>
                      <td>
                        <span class="inner-table-content">{{ (item?.enhm_kefal + item?.kathister_kefal) }} <span
                            v-if="item?.enhm_kefal && item?.kathister_kefal">{{ currency.symbol }}</span></span>
                      </td>
                      <td>
                        <span class="inner-table-content">{{
                          item?.logistikop_tokoi
                        }}<span v-if="item.logistikop_tokoi">{{ currency.symbol }}</span></span>
                      </td>
                      <td>
                        <span class="inner-table-content">{{
                          item?.mh_logistikop_tokoi
                        }}<span v-if="item?.mh_logistikop_tokoi">{{ currency.symbol }}</span></span>
                      </td>
                      <td>
                        <span class="inner-table-content">
                          {{ item?.eksoda }}<span v-if="item?.eksoda">{{ currency.symbol }}</span>
                        </span>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
              <tr>
                <th>{{ t('LoanAccountDetails.date_of_current_balance') }}</th>
                <td v-if="item?.date_of_current_balance">{{ DateTime.fromFormat(item?.date_of_current_balance, "yyyy-MM-dd").toFormat("dd/MM/yyyy") }}</td>
              </tr>
              <tr>
                <th>{{ t('LoanAccountDetails.days_of_delay') }}</th>
                <td>{{ item?.hmeres_kathister }}</td>
              </tr>
              <tr>
                <th>{{ t('LoanAccountDetails.margin') }}</th>
                <td>{{ StoreSummaryCheckLoanAgreement.perithorio }}</td>
              </tr>
              <tr>
                <th>{{ t('LoanAccountDetails.base_rate_value') }}</th>
                <td>
                  <table style="width: 100%" class="inner-table-basic-note">
                    <tr>
                      <td>
                        <h6>{{ t('LoanAccountDetails.base_rate') }}</h6>
                      </td>
                      <td>
                        <h6>{{ t('LoanAccountDetails.margin') }}</h6>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span class="inner-table-content">{{ interest?.interest }}</span>
                      </td>
                      <td>
                        <span class="inner-table-content">
                          {{ StoreSummaryCheckLoanAgreement.perithorio }}
                        </span>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
              <tr>
                <th>{{ t('LoanAccountDetails.overude_rate') }}</th>
                <td>{{ StoreSummaryCheckLoanAgreement.epitok_uperhm }}</td>
              </tr>
            </table>
          </div>
          <table style="width: 100%; margin-top: 20px;" class="basic-note-table">
            <tr>
              <th colspan="2" class="table-heading">
                <p>{{ t('SummaryChecks.collaterals_control') }}</p>
              </th>
            </tr>
          </table>
          <table style="width: 100%;" class="basic-note-table">
            <tr>
              <th style="width: 20%;">{{ t('SummaryChecks.empragmates') }}</th>
              <td>
                <table style="width: 100%" class="inner-table-basic-note">
                  <tr>
                    <td>
                      <h6>{{ t('RealEstateDetails.type_of_property') }}</h6>
                    </td>
                    <td>
                      <h6>{{ t('SummaryChecks.address') }}</h6>
                    </td>
                    <td>
                      <h6>{{ t('real_estate_collateral.lien') }}</h6>
                    </td>
                    <td>
                      <h6>{{ t('real_estate_collateral.prenotation_amount') }}</h6>
                    </td>
                    <td>
                      <h6>{{ t('SummaryChecks.texniki_ektimisi') }}</h6>
                    </td>
                  </tr>
                  <tr v-if="StoreSummaryCheckRealEstateCollaterals.length != 0"
                    v-for="collaterals in StoreSummaryCheckRealEstateCollaterals">
                    <td>
                      <span class="inner-table-content">
                        {{ collaterals?.real_estate.eidos }}
                      </span>
                    </td>
                    <td>
                      <span class="inner-table-content">
                        <ul style="margin: 0px; list-style: none; padding: 0px">
                          <li>{{ collaterals?.real_estate?.address }} {{ collaterals?.real_estate?.address_number }}</li>
                          <li>{{ collaterals?.real_estate?.region }} {{ collaterals?.real_estate?.city }}</li>
                          <li>{{ collaterals?.real_estate?.district }} {{ collaterals?.real_estate?.postalcode }}</li>
                        </ul>
                      </span>
                    </td>
                    <td>
                      <span class="inner-table-content">{{
                        collaterals?.seira_prosim
                      }}</span>
                    </td>
                    <td>
                      <span class="inner-table-content">{{ collaterals?.poso_prosim }}<span
                          v-if="collaterals?.poso_prosim">{{ currency.symbol }}</span></span>
                    </td>
                    <td>
                      <span class="inner-table-content">
                        {{ collaterals?.real_estate?.ektim_aksias_akin
                        }}<span v-if="collaterals?.real_estate?.ektim_aksias_akin">{{ currency.symbol }}</span>
                      </span>
                    </td>
                  </tr>
                  <tr v-else>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                </table>
              </td>
            </tr>
            <tr>
              <th>
                {{ t('SummaryChecks.enoxikes') }}
                <br />
                <br />
                {{ (StoreSummaryCheckDebtorGuaranteesIndividual.length ||
                  StoreSummaryCheckDebtorGuaranteesLegalEntity.length || StoreSummaryCheckGreekStateGuarantees) ?
                  t('global.true') : t('global.false') }}
              </th>
              <td>
                <table style="width: 100%" class="inner-table-basic-note">
                  <tr>
                    <td>
                      <h6>{{ t('SummaryChecks.individual_guarantees') }}</h6>
                    </td>
                    <td>
                      <h6>{{ t('SummaryChecks.legal_entities_guarantees') }}</h6>
                    </td>
                    <td>
                      <h6>{{ t('SummaryChecks.greek_state_guarantees') }}</h6>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span class="inner-table-content">
                        <p v-if="StoreSummaryCheckDebtorGuaranteesIndividual.length != 0"
                          v-for="GuaranteesIndividual in StoreSummaryCheckDebtorGuaranteesIndividual">
                          {{ GuaranteesIndividual?.fullname }} - {{ t('SummaryChecks.afm') }}:
                          {{ GuaranteesIndividual?.vat_number }}
                        </p>
                      </span>
                    </td>
                    <td>
                      <span class="inner-table-content">
                        <p v-if="StoreSummaryCheckDebtorGuaranteesLegalEntity.length != 0"
                          v-for="GuaranteesLegalEntity in StoreSummaryCheckDebtorGuaranteesLegalEntity">
                          {{ GuaranteesLegalEntity?.fullname }} - {{ t('SummaryChecks.afm') }}:
                          {{ GuaranteesLegalEntity?.vat_number }}
                        </p>
                      </span>
                    </td>
                    <td>
                      <span class="inner-table-content">
                        <p v-if="StoreSummaryCheckGreekStateGuarantees.length != 0"
                          v-for="GreekStateG in StoreSummaryCheckGreekStateGuarantees">
                          {{ GreekStateG?.kua_paroxhs_eed }}
                        </p>
                      </span>
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
          </table>

          <table style="width: 100%;">
            <tr>
              <th colspan="2" class="table-heading">
                <p>{{ t('SummaryChecks.ekxoriseis') }}</p>
              </th>
            </tr>
          </table>

          <table style="width: 100%; table-layout: fixed" class="inner-table-basic-note editable">
            <tr>
              <td style="border-left: none">
                <label style="padding: 8px; font-weight: bold;" class="select-label" data-v-e979449a="">{{
                  t('SummaryChecks.ekxoriseis')
                }}</label>
                <p style="padding: 8px;"
                  v-if="((has_deposits == 'YES') || (has_pledged_claims == 'YES') || (has_warehouse_receipt == 'YES') || (has_bill_of_exchange == 'YES') || (has_movable_collaterals == 'YES'))">
                  {{ $t('components.switch.yes') }}</p>
                <p style="padding: 8px;" else>
                  {{ $t('components.switch.no') }}
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <EntityField type="input" :label="t('SummaryChecks.elegxos_sumvasis')"
                  :object="fullObj.ekxoriseis_elegxos_sumvasis" @decision="onDecision"
                  style="padding: 8px; font-weight: bold;" />
              </td>
            </tr>
            <tr>
              <td>
                <EntityField type="input" :label="t('SummaryChecks.elegxos_epidosis')"
                  :object="fullObj.ekxoriseis_elegxos_epidosis" @decision="onDecision"
                  style="padding: 8px; font-weight: bold;" />
              </td>
            </tr>
            <tr>
              <td>
                <EntityField type="input" :label="t('SummaryChecks.eispraxi_gegenimenon')"
                  :object="fullObj.ekxoriseis_eispraxi_gegen_apait" @decision="onDecision"
                  style="padding: 8px; font-weight: bold;" />
              </td>
            </tr>

          </table>

          <table style="width: 100%; table-layout: fixed" class="inner-table-basic-note">
            <tr>
              <th style="width: 20%;">{{ t('SummaryChecks.lixi_ekx_asf') }}</th>
              <td>
                <el-row class="display_table editable">
                  <el-col :span="20">
                    <EntityField type="datepicker" label="" :object="fullObj.lixi_ekxorimenon_asf"
                      @decision="onDecision" />
                  </el-col>
                </el-row>
              </td>
            </tr>
            <tr>
              <th>
                {{ t('SummaryChecks.ekxorisi_xreogr') }}
              </th>
              <td>
                <table style="width: 100%" class="inner-table-basic-note">
                  <tr>
                    <td class="ekxorisi_epitages ekxorisi_epitages_title">
                      <h6>{{ t('SummaryChecks.checks') }}</h6>
                    </td>
                    <td class="ekxorisi_metoxes ekxorisi_epitages_title">
                      <h6>{{ t('SummaryChecks.shares') }}</h6>
                    </td>
                  </tr>
                  <tr>
                    <td class="ekxorisi_epitages">
                      <span class="inner-table-content">
                        {{ Epitages }}
                      </span>
                    </td>
                    <td class="ekxorisi_metoxes">
                      <span class="inner-table-content">
                        {{ Metoxes }}
                      </span>
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
          </table>


          <table style="width: 100%;">
            <tr>
              <th colspan="2" class="table-heading">
                <p>{{ t('SummaryChecks.promitheies') }}</p>
              </th>
            </tr>
          </table>


          <table style="width: 100%" class="inner-table-basic-note editable">
            <tr>
              <td style="width: 20%; font-weight: bold">
                {{ t('SummaryChecks.idle') }}
              </td>
              <td>
                <EntityField type="input" label="" :object="fullObj.promitheies_adraneias" @decision="onDecision" />
              </td>
            </tr>
            <tr>
              <td style="font-weight: bold">
                {{ t('SummaryChecks.anadoxis') }}
              </td>
              <td>
                <EntityField type="input" label="" :object="fullObj.promitheies_anadoxis" @decision="onDecision" />
              </td>
            </tr>
            <tr>
              <td style="font-weight: bold">
                {{ t('SummaryChecks.diaxeirisis') }}
              </td>
              <td>
                <EntityField type="input" label="" :object="fullObj.promitheies_diaxeirisis" @decision="onDecision" />
              </td>
            </tr>
            <tr>
              <td style="font-weight: bold">
                {{ t('SummaryChecks.tropopoihshs') }}
              </td>
              <td>
                <EntityField type="input" label="" :object="fullObj.promitheies_tropopoihshs" @decision="onDecision" />
              </td>
            </tr>
            <tr>
              <td style="font-weight: bold">
                {{ t('SummaryChecks.waiver') }}
              </td>
              <td>
                <EntityField type="input" label="" :object="fullObj.promitheies_waiver_fee_ekpr_omolog"
                  @decision="onDecision" />
              </td>
            </tr>
            <tr>
              <td style="font-weight: bold">
                {{ t('SummaryChecks.waiver_gia_kathe') }}
              </td>
              <td>
                <EntityField type="input" label="" :object="fullObj.promitheies_waiver_fee_omolog"
                  @decision="onDecision" />
              </td>
            </tr>
          </table>


          <table style="width: 100%" class="inner-table-basic-note">

            <tr>
              <th style="width: 20%">{{ t('SummaryChecks.upoxr_plirof_clauses') }}</th>
              <td>
                <el-row class="display_table editable">
                  <el-col :span="20">
                    <EntityField type="input" label="" :object="fullObj.upoxreoseis_plirof_oikon_stoixeia"
                      @decision="onDecision" />
                  </el-col>
                </el-row>
              </td>
            </tr>

            <tr>
              <th>
                {{ t('SummaryChecks.gegonota') }}
              </th>
              <td>
                <el-row class="display_table editable">
                  <el-col :span="20">
                    <EntityField type="input" label="" :object="fullObj.gegonota_kataggelias" @decision="onDecision" />
                  </el-col>
                </el-row>
              </td>
            </tr>

            <tr>
              <th>
                {{ t('SummaryChecks.limitation_period') }}
              </th>
              <td>
                <el-row class="display_table editable">
                  <el-col :span="20">
                    <EntityField type="input" label="" :object="fullObj.xronos_paragrafis" @decision="onDecision" />
                  </el-col>
                </el-row>
              </td>
            </tr>

            <tr>
              <th>{{ t('SummaryChecks.ekdosi_diatagis_pliromis') }}</th>
              <td>
                <p v-if="((isDenounced === 'YES') && (has_ekdosi_diatagis === 'YES'))">
                <ul v-if="hmer_ekd_datag_pliromis.length != 0" v-for="diat_plir in hmer_ekd_datag_pliromis">
                  <li>{{ diat_plir }} - {{ DateTime.fromFormat(diat_plir, "yyyy-MM-dd").plus({
                    days: 45
                  }).toFormat("dd/MM/yyyy")
                  }}</li>
                </ul>
                </p>
              </td>
            </tr>

            <tr>
              <th>
                {{ t('SummaryChecks.paratiriseis') }}
              </th>
              <td>
                <el-row class="display_table editable">
                  <el-col :span="20">
                    <EntityField type="textarea" label="" :object="fullObj.paratiriseis" @decision="onDecision" />
                  </el-col>
                </el-row>
              </td>
            </tr>

          </table>

        </div>
      </div>

    </el-form>

    <div v-else>
      <div class="info" style="margin-top: 100px; margin-bottom: 100px">
        <h2>Summary Check</h2>
        <p>Please select a loan agreement.</p>
      </div>
    </div>


  </div>
</template>

<style scoped>
.el-row {
  margin-bottom: 20px;
}

.el-row:last-child {
  margin-bottom: 0;
}

.el-col {
  border-radius: 4px;
}

.grid-content {
  border-radius: 4px;
  min-height: 36px;
}

.info {
  margin: auto;
  width: 50%;
  padding: 10px;
  text-align: center;
}

.el-tabs__content {
  padding: 0px !important;
}

th,
td {
  padding: 5px;
  text-align: left;
}

.border_table {
  border: 0px !important;
}

.el-form-item {
  margin-bottom: 0px;
}

.el-table__header>thead:nth-child(2)>tr:nth-child(1) th {
  color: red !important;
}
</style>

