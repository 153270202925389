<script lang="ts" setup>
import { useMainStore } from "../store/main";
import { Bell, Notebook, Download } from "@element-plus/icons-vue";
import { useMOSAPIStore } from "../store/mos_api";
import { usePGRESTAPIStore } from "../store/pgrest_api";
import { useDataHelpers } from "../composables/dataHelpers";
import { ref, watch, onMounted } from "vue";
import PGRESTResult from "../classes/PGRESTResult";
import { RefreshLeft, Loading } from "@element-plus/icons-vue";
import { storeToRefs } from "pinia";
import { useI18n } from "vue-i18n";
import { useSort } from "../composables/sort";
import UserType from "../enums/UserType";
import { useRouter } from "vue-router";
import { useGenericMethodsVariables } from "../composables/genericMethodsVariables";
import ISelectEntry from "../interfaces/ISelectEntry";
import ConfigProduct from "../classes/DB_Entities/ConfigProduct";

const {
  showError
} = useGenericMethodsVariables();

const router = useRouter();
let dataCount = ref(0);
const mainStore = useMainStore();
const pgrestapi = usePGRESTAPIStore();
const mosapi = useMOSAPIStore();
const dataHelpers = useDataHelpers();
let LoadSummaryCheckToggler = ref(true);
const { globalLoanAgreementID } = storeToRefs(mainStore);
const { t } = useI18n({ useScope: "global" });
const { onSortChange, sortColumn, sortOrder } = useSort();
let product_type = ref<any>();
let selectedLoanAgreementID = ref<string>("");
let lastPage = ref(0);
let dataList = ref<any[]>([]);
let isLoading = ref(false);
let filterContractNumber = ref<string>("");
let filterFao = ref<string>("");
let filterTaxRegistration = ref<string>("");
let filterProduct = ref<string>("");
let filterStatus = ref<string>("");
let filterPending = ref(false);
let timer = ref<any>(null);

let productTypes = ref<ISelectEntry[]>([]);

watch(globalLoanAgreementID, async () => {
  selectedLoanAgreementID.value = globalLoanAgreementID.value;
  if (mainStore.loggedUser.username !== '') // Do not load if user logged out (changes the value of globalLoanAgreementID to "")
    await loadPage(1);
});

onMounted(async () => {
  productTypes.value = await dataHelpers.getAllObjectsFromTableForSelect<ConfigProduct>(ConfigProduct, (dbObj: any) => dbObj.product, (dbObj: any) => dbObj.product);
  await loadPage(1);
});

const loadPage = async (page: number) => {
  isLoading.value = true;

  lastPage.value = page;
  page = page - 1;

  let filterUrl = "";

  if (filterContractNumber.value !== "") {
    filterUrl += `&ar_sumbashs=ilike.*${filterContractNumber.value}*`;
  }

  if (filterPending.value) {
    filterUrl += "&pending_approvals=gt.0";
  }

  if (filterFao.value !== "") {
    filterUrl += `&ar_sumbashs_fao=ilike.*${filterFao.value}*`;
  }

  if (filterTaxRegistration.value !== "") {
    filterUrl += `&vat_number=ilike.*${filterTaxRegistration.value}*`;
  }
  console.log('product', filterProduct.value)
  if (filterProduct.value !== "") {
    filterUrl += `&product=eq.${filterProduct.value}`;
  }
  if (filterStatus.value !== "") {
    filterUrl += `&status=eq.${filterStatus.value}`;
  }
  if (sortOrder.value !== "") {
    filterUrl += `&order=${sortColumn.value}.${sortOrder.value}`;
  }

  let addedFilter = "";

  if (mainStore.globalPortfolioID !== "") {
    addedFilter += "&debtorportfolio=eq." + mainStore.globalPortfolioID;
  }

  if (mainStore.globalDebtorID !== "") {
    addedFilter += "&debtorid=eq." + mainStore.globalDebtorID;
  }

  if (globalLoanAgreementID.value !== "") {
    addedFilter += "&loan_agreement_id=eq." + globalLoanAgreementID.value;
  }
  if (mainStore.loggedUser.user_type == UserType.Agent) {
    addedFilter += `&allowed_users=cs.{${mainStore.loggedUser.id}}`;
  } else if (mainStore.loggedUser.user_type == UserType.Supervisor) {
    addedFilter += `&portfolio_allowed_users=cs.{${mainStore.loggedUser.id}}`;
  }

  let result: PGRESTResult = await pgrestapi.getPaginated(
    "view_summary_check_xtra_view1?select=*" +
    addedFilter +
    filterUrl +
    "&order=ar_sumbashs",
    page,
    10
  );

  if (result.error) {
    showError(result.error.message);
    isLoading.value = false;
    return;
  }

  if (result.headers)
    dataCount.value = parseInt(result.headers["content-range"].split("/")[1]);

  //Pending Approvals Counter

  dataList.value = result.data;
  let CountGuaranteesPendingChanges = 0;
  let CountReCollateralsPendingChanges = 0;

  for (let datarow of dataList.value) {
    datarow.is_loading = false;

    if ((datarow as any).product_type === "summary_check_another_loan") {
      //ChecklistGuarantees
      let result: PGRESTResult = await pgrestapi.get(
        "view_summary_check_pending_guarantees?loan_agreement_id=eq." +
        (datarow as any).loan_agreement_id
      );
      for (let i = 0; i < result.data.length; i++) {
        CountGuaranteesPendingChanges =
          CountGuaranteesPendingChanges + result.data[i].pending_approvals;
      }
      //ChecklistReCollaterals
      let result2: PGRESTResult = await pgrestapi.get(
        "view_summary_check_pending_recollaterals?loan_agreement_id=eq." +
        (datarow as any).loan_agreement_id
      );
      for (let i = 0; i < result2.data.length; i++) {
        CountReCollateralsPendingChanges =
          CountReCollateralsPendingChanges + result2.data[i].pending_approvals;
      }

      (datarow as any).pending_approvals =
        (datarow as any).pending_approvals +
        CountGuaranteesPendingChanges +
        CountReCollateralsPendingChanges;

      CountGuaranteesPendingChanges = 0;
      CountReCollateralsPendingChanges = 0;
    }
    if ((datarow as any).product_type === "summary_check_OD") {
      let result: PGRESTResult = await pgrestapi.get(
        "view_summary_check_pending_guarantees?loan_agreement_id=eq." +
        (datarow as any).loan_agreement_id
      );
      for (let i = 0; i < result.data.length; i++) {
        CountGuaranteesPendingChanges =
          CountGuaranteesPendingChanges + result.data[i].pending_approvals;
      }

      //ChecklistReCollaterals
      let result2: PGRESTResult = await pgrestapi.get(
        "view_summary_check_pending_recollaterals?loan_agreement_id=eq." +
        (datarow as any).loan_agreement_id
      );
      for (let i = 0; i < result2.data.length; i++) {
        CountReCollateralsPendingChanges =
          CountReCollateralsPendingChanges + result2.data[i].pending_approvals;
      }

      (datarow as any).pending_approvals =
        (datarow as any).pending_approvals +
        CountGuaranteesPendingChanges +
        CountReCollateralsPendingChanges;

      CountGuaranteesPendingChanges = 0;
      CountReCollateralsPendingChanges = 0;
    }
  }

  // console.log(JSON.stringify(dataList.value));
  isLoading.value = false;

  // Download all for testing
  // for (let i = 0; i < dataList.value.length; ++i) {
  //   console.log(i);
  //   await DownloadExport(dataList.value[i]);
  // }
};

const localSortChange = async (sortObj: any) => {
  onSortChange(sortObj);
  await loadPage(lastPage.value);
};

const filterChanged = async (value: string) => {
  if (timer.value !== 0) {
    clearTimeout(timer.value);
  }

  timer.value = setTimeout(() => {
    loadPage(1);

    timer.value = 0;
  }, 500);
};

const handleClick = async (loan_agreement_id: string) => {
  LoadSummaryCheckToggler.value = false;
  selectedLoanAgreementID.value = loan_agreement_id;

  let result: PGRESTResult = await pgrestapi.get(
    "view_summary_check_xtra_view1?select=product_type&loan_agreement_id=eq." +
    loan_agreement_id
  );

  if (result) {
    product_type.value = result.data[0].product_type;
  }

  if (product_type.value == "summary_check_KOD") {
    router.push({
      name: "Summary Check KOD",
      params: { loan_agreement_id: loan_agreement_id },
    });
  } else if (product_type.value == "summary_check_OD") {
    router.push({
      name: "Summary Check OD",
      params: { loan_agreement_id: loan_agreement_id },
    });
  } else if (product_type.value == "summary_check_another_loan") {
    router.push({
      name: "Summary Check AL",
      params: { loan_agreement_id: loan_agreement_id },
    });
  }
};

const pendingFilterChange = async () => {
  await loadPage(1);
};

const DownloadExport = async (row: any) => {
  //(product_type: string, loan_agreement_id: number) => {
  if (row.product_type === "summary_check_KOD") {
    try {
      row.is_loading = true;
      let res = await mosapi.getSummaryCheckKOD(row.loan_agreement_id, row.ar_sumbashs);

      if (res !== "") {
        showError(res);
      }

    } catch (ex) {
      console.log(ex);
    }

    row.is_loading = false;
    return;
  }

  if (row.product_type === "summary_check_another_loan") {
    try {
      row.is_loading = true;
      let res = await mosapi.getSummaryCheckAL(row.loan_agreement_id, row.ar_sumbashs);

      if (res !== "") {
        showError(res);
      }

    } catch (ex) {
      console.log(ex);
    }

    row.is_loading = false;
    return;
  }
};
</script>

<template style="height: 100%">
  <div class="content-wrapper" v-loading="isLoading" v-if="LoadSummaryCheckToggler == true">
    <el-row :gutter="10">
      <el-col :span="12">
        <h3 class="heading-page">
          {{ $t("SummaryCheckView.summary_check") }} ({{ dataCount }})
        </h3>
      </el-col>
    </el-row>

    <div style="margin: 30px">
      <el-row :gutter="20">
        <el-col :span="5">
          <el-input v-model="filterContractNumber" class="client-input"
            :placeholder="$t('SummaryCheckView.loan_agreement')" @input="filterChanged" />
        </el-col>
        <el-col :span="5">
          <el-input v-model="filterFao" class="client-input" :placeholder="$t('SummaryCheckView.loan_agreement_fao')"
            @input="filterChanged" />
        </el-col>
        <el-col :span="5">
          <el-input v-model="filterTaxRegistration" class="client-input"
            :placeholder="$t('SummaryCheckView.tax_registration_nbr')" @input="filterChanged" />
        </el-col>
        <el-col :span="5">
          <el-select clearable v-model="filterProduct" class="m-2 client-input" :placeholder="$t('SummaryCheckView.product')"
            value-key="id" @change="filterChanged" style="width: 100%">
            <el-option v-for="item in productTypes" :key="item.value" :label="item.label" :value="item.value" />
          </el-select>
          <!-- <el-input v-model="filterProduct" class="client-input" :placeholder="$t('SummaryCheckView.product')"
            @input="filterChanged" /> -->
        </el-col>
      </el-row>
    </div>
    <div>
      <el-row :gutter="10" style="margin: 10px; margin-top: 15px">
        <el-col :span="12" class="realestate-add-new-btn">
          <el-switch v-model="filterPending" size="small" :active-text="$t('global.pending_changes')" inactive-text=""
            @change="pendingFilterChange()" style="float: left" />
        </el-col>
        <el-col :span="12" class="realestate-add-new-btn">
          <el-button link type="primary" size="small" class="btn-add" @click="loadPage(lastPage)" style="float: right">
            <el-icon>
              <RefreshLeft />
            </el-icon>
          </el-button>
        </el-col>
      </el-row>
    </div>

    <div>
      <el-table :data="dataList" stripe border table-layout="fixed" sortable="custom" @sort-change="localSortChange"
        size="small">
        <el-table-column sortable fixed prop="ar_sumbashs" :label="$t('SummaryCheckView.loan_agreement')" />
        <el-table-column sortable fixed prop="ar_sumbashs_fao" :label="$t('SummaryCheckView.loan_agreement_fao')" />
        <el-table-column sortable fixed prop="product" :label="$t('SummaryCheckView.product')" />
        <el-table-column sortable fixed prop="vat_number" :label="$t('SummaryCheckView.tax_registration_nbr')" />
        <el-table-column sortable fixed prop="pending_approvals" :label="$t('global.pending_approvals')" />

        <el-table-column fixed="right" :label="$t('SummaryCheckView.summary_check')">
          <template #default="scope">
            <el-button link type="primary" class="btn-portfolios" size="small"
              @click="handleClick(scope.row.loan_agreement_id)">
              <el-icon>
                <Notebook />
              </el-icon>
            </el-button>
            <el-button link type="primary" class="btn-portfolios" size="small" @click="DownloadExport(scope.row)">
              <el-icon :class="{ 'is-loading': scope.row.is_loading }">
                <Loading v-if="scope.row.is_loading" />
                <Download v-else />
              </el-icon>
            </el-button>
            <el-popover v-if="scope.row.pending_approvals > 0" placement="top-start" title="" :width="155"
              trigger="hover" :content="$t('global.pending_changes')">
              <template #reference>
                <el-button :icon="Bell" circle size="small"
                  style="background-color: coral; border: 0px; color: white" />
              </template>
            </el-popover>

          </template>
        </el-table-column>
      </el-table>
    </div>
    <div>
      <el-pagination small layout=" prev, pager, next" :page-size="10" :total="dataCount" @current-change="loadPage" />
    </div>
  </div>
</template>

<style scoped>
.el-row {
  margin-bottom: 20px;
}

.el-row:last-child {
  margin-bottom: 0;
}

.el-col {
  border-radius: 4px;
}

.grid-content {
  border-radius: 4px;
  min-height: 36px;
}

.info {
  margin: auto;
  width: 50%;
  padding: 10px;
  text-align: center;
}

th,
td {
  padding: 5px;
  text-align: left;
}

.border_table {
  border: 0px !important;
}

.el-form-item {
  margin-bottom: 0px;
}
</style>
