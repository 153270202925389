{
  "documents_folders": {
    "collaterals": "Collaterals",
    "conventional_documents": "Conventional Documents",
    "legal_actions": "Legal Actions",
    "legalization_documents": "Legalization Documents",
    "misc_documents": "Misc Documents",
    "rollovers": "Rollovers",
    "technical_evaluations": "Technical Evaluations"
  },
  "menu": {
    "MOS": "MOS",
    "Dashboard": "Dashboard",
    "Summary Check": "Summary Check",
    "Approvals": "Approvals",
    "Clients": "Clients",
    "Portfolios": "Portfolios",
    "Debtors": "Debtors",
    "Loan Agreements": "Loan Agreements",
    "Register of Bonds": "Register of Bonds",
    "Loan Accounts": "Loan Accounts",
    "Real Estates": "Real Estates",
    "RE Collaterals": "RE Collaterals",
    "Guarantees": "Guarantees",
    "Greek State Guarantees": "Greek State Guarantees",
    "Auctions": "Auctions",
    "NRE Collaterals": "NRE Collaterals",
    "NRE Collaterals 2": "NRE Collaterals",
    "Eκtokismos": "Interest Calculation",
    "Entity Changes": "Entity Changes",
    "File Upload": "File Upload",
    "Configuration": "Configuration",
    "Platform Settings": "Platform Settings",
    "Monitoring": "Monitoring",
    "Diary": "Diary",
    "Users": "Users",
    "Import Data": "Import Data",
    "Reports": "Reports",
    "ReportPreview": "Report Preview",
    "Stats": "Stats",
    "Test": "Test",
    "Metabase-Agents": "Statistics - Agents",
    "Metabase-SupervisorsEdits": "Statistics - Supervisors (Edits)",
    "Metabase-SupervisorsApprovals": "Statistics - Supervisors (Approvals)",
    "Metabase-DLAs": "Statistics - Digitized LAs",
    "Digitized LA": "Physical file indexing",
    "TestEktok": "TestEktokismos",
    "Privileges": "Privileges",
    "Workflow":"Workflow",
    "Projects":"Projects",
    "TaskTemplate":"Task Template",
    "ProjectTemplate":"Project Template",
    "Task templates":"Task Templates",
    "Project templates":"Project Templates",
    "Custom Fields":"Custom Fields",
    "Frontier Indexing":"Frontier Indexing",
    "Indexing":"Indexing",
    "Frontier":"Frontier Indexing"
  },
  "generic": {
    "name": "Name",
    "surname": "Surname",
    "fullname": "Full Name",
    "userdetails": "User Details",
    "cancel": "Cancel",
    "delete": "Delete",
    "close": "Close",
    "save": "Save"
  },
  "questions": {
    "enter_username": "Enter username"
  },
  "components": {
    "switch": {
      "yes": "Yes",
      "no": "No"
    }
  },
  "loan_agreement": {
    "in_progress": "In progress",
    "unchecked": "Unchecked",
    "completed": "Completed",
    "performing": "Performing",
    "non_performing": "Non Performing",
    "denounced": "Denounced"
  },
  "LiksiarioView": {
    "id": "Internal DB ID",
    "diary": "Diary",
    "remaining_balanc": "Remaining Balance (total)",
    "repayment_date": "Repayment Date",
    "margin": "Margin",
    "cert_sent": "Certificate sent",
    "total_remaining_balance": "Remaining Balance (total)",
    "our_participation": "Our participation",
    "interest_calc_start_date": "Interest calculation start date",
    "interest_calc_end_date": "Interest calculation end date",
    "interest_rate": "Interest rate",
    "interest_amount": "Interest amount (total)",
    "withholdind_tax": "Withholdind tax 15%",
    "interest_amount_due": "Interest amount due",
    "comments": "Comments",
    "tax_certificate": "Tax certificate",
    "days_overdue": "Days overdue",
    "alarm_date": "Alarm date",
    "due_date": "Due date",
    "from": "from",
    "to": "to",
    "ektokismos": "Interest period",
    "loan_agreement_id": "Loan Agreement"
  },
  "validation": {
    "check_fields": "Please check problematic fields",
    "client_name_already_exists": "Client with the same name already exists",
    "insert_asset_owner": "Enter asset owner",
    "insert_client_name": "Enter client name",
    "insert_portfolio_name": "Enter portfolio name",
    "max_20chars": "Must be 20 characters length maximum",
    "only_numbers": "Must contain only numbers",
    "portfolio_name_already_exists": "Portfolio with the same name already exists",
    "select_client": "Please select client",
    "select_debtor": "Debtor is required",
    "select_real_estate": "Real estate is required",
    "select_re_collateral": "Real estate collateral is required",
    "insert_cid": "CID is required",
    "insert_portfolio": "Portfolio  is required",
    "insert_tax_registration_nbr": " VAT is required",
    "tax_registration_nbr": "VAT is required",
    "select_third_part_id": "Third Party ID is required",
    "select_ar_prog_pl": "Auction program nbr is required",
    "select_hmer_pl": "Auction Date is required",
    "select_anag_apetisis": "Declaration of Claim is required",
    "select_epispeudon": "Claimant is required",
    "select_timh_pl": "Auction Initial Price is required",
    "select_timh_katak": "Auction Price is required",
    "select_pleiodotis": "Bidder is required",
    "select_hmer_ekd": "Date of Previous Auction is required",
    "realestate_nbr": "Real Estate Nbr is required and must be numeric."
  },
  "summary_check": {
    "full_ownership": "Full Ownership",
    "bare_ownership": "Bare Ownership",
    "usufruct": "Usufruct",
    "right_of_residence_for_life": "Right of residence for life",
    "air_right": "Air Right",
    "null": " "
  },
  "errors": {
    "access_denied": "Access Denied",
    "debtor_already_exists": "Debtor already exists (CID & VAT combination)",
    "debtor_already_exists_portfolio_cid": "Debtor already exists (Portfolio & CID combination)",
    "debtor_already_exists_portfolio_vat": "Debtor already exists (Portfolio & VAT combination)",
    "missing_asset_owner": "Missing Asset Owner",
    "missing_bank_name": "Missing Bank Name",
    "missing_client": "Missing Client",
    "missing_client_name": "Missing Client Name",
    "missing_construction_date": "Missing construction date",
    "missing_contact_details": "Missing Contact Details",
    "missing_debtor": "Missing Debtor",
    "missing_debtor_cid": "Missing Debtor CID",
    "missing_lhksh_asfalistiriou": "Missing End Date of Insurance",
    "missing_real_estate_id": "Missing Real Estate ID",
    "missing_loan_agreement": "Missing Loan Agreement",
    "missing_participation_percent": "Missing Participation Percent",
    "missing_portfolio_name": "Missing Portfolio Name",
    "missing_start_bond_number": "Missing Starting Bond Number",
    "missing_end_bond_number": "Missing End Bond Number",
    "missing_nominal_value": "Missing Nominal Value",
    "missing_issue_date": "Missing Issue Date",
    "missing_end_date": "Missing End Date",
    "missing_bond_status": "Missing Bond Status",
    "missing_title_name": "Missing Title Name",
    "missing_valuation_date": "Missing valuation date",
    "another_loan_no_bonds": "The loan type can't have a register of bonds",
    "aplo_omologiako_mia_trapeza": "The loan type can't have more than one participating banks",
    "aplo_omologiako_mia_trapeza_pososto": "The bank percentage must be 100.",
    "percentage_issue": "The percentages are wrong",
    "no_debtor_with_this_cid": "There is no debtor with this CID",
    "please_insert_kad": "Please Select KAD - Business Activity Code",
    "denounce_amounts_wrong": "Denouncement amount doesn't sum up to the 5 fields.",
    "select_portfolio": "Please Select Portfolio",
    "loan_accounts_balance_error": "The amounts don't sum up.",
    "missing_field_hmer": "Payment date is required.",
    "missing_field_poso": "Payment amount is required.",
    "portfolio_account_count_error": "More than one Bank/Servicer has been selected as the portfolio's account.",
    "loan_accounts_balance_error_mh_logistik": "Το άθροισμα των μη λογιστικοποιημένων τόκων δεν ταιριάζει.",
    "loan_accounts_balance_error_logistik": "Το άθροισμα των λογιστικοποιημένων τόκων δεν ταιριάζει."
  },
  "notifications": {
    "pending_changes_register": "This register of Bonds has pending changes"
  },
  "loan_account": {
    "approval": "Approval",
    "approval_status_null": "-",
    "approval_status_rejected": "Rejected",
    "approval_status_accepted": "Accepted",
    "pliromes": "Payments"
  },
  "payments": {
    "hmer": "Payment date",
    "poso": "Payment amount",
    "kefalaio": "Principal paid",
    "tokoi": "Interest paid",
    "exoda": "Expensed paid",
    "proskomisi": "Presentation of certificate",
    "hmer_prosk": "Ημερομηνία Date of presentation",
    "loan_account": "Loan Account"
  },
  "LoanAccountEktokismosView": {
    "hmer_epomenis_dosis": "Next Installment Date",
    "loan_account_id": "Loan Account",
    "kefalaio": "Next Installment Principal Due",
    "tokoi": "Next Installment Interest Due"
  },
  "LoanAgreementEktokismosView": {
    "bank_percent": "Bank Percentage",
    "base_rate_value": "Base Rate Value",
    "margin": "Margin",
    "total_due_rate": "Total Due Rate",
    "overdue_rate": "Overdue Rate",
    "total_rate": "Total Rate",
    "participating_banks": "Participating Banks",
    "due_principal": "Due Principal",
    "interest_on_due_principal": "Interest on Due Principal",
    "overdue_principal": "Overdue Principal",
    "overdue_interest": "Overdue Interest",
    "interest_on_overdue_amount": "Interest on Overdue amount",
    "start_date": "Start Date",
    "end_date": "End Date",
    "imeres": "Days"
  },
  "ektokismos": {
    "ektokismos": "Interest calculation",
    "hmer_enarxis_ekt_periodou": "Start date",
    "hmer_lixis_ekt_periodou": "End date",
    "enimero_kef": "Due Principal",
    "kathisterimeno_kef": "Overdue Principal",
    "kathisterimenoi_tokoi": "Overdue interest",
    "epitokio": "Base Rate Value",
    "tokoi_enimeris": "Interest on due amount",
    "tokoi_kath": "Interest on overdue amount",
    "apodate": "From",
    "todate": "To"
  },
  "global": {
    "hide_rejected_entities": "Hide rejected entities",
    "operations": "Operations",
    "pending_approvals": "Pending Approvals",
    "pending_changes": "Pending Changes",
    "pending_changes_new_entities": "Pending New Entities",
    "true": "YES",
    "false": "NO",
    "active": "Active",
    "inactive": "Inactive",
    "date": "Date",
    "back": "Back",
    "save": "Save",
    "next_step": "Next Step",
    "data_entry": "Data Entry",
    "select": "Select",
    "approve_status": "Approve Status",
    "saved_dates": "Saved Dates",
    "summary_check_error": "Could not find all required data for loan agreement summary.",
    "ChangedToDenounced": "Loan agreement legal status changed to denounced. Proposed by ",
    "debtor_not_approved": "Debtor requires approval(s)",
    "loan_agreement_not_approved": "Loan Agreement requires approval(s)",
    "real_estate_not_approved": "Real Estate requires approval(s)",
    "null": " ",
    "ignore_inactive_users": "Ignore inactive users",
    "lockedrow": "Another user is editing this row. Click to unlock row.",
    "unlockalllockedrow": "Unlock all locked rows",
    "ImportError": "Προβλήματα σε εγγραφές - Import",
    "import_errors": "Σφάλματα - Imports",
    "import_error_field": "Import Errors",
    "import_error_info": "Clear import error field to remove the error notification"
  },
  "legal_status_list": {
    "performing": "Performing",
    "non_performing": "Non performing",
    "denounced": "Denounced"
  },
  "payment_type_list": {
    "xreolitiko": "AMORTISING EQUAL PAYMENTS",
    "tokoxreolitiko": "AMORTIZATION",
    "balloon": "AMORTISING UNEQUAL PAYMENTS"
  },
  "SummaryCheckView": {
    "loan_agreement": "Loan Agreement",
    "loan_agreement_fao": "Loan Agreement FAO",
    "tax_registration_nbr": "Tax Registration Nbr",
    "product": "Product",
    "status": "Status",
    "pending_approvals": "Pending Approvals",
    "summary_check": "Summary Check"
  },
  "ApprovalView": {
    "loan_agreement": "Loan Agreement",
    "mos_client": "Mos client",
    "approvals": "Approvals",
    "middle_officer": "Middle Officer"
  },
  "ClientsView": {
    "clients": "Clients",
    "company_name": "Company Name"
  },
  "ClientDetails": {
    "clientname": "Client Name"
  },
  "PortfolioView": {
    "portfolios": "Portfolios"
  },
  "PortfolioDetails": {
    "name": "Name",
    "asset_owner": "Asset Owner",
    "digitization": "Digitization",
    "digital_code": "Digital Code"
  },
  "DebtorView": {
    "debtors": "Debtors",
    "company_name": "Company Name / Full Name",
    "phone": "Phone",
    "tax_registration_nbr": "Tax Registration Nbr",
    "debtor_company_name": "Debtor Company Name / Full Name",
    "debor_tax_registration_nbr": "Debtor Tax Registration Nbr"
  },
  "DigitizedView": {
    "loan_agreements": "Physical file indexing"
  },
  "LoanAgreementView": {
    "loan_agreements": "Loan Agreements",
    "loan_agreement": "Loan Agreement",
    "loan_agreement_fao": "Loan Agreement FAO",
    "tax_registration_nbr": "Tax Registration Nbr",
    "product": "Product",
    "status": "Status",
    "legal_status": "Legal Status",
    "ektokismos": "Interest Calculation",
    "add_loan_agreement": "Add loan agreement",
    "delete_la": "Delete Loan Agreement",
    "delete_la_check": "Are you sure you want to delete this Loan Agreement?",
    "la_details": "Loan Agreement Details"
  },
  "LoanAccountsView": {
    "loan_accounts": "Loan Accounts",
    "loanaccount": "Loan Account",
    "original_loan_account": "Original Loan Account",
    "loan_agreement": "Loan Agreement",
    "installments": "Next Installments",
    "loan_calculations": "Loan Calculations"
  },
  "RealEstateView": {
    "real_estate": "Real Estate",
    "real_estate_nbr": "Real Estate Nbr",
    "city": "City",
    "debtor_cid": "Debtor CID",
    "type_of_property": "Type of Property"
  },
  "RealEstateCollateralsView": {
    "RealEstateCollaterals": "Real Estate Collaterals",
    "collateral_nbr": "Collateral Nbr",
    "loan_agreement": "Loan Agreement Nbr",
    "real_estate_nbr": "Real Estate Nbr"
  },
  "GuaranteeView": {
    "guarantees": "Guarantees",
    "loan_agreement": "Loan Agreement",
    "guaranteed_amount": "Guaranteed Amount",
    "full_agreement_guarantee": "Full Agreement Guarantee",
    "guarantee_full_name": "Guarantee Company Name / Full Name",
    "guarantee_vat": "Guarantee Tax Registration Nbr"
  },
  "GreekStateGuaranteesView": {
    "greek_state_guarantees": "Greek State Guarantees",
    "kya_of_greek_state_guarantee_provision": "ΚΥΑ of Greek State Guarantee Provision",
    "loan_agreement": "Loan Agreement Nbr",
    "guaranteed_amount": "Guaranteed Amount"
  },
  "AuctionsView": {
    "auctions": "Auctions",
    "debtor_cid": "Debtor CID",
    "real_estate_nbr": "Real Estate Nbr",
    "tax_registration_nbr": "Tax Registration Nbr"
  },
  "NRECollateralsView": {
    "nre_collaterals": "NRE Collaterals",
    "loan_agreement": "Loan Agreement Nbr",
    "checks": "Checks",
    "listed_shares": "Listed Shares",
    "unlisted_shares": "Unlisted Shares"
  },
  "UsersView": {
    "users": "Users",
    "username": "Username",
    "name": "Name",
    "company": "Company",
    "user_type": "User Type",
    "deactive_user_question": "Are you sure you want to deactivate this user?"
  },
  "RegisterOfBondsView": {
    "register_of_bonds": "Register Of Bonds",
    "loan_agreement": "Loan Agreement",
    "bondholder": "Bondholder",
    "participating_banks": "Participating Banks",
    "participating_percent": "% of participation",
    "register": "Register",
    "banks": "Bank/Servicer",
    "titles": "Titles",
    "bonds": "Bonds",
    "payment_representative": "Payment Representative/Representative of Bondholders",
    "replacement_mechanism": "Bondholder Representative replacement mechanism",
    "information": "Information",
    "Participating_banks_servicers": "Participating Banks/Servicers",
    "contact_details": "Contact Details",
    "nominal_value": "Nominal Value of Title",
    "issue_date": "Issue Date",
    "end_date": "End Date",
    "status": "Status",
    "from": "From",
    "to": "To",
    "portfolio_account": "Portfolio Account",
    "start_bond_number": "From",
    "end_bond_number": "To",
    "ar_sumbashs": "Loan Agreement",
    "bank_name": "Bank",
    "participation_percent": "Participating Banks %",
    "title_name": "Title"
  },
  "EntityChangesView": {
    "editor": "Editor",
    "fieldname": "Fieldname",
    "approver": "Approver",
    "approval_status": "Approval Status",
    "entity": "Entity",
    "field": "Field",
    "value": "Value",
    "inserted_at": "Inserted at",
    "decision_at": "Decision at",
    "select_entity_type": "Please select entity type"
  },
  "global_validation": {
    "portfolio_is_required": "Portfolio Name is required",
    "asset_owner_is_required": "Asset Owner is required",
    "mos_client_is_required": "MOS Client is required",
    "debtor_is_required": "Debtor is required",
    "loan_agreement_is_required": "Loan Agreement is required",
    "real_estate_is_required": "Real Estate is required",
    "nre_collateral_is_required": "NRE Collateral is required",
    "product_is_required": "Product is required",
    "collateral_number": "Collateral Number is required",
    "loan_agreement_number": "Loan Agreement Number is required",
    "type": "Collateral Type is required"
  },
  "ApprovalDetailsView": {
    "loan_agreement": "Loan Agreement",
    "approval_decision_send_date": "Approval decision send date",
    "account_manager": "Account Manager",
    "approval_decision_subject": "Approval decision subject",
    "mos_assignment_date": "MOS Assignment date",
    "mos_estimated_imlementation_date": "MOS Estimated imlementation date",
    "middle_officer": "Middle Officerς",
    "end_date": "End Date",
    "lawyer": "Lawyer",
    "comments": "Comments",
    "choose_client": "Please Select MOS Client",
    "choose_debtor": "Please Select Debtor",
    "loan_agreement_id": "Loan Agreement",
    "hmer_apost_egkrishs": "Approval decision send date",
    "thema_egkrishs": "Approval decision subject",
    "hmer_anatheshs_mos": "MOS Assignment date",
    "ektim_hmer_ylop": "MOS Estimated imlementation date",
    "hmer_olokl": "End Date"
  },
  "DDebtorDetailsView": {
    "details": "Clients Data",
    "extra_fields": "Indexing details",
    "cid": "CID",
    "portfolio": "Portfolio",
    "pelatis": "Πελάτης",
    "cor_contractcode": "COR Contract Code",
    "cor_sdocontractid": "COR SdoContract Id",
    "physical_contract_id": "Physical Contract Id",
    "cor_importdate": "COR Import Date",
    "securitized_perimeter": "Securitized perimeter",
    "analyt_thesi_fakel_ana_cid": "Αναλυτική θέση φακέλων ανά CID",
    "checked_not_included_incairo": "Checked Not included in Cairo",
    "fak_pistoux_cairo": "Φακέλλοι πιστούχων cairo",
    "denounced_flag": "Denounced Flag",
    "stoixeia_xeiristi": "Στοιχεία χειριστή",
    "anoigma": "Άνοιγμα",
    "elegxos": "Έλεγχος",
    "apografh": "Απογραφή",
    "elegxos_so1": "Έλεγχος - S.0.1",
    "stoixeia_pshfiop": "Στοιχεία Ψηφιοποίησης",
    "shmansh_barcode": "Σήμανση Barcode",
    "elegxos_so2_1": "Έλεγχος - S.O.2",
    "pshfiopoihsh": "Ψηφιοποίηση",
    "suskeuasia": "Συσκευασία",
    "elegxos_pmo": "Έλεγχος PMO",
    "stby_apostolhs": "STBY Αποστολής",
    "apostolh": "Αποστολή",
    "end_stand_by_apograf": "Ένδειξη Stand By Απογραφής",
    "sxolio": "Comment",
    "folders": "Scanning details",
    "downloadexportprot": "Export Protocol",
    "downloadexportdt": "Export Data Tape",
    "permissions": "You do not have permission to view this page.",
    "Please_select": "Please select portfolio for export",
    "cor_center": "COR Center",
    "physical_contract_id_copy": "Physical Contract Id COPY",
    "apografi": "Απογραφή",
    "sumvatika_eggrafa": "Loan Agreement Docs - 01",
    "sumvasi": "Σύμβαση",
    "symv_arithmos_zelatinon": "Αριθμός ζελατίνων",
    "symv_symv_prot": "Πλήθος πρωτοτύπων Συμβ.",
    "symv_symv_antig": "Πλήθος αντιγράφων Συμβ",
    "prosthetes_praxeis": "Πρόσθετες Πράξεις",
    "symv_prosth_prot": "Πλήθος πρωτοτύπων Πρ. Πράξης",
    "symv_prosth_antigr": "Πλήθος αντιγράφων Πρ. Πράξης",
    "symv_prosth_sxolio": "Σχόλιο",
    "nomimopoihtika": "Legalization Docs (Only for BLs) - 02",
    "praktikaGS_DS": "Πρακτικά ΓΣ/ΔΣ",
    "nomim_antig": "Πλήθος αντιγράφων Νομιμοποιητικών",
    "nomim_prot": "Πλήθος πρωτοτύπων Νομιμοποιητικών",
    "axiografa": "Bonds (Only for BLs) – 03",
    "axiogr_arithmos_zelatinon": "Αριθμός ζελατίνων αξιογράφων",
    "axiogr_prot": "Πλήθος πρωτοτύπων αξιογράφων",
    "axiogr_antig": "Πλήθος αντιγράφων αξιογράφων",
    "exasfalistika": "Collateral Docs - 04",
    "arithmos_zelatinon": "Αριθμός ζελατίνων",
    "prosim_upoth": "Προσημειώσεις / Υποθήκες",
    "prosim_plithos": "Πλήθος",
    "plithos_eggr": "Πλήθος εγγράφων",
    "enexiriasis_ekx": "Ενεχυριάσεις / Εκχωρήσεις",
    "enex_plithos_prot": "Πλήθος πρωτοτύπων Ενεχ. / Εκχ.",
    "enex_plithos_antig": "Πλήθος αντιγράφων Ενεχ. / Εκχ.",
    "epid_enexiriasis_ekx": "Επιδόσεις ενεχυριάσεων/εκχωρήσεων",
    "epid_enex_plithos_prot": "Πλήθος πρωτοτύπων Επιδόσεων Ενεχ. / Εκχ.",
    "epid_enex_plithos_antig": "Πλήθος αντιγράφων Επιδόσεων Ενεχ. / Εκχ.",
    "nomikes_energeies": "Legal Action Docs - 05",
    "nom_epist_kataggelias": "Επιστολές καταγγελίας",
    "nom_epist_kataggelias_prot": "Πλήθος πρωτοτύπων Επιστ. Καταγγ.",
    "nom_epist_kataggelias_antig": "Πλήθος αντιγράφων Επιστ. Καταγγ.",
    "nom_ekth_epid": "Εκθέσεις επίδοσης",
    "nom_ekthes_epid_prot": "Πλήθος πρωτοτύπων Εκθ. Επιδ.",
    "nom_ekthes_epid_antig": "Πλήθος αντιγράφων Εκθ. Επιδ.",
    "nom_diat_plir": "Διαταγές πληρωμής",
    "nom_diat_plir_prot": "Πλήθος πρωτοτύπων Διατ. Πληρ.",
    "nom_diat_plir_antig": "Πλήθος αντιγράφων Διατ. Πληρ.",
    "nom_epid_dp": "Επιδόσεις Δ.Π.",
    "nom_epid_dp_prot": "Πλήθος πρωτοτύπων Επίδ. Δ.Π.",
    "nom_epid_dp_antig": "Πλήθος αντιγράφων Επίδ. Δ.Π.",
    "nom_apogr": "Απόγραφο",
    "nom_apogr_prot": "Πλήθος πρωτοτύπων Απόγρ.",
    "nom_apogr_antig": "Πλήθος αντιγράφων Απόγρ.",
    "loipa": "Other Docs - 06",
    "eggrafa_ektos_DT": "'Εγγραφα εκτός DT",
    "sxolio_apogr": "Σχόλιο",
    "ccy": "Currency",
    "folder_barcode": "Folder Barcode",
    "container_barcode": "Box barcode",
    "gelatin_barcode": "Pocket Barcode",
    "portfolio_id": "Portfolio"
  },
  "DebtorDetailsView": {
    "individual": "Individual",
    "legal_entity": "Legal entity private law",
    "legal_entity_public": "Legal entity public law",
    "details": "Details",
    "legal_details": "Legal Details ",
    "legal_address": "Legal Address",
    "postal_address": "Postal Address",
    "rest_of_legal_details": "Rest of Legal Details",
    "cid": "CID",
    "mos_client": "MOS client",
    "portfolio": "Portfolio",
    "former_asset_owner_fao": "Former Asset Owner (FAO)",
    "cid_fao": "CID FAO",
    "customer_category": "Customer Category",
    "customer_type": "Customer Category",
    "legal_status": "Legal Status",
    "company_fullname": "Company Name / Full Name",
    "fullname": "Company Name / Full Name",
    "id_card_type": "ID Card Type",
    "id_card": "ID card",
    "tax_registration_nbr": "Tax Registration Nbr",
    "vat_number": "VAT number",
    "gemi": "GEMI",
    "gemh": "GEMI",
    "industrial_sector": "Industrial Sector",
    "klados": "Industrial Sector",
    "kad": "KAD",
    "street": "Street",
    "address": "Street",
    "street_number": "Street Number",
    "address_number": "Street Number",
    "region": "Region",
    "city": "City",
    "prefecture": "Prefecture",
    "district": "Prefecture",
    "zip_code": "Zip Code",
    "postcode": "Zip Code",
    "country": "Country",
    "phone": "Phone",
    "email": "Email",
    "phone_number": "Phone",
    "postal_street": "Postal Street",
    "postal_nbr": "Postal Nbr",
    "postal_address_number": "Postal Nbr",
    "postal_region": "Postal Region",
    "postal_city": "Postal City",
    "postal_prefecture": "Postal Prefecture",
    "postal_district": "Postal Prefecture",
    "postal_zip_code": "Postal Zip Code",
    "postal_postcode": "Postal Zip Code",
    "postal_country": "Postal Country",
    "legal_start_date": "Legalization Start Date",
    "hmer_enar_isx_nomim": "Legalization Start Date",
    "legal_end_date": "Legalization End Date",
    "hmer_liks_isx_nomim": "Legalization End Date",
    "social_security_clearance_issue_date": "Social Security Clearance Issue Date",
    "hmer_ekd_asfa_enim": "Social Security Clearance Issue Date",
    "social_security_clearance_end_date": "Social Security Clearance End Date",
    "hmer_liks_asfa_enim": "Social Security Clearance End Date",
    "mos_client_account_officer": "MOS Clients Account Officer",
    "mos_clients_acc_off": "MOS Clients Account Officer",
    "allowed_users": "",
    "approve_status": "Κατάσταση Έγκρισης",
    "group_id": "Group ID",
    "group_name": "Group Name",
    "loan_agreement": "Loan Agreement",
    "mos_officer": "MOS Officer",
    "supervisors": "Supervisors",
    "fao": "FAO"
  },
  "LoanAgreementDetails": {
    "status": "Status",
    "loan_agreement": "Loan Agreement",
    "loan_agreement_fao": "Loan Agreement FAO",
    "legal_status": "Legal Status",
    "product": "Product",
    "currency": "Currency",
    "total_amount": "Total Amount",
    "date_of_agreement": "Date of Agreement",
    "date_of_additional_arrangement": "Date of Amendment",
    "choose_agent": "Choose Agent",
    "agent": "Agent",
    "comments": "Comments",
    "initiation_of_legal_actions": "Denouncement Date",
    "serving_of_legal_actions": "Denouncement Servicing Date",
    "serving_fees": "Serving Fees",
    "payment_order": "Payment Order",
    "payment_order_details": "Payment Order Details",
    "process_interruption_margin": "Process Interruption Margin",
    "suspension_request": "Suspension Request",
    "suspension_request_margin": "Suspension Request Margin",
    "appraisal_by_a_certified_appraiser": "Appraisal by a certified appraiser",
    "appraisal_fees": "Appraisal Fees",
    "date_of_serving_of_confiscation_report": "Date of serving of confiscation report",
    "confiscation_amount": "Confiscation Amount",
    "confiscation": "Confiscation",
    "in_the_hands_of_a_third_party": "In the hands of a third party",
    "date_of_upcoming_auction": "Date of upcoming auction",
    "suspension_request_from": "Suspension Request From",
    "suspension_request_to": "Suspension Request To",
    "auction": "Auction",
    "paymentorders": "Payment Order Additions",
    "kefaleo": "Principal",
    "log_tokoi": "On balance interest",
    "mh_log_tokoi": "Off balance interest",
    "promithies_exoda": "Commissions/Expenses",
    "tokoi_promithion_exodon": "Commissions/Expenses Interest",
    "denouncedamount": "Denouncement amount: ",
    "ypovoli_paratiriseon": "Η ημερομηνία υποβολής παρατηρήσεων δεν είναι έως 5 ημέρες από τη λήξη της αναγγελίας απαίτησης.",
    "hmer_kai_poso_ofeilhs": "Date / Amount of Principal Payment",
    "arxikh_hmer_lukshs": "Original End Date",
    "nea_hmer_lukshs": "End Date",
    "vasi_ektokismou": "Year Base",
    "kathgor_epitok_bashs": "Base Rate",
    "perithorio": "Margin",
    "epitok_uperhm": "Overdue Rate",
    "eisfora_n128_1975": "Levy N128/75",
    "parakra_forou": "Tax Withholding",
    "oros_propliromis": "Description of Prepayment Right",
    "new_amendment_date": "New Amendment Date",
    "date_amount_repayment": "New Date/Amount",
    "debtor_id": "Debtor",
    "ar_sumbashs": "Loan Agreement",
    "ar_sumbashs_fao": "Loan Agreement FAO",
    "katast_sumbashs": "Legal Status",
    "synol_poso_sumbashs": "Total Amount",
    "hmer_sumbashs": "Date of Agreement",
    "hmer_tropop_sumbashs": "Date of Amendment",
    "hmer_enarx_dikast_energ": "Denouncement Date",
    "hmer_epidosis_dikast_energ": "Denouncement Servicing Date",
    "exoda_epidosis": "Serving Fees",
    "ekdosi_diatagis": "Payment Order",
    "diatagi_pl_hmek_hmep_ex_per": "Payment Orders",
    "aitisi_anastolis": "Suspension Request",
    "katasxesi": "Confiscation",
    "ektimisi": "Appraisal by a certified appraiser",
    "exoda_ektimisis": "Appraisal Fees",
    "hmer_epid_ekth_katasx": "Date of serving of confiscation report",
    "exoda_epidosis_ekth_katasx": "Serving Fees",
    "poso_katasxesis": "Confiscation Amount",
    "eis_xeiras_tritou": "In the hands of a third party",
    "hmer_eperx_pleist": "Date of upcoming auction",
    "perithorio_anakopis": "Process Interruption Margin",
    "perithorio_anastolis_apo": "Suspension Request From",
    "perithorio_anastolis_eos": "Suspension Request To",
    "denouncement_amount": "Denouncement amount",
    "tropos_apopl": "Payment Type",
    "hmer_enark_period_xaritos": "Start Date of Grace Period",
    "hmer_liksis_period_xaritos": "End Date of Grace Period",
    "program_apopl_kefal": "Principal Repayment Schedule",
    "hmer_prwths_doshs_kefal": "1st Principal Repayment Date",
    "program_apopl_tokon": "Interest Repayment Schedule",
    "hmer_protis_doshs_tokon": "1st Interest Payment Date",
    "plithos_doseon_kefal": "Number of Principal Payments",
    "dikaioma_propl": "Prepayment Right",
    "hmer_poso_ofeilhs": "Date / Amount of Principal Payment",
    "poso_diatagis_plhr": "Payment order amount",
    "empty": "Empty",
    "issue_date": "Issue Date",
    "first_serving_date": "1st Serving Date",
    "first_serving_fees": "Serving Fees",
    "second_serving_date": "2nd Serving Date",
    "new_payment_order": "New payment order",
    "pros_diatg_ektel_plhr": "Τemporary order for non-execution of a payment order",
    "telesidikh_apof": "Final Judgment"
  },
  "UploadView": {
    "upload_title": "Import Data",
    "id": "ID",
    "insert_timestamp": "Inserted at",
    "original_filename": "Original Filename",
    "saved_filename": "Saved Filename",
    "status": "Status",
    "username": "Username"
  },
  "LoanAccountDetails": {
    "loan_account_details": "Loan Account Details",
    "repayment_plan": "Repayment Plan",
    "balance": "Balance",
    "transactions": "Transactions",
    "loan_agreement": "Loan Agreement",
    "loan_account": "Loan Account",
    "original_loan_accound": "Original Loan Account",
    "previous_loan_accound": "Previous Loan Account",
    "currency": "Currency",
    "product": "Product",
    "start_date": "Start Date",
    "original_end_date": "Original End Date",
    "end_date": "End Date",
    "disbursment_date": "Disbursment Date",
    "disbursment_amount": "Disbursment Amount",
    "payment_type": "Payment Type",
    "start_date_of_grace_period": "Start Date of Grace Period",
    "end_date_of_grace_period": "End Date of Grace Period",
    "principal_repayment_schedule": "Principal Repayment Schedule",
    "1st_principal_payment_date": "1st Principal Repayment Date",
    "interest_repayment_schedule": "Interest Repayment Schedule",
    "1st_interest_payment_date": "1st Interest Payment Date",
    "number_of_principal_payments": "Number of Principal Payments",
    "date_of_principal_payment": "Date of Principal Payments",
    "amount_of_principal_payment": "Amount of Principal Payment",
    "prepayment_right": "Prepayment Right",
    "current_balance": "Current Balance",
    "due_principal": "Due Principal",
    "overdue_principal": "Overdue Principal",
    "on_balance_sheet_interest": "On balance sheet Interest",
    "interest_on_due_balance": "Interest on Due Balance",
    "interest_on_overdue_balance": "Interest on Overdue Balance",
    "interest_on_interest": "Interest on Interest",
    "off_balance_sheet_interest": "Off balance sheet Interest",
    "commissions_expenses": "Commissions / Expenses",
    "conditional_write_off_amount": "Conditional Write off amount",
    "accruals": "Accruals",
    "1st_delay_date": "1st delay date",
    "days_of_delay": "Days of delay",
    "hmer_tropop": "Amendment Date",
    "legal_status": "Legal Status",
    "base_rate": "Base Rate",
    "base_rate_value": "Base Rate Value",
    "margin": "Margin",
    "overude_rate": "Overdue Rate",
    "levy_N128/75": "Levy N128/75",
    "tax_withholding": "Tax Withholding",
    "presentation_of_certificate": "Presentation of Certificate",
    "payment_date": "Payment Date",
    "payment_amount": "Payment Amount",
    "principal_paid": "Principal Paid",
    "interest_paid": "Interest Paid",
    "expenses_paid": "Expenses Paid",
    "payment_components": "Payment Components",
    "next_installment_date": "Next Installment Date",
    "next_installment_principal_due": "Next Installment Principal Due",
    "next_installment_interest_due": "Next Installment Interest Due",
    "status": "Status",
    "hmer_proskom": "Date of Presentation",
    "date_of_current_balance": "Date of current balance",
    "loan_agreement_id": "Loan Agreement",
    "ar_trexontos_logariasmou": "Loan Account",
    "ar_arxikou_logariasmou": "Original Loan Account",
    "palaios_logariasmos": "Previous Loan Account",
    "hmer_enarkshs": "Payment Type",
    "arxikh_hmer_lukshs": "Original End Date",
    "nea_hmer_lukshs": "End Date",
    "hmer_ektam": "Disbursment Date",
    "poso_ektam": "Disbursment Amount",
    "trexon_upol": "Current Balance",
    "enhm_kefal": "Current Balance",
    "kathister_kefal": "Overdue Principal",
    "logistikop_tokoi": "On balance sheet Interest",
    "sumbatikoi_log_tokoi": "Interest on Due Balance",
    "yperhmerias_log_tokoi": "Interest on Overdue Balance",
    "n2601_1998_log_tokoi": "Interest on Interest",
    "mh_logistikop_tokoi": "Off balance sheet Interest",
    "eksoda": "Commissions / Expences",
    "diegram_poso": "Conditional Write off amount",
    "dedoul_tokoi": "Accruals",
    "hmer_prwths_kathister": "1st delay date",
    "hmeres_kathister": "Days of delay",
    "kathgor_epitok_bashs": "Base Rate",
    "epitok_bashs": "Επιτόκιο βάσης",
    "perithorio": "Margin",
    "epitok_uperhm": "Overdue Rate",
    "eisfora_n128_1975": "Levy N128/75",
    "parakra_forou": "Tax Withholding",
    "proskom_bebeos": "Presentation of Certificate",
    "logistikopoihmenoi": "On balance sheet Interest",
    "mhlogistikopoihmenoi": "Off balance sheet Interest",
    "pistwseis": "Credits",
    "xrewseis": "Debits",
    "doseis": "Installments",
    "tokoi": "Ημ/νίες Τοκισμού",
    "exoda": "Expenses",
    "synoliko_poso": "Total amount",
    "loan_capital_repayment_type": "Loan Capital Repayment Type",
    "loan_capital_repayment_frequency": "Loan Capital Repayment Frequency",
    "loan_capital_repayment_epeteiako": "Loan Capital Repayment Epeteiako",
    "loan_interest_repayment_frequency": "Loan Interest Repayment Frequency",
    "loan_interest_repayment_epeteiako": "Loan Interest Repayment Epeteiako",
    "loan_interest_change_frequency": "Interest Change Frequency",
    "loan_interest_change_epeteiako": "Interest Change Anniversary",
    "hmer_enarxis_xaritos": "Ημερομηνία έναρξης περ. χάριτος",
    "hmer_lixis_xaritos": "Ημερομηνία λήξης περ. χάριτος",
    "hmer_1is_dosis_tokon": "Ημερομηνία πρώτης δόσης τόκων",
    "kathisterimeno": "Καθυστερημένο εξαρχής",
    "kathisterimenoKEF": "Καθυστερημένο αρχικό κεφάλαιο",
    "kathisterimenoTOK": "Καθυστερημένοι αρχικοί τόκοι",
    "kathisterimenodate": "Καθυστερημένο αρχική ημερομηνία",
    "kathisterimeno_enim_arx": "Ενήμερο κεφάλαιο σε καθυστερημένο",
    "tokoitokwn": "Αρχικοί τόκοι τόκων καθυστερημένου",
    "arnitika_epitokia": "Αρνητικά επιτόκια",
    "pedia_upologismou": "Στοιχεία δανείου - Εκτοκισμός"
  },
  "Monitoring": {
    "id": "Internal DB ID",
    "short_desc": "Short description",
    "due_date": "Due date",
    "description": "Description",
    "actions_comments_clarifications": "Actions/ Comments/ Clarifications",
    "alarm_date": "Alarm date",
    "end_date": "End date",
    "mos_officer": "MOS Officer",
    "account_manager": "Account Manager",
    "obligations": "Obligations",
    "recruit_final_stat": "Requirments as to financial statements",
    "comments": "Comments",
    "account_manager_financials": "Account Manager",
    "insurance_company": "Insurance company",
    "insurance_policy": "Insurance policy",
    "start_date": "Start date",
    "end_date_as": "End date",
    "alarm_date_as": "Alarm date",
    "object_of_insurance": "Object of insurance",
    "insurance_risk": "Insurance risk",
    "insured_capital": "Insured capital",
    "cession_of_insurance_policy": "Cession of Insurance policy",
    "actions_comments_clarifications_as": "Actions/ Comments/ Clarifications",
    "type_of_agency_fee": "Type of Agency Fee",
    "agency_fee_amount": "Agency Fee amount",
    "agency_fee_percentage_first": "Agency Fee percentage",
    "agency_fee_percentage_second": "Agency Fee",
    "fee_payment_freq": "Fee payment frequency",
    "due_date_p": "Due Date",
    "alarm_date_p": "Alarm date",
    "payment_date": "Payment date",
    "comments_p": "Comments",
    "pending_issues": "Pending Issues",
    "financials": "Financials Ενήμερων και ρυθμισμένων",
    "asfalistiria": "Ασφαλιστήρια Συμβόλαια",
    "promitheies": "Προμήθειες",
    "monitoring_type": "Monitoring type",
    "loan_agreement_id": "Loan Agreement"
  },
  "RealEstateDetails": {
    "id": "Internal DB ID",
    "information": "Real Estate Details",
    "apartment_information": "Apartment Information",
    "arithmos_akinitou_diax": "Real Estate Nbr",
    "eidos": "Type of Property",
    "address": "Street",
    "address_number": "Address Number",
    "region": "Region",
    "city": "City",
    "district": "Prefecture",
    "postalcode": "Zip Code",
    "country": "Country",
    "google_map_link": "Google map link",
    "description": "Description",
    "constr_date": "Date of construction",
    "eidos_idioktisias": "Ownership Type",
    "percentage_property": "Ownership %",
    "antikeim_aksia_akin_rev": "Objective RE Value",
    "ektim_aksias_akin": "RE Valuation",
    "eidos_ektim": "Type of Valuation",
    "hmer_ektim": "Date of Valuation",
    "ektimiths": "Valuator",
    "dikaiouxos": "Beneficiary",
    "kthmatolog_graf": "Cadastral Office",
    "kaek": "KAEK",
    "ypothikof": "Mortgage Office",
    "tomos": "Volume",
    "fullo": "Page",
    "number": "Number",
    "poleod_parabaseis": "Urban Planning Violations",
    "ektim_kostos_taktop": "Estimated Settlement Cost",
    "tm_oikopedou": "Plot square meters",
    "tm_ktismatos": "Building square meters",
    "tm_apothikhs": "Warehouse square meters",
    "tm_thesis_stathmeushs": "Parking Space square meters",
    "orofoi": "Number of floors",
    "type_of_prenotation": "Type of Prenotation",
    "construction_status": "Construction status",
    "liquidation_value": "Liquidation Value",
    "approve_status": "Approve Status",
    "debtor": "Debtor CID",
    "type_of_property": "Type of Property"
  },
  "re_ownershiptype": {
    "full_ownership": "Full ownership",
    "bare_ownership": "Bare ownership",
    "usufruct": "Usufruct",
    "right_of_residence_for_life": "Right of residence for life",
    "air_right": "Air Right"
  },
  "re_typeofproperty": {
    "field": "Field",
    "commercial": "Commercial",
    "residential": "Residential",
    "office": "Office",
    "landplot": "Landplot",
    "industrial": "Industrial",
    "hotel": "Hotel",
    "null": " "
  },
  "real_estate_collateral": {
    "loan_agreement_number": "Loan Agreement",
    "collateral_number": "Collateral Number",
    "original_collateral_nbr": "Original Collateral Number",
    "real_estate_nbr": "Real Estate Number",
    "type_of_prenotation": "Type of Prenotation",
    "lien": "Lien",
    "prenotation_amount": "Prenotation Amount",
    "total_prenotation_amount": "Total Prenotation Amount",
    "perc_of_participation": "% of Participation",
    "prenotation_summary": "Prenotation Summary",
    "transfer_of_prenot_to_cadastral": "Transfer of Prenotation to Cadastral / Mortgage Office",
    "volume": "Volume",
    "page": "Page",
    "number": "Number",
    "insurer": "Insurer",
    "insurance_policy_nbr": "Insurance policy number",
    "cession_of_insurance_policy": "Cession of Insurance policy",
    "legal_status": "Legal Status",
    "comments": "Comments",
    "lhksh_asfalistiriou": "End Date Of Insurance",
    "loan_agreement_id": "Loan Agreement",
    "ar_kalummatos": "Collateral Number",
    "arxikos_arith_kalummatos": "Original Collateral Number",
    "real_estate_id": "Real Estate Number",
    "eidos_prosim": "Type of Prenotation",
    "seira_prosim": "Lien",
    "poso_prosim": "Prenotation Amount",
    "sunoliko_poso_prosim": "Total Prenotation Amount",
    "pososto_summetoxhs": "% of Participation",
    "perilhps_eggrafhs_prosim": "Prenotation Summary",
    "meteggrafh_se_ypothikof_ktimat_graf": "Transfer of Prenotation to Cadastral / Mortgage Office",
    "tomos": "Volume",
    "fullo": "Page",
    "asfal_foreas": "Insurer",
    "ar_asfal": "Insurance policy number",
    "ekxor_asfalist": "Cession of Insurance policy",
    "pistop_ypoth_ktimatol": "Cadastral / Mortgage Office Certificates",
    "kalimaNRE": "Collateral NRE",
    "synol_poso_sumbashs": "Total Amount"
  },
  "TypeofPrenotation": {
    "sunenetikh": "CONSENSUAL PRENOTATION",
    "anagkastikh": "COMPULSORY PRENOTATION",
    "sunenetikh_ypothiki": "CONSENSUAL MORTGAGE",
    "anagkastikh_ypothiki": "COMPULSORY MORTGAGE",
    "null": " "
  },
  "GuaranteesDetails": {
    "loan_agreement_number": "Loan Agreement Number",
    "debtor_cid": "Debtor CID",
    "full_agreement_guarantee": "Full Agreement Guarantee",
    "guaranteed_amount": "Guaranteed Amount",
    "comments": "Comments",
    "loan_agreement_id": "Loan Agreement",
    "egguhsh_sunolou_sumbas": "Full Agreement Guarantee",
    "poso_egg": "Guaranteed Amount",
    "tautopoihsh": "Validation",
    "epipleonnomeggrafa": "Other Documents"
  },
  "GreekStateGuarantees": {
    "loan_agreement": "Loan Agreement",
    "kya_of_greek_state": "ΚΥΑ of Greek State Guarantee Provision",
    "guaranteed_amount": "Guaranteed Amount",
    "contractual_document": "Contractual Document",
    "contract_servicing": "Contract Servicing",
    "monitoring_of_debt_25th": "Monitoring of debt and informing of the 25th Administration",
    "delivery_of_comm_25th": "Delivery of commisions to the 25th Administration",
    "claim_limitation_period": "Claim limitation period",
    "forfeiture_actions": "Forfeiture actions",
    "kua_paroxhs_eed": "ΚΥΑ of Greek State Guarantee Provision",
    "poso_eggyhseos": "Guaranteed Amount",
    "sumbatiko_eggrafo": "Contractual Document",
    "epidosh_sumbas_se_dhmos_foreis": "Contract Servicing",
    "parakolouth_ofilon": "Monitoring of debt and informing of the 25th Administration",
    "apodosh_prom": "Delivery of commisions to the 25th Administration",
    "prothesmia_paragraf_apet": "Claim limitation period",
    "energies_katapt": "Forfeiture actions",
    "loan_agreement_id": "Loan Agreement"
  },
  "AuctionsDetails": {
    "auction_program_nbr": "Auction program number",
    "auction_date": "Auction Date",
    "declaration_of_claim": "Declaration of Claim",
    "claimant": "Claimant",
    "auction_initial_price": "Auction Initial Price",
    "auction_status": "Auction Status",
    "auction_price": "Auction Price",
    "bidder": "Bidder",
    "sufficient_auction_amount": "Sufficient Auction Amount",
    "submission_of_comments": "Submission of comments",
    "compiling_of_ranking_table": "Compiling of Ranking Table",
    "date_of_suspension": "Date of Suspension",
    "amount_attributed_to_client": "Amount attributed to Client",
    "collection_by_Letter_of_Guarantee": "Collection by Letter of Guarantee",
    "issuing_bank": "Issuing Bank",
    "issue_date": "Issue Date",
    "lg_amount": "LG Amount",
    "lg_commission": "LG Commission",
    "beneficiary": "Beneficiary",
    "maturity": "Maturity",
    "attorney_fees": "Attorney's Fee",
    "certified_appraisers_fee": "Certified Appraiser's Fee",
    "date_of_previous_auction": "Date of Previous Auction",
    "price_of_previous_auction": "Price of Previous Auction",
    "status_of_previous_auction": "Status of Previous Auction",
    "third_part_id": "Third Party ID",
    "ar_prog_pl": "Auction program number",
    "hmer_pl": "Auction Date",
    "anag_apetisis": "Declaration of Claim",
    "epispeudon": "Claimant",
    "timh_pl": "Auction Initial Price",
    "katastash": "Auction Status",
    "timh_katak": "Τιμή κατακύρωσης",
    "pleiodotis": "Bidder",
    "eparkes_pl": "Sufficient Auction Amount",
    "yp_parat": "Submission of comments",
    "suntak_pinaka_katat": "Compiling of Ranking Table",
    "hmer_anakophs": "Date of Suspension",
    "poso_ana_client": "Amount attributed to Client",
    "eisp_me_egg_lf": "Collection by Letter of Guarantee",
    "ekd_trapeza_ib": "Issuing Bank",
    "hmer_ekd": "Issue Date",
    "dikaiouxos_text": "Beneficiary",
    "prothesmia_liksis": "Maturity",
    "eksoda_dikhgorou": "Attorney's Fee",
    "eksoda_pistop_ektim": "Certified Appraiser's Fee",
    "hmer_prohg_pl": "Date of Previous Auction",
    "timh_prohg_pl": "Price of Previous Auction",
    "katastash_prohg_pl": "Status of Previous Auction",
    "lg_comission": "LG Commission",
    "debtor_id": "CID Debtor",
    "real_estate_id": "Real Estate Nbr",
    "ar_kalummatos": "Collateral Number",
    "re_collateral_id": "Collateral Number"
  },
  "AuctionsStatusList": {
    "successful": "Successful",
    "fruitless": "Fruitless",
    "suspended": "Suspended",
    "frustrated": "Frustrated",
    "cancelled": "Cancelled"
  },
  "NRECollateralsDetails": {
    "loan_agreement": "Loan Agreement",
    "collateral_number": "Collateral Nbr",
    "ar_kalimatos": "Collateral Nbr",
    "em_ekd_etair_metox": "Issuing Stock Company",
    "em_katox_metox": "Stockholder",
    "em_sumbash_enexurou": "Pledge Agreement",
    "em_plithos_metoxon": "Number of shares",
    "em_onomastikh_timh": "Nominal Value",
    "em_dhlosh_protheshs_metabib": "Declaration of intent to transfer or offer as collateral pledged",
    "em_hmer_epidoshs": "Servicing Date",
    "mem_ekdo_eteria_metox": "Issuing Stock Company",
    "mem_katoxos_metox": "Stockholder",
    "mem_sumbash_enexurou": "Pledge Agreement",
    "mem_plithos_metoxon": "Number of shares",
    "mem_onom_aksia_metox": "Nominal Values",
    "mem_sunol_onomas_aksia_metox": "Total Nominal Value",
    "mem_epishm_metox_biblio_metox": "Share and shareholder book annotations",
    "mem_hmer_epidoshs": "Servicing Date",
    "k_dikaiouxos_katath": "Beneficiary",
    "k_sumbash_enex": "Pledge Agreement",
    "k_sumbatika_eggrafa": "Contractual Document",
    "k_trapeza": "Bank",
    "k_iban": "IBAN",
    "k_ypol": "Balance",
    "k_hmer_ypol": "Date of balance",
    "ea_sumbash_enex": "Pledge Agreement",
    "ea_dikaiol_enexur_apait": "Evidence of the pledged claim",
    "ea_gnost_sust_enex": "Notice of Pledge",
    "ea_hmer_epidoshs": "Servicing Date",
    "tp_sumbash_enex": "Pledge Agreement",
    "tp_hmer_epidoshs": "Servicing Dates",
    "tp_sxetikh_praksh": "Relevant Act",
    "tp_ypogr_titl_paegae": "Sign of titles",
    "tp_sumbatika_eggrafa": "Contractual Document",
    "tp_sumbas_enex_apait_": "Pledge Agreement of Insurance policy",
    "e_sumbatika_eggrafa": "Contractual Document",
    "e_pinakio_epitagon": "Checks Table",
    "e_sxetika_dikaiol": "Other documents",
    "e_log_takseos": "Off Balance Account",
    "e_xor_log": "Loan Account",
    "e_log_xre_eks_prom": "Commission Account",
    "e_egkekr_orio_diasp_ana_ekdoth": "Approved Spread Limit per Issuer",
    "e_nomotipo_ekdos_epitaghs": "Legal issuing of check",
    "e_sumf_poson_epitagis": "Amount agreement",
    "e_diaspora_ana_ekdoth": "Spread per Issuer",
    "e_ypar_kartel_xreost_ypol": "Existence of account and debit balance",
    "e_hmer_ekd_epitaghs": "Issuing Date",
    "e_end_sfragishs_epit": "Stamped check",
    "e_loipa_stoixeia_epitaghs": "Check details",
    "s_eborikotita": "Commerciality",
    "s_pinakio_sunal": "Bill of Exchange Table",
    "s_sxetika_dikaol": "Related Documents",
    "s_ypo_ait_pa_meta_sunal": "Signed request for the receipt of Bill of Exchange",
    "s_log_takseos": "Off Balance Account",
    "s_xor_log_sund_takseos": "Loan Account",
    "s_log_xre_eks_prom": "Commission Account",
    "s_egkek_orio_diasporas_ekd": "Spread Limit per Issuer",
    "s_nom_ekd_epit": "Legal issuing of check",
    "s_sumf_poson_epit": "Amount agreement",
    "s_hmer_ekd_epit": "Issue Date",
    "s_ypar_kart_xreost_ypol": "Issue Date",
    "s_diasp_ana_ekd": "Spread per Issuer",
    "s_ypar_dusmenon_stoix": "Default credit behavior",
    "s_ypog_sumbash": "Signed contract",
    "kel_eidos": "Type",
    "kel_idioktisia": "Property",
    "kel_poso": "Amount",
    "loan_agreement_id": "Loan Agreement",
    "type": "Type of Collateral",
    "listed_shares": {
      "issuing_stock_company": "Issuing Stock Company",
      "stockholder": "Stockholder",
      "pledge_agreement": "Pledge Agreement",
      "nbr_of_shares": "Number of shares",
      "nominal_value": "Nominal Value",
      "declaration_of_intent": "Declaration of intent to transfer or offer as collateral pledged",
      "servicing_date": "Servicing Date"
    },
    "unlisted_shares": {
      "issuing_stock_company": "Issuing Stock Company",
      "stockholder": "Stockholder",
      "pledge_agreement": "Pledge Agreement",
      "nbr_of_shares": "Number of shares",
      "nominal_value": "Nominal Values",
      "total_nominal_value": "Total Nominal Value",
      "share_and_shareholder_book_annotations": "Share and shareholder book annotations",
      "servicing_date": "Servicing Date"
    },
    "deposits": {
      "beneficiary": "Beneficiary",
      "pledge_agreement": "Pledge Agreement",
      "contractual_document_agreement": "Contractual Document",
      "bank": "Bank",
      "iban": "IBAN",
      "balance": "Balance",
      "date_of_balance": "Date of balance"
    },
    "pledged_claims": {
      "pledged_agreement": "Pledge Agreement",
      "evidence_of_the_pledged_claim": "Evidence of the pledged claim",
      "notice_of_pledge": "Notice of Pledge",
      "servicing_date": "Servicing Date"
    },
    "Warehouse_receipt_(PAEGAE)": {
      "pledge_agreement": "Pledge Agreement",
      "servicing_date": "Servicing Dates",
      "relevant_act": "Relevant Act",
      "sign_of_titles": "Sign of titles",
      "contractual_document": "Contractual Document",
      "pledge_agreement_of_insurance_policy": "Pledge Agreement of Insurance policy"
    },
    "checks": {
      "contractual_document": "Contractual Document",
      "checks_table": "Checks Table",
      "other_documents": "Other documents",
      "off_balance_account": "Off Balance Account",
      "loan_account": "Loan Account",
      "commission_account": "Commission Account",
      "approved_spread_limit_per_issuer": "Approved Spread Limit per Issuer",
      "legal_issuing_of_check": "Legal issuing of check",
      "amount_agreement": "Amount agreement",
      "spread_per_issuer": "Spread per Issuer",
      "existence_of_account_and_debit_balance": "Existence of account and debit balance",
      "issuing_date": "Issuing Date",
      "stamped_check": "Stamped check",
      "check_details": "Check details"
    },
    "bill_of_exchange": {
      "commerciality": "Commerciality",
      "bill_of_exchange_table": "Bill of Exchange Table",
      "related_documents": "Related Documents",
      "signed_request_for_the_receipt": "Signed request for the receipt of Bill of Exchange",
      "off_balance_account": "Off Balance Account",
      "loan_account": "Loan Account",
      "commission_account": "Commission Account",
      "spread_limit_per_issuer": "Spread Limit per Issuer",
      "legal_issuing_of_check": "Legal issuing of check",
      "amount_agreement": "Amount agreement",
      "issue_date": "Issue Date",
      "issue_date_prepei": "Issue Date",
      "existence_of_account_and_ebit_balance": "Existence of account and debit balance",
      "spread_per_issuer": "Spread per Issuer",
      "default_credit_behavior": "Default credit behavior",
      "signed_contract": "Signed contract"
    },
    "movable_collaterals": {
      "type": "Type",
      "property": "Property",
      "amount": "Amount"
    }
  },
  "nre_collaterals_category": {
    "listed_shares": "Listed Shares",
    "unlisted_shares": "Unlisted Shares",
    "deposits": "Deposits",
    "pledged_claims": "Pledged Claims",
    "warehouse_receipt": "Warehouse Receipit",
    "checks": "Checks",
    "bill_of_exchange": "Bill of Exchange",
    "movable_collaterals": "Movable Collaterals",
    "collateral_type": "Collateral Type"
  },
  "ListedSharesView": {
    "issuing_stock_company": "Issuing Stock Company",
    "stockholder": "Stockholder",
    "nominal_value": "Nominal Value",
    "servicing_date": "Servicing Date"
  },
  "UnlistedSharesView": {
    "issuing_stock_company": "Issuing Stock Company",
    "stockholder": "Stockholder",
    "total_nominal_value": "Total Nominal Value",
    "servicing_date": "Servicing Date"
  },
  "DepositsView": {
    "beneficiary": "Beneficiary",
    "pledge_agreement": "Pledge Agreement",
    "bank": "Τράπεζα",
    "date_of_balance": "Date of balance"
  },
  "PledgedClaimsView": {
    "pledge_agreement": "Pledge Agreement",
    "evidence_of_the_pledged_claim": "Evidence of the pledged claim",
    "servicing_date": "Servicing Date"
  },
  "WarehouseReceiptView": {
    "pledge_agreement": "Pledge Agreement",
    "servicing_date": "Servicing Date",
    "sign_of_titles": "Sign of titles",
    "contractual_document": "Contractual Document"
  },
  "ChecksView": {
    "contractual_document": "Contractual Document",
    "checks_table": "Checks Table",
    "off_balance_account": "Off Balance Account",
    "issue_date": "Issuing Date"
  },
  "BillOfExchangeView": {
    "commerciality": "Commerciality",
    "bill_of_exchange_table": "Bill of Exchange Table",
    "off_balance_account": "Off Balance Account",
    "issue_date": "Issuing Date"
  },
  "MovableCollateralsView": {
    "type": "Type",
    "property": "Property",
    "amount": "Amount"
  },
  "SummaryChecks": {
    "basic_data": "Basic Data",
    "cid": "Customer ID",
    "summary_check_date": "Summary Check Date",
    "basic_check_note": "Financing Details",
    "details_of_contract": "Details of Contract",
    "type_of_financing": "Type of financing",
    "guarantees": "Guarantees",
    "orth_plirot_ypografon": "Correctness - Completeness - Signatures",
    "payment_representative": "Payment Representative/Representative of Bondholders",
    "replacement_mechanism": "Bondholder Representative replacement mechanism",
    "summet_trapezes": "Bondholders",
    "contact_information": "Contact Information",
    "bank_representative": "Bank Representative",
    "creditor_signatures": "Creditor Signatures",
    "signatures_of_guarantors": "Signatures of Guarantors",
    "skopos_ekdosis": "Purpose of publication",
    "extension_of_duration": "Extension of Duration",
    "account_details": "Account Details",
    "product_type": "Type of Loan",
    "loan_account_number": "Account Number",
    "repayment": "Repayment",
    "interests": "Interest",
    "capital": "Principal",
    "total_amount": "Total Amount",
    "collaterals_control": "Collaterals Control",
    "property_type": "Property Type",
    "address": "Address",
    "individual_guarantees": "Individual Guarantees",
    "legal_entities_guarantees": "Legal Entities Guarantees",
    "greek_state_guarantees": "Greek State Guarantees",
    "shares": "Shares",
    "portfolio": "Portfolio",
    "name": "Company Name/Full Name",
    "afm": "Tax Registration Nbr.",
    "gemi": "GEMI",
    "klados": "Industrial Sector",
    "lixi_nom": "Legalization End Date",
    "street": "Street",
    "street_number": "Street Number",
    "region": "Region",
    "city": "City",
    "prefecture": "Prefecture",
    "zip_code": "Zip Code",
    "country": "Country",
    "phone": "Phone",
    "postal_street": "Street",
    "postal_nbr": "Nbr",
    "postal_region": "Region",
    "postal_city": "City",
    "postal_prefecture": "Prefecture",
    "postal_zip_code": "Zip Code",
    "postal_country": "Country",
    "email": "Email",
    "account_manager": "Account Manager (FL)",
    "loan_agreement": "Loan Agreement",
    "bank": "Bondholder",
    "pososto": "% of participation",
    "contact_details": "Contact details",
    "total_amount_la": "Total Amount",
    "tropopoihseis": "Date of Amendment",
    "paratasi_diarkeias": "End Date",
    "bonds": "Bonds",
    "imer_kalipsis_ektam": "Date of Disbursement",
    "ektam_poso": "Disbursed Amount",
    "propliromi": "Repayment Right",
    "empragmates": "RE Collaterals",
    "texniki_ektimisi": "Valuation",
    "enoxikes": "Guarantees",
    "ekxoriseis": "Pledged Claims",
    "uparxi_ekx_ap": "Existance of Pledged Claims",
    "lixi_ekx_asf": "End date of Cessed Insurance Policies",
    "ekxorisi_xreogr": "Cession of Securities",
    "checks": "Cheques",
    "promitheies": "Commissions",
    "idle": "Idle",
    "anadoxis": "Underwriting - Organization",
    "diaxeirisis": "Management",
    "tropopoihshs": "Modification",
    "waiver": "Representative of Bondholders Waiver Fee",
    "waiver_gia_kathe": "Bondholder Waiver Fee",
    "upoxr_plirof_clauses": "Information Obligations (clauses)",
    "gegonota": "Grounds for denouncement (clauses)",
    "limitation_period": "Limitation Period",
    "ekdosi_diatagis_pliromis": "Payment Order Date and Timeframe for turning a prenotation to mortgage",
    "paratiriseis": "Comments - Pending issues",
    "elegxos_sumvasis": "Pledge Agreement Check",
    "elegxos_epidosis": "Servicing Check",
    "eispraxi_gegenimenon": "Collection of Claims",
    "onomateponimo": "Company Name / Full Name",
    "personal_corp_guar": "Personal / Corporate Guarantee",
    "guar_name": "Company Name / Full Name",
    "tautopoihsh": "Validation",
    "epipleon_nom_egg": "Legalization Documents",
    "upothikes_pros": "Prenotations / Mortgages",
    "katoxos_akinitou": "Real Estate Owner",
    "pistopoihtika_upoth": "Cadastral / Mortgage Office Certificates",
    "asfalistiria_sumv": "Insurance Policies",
    "ekxorisi_asf": "Cession Of Insurance Policy",
    "lixi_asf": "End Date Of Insurance",
    "ekxorisi_apait_tritou": "Pledged Claims",
    "sumvasi_enex": "Pledge Agreement",
    "episimiosis_met": "Share And Shareholder Book Annotations",
    "egguhsh_ell_dimosiou": "Greek State Guarantees",
    "epipleon_eggrafa": "Other Documents",
    "null": " "
  },
  "CollateralTypes": {
    "listed_shares": "Listed Shares",
    "unlisted_shares": "Unlisted shares",
    "deposits": "Deposits",
    "pledged_claims": "Pledged Claims",
    "warehouse_receipt": "Warehouse Receipt (PAEGAE)",
    "checks": "Checks",
    "bill_of_exchange": "Bill of exchange",
    "movable_collaterals": "Movable Collaterals"
  },
  "PlatformSettingsView": {
    "agents": "Bondholders",
    "agents_details": "Bondholder Details",
    "currencies": "Currencies",
    "currencies_details": "Currency",
    "loan_agreement_product": "Loan Agreement Products",
    "interests": "Interests",
    "kad": "Business Activity Code",
    "klados": "Business Activity",
    "loan_account_product": "Loan Account Products",
    "short": "Shorts",
    "short_details": "Short",
    "new_agent": "New Bondholder",
    "agent_name": "Bondholder Name",
    "agent_delete_message": "Are you sure you want to delete this bondholder ?",
    "currency_code": "Codes",
    "currency_code_details": "Code",
    "symbol": "Symbols",
    "symbol_details": "Symbol",
    "new_currency": "New Currency",
    "currency_delete_message": "Are you sure you want to delete this currency ?",
    "summary_check_type": "Summary Check Types",
    "la_product_details": "Loan Account Product",
    "la_summarycheck_details": "Summary Check Type",
    "la_greek_trans": "Greek Translations",
    "la_english_trans": "English Translations",
    "la_new_product": "New Loan Account Product",
    "la_delete_message": "Are you sure you want to delete this Loan Account Product ?",
    "interest_categories": "Interest Categories",
    "interest_categories_details": "Interest Category",
    "new_interest_category": "New Interest Category",
    "interest_rate": "Interest Rate",
    "interest_value_date": "Interest Value Date",
    "description": "Description",
    "description_details": "Descriptions",
    "kad_details": "Activity Code",
    "new_kad_code": "New Activity Code",
    "kad_delete_message": "Are you sure you want to delete this activity code ?",
    "klados_code": "Business Activity",
    "klados_code_details": "Business Activity",
    "new_klados_code": "New Business Activity",
    "loan_account_product_details": "Loan Account Product",
    "new_loan_account_product": "New Product",
    "prefectures": "Prefectures",
    "prefecture_name": "Prefecture",
    "new_prefecture": "New Prefecture",
    "koinopraktiko": "Κοινοπρακτικό",
    "omologiako": "Ομολογιακό",
    "prefecture_delete_message": "Are you sure you want to delete this prefecture ?",
    "postal_codes": "Postal Codes",
    "new_postalcode": "New Postal Code",
    "postalcode_name": "Postal Code",
    "postalcode_delete_message": "Are you sure you want to delete this postal code ?",
    "countries": "Countries",
    "new_country": "New Country",
    "country_name": "Country",
    "country_delete_message": "Are you sure you want to delete this country ?",
    "argies": "Holidays",
    "new_holiday": "New Holiday",
    "argia_name": "Holiday",
    "argia_delete_message": "Are you sure you want to delete this holiday ?",
    "short_descriptions": "Short Descriptions",
    "short_desc_delete_message": "Are you sure you want to delete this short description ?",
    "new_short_desc": "New short description",
    "short_desc": "Short description",
    "ins_comps": "Insurance Companies",
    "inscomp_delete_message": "Are you sure you want to delete this insurance company ?",
    "ins_comp": "Insurance Company",
    "new_ins_comp": "New insurance company",
    "obj_of_ins": "Object of insurance",
    "obj_of_inss": "Objects of insurance",
    "obj_of_ins_delete_message": "Are you sure you want to delete this object of insurance ?",
    "new_obj_of_ins": "New object of insurance",
    "insurance_risk": "Insurance Risk",
    "ins_risks": "Insurance risks",
    "ins_risk": "Insurance risk",
    "inst_risk_delete_message": "Are you sure you want to delete this insurance risk ?",
    "new_ins_risk": "New insurance risk",
    "type_of_agency_fee": "Type of Agency Fee",
    "config_type_of_agency_fees": "Τype of agency fees",
    "type_of_agency_fees_delete_message": "Are you sure you want to delete this type of agency fee ?",
    "new_type_of_agency_fee": "New type of agency fee",
    "fee_payment_freq": "Fee payment frequency",
    "config_fee_payment_freqs": "Fee payment frequency",
    "fee_delete_message": "Are you sure you want to delete this type of fee payment frequency ?",
    "new_fee": "New fee payment",
    "groups": "Groups",
    "group_id": "Group ID",
    "group_name": "Group Name",
    "new_group": "New Group",
    "group_details": "Group",
    "group_delete_message": "Are you sure you want to delete this group ?",
    "min_tk": "Minimum Postalcode",
    "max_tk": "Maximum Postalcode",
    "new_printer": "New Printer",
    "printer_details": "Printer Details",
    "printer_delete_message": "Are you sure you want to delete this printer ?",
    "automato_epitokio": "Automatic Interest",
    "calculateArgies": "Calculate Holidays",
    "allhloxreo": "Allhloxreo"
  },
  "DigitizedGelatin": {
    "Conventional": "Loan Agreement Docs (01)",
    "Legalization": "Legalization Docs (02)",
    "Securities": "Bonds (03)",
    "Surety": "Collateral Docs (04)",
    "LegalAction": "Legal Action Docs (05)",
    "Miscellaneous": "Other Docs (06)"
  },
  "DebitCreditList": {
    "date": "Date",
    "amount": "Amount",
    "newamount": "New Amount",
    "newdate": "New Date",
    "newstatus": "New Status",
    "addition": "Addition",
    "removal": "Removal",
    "perigrafi": "Description",
    "newperigrafi": " New Description"
  },
  "ProjectsList": {
    "name": "Projects",
    "arithmos_aitisis":"Αριθμός αίτησης",
    "customer_code":"Customer Code",
    "hmer_anatheshs": "Ημερομηνία Ανάθεσης",
    "customer":"Customer",
    "projectTemplate":"Project Template"
  },
  "ProjectsTemplatesList": {
    "name": "Project Templates",
    "name_el": "Project Template Greek Name",
    "name_en": "Project Template English Name"
  },
  "TaskTemplatesList": {
    "name": "Task Templates",
    "name_el": "Task Template Greek Name",
    "name_en": "Task Template English Name"
  },
  "CustomField": {
    "name_el":"Field name Greek",
    "name_en":"Field name English",
    "type":"Type",
    "status":"Status",
    "CustomField":"Custom Fields",
    "text":"Text",
    "date":"Date",
    "number":"Number",
    "list":"Dropdown List",
    "boolean":"Yes/No",
    "status_list": "Status List",
    "money": "Money"
  },
  "ProjectTaskHistory": {
    "project_task": "Project Task",
    "field": "Field",
    "value": "Value",
    "timestamp": "Timestamp"
  },
  "Apografi_Frontier":{
    "borrower_id":"Borrower ID 30_6_2020",
    "borrower_name":"Borrower Name",
    "asset_class":"Asset Class",
    "contract_id":"Contract ID 30_6_2020",
    "loan_id_31_12_2021":"Loan ID 31_12_2021",
    "origination_date":"Origination_Date of Loan ID 31_12_2021",
    "loan_type_31_12_2021":"Loan Type (as Loan ID 31_12_2021)",
    "loan_id_open_date_31_12_2021":"Loan ID 31_12_2021 Open Date",
    "last_restructured_cut_off":"Last Restructured <> Cut-off",
    "loan_id_30_06_2020":"Loan ID 30_6_2020",
    "origination_date_loan_id_30_06_2020":"Origination_Date of Loan ID 30_6_2020",
    "looking_for_initial":"Looking for Initial?",
    "looking_for_last":"Looking for Last?",
    "looking_for_collateral":"Looking for Collateral?",
    "breach_category":"Breach Category",
    "management_branch_31_12_2021_meta_609":"Management Branch 31_12_2021 μετά από αντικατάσταση τουν 609",
    "priority":"Priority",
    "activation_branch":"Activation Branch",
    "management_branch_30_06_2020":"Management Branch 30_6_2020",
    "management_branch_31_12_2021_me_609_pro_antik":"Management Branch 31_12_2021 with 609 προ αντικατάστασης",
    "dms_status":"DMS Status",
    "iron_mountain_deliv_status":"Iron Mountain Delivery Status",
    "dovlaue_delivery_status":"DoValue Delivery Status physical file",
    "kwd":"Κωδ",
    "katastasi":"Κατάσταση",
    "sxolio":"Σχολιο",
    "anathesi_mo_officer":"Ανάθεση MO Officer",
    "hmerominia_enarxis_epexergasias":"Ημ/νία έναρξης επεξεργασίας",
    "imerominia_olokl_epexerg":"Ημ/νία ολοκλήρωσης επεξεργασίας",
    "mo_supervisor":"MO Supervisor",
    "imeromhnia_elegxou":"Ημ/νία ελέγχου",
    "anartimeno_psif_iliko_public_dms":"Αναρτημένο Ψηφιακό Υλικό στο Public DMS",
    "arxiki_sumvasi_pliris":"ΑΡΧΙΚΗ ΣΥΜΒΑΣΗ ΠΛΗΡΗΣ",
    "teleutaia_pp_pliris":"ΤΕΛΕΥΤΑΙΑ ΠΠ ΠΛΗΡΗΣ ",
    "eggrafa_prosimiosis":"ΕΓΓΡΑΦΑ ΠΡΟΣΗΜΕΙΩΣΗΣ",
    "initial_loan_id":"Loan ID",
    "initial_contract_id":"Contract ID",
    "initial_digitization_date":"Digitization Date",
    "initial_subfolder":"Subfolder",
    "initial_pages":"Pages",
    "last_amend_loan_id":"Loan ID",
    "last_amend_contract_id":"Contract ID",
    "last_amend_digitization_date":"Digitization Date",
    "last_amend_subfolder":"Subfolder",
    "last_amend_pages":"Pages",
    "re_collateral_loan_id":"Loan ID",
    "re_collateral_contract_id":"Contract ID",
    "re_collateral_digitization_date":"Digitization Date",
    "re_collateral_subfolder":"Subfolder",
    "re_collateral_pages":"Pages",
    "elegxos_dms":"Έλεγχος στο DMS ΕΤΕ",
    "fakelos_rc":"Φάκελος RC",
    "mitos":"mitos",
    "arxiki_sumv_pliris_se_allo_sustima":"ΑΡΧΙΚΗ ΣΥΜΒΑΣΗ ΠΛΗΡΗΣ σε άλλο σύστημα",
    "teleutaia_pp_pliris_se_allo_sustima":"ΤΕΛΕΥΤΑΙΑ ΠΠ ΠΛΗΡΗΣ σε άλλο σύστημα ",
    "eggrafa_pros_se_allo_sustima":"ΕΓΓΡΑΦΑ ΠΡΟΣΗΜΕΙΩΣΗΣ σε άλλο σύστημα",
    "entopismos_se_sustima":"Εντοπισμός σε σύστημα",
    "remedy":"Remedy",
    "apothikeusi_psifiakou_ulikou":"Αποθήκευση ψηφιακού υλικού (πολλαπλά ονόματα)",
    "project_name":"Frontier Indexing",
    "downloadexportprot":"Download Protocol",
    "DataTape":"Row Data",
    "EpivevaiosiClaim":"Επιβεβαίωση Claim",
    "LoanTypeOther":"Other",
    "L3869":"L3869",
    "L4605":"L4605",
    "Mortgage":"Mortgage",
    "SBL":"SBL",
    "Consumer":"Consumer",
    "deZhteitai":"Δεν ζητείται",
    "deZhteitaiRC":"Δεν ζητείται (ενέργειες RC)",
    "Y":"Y",
    "denApaiteitaiLogwMirythmisis":"Δεν απαιτείται λόγω μη ρύθμισης",
    "ApofN3869":"Αποφ Ν.3869",
    "DenApaiteitai_L4605":"Δεν απαιτείται (4605)",
    "DenZiteitai_energeies_RC":"Δεν ζητείται (ενέργειες RC)",
    "Delivered":"Delivered",
    "Uploaded":"Uploaded",
    "Apostoli":"Αποστολή",
    "ProsPsifiopoihsh":"Προς Ψηφιοποίηση",
    "Pending":"Pending",
    "Completed":"Completed",
    "N":"N",
    "portfolio_id":"Portfolio",
    "vrethikan_eggrafa3869":"Βρέθηκαν έγγραφα Ν.3869",
    "den_vrethikan_eggrafa3869":"Δεν βρέθηκαν έγγραφα Ν.3869",
    "ektimiseis_texn_fakelos":"Εκτιμήσεις / Τεχνικός Φάκελος",
    "aitisi_egkrisi_oikonomika_stoixeia":"Αίτηση / Εγκριση / Οικονομικά Στοιχεία",
    "sumvatika_eggrafa":"Συμβατικά Έγγραφα",
    "prosim_upoth_dikogr":"Προσημειώσεις / Υποθήκες / Δικόγραφα",
    "eggrafa_epidik_kai_anagk_ektel":"Έγγραφα Επιδίκασης  και Αναγκαστικής Εκτέλεσης",
    "dikografa_3869":"Δικόγραφα Ν.3869",
    "entopistike_public_DMS":"Εντοπίστηκε σε public DMS",
    "rc":"RC",
    "dms_ete":"DMS ETE",
    "PartiallyCompleted":"Partially Completed",
    "NoRemedy":"No Remedy",
    "ektiposi":"Print",
    "ektiposi_date":"Date",
    "epivevaiosi_kataxorisis":"Επιβεβαίωση Καταχώρισης Ευρημάτων στο DMS Public",
    "epivevaiosi_date":"Ημερομηνία",
    "xristis":"Χρήστης",
    "arithmos_kivotiou":"Αριθμός Κιβωτίου",
    "Please_select":"Please select",
    "portfolio":"Portfolio",
    "remedy_actions_comment": "Comments"
  }
}