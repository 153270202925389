<script lang="ts" setup>
import { ref, onMounted, watch } from "vue";
import { storeToRefs } from "pinia";
import { useMainStore } from "../store/main";
import { usePGRESTAPIStore } from "../store/pgrest_api";
import { useI18n } from "vue-i18n";
import { ElMessage } from "element-plus";
import PGRESTResult from "../classes/PGRESTResult";
import { useGenericMethodsVariables } from "../composables/genericMethodsVariables";
import { useValueLists } from "../composables/valueLists";
import { RefreshLeft } from "@element-plus/icons-vue";
import { get, find, forEach } from "lodash";
import * as ExcelJS from 'exceljs';
import { Download } from "@element-plus/icons-vue";
import UserType from "../enums/UserType";
import { DateTime } from "luxon";

const pgrestapi = usePGRESTAPIStore();
const mainStore = useMainStore();
const { globalPortfolioID, globalDebtorID, globalLoanAgreementID } = storeToRefs(mainStore);

const { t, locale } = useI18n({ useScope: "global" });
const { OwnerShipTypeList, TypeofPropertyList, LegalEntityList, IDCardList, contractStatusList, TrueFalseList, paymentTypeList, TypeofPrenotationList, NREcollateralTypeList } = useValueLists();

const {
	isLoading,
	showError
} = useGenericMethodsVariables();

let table_columns = ref([] as string[]);
let table_columns_translated = ref({} as { [key: string]: string });
let data = ref([]);
let dataCount = ref(0);
let currentPage = ref(1);
let columnOptions = ref([] as { value: string; label: string }[]);
let selectedColumns = ref([] as string[]);
let selectTables = ref([] as any[]);
let selectedTable = ref("");
let visibleFields = ref(false);

watch(globalPortfolioID, async () => {
	await loadPage(1);
});

watch(globalDebtorID, async () => {
	await loadPage(1);
});

watch(globalLoanAgreementID, async () => {
	await loadPage(1);
});

const availableTables = {
	"Diary": {
		table: "la_liksiario",
		translation: "LiksiarioView",
		added_to_query: "?select=*,loan_agreement!inner(id,ar_sumbashs,debtor!inner(allowed_users,portfolio!inner(id,allowed_users)))",
		portfolio_query: "&loan_agreement.debtor.portfolio.id=eq.",
		debtor_query: "&loan_agreement.debtor.id=eq.",
		loan_agreement_query: "&loan_agreement.id=eq.",
	},
	"Monitoring": {
		table: "la_monitoring",
		translation: "Monitoring",
		added_to_query: "?select=*,loan_agreement!inner(id,ar_sumbashs,debtor!inner(allowed_users,portfolio!inner(id,allowed_users)))",
		portfolio_query: "&loan_agreement.debtor.portfolio.id=eq.",
		debtor_query: "&loan_agreement.debtor.id=eq.",
		loan_agreement_query: "&loan_agreement.id=eq.",
	},
	"NRE Collaterals 2": {
		table: "rest_of_collaterals",
		translation: "NRECollateralsDetails",
		added_to_query: "?select=*,loan_agreement!inner(id,ar_sumbashs,debtor!inner(allowed_users,portfolio!inner(id,allowed_users)))&approve_status=eq.1",
		portfolio_query: "&loan_agreement.debtor.portfolio.id=eq.",
		debtor_query: "&loan_agreement.debtor.id=eq.",
		loan_agreement_query: "&loan_agreement.id=eq.",
	},
	"Register of Bonds": {
		table: "view_rob_report_view1",
		translation: "RegisterOfBondsView",
		added_to_query: "?select=*&approve_status=eq.1",
		portfolio_query: "&portfolio=eq.",
		debtor_query: "&debtor_id=eq.",
		loan_agreement_query: "&loan_agreement_id=eq.",
	},
	"RE Collaterals": {
		table: "re_collaterals",
		translation: "real_estate_collateral",
		added_to_query: "?select=*,loan_agreement!inner(id,ar_sumbashs,debtor!inner(id,allowed_users,portfolio!inner(id,allowed_users))),real_estate!inner(arithmos_akinitou_diax)&approve_status=eq.1",
		portfolio_query: "&loan_agreement.debtor.portfolio.id=eq.",
		debtor_query: "&loan_agreement.debtor.id=eq.",
		loan_agreement_query: "&loan_agreement.id=eq.",
	},
	"Auctions": {
		table: "auctions",
		translation: "AuctionsDetails",
		added_to_query: "?select=*,debtor!inner(id,cid,portfolio!inner(id,allowed_users)),real_estate!inner(arithmos_akinitou_diax),re_collaterals!inner(loan_agreement_id,ar_kalummatos)&approve_status=eq.1",
		portfolio_query: "&debtor.portfolio.id=eq.",
		debtor_query: "&debtor.id=eq.",
		loan_agreement_query: "&re_collaterals.loan_agreement.id=eq.",
	},
	"Greek State Guarantees": {
		table: "greek_state_guarantees",
		translation: "GreekStateGuarantees",
		added_to_query: "?select=*,loan_agreement!inner(id,ar_sumbashs,debtor!inner(id,allowed_users,portfolio!inner(id,allowed_users)))&approve_status=eq.1",
		portfolio_query: "&loan_agreement.debtor.portfolio.id=eq.",
		debtor_query: "&loan_agreement.debtor.id=eq.",
		loan_agreement_query: "&loan_agreement.id=eq.",
	},
	"Guarantees": {
		table: "guarantees",
		translation: "GuaranteesDetails",
		added_to_query: "?select=*,loan_agreement!inner(id,ar_sumbashs,debtor!inner(id,allowed_users,portfolio!inner(id,allowed_users)))&approve_status=eq.1",
		portfolio_query: "&loan_agreement.debtor.portfolio.id=eq.",
		debtor_query: "&loan_agreement.debtor.id=eq.",
		loan_agreement_query: "&loan_agreement.id=eq.",
	},
	"Approvals": {
		table: "approvals",
		translation: "ApprovalDetailsView",
		added_to_query: "?select=*,loan_agreement!inner(id,ar_sumbashs,debtor!inner(id,allowed_users,portfolio!inner(id,allowed_users)))&approve_status=eq.1",
		portfolio_query: "&loan_agreement.debtor.portfolio.id=eq.",
		debtor_query: "&loan_agreement.debtor.id=eq.",
		loan_agreement_query: "&loan_agreement.id=eq.",
	},
	"Loan Accounts": {
		table: "loan_accounts",
		translation: "LoanAccountDetails",
		added_to_query: "?select=*,loan_agreement!inner(id,ar_sumbashs,debtor!inner(allowed_users,portfolio!inner(id,allowed_users))),config_currency!left(currency),product!left(product)&approve_status=eq.1",
		portfolio_query: "&loan_agreement.debtor.portfolio.id=eq.",
		debtor_query: "&loan_agreement.debtor.id=eq.",
		loan_agreement_query: "&loan_agreement.id=eq.",
	},
	"Loan Agreements": {
		table: "loan_agreement",
		translation: "LoanAgreementDetails",
		added_to_query: "?select=*,debtor!inner(id,cid,fullname,allowed_users,portfolio!inner(id,allowed_users)),config_currency!left(currency),config_agent!left(agent),config_product!left(product)",
		portfolio_query: "&debtor.portfolio.id=eq.",
		debtor_query: "&debtor.id=eq.",
		loan_agreement_query: "&id=eq.",
	},
	"Debtors": {
		table: "debtor",
		translation: "DebtorDetailsView",
		added_to_query: "?select=*,portfolio!inner(id,allowed_users),loan_agreement!left(id,ar_sumbashs),config_klados!debtor_fk_6(*),config_kad!debtor_fk_5(*),config_prefecture!debtor_fk_4(*),config_postalcode!debtor_fk_3(*),config_country!debtor_fk_9(*),config_group!debtor_fk_10(*)&approve_status=eq.1",
		portfolio_query: "&portfolio.id=eq.",
		debtor_query: "&id=eq.",
		loan_agreement_query: "&loan_agreement.id=eq.",
		replace_loan_agreement_left_with_inner_join: true,
	},
	"Real Estates": {
		table: "real_estate",
		translation: "RealEstateDetails",
		added_to_query: "?select=*,debtor!inner(id,cid,portfolio!inner(id,allowed_users)),config_prefecture!left(*),config_postalcode!left(*),config_country!left(*)&approve_status=eq.1",
		portfolio_query: "&debtor.portfolio.id=eq.",
		debtor_query: "&debtor.id=eq.",
	},
	"Digitized LA": {
		table: "d_debtor",
		translation: "DDebtorDetailsView",
		added_to_query: "?select=portfolio_id,cor_center,cid,pelatis,cor_contractcode,cor_sdocontractid,physical_contract_id,physical_contract_id_copy,"
			+ "cor_importdate,securitized_perimeter,analyt_thesi_fakel_ana_cid,checked_not_included_incairo,fak_pistoux_cairo,denounced_flag,stoixeia_xeiristi,"
			+ "anoigma,elegxos,apografh,elegxos_so1,stoixeia_pshfiop,elegxos_so2_1,pshfiopoihsh,elegxos_pmo,stby_apostolhs,apostolh,end_stand_by_apograf,"
			+ "sxolio,portfolio!inner(id,allowed_users,portf_name),psif:users!d_debtor_users_fk_1_psifiop(id,username),elegxos_so1_join:users!d_debtor_users_fk(id,username),"
			+ "xeiristis:users!d_debtor_users_fk_xeir(id,username),elegxos_so2_join:users!d_debtor_users_fk_so2_1(id,username)",

		//added_to_query: "?select=portfolio_id,cor_center,cid,pelatis,cor_contractcode,elegxos_so1,users!left(id,username)",
		portfolio_query: "",
		debtor_query: "",
	},	
	"Frontier Indexing": {
		table: "apografi_frontier",
		translation: "Apografi_Frontier",
		added_to_query: "?select=id,borrower_id,borrower_name,asset_class,contract_id,loan_id_31_12_2021,origination_date,loan_type_31_12_2021,loan_id_open_date_31_12_2021,"
		+"last_restructured_cut_off,loan_id_30_06_2020,origination_date_loan_id_30_06_2020,looking_for_initial,looking_for_last,looking_for_collateral,breach_category,"
		+"management_branch_31_12_2021_meta_609,priority,activation_branch,management_branch_30_06_2020,management_branch_31_12_2021_me_609_pro_antik,dms_status,"
		+"iron_mountain_deliv_status,dovlaue_delivery_status,kwd,katastasi,sxolio,anathesi_mo_officer,hmerominia_enarxis_epexergasias,imerominia_olokl_epexerg,mo_supervisor,"
		+"imeromhnia_elegxou,anartimeno_psif_iliko_public_dms,arxiki_sumvasi_pliris,teleutaia_pp_pliris,eggrafa_prosimiosis,initial_loan_id,initial_contract_id,"
		+"initial_digitization_date,initial_subfolder,initial_pages,last_amend_loan_id,last_amend_contract_id,last_amend_digitization_date,last_amend_subfolder,"
		+"last_amend_pages,re_collateral_loan_id,re_collateral_contract_id,re_collateral_digitization_date,re_collateral_subfolder,re_collateral_pages,elegxos_dms,"
		+"fakelos_rc,mitos,arxiki_sumv_pliris_se_allo_sustima,teleutaia_pp_pliris_se_allo_sustima,eggrafa_pros_se_allo_sustima,entopismos_se_sustima,remedy,"
		+"apothikeusi_psifiakou_ulikou,portfolio_id,row_problem,portfolio!inner(id,allowed_users,portf_name),"		
		+"mos_officer_join:users!apografi_frontier_users_fk(id,username),mos_supervisor_join:users!apografi_frontier_users_fk_1(id,username)",
		//+"mos_officer_join,mos_supervisor_join",
		portfolio_query: "",
		debtor_query: "",
	},
} as any;

watch(locale, async () => {
	getTables();
	await loadPage(currentPage.value + 1);
});

const getTables = () => {
	selectTables.value = [];

	let x = Object.keys(availableTables).sort();
	for (let i = 0; i < x.length; ++i) {
		if (mainStore.loggedUser.user_type == UserType.Agent || (mainStore.loggedUser.user_type == UserType.Supervisor && !mainStore.loggedUser.digitization)) {
			if (x[i] !== "Digitized LA") {
				selectTables.value.push({ value: x[i], label: t("menu." + x[i]) });
			}
		}
		//  else if (mainStore.loggedUser.user_type == UserType.Supervisor && mainStore.loggedUser.digitization==false) {
		//   if (x[i] !=="Digitized LA" ) {
		//       selectTables.value.push({ value: x[i], label: t("menu." + x[i]) });
		//     }
		//  }
		else {
			selectTables.value.push({ value: x[i], label: t("menu." + x[i]) });
		}

	}
};

const edits = {
	la_liksiario: {
		remove_columns: ["loan_agreement", "ektokismos_id"],
		replace_columns: {
			"loan_agreement_id": "loan_agreement.ar_sumbashs"
		},
		translate_columns: {
		},
	},
	la_monitoring: {
		remove_columns: ["loan_agreement", "approve_status"],
		replace_columns: {
			"loan_agreement_id": "loan_agreement.ar_sumbashs"
		},
		translate_columns: {
		},
	},
	rest_of_collaterals: {
		remove_columns: ["id", "approve_status", "allowed_users", "insert_timestamp", "insert_user", "approve_user", "approve_timestamp", "loan_agreement"],
		replace_columns: {
			"loan_agreement_id": "loan_agreement.ar_sumbashs",
		},
		translate_columns: {
			"type": NREcollateralTypeList,
		},
	},

	view_rob_report_view1: {
		remove_columns: ["approve_status", "allowed_users", "portf_name", "loan_agreement_id", "rob_id", "portfolio_allowed_users", "debtor_allowed_users"],
		replace_columns: {
			"": "",
		},
		translate_columns: {
			"": null,
		},
	},
	re_collaterals: {
		remove_columns: ["id", "insert_timestamp", "insert_user", "approve_user", "approve_timestamp", "approve_status", "loan_agreement", "real_estate"],
		replace_columns: {
			"loan_agreement_id": "loan_agreement.ar_sumbashs",
			"real_estate_id": "real_estate.arithmos_akinitou_diax",
		},
		translate_columns: {
			"eidos_prosim": TypeofPrenotationList,
			"meteggrafh_se_ypothikof_ktimat_graf": TrueFalseList,
			"ekxor_asfalist": TrueFalseList
		},
	},
	auctions: {
		remove_columns: ["id", "approve_status", "real_estate", "re_collaterals", "debtor", "insert_timestamp", "insert_user", "approve_user", "approve_timestamp"],
		replace_columns: {
			"debtor_id": "debtor.cid",
			"real_estate_id": "real_estate.arithmos_akinitou_diax",
			"re_collateral_id": "re_collaterals.ar_kalummatos"
		},
		translate_columns: {
			"eparkes_pl": TrueFalseList,
			"eisp_me_egg_lf": TrueFalseList,
		},
	},
	greek_state_guarantees: {
		remove_columns: ["id", "approve_status", "insert_timestamp", "insert_user", "approve_user", "approve_timestamp", "loan_agreement"],
		replace_columns: {
			"loan_agreement_id": "loan_agreement.ar_sumbashs",
		},
		translate_columns: {
			"sumbatiko_eggrafo": TrueFalseList,
			"epidosh_sumbas_se_dhmos_foreis": TrueFalseList,
		},
	},
	guarantees: {
		remove_columns: ["id", "approve_status", "loan_agreement", "insert_timestamp", "insert_user", "approve_user", "approve_timestamp"],
		replace_columns: {
			"loan_agreement_id": "loan_agreement.ar_sumbashs",
		},
		translate_columns: {
			"egguhsh_sunolou_sumbas": TrueFalseList,
		},
	},
	approvals: {
		remove_columns: ["id", "approve_status", "loan_agreement", "insert_timestamp", "insert_user", "approve_user", "approve_timestamp"],
		replace_columns: {
			"loan_agreement_id": "loan_agreement.ar_sumbashs",
		},
		translate_columns: {
			"": null,
		},
	},
	real_estate: {
		remove_columns: ["debtor_id", "config_prefecture", "config_postalcode", "config_country", "insert_timestamp", "insert_user", "approve_user", "approve_timestamp", "approve_status"],
		replace_columns: {
			"debtor": "debtor.cid",
			"district": "config_prefecture.prefecture",
			"postalcode": "config_postalcode.postalcode",
			"country": "config_country.country",
		},
		translate_columns: {
			"eidos": TypeofPropertyList,
			"eidos_idioktisias": OwnerShipTypeList,
			"poleod_parabaseis": TrueFalseList
		},
	},
	debtor: {
		remove_columns: ["id", "portfolio", "loan_agreement", "allowed_users", "approve_status", "config_klados", "config_kad", "config_group", "config_prefecture", "config_postalcode", "config_country", "insert_timestamp", "insert_user", "approve_user", "approve_timestamp"],
		replace_columns: {
			"klados": "config_klados.perigrafi",
			"kad": "config_kad.kodikos_kad",
			"district": "config_prefecture.prefecture",
			"postcode": "config_postalcode.postalcode",
			"postal_district": "config_prefecture.prefecture",
			"postal_postcode": "config_postalcode.postalcode",
			"country": "config_country.country",
			"postal_country": "config_country.country",
			"group_id": "config_group.group_id"
		},
		translate_columns: {
			"customer_type": LegalEntityList,
			"id_card_type": IDCardList,
		},
	},
	loan_agreement: {
		remove_columns: ["id", "perithorio_aitisis_anastolis_DE_XRHSIMOPOIEITAI", "prosorini_ditagi_gia_mi_ekt", "telesidiki", "approve_status", "debtor", "status", "config_currency", "config_agent", "config_product", "insert_timestamp", "insert_user", "approve_user", "approve_timestamp"],
		replace_columns: {
			"debtor_id": "debtor.fullname",
			"currency": "config_currency.currency",
			"product": "config_product.product",
			"agent": "config_agent.agent"
		},
		translate_columns: {
			"katast_sumbashs": contractStatusList,
			"ekdosi_diatagis": TrueFalseList,
			"aitisi_anastolis": TrueFalseList,
			"auction": TrueFalseList,
			"dikaioma_propl": TrueFalseList,
			"katasxesi": TrueFalseList,
			"tropos_apopl": paymentTypeList
		},
	},

	loan_accounts: {
		remove_columns: ["id", "hmerom_plhrom", "config_currency", "poso_plhrom", "kefal_pou_eksofl", "tokoi_pou_eksofl", "eksoda_pou_eksofl", "components_ofeilhs", "hmer_epomen_doshs", "kefal_epom_doshs", "tokos_epomen_doshs", "sumbatikoi_mh_log_tokoi", "yperhmerias_mh_log_tokoi", "n2601_1998_mh_log_tokoi", "approve_status", "loan_agreement", "insert_timestamp", "insert_user", "approve_user", "approve_timestamp"],
		replace_columns: {
			"loan_agreement_id": "loan_agreement.ar_sumbashs",
			"currency": "config_currency.currency",
			"product": "product.product"
		},
		translate_columns: {
			"eidos": TypeofPropertyList,
			"proskom_bebeos": TrueFalseList
		},
	},
	d_debtor: {
		remove_columns: ["id", "portfolio", "psif", "elegxos_so1_join", "xeiristis", "elegxos_so2_join"],
		replace_columns: {
			"loan_agreement_id": "loan_agreement.ar_sumbashs",
			"portfolio_id": "portfolio.portf_name",
			"elegxos_so1": "elegxos_so1_join.username",
			"stoixeia_pshfiop": "psif.username",
			"stoixeia_xeiristi": "xeiristis.username",
			"elegxos_so2_1": "elegxos_so2_join.username"
		},
		translate_columns: {

		},
	},
	apografi_frontier: {
		remove_columns: ["id", "sxolio", "portfolio","mos_officer_join", "mos_supervisor_join","row_problem"],
		replace_columns: {
			"portfolio_id": "portfolio.portf_name",
			"anathesi_mo_officer": "mos_officer_join.username",
			"mo_supervisor": "mos_supervisor_join.username",			
		},
		translate_columns: {

		},
	},
} as any;
//		remove_columns: ["id", "sxolio", "portfolio", "mos_officer", "mos_supervisor"],
// "anathesi_mo_officer": "mos_officer.username",
// 			"mo_supervisor": "mos_supervisor.username",			
// 		},

const jsonConverts = {
	'Loan Agreements': {
		'diatagi_pl_hmek_hmep_ex_per': (json_obj_array: any[], for_excel: boolean): string => {
			if (json_obj_array === null || json_obj_array === undefined || json_obj_array.length === 0) return '';

			let fv = '';

			for (let index = 0; index < json_obj_array.length; index++) {
				let hmek = DateTime.fromFormat((json_obj_array[index].hmek), 'yyyy-MM-dd').toFormat("dd/MM/yyyy");
				let hmep = DateTime.fromFormat((json_obj_array[index].hmep), 'yyyy-MM-dd').toFormat("dd/MM/yyyy");
				let exoda = json_obj_array[index].exoda;

				fv += ' Serving Date: ' + hmek + ' Serving Fees: ' + hmep + ' Process Interruption Margin: ' + exoda;
				fv += (for_excel ? '\n' : '<br>');
			}

			return fv;
		},
		'hmer_tropop_sumbashs': (json_obj_array: any[], for_excel: boolean): string => {
			if (json_obj_array === null || json_obj_array === undefined || json_obj_array.length === 0) return '';

			let fv = '';

			for (let index = 0; index < json_obj_array.length; index++) {
				fv += DateTime.fromFormat(json_obj_array[index].hmer_tropop, 'yyyy-MM-dd').toFormat("dd/MM/yyyy");
				fv += (for_excel ? '\n' : '<br>');
			}

			return fv;
		},
		'hmer_poso_ofeilhs': (json_obj_array: any[], for_excel: boolean): string => {
			if (json_obj_array === null || json_obj_array === undefined || json_obj_array.length === 0) return '';

			let fv = '';

			for (let index = 0; index < json_obj_array.length; index++) {
				let hmeres = DateTime.fromFormat((json_obj_array[index].hmeres), 'yyyy-MM-dd').toFormat("dd/MM/yyyy");
				let poso = json_obj_array[index].poso;

				fv += ' Date of Principal Payments: ' + hmeres + ' Amount of Principal Payment: ' + poso;
				fv += (for_excel ? '\n' : '<br>');
			}

			return fv;
		}
	}
} as any;

const makeSelectOptions = () => {
	for (let i = 0; i < table_columns.value.length; ++i) {
		columnOptions.value.push({
			value: table_columns.value[i],
			label: table_columns_translated.value[table_columns.value[i]],
		});
	}

};

const removeColumns = () => {
	for (let j = 0; j < edits[availableTables[selectedTable.value].table].remove_columns.length; ++j) {
		for (let i = 0; i < table_columns.value.length; ++i) {
			if (table_columns.value[i] === edits[availableTables[selectedTable.value].table].remove_columns[j]) {
				table_columns.value.splice(i, 1);
			}
		}
	}
}

const replaceColumns = () => {
	for (let j = 0; j < edits[availableTables[selectedTable.value].table].replace_columns.length; ++j) {
		for (let i = 0; i < table_columns.value.length; ++i) {
			if (table_columns.value[i] === edits[availableTables[selectedTable.value].table].replace_columns[j]) {
				table_columns.value[i] = edits[availableTables[selectedTable.value].table].replace_columns[j];
			}
		}
	}
}

const getData = async (with_paging: boolean): Promise<PGRESTResult> => {

	let query = availableTables[selectedTable.value].table + availableTables[selectedTable.value].added_to_query;
	console.log('aaaa' + availableTables[selectedTable.value].table)
	if (availableTables[selectedTable.value].portfolio_query !== undefined && globalPortfolioID.value !== "") {
		query += availableTables[selectedTable.value].portfolio_query + globalPortfolioID.value;
	}

	if (availableTables[selectedTable.value].debtor_query !== undefined && globalDebtorID.value !== "") {
		query += availableTables[selectedTable.value].debtor_query + globalDebtorID.value;
	}

	if (availableTables[selectedTable.value].loan_agreement_query !== undefined && globalLoanAgreementID.value !== "") {
		query += availableTables[selectedTable.value].loan_agreement_query + globalLoanAgreementID.value;

		if (availableTables[selectedTable.value].replace_loan_agreement_left_with_inner_join !== undefined) {
			query = query.replace("loan_agreement!left", "loan_agreement!inner"); // Care, replaces only the first occurrence currently
		}
	}

	if (mainStore.loggedUser.user_type == UserType.Agent) {
		let tSet = new Set(['RE Collaterals', 'Greek State Guarantees', 'Guarantees', 'Approvals', 'Loan Accounts', 'NRE Collaterals 2', 'Monitoring', 'Diary'])

		if (tSet.has(selectedTable.value)) {
			query += `&loan_agreement.debtor.allowed_users=cs.{${mainStore.loggedUser.id}}`;
		} else if (selectedTable.value == 'Debtors') {
			query += `&allowed_users=cs.{${mainStore.loggedUser.id}}`;
		} else if (selectedTable.value == 'Register of Bonds') {
			query += `&debtor_allowed_users=cs.{${mainStore.loggedUser.id}};`;
		} else {
			query += `&debtor.allowed_users=cs.{${mainStore.loggedUser.id}}`;
		}
	} else if (mainStore.loggedUser.user_type == UserType.Supervisor) {
		let tSet = new Set(['RE Collaterals', 'Greek State Guarantees', 'Guarantees', 'Approvals', 'Loan Accounts', 'NRE Collaterals 2', 'Monitoring', 'Diary'])

		if (tSet.has(selectedTable.value)) {
			query += `&loan_agreement.debtor.portfolio.allowed_users=cs.{${mainStore.loggedUser.id}}`;
		} else if (selectedTable.value == 'Debtors') {
			query += `&portfolio.allowed_users=cs.{${mainStore.loggedUser.id}}`;
		} else if (selectedTable.value == 'Register of Bonds') {
			query += `&portfolio_allowed_users=cs.{${mainStore.loggedUser.id}};`;
		} else {
			if (availableTables[selectedTable.value].table !== 'd_debtor') {
				query += `&debtor.portfolio.allowed_users=cs.{${mainStore.loggedUser.id}}`;
			} else { query += `&portfolio.allowed_users=cs.{${mainStore.loggedUser.id}}`; }
		}
	}

	if (with_paging) {
		return await pgrestapi.getPaginated(query, currentPage.value);
	} else {
		return await pgrestapi.get(query);
	}
}

const transformData = (result: PGRESTResult, for_excel: boolean) => {
	if (!(selectedTable.value in jsonConverts)) return; // Do nothing if no JSON transforms are defined for this table

	let fields: any = Object.keys(jsonConverts[selectedTable.value]); // Get fields that have to be transformed for this entity

	// Apply for each data row the JSON transforms for each field retrieved above
	for (let i = 0; i < result.data.length; i++) {
		for (let field of fields) {
			result.data[i][field] = jsonConverts[selectedTable.value][field](result.data[i][field], for_excel);
		}
	}

	//Φιλτραρο ολα τα πεδια και αν το πεδιο ειναι σε μορφή date του περναω το σωστο date format με το luxon
	const isDate = (item: any) => {
		const datePattern = /^\d{4}-\d{2}-\d{2}$/;

		if (datePattern.test(item) == true) {
			return true;
		}
	}

	for (let i = 0; i < result.data.length; i++) {
		for (const [key, value] of Object.entries(result.data[i])) {
			if (isDate(value as any) == true) {
				result.data[i][key] = DateTime.fromFormat(value as string, 'yyyy-MM-dd').toFormat("dd/MM/yyyy")
			}
		}
	}
}

const loadPage = async (page: number) => {
	currentPage.value = page - 1;

	data.value = [];

	if (selectedTable.value === "") {
		return;
	}

	isLoading.value = true;

	let result = await getData(true);

	if (result.error) {
		showError("Error loading data:" + result.error, 2500);
		isLoading.value = false;
		return;
	}

	if (result.data.length === 0) {
		showError("No data found", 1500);
		isLoading.value = false;
		return;
	}

	transformData(result, false);

	table_columns.value = Object.keys(result.data[0]);

	removeColumns();
	replaceColumns();

	selectedColumns.value = JSON.parse(JSON.stringify(table_columns.value));

	table_columns_translated.value = {};

	for (let i = 0; i < table_columns.value.length; ++i) {
		(table_columns_translated as any).value[table_columns.value[i]] = t(availableTables[selectedTable.value].translation + "." + table_columns.value[i]);
	}

	data.value = result.data;
	// console.log(data.value)

	makeSelectOptions();

	if (result.headers)
		dataCount.value = parseInt(result.headers["content-range"].split("/")[1]);

	isLoading.value = false;
};

onMounted(async () => {
	getTables();
});

const onTableChange = async () => {
	data.value = [];
	selectedColumns.value = [];
	columnOptions.value = [];
	if (selectedTable.value === "") {
		visibleFields.value = false;
		return;
	}

	visibleFields.value = true;

	await loadPage(1);
};

const getProperValue = (dataObj: any, column_name: string): any => {
	if (column_name in edits[availableTables[selectedTable.value].table].replace_columns) {
		return get(dataObj, edits[availableTables[selectedTable.value].table].replace_columns[column_name]);
	} else if (column_name in edits[availableTables[selectedTable.value].table].translate_columns) {
		return (find(edits[availableTables[selectedTable.value].table].translate_columns[column_name].value, { value: dataObj[column_name] }))?.label
	} else {
		return dataObj[column_name];
	}
}

const downloadPage = async () => {
	const workbook = new ExcelJS.Workbook();
	const sheet = workbook.addWorksheet(selectedTable.value);

	let fullData = await getData(false);

	transformData(fullData, true);

	let row = sheet.getRow(1);

	for (let i = 1; i < selectedColumns.value.length + 1; ++i) {
		row.getCell(i).value = table_columns_translated.value[selectedColumns.value[i - 1]];
	}

	for (let i = 0; i < fullData.data.length; ++i) {
		row = sheet.getRow(i + 2);
		for (let j = 0; j < selectedColumns.value.length; ++j) {
			row.getCell(j + 1).value = getProperValue(fullData.data[i], selectedColumns.value[j]);
		}
	}

	let buffer = await workbook.xlsx.writeBuffer();
	let blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
	var link = document.createElement("a");
	link.setAttribute("href", window.URL.createObjectURL(blob));
	link.setAttribute(
		"download",
		`${selectedTable.value}.${DateTime.now().toFormat("yyyy-MM-dd_HHmmss")}.xlsx`
	);
	document.body.appendChild(link); // Required for FF

	link.click(); // Trigger file download
}
</script>

<template>
  <div v-loading="isLoading" class="content-wrapper">
    <el-form>
      <p />      Available entities

      <el-select v-model="selectedTable" clearable collapse-tags collapse-tags-tooltip :max-collapse-tags="20"
        placeholder="Entity selection" style="width: 90%" size="small" @change="onTableChange">
        <el-option v-for="item in selectTables" :key="item.value" :label="item.label" :value="item.value" />
      </el-select>

      <!-- style="float: left; margin: 2.5px" -->
      <el-button link type="primary" size="small" class="btn-add" @click="loadPage(currentPage + 1)" :icon="RefreshLeft"
        v-show="visibleFields" />

      <p />
      <span v-show="visibleFields">Available fields</span>

      <el-select v-model="selectedColumns" multiple collapse-tags collapse-tags-tooltip :max-collapse-tags="20"
        placeholder="Displayed Columns" style="width: 90%" size="small" v-show="visibleFields">
        <el-option v-for="item in columnOptions" :key="item.value" :label="item.label" :value="item.value" />
      </el-select>
      <el-button v-show="visibleFields" link type="primary" class="btn-add" size="small" @click="downloadPage">
        <el-icon>
          <Download />
        </el-icon>
      </el-button>

      <el-table :data="data" border table-layout="fixed" sortable="custom" size="small" max-height="600" class="reporttable">
        <el-table-column v-for="column in selectedColumns" :key="column" :prop="column"
          :label="table_columns_translated[column]" sortable width="140">
          <template #header>
            <span>{{ table_columns_translated[column] }}</span>
          </template>
          <template #default="scope">
            <div v-html="getProperValue(scope.row, column)" />
          </template>
        </el-table-column>
      </el-table>

      <div v-show="data.length > 0">
        <el-pagination small layout="prev, pager, next" :page-size="10" :total="dataCount" @current-change="loadPage" />
      </div>
    
    </el-form>

  </div>
</template>
