<script lang="ts" setup>
import { ref, watch, onMounted } from "vue";
import { usePGRESTAPIStore } from "../store/pgrest_api";
import PGRESTResult from "../classes/PGRESTResult";
import { Avatar, Bell, Edit, Delete, Document, Plus, RefreshLeft, CircleCheck, CircleClose, View, Watch } from "@element-plus/icons-vue";
import AddEdit_RealEstate from "../components/AddEdit_RealEstate.vue";
import { storeToRefs } from "pinia";
import { useMainStore } from "../store/main";
import UserType from "../enums/UserType";
import { useSort } from "../composables/sort";
import { useI18n } from "vue-i18n";
import RealEstate from "../classes/DB_Entities/RealEstate";
import { useGenericMethodsVariables } from "../composables/genericMethodsVariables";
import DeleteEntityDialog from "../components/DeleteEntityDialog.vue";
import DocumentsList from "../components/DocumentsList.vue";
import EntityType from "../enums/EntityType";
import DocumentFolders from "../enums/DocumentFolders";

const { entityApprove, showError, showSuccess, isLoading } = useGenericMethodsVariables();
const { t } = useI18n({ useScope: "global" });
const re_typeofpropertyList = ref<any>([]);
const pgrestapi = usePGRESTAPIStore();
const mainStore = useMainStore();
const { globalPortfolioID } = storeToRefs(mainStore);
const { globalDebtorID } = storeToRefs(mainStore);
const { globalLoanAgreementID } = storeToRefs(mainStore);
const { onSortChange, sortColumn, sortOrder } = useSort();

let filterPending = ref(false);
let dataList = ref([]);
let dataCount = ref(0);
let lastPage = ref(0);
let addEditVisible = ref(false);
let selectedRow = ref(-1);
let deleteDialogVisible = ref(false);

let filterPropertyManageNumber = ref<string>("");
let filterCity = ref<string>("");
let filterFullname = ref<string>("");
let filterVAT = ref<string>("");
let filterEidos = ref<string>("");
let filterPendingNew = ref(false);
let filterHideRejected = ref(true);
let timer = ref<any>(null);
let viewOnly = ref(false);

let showDocumentsList = ref(false);
let selectedDocumentEntity = ref<any>(undefined);

watch(globalPortfolioID, async () => {
  if (mainStore.loggedUser.username !== '')
    await loadPage(1);
});

watch(globalDebtorID, async () => {
  if (mainStore.loggedUser.username !== '')
    await loadPage(1);
});

watch(globalLoanAgreementID, async () => {
  if (mainStore.loggedUser.username !== '')
    await loadPage(1);
});

const handleClickEdit = (row: any) => {
  viewOnly.value = !isEditable(row);
  selectedRow.value = row.id;
  addEditVisible.value = true;
};

const handleClickAdd = () => {
  selectedRow.value = -1;
  addEditVisible.value = true;
};

const handleClickDelete = (row_id: number) => {
  selectedRow.value = row_id;
  deleteDialogVisible.value = true;
};

const deleteRealEstate = async () => {
  let result = await pgrestapi.delete_data("real_estate?id=eq." + selectedRow.value);

  if (result.error) {
    showError(result.error.message);
    deleteDialogVisible.value = false;
    return;
  }

  onDelete();
};

onMounted(async () => {
  loadPage(1);
});

const loadPage = async (page: number) => {
  isLoading.value = true;

  lastPage.value = page;
  page = page - 1;

  let filterUrl = "";

  if (filterPropertyManageNumber.value !== "") {
    filterUrl += `&arithmos_akinitou_diax=like.*${filterPropertyManageNumber.value}*`;
  }

  if (filterPending.value) {
    filterUrl += "&pending_approvals=gt.0";
  }

  if (filterCity.value !== "") {
    filterUrl += `&city=ilike.*${filterCity.value}*`;
  }

  if (sortOrder.value !== "") {
    filterUrl += `&order=${sortColumn.value}.${sortOrder.value}`;
  }

  if (filterFullname.value !== "") {
    filterUrl += `&fullname=ilike.*${filterFullname.value}*`;
  }

  if (filterVAT.value !== "") {
    filterUrl += `&vat_number=ilike.*${filterVAT.value}*`;
  }
  if (filterEidos.value !== "") {
    filterUrl += `&eidos=ilike.*${filterEidos.value}*`;
  }

  if (filterPendingNew.value) {
    filterUrl += "&approve_status=eq.0";
  }

  if (filterHideRejected.value) {
    filterUrl += "&approve_status=neq.2";
  }

  let selectedPortfolio = "";
  let selectedDebtor = "";
  let selectedLoanAgreement = "";
  let addedFilter = "";

  if (mainStore.globalPortfolioID !== "") {
    selectedPortfolio = mainStore.globalPortfolioID;
    addedFilter += "&portfolio=eq." + selectedPortfolio;
  }

  if (mainStore.globalDebtorID !== "") {
    selectedDebtor = mainStore.globalDebtorID;
    addedFilter += "&debtor_id=eq." + selectedDebtor;
  }

  if (mainStore.globalLoanAgreementID !== "") {
    selectedLoanAgreement = mainStore.globalLoanAgreementID;
    addedFilter += "&loan_agreement_id=cs.{" + selectedLoanAgreement + "}";
  }
  if (mainStore.loggedUser.user_type == UserType.Agent) {
    addedFilter += `&allowed_users=cs.{${mainStore.loggedUser.id}}`;
  } else if (mainStore.loggedUser.user_type == UserType.Supervisor) {
    addedFilter += `&portfolio_allowed_users=cs.{${mainStore.loggedUser.id}}`;
  }
  let result: PGRESTResult = await pgrestapi.getPaginated(
    "view_mosreal_estates_view1?select=*,arithmos_akinitou_diax::text" + addedFilter + filterUrl,
    page
  );

  if (result.error) {
    showError(result.error.message);
    return;
  }

  if (result.headers)
    dataCount.value = parseInt(result.headers["content-range"].split("/")[1]);

  dataList.value = result.data;

  isLoading.value = false;
};

const onAdd = async () => {
  showSuccess("Real Estate added Successfully");
  addEditVisible.value = false;
  loadPage(lastPage.value);
};

const onDelete = async () => {
  showSuccess("Real Estate Property Deleted Successfully");
  deleteDialogVisible.value = false;
  loadPage(lastPage.value);
};

const onFailSave = async () => {
  showError("Real Estate property failed to update", 2000);
  addEditVisible.value = false;
  selectedRow.value = -1;
};

const filterChanged = async (value: string) => {
  if (timer.value !== 0) {
    clearTimeout(timer.value);
  }

  timer.value = setTimeout(() => {
    loadPage(1);

    timer.value = 0;
  }, 500);
};

const localSortChange = async (sortObj: any) => {
  onSortChange(sortObj);
  await loadPage(lastPage.value);
};

const pendingFilterChange = async () => {
  await loadPage(1);
};

const onCloseAddEdit = () => {
  addEditVisible.value = false;
};

re_typeofpropertyList.value = [
  {
    value: "field",
    label: t("re_typeofproperty.field"),
  },
  {
    value: "commercial",
    label: t("re_typeofproperty.commercial"),
  },
  {
    value: "residential",
    label: t("re_typeofproperty.residential"),
  },
  {
    value: "office",
    label: t("re_typeofproperty.office"),
  },
  {
    value: "landplot",
    label: t("re_typeofproperty.landplot"),
  },
  {
    value: "hotel",
    label: t("re_typeofproperty.hotel"),
  },
  {
    value: "industrial",
    label: t("re_typeofproperty.industrial"),
  },
];

const getTranslationTypeofProperty = (row: any, column: any) => {
  return t("re_typeofproperty." + row[column.property]);
};

const isEditable = (row: any) => {
  return (row.debtor_approve_status === 1);
}

const approve = async (entity_id: number, state: boolean) => {
  let fv = await entityApprove(RealEstate.getTableNameStatic(), entity_id, state);

  if (fv) {
    showSuccess(`Debtor updated`);
  } else {
    showError(`Debtor update failed`);
  }

  await loadPage(lastPage.value);
};

const handleDocumentsListClick = (row: any) => {
  selectedRow.value = row.id;
  selectedDocumentEntity.value = row;
  showDocumentsList.value = true;
}

</script>

<template>
  <div class="content-wrapper" v-loading="isLoading">
    <el-row :gutter="10">
      <el-col :span="12">
        <h3 class="heading-page">
          {{ $t("RealEstateView.real_estate") }} ({{ dataCount }})
        </h3>
      </el-col>
    </el-row>

    <div style="margin: 30px">
      <el-row :gutter="20">
        <el-col :span="4">
          <el-input v-model="filterFullname" class="client-input" :placeholder="$t('DebtorDetailsView.company_fullname')"
            @input="filterChanged" />
        </el-col>
        <el-col :span="4">
          <el-input v-model="filterVAT" class="client-input" :placeholder="$t('DebtorView.tax_registration_nbr')"
            @input="filterChanged" />
        </el-col>
        <el-col :span="4">
          <el-input v-model="filterPropertyManageNumber" class="client-input"
            :placeholder="$t('RealEstateView.real_estate_nbr')" @input="filterChanged" />
        </el-col>
        <el-col :span="4">
          <el-select clearable v-model="filterEidos" class="m-2 client-input"
            :placeholder="$t('RealEstateView.type_of_property')" @change="filterChanged" style="width: 100%">
            <el-option v-for="item in re_typeofpropertyList" :key="item.value"
              :label="$t('re_typeofproperty.' + item.value)" :value="item.value" />
          </el-select>
        </el-col>
        <el-col :span="4">
          <el-input v-model="filterCity" class="client-input" :placeholder="$t('RealEstateView.city')"
            @input="filterChanged" />
        </el-col>
      </el-row>
    </div>

    <div>
      <el-row :gutter="10" style="margin: 10px; margin-top: 15px">
        <el-col :span="12" class="realestate-add-new-btn">
          <el-switch v-model="filterPending" size="small" :active-text="$t('global.pending_changes')" inactive-text=""
            @change="pendingFilterChange()" style="float: left" />
          <el-switch v-model="filterPendingNew" size="small" :active-text="$t('global.pending_changes_new_entities')"
            inactive-text="" @change="pendingFilterChange()" style="float: left" />
          <el-switch v-model="filterHideRejected" size="small" :active-text="$t('global.hide_rejected_entities')"
            inactive-text="" @change="pendingFilterChange()" style="float: left" />
        </el-col>
        <el-col :span="12" class="realestate-add-new-btn">
          <el-button link type="primary" size="small" class="btn-add" @click="loadPage(lastPage)"
            style="float: right; margin: 2.5px" :icon="RefreshLeft" />
          <el-button link type="primary" size="small" class="btn-add" style="float: right; margin: 2.5px"
            @click="handleClickAdd()" :icon="Plus" />
        </el-col>
      </el-row>
    </div>

    <el-table :data="dataList" stripe border table-layout="fixed" sortable="custom" @sort-change="localSortChange"
      size="small">
      <el-table-column fixed prop="fullname" :label="$t('DebtorDetailsView.company_fullname')" sortable />
      <el-table-column fixed prop="vat_number" :label="$t('DebtorView.tax_registration_nbr')" sortable />
      <el-table-column fixed prop="arithmos_akinitou_diax" :label="$t('RealEstateView.real_estate_nbr')" sortable />
      <el-table-column fixed prop="eidos" :label="$t('RealEstateView.type_of_property')"
        :formatter="getTranslationTypeofProperty" sortable />
      <el-table-column sortable fixed prop="city" :label="$t('RealEstateView.city')" />
      <!-- <el-table-column sortable fixed prop="cid" :label="$t('RealEstateView.debtor_cid')" /> -->
      <el-table-column sortable fixed prop="pending_approvals" :label="$t('global.pending_approvals')" />
      <el-table-column fixed="right" :label="$t('global.operations')" width="280">
        <template #default="scope">
          <el-tooltip class="box-item" effect="dark" content="Documents List" placement="left">
            <el-button link type="primary" size="small" class="btn-detail" @click="handleDocumentsListClick(scope.row)"
              :icon="Document" />
          </el-tooltip>
          <el-button link type="primary" size="small" class="btn-detail" @click="handleClickEdit(scope.row)"
            :icon="isEditable(scope.row) ? Edit : View" />
          <el-tooltip v-if="scope.row.approve_status == 0 && mainStore.loggedUser.user_type === UserType.Agent"
            effect="dark" content="Pending Supervisor approval" placement="top">
            <el-button size="small" type="primary" class="btn-warn" :icon="Watch" circle />
          </el-tooltip>
          <el-button v-if="mainStore.loggedUser.user_type === UserType.Administrator" link type="primary" size="small"
            class="btn-delete" @click="handleClickDelete(scope.row.id)" :icon="Delete" />
          <span v-if="mainStore.loggedUser.user_type < UserType.Agent &&
            scope.row.approve_status === 0
            ">
            <el-button class="btn-accept_square" @click="approve(scope.row.id, true)" :icon="CircleCheck" />
            <el-button class="btn-reject_square" @click="approve(scope.row.id, false)" :icon="CircleClose" />
          </span>
          <el-popover v-if="scope.row.pending_approvals > 0" placement="top-start" title="" :width="155" trigger="hover"
            :content="$t('global.pending_changes')">
            <template #reference>
              <el-button :icon="Bell" circle size="small" style="background-color: coral; border: 0px; color: white" />
            </template>
          </el-popover>
          <el-popover v-if="scope.row.debtor_approve_status !== 1" placement="top-start" title="" :width="205"
            trigger="hover" :content="$t('global.debtor_not_approved')">
            <template #reference>
              <el-button :icon="Avatar" circle size="small"
                style="background-color: blueviolet; border: 0px; color: white" />
            </template>
          </el-popover>
        </template>
      </el-table-column>
    </el-table>

    <div>
      <el-pagination small layout="prev, pager, next" :page-size="10" :total="dataCount" @current-change="loadPage" />
    </div>

    <el-dialog v-if="addEditVisible" v-model="addEditVisible" @update:model-value="onCloseAddEdit"
      :title="selectedRow === -1 ? 'Add Real Estate' : 'Real Estate Details'">
      <AddEdit_RealEstate :row_id="selectedRow" @close="onCloseAddEdit" @save="onAdd" @fail-save="onFailSave"
        :view_only="viewOnly" />
    </el-dialog>

    <!-- TODO: Translations -->
    <el-dialog v-if="showDocumentsList" v-model="showDocumentsList"
      :title="`Real Estate / ${selectedDocumentEntity.arithmos_akinitou_diax} - Documents List`" width="700">
      <DocumentsList v-if="showDocumentsList" :entity_type="EntityType.RealEstate" :entity_id="selectedDocumentEntity.id"
        :row_id="selectedRow" :allowed_folders="[DocumentFolders.TechnicalEvaluations]" />
    </el-dialog>

    <DeleteEntityDialog v-if="deleteDialogVisible" :row_id="selectedRow" entity_name="Real Estate" table="real_estate"
      title="Delete Real Estate" width="50%" @close="deleteDialogVisible = false" @delete="onDelete()" />



  </div>
</template>

<style scoped></style>
