<script lang="ts" setup>
import { ref, watch, onMounted, reactive } from "vue";
import { usePGRESTAPIStore } from "../store/pgrest_api";
import { useMOSAPIStore } from "../store/mos_api";
import PGRESTResult from "../classes/PGRESTResult";
import type { FormRules, FormInstance } from "element-plus";
import LoanAccount from "../classes/DB_Entities/LoanAccount";
import Decision from "../classes/Decision";
import ChangeApprovalStatus from "../enums/ChangeApprovalStatus";
import { useMainStore } from "../store/main";
import ChangesetHelper from "changeset-helper";
import EntityField from "./EntityField.vue";
import { useI18n } from "vue-i18n";
import { useGenericMethodsVariables } from "../composables/genericMethodsVariables";
import AcceptAllPending from "./AcceptAllPending.vue";
import UserType from "../enums/UserType";
import DebitCreditType from "../enums/DebitCreditType";
import DebitCreditList from "./DebitCreditList.vue";
import { Warning } from "@element-plus/icons-vue";
import LoanRepaymentType from "../enums/LoanRepaymentType";

const { showError } = useGenericMethodsVariables();

const props = defineProps({
  loanaccountid: Number,
  view_only: Boolean
});

const { locale } = useI18n({ useScope: "global" });
const { t } = useI18n({ useScope: "global" });
const emits = defineEmits(["save", "close", "fail-save"]);
let fullObj = ref<any>({});
let originalObj = ref<any>({});
const pgrestapi = usePGRESTAPIStore();
const mosapi = useMOSAPIStore();
const interests = ref<any>([]);
const data = ref(new LoanAccount());
const products = ref<any>([]);
const currencies = ref<any>([]);
const activeName = ref("loan_agreement_details");
const loanAgreementLegalStatus = ref("");
const loanAgreementArSumb = ref("");
const ruleFormRef = ref<FormInstance>();
const mainStore = useMainStore();
let StoreLoanAgreements = ref<any>([]);
let isLoading = ref(false);
let hasPendingChanges = ref<boolean>(false);
const paymentTypeList = ref<any>([]);
const legalStatusList = ref<any>([]);
let showDebitsCredits = ref<boolean>(false);
let selectedDebitCreditType = ref<DebitCreditType>(DebitCreditType.Credit);
let pendingDebitsCredits: any = ref([]);

const rules = reactive<FormRules>({
  loan_agreement_id: [
    {
      required: true,
      message: "Loan agreement is required",
      trigger: "blur",
    },
  ],
});

const submitForm = async (formEl: FormInstance | undefined) => {
  if (!formEl) return;
  await formEl.validate((valid, fields) => {
    if (valid) {
      // console.log("submit!");
      save();
    } else {
      console.log("error submit!", fields);
    }
  });
};

watch(
  () => fullObj.value?.loan_agreement_id?.originalValue,
  async (newVal, oldVal) => {
    // console.log('changed loan account\'s loan agreement id to' + fullObj.value?.loan_agreement_id?.originalValue)

    if (fullObj.value?.loan_agreement_id?.originalValue === null || fullObj.value?.loan_agreement_id?.originalValue === '') return;

    if (fullObj.value.loan_agreement_id.originalValue !== undefined) {
      let result = await pgrestapi.get(`loan_agreement?id=eq.${fullObj.value.loan_agreement_id.originalValue}`);

      if (result.error) {
        showError(result.error.message);

        return;
      }
      // console.log(JSON.stringify(result.data))
      console.log('fullObj.value=' + JSON.stringify(fullObj.value))
      if (fullObj.value.kathgor_epitok_bashs.originalValue === null || fullObj.value.kathgor_epitok_bashs.originalValue === undefined) {
        fullObj.value.kathgor_epitok_bashs.originalValue = result.data[0].kathgor_epitok_bashs;
      }
      if (fullObj.value.perithorio.originalValue === null || fullObj.value.perithorio.originalValue === undefined) {
        fullObj.value.perithorio.originalValue = result.data[0].perithorio;
      }
      if (fullObj.value.epitok_uperhm.originalValue === null || fullObj.value.epitok_uperhm.originalValue === undefined) {
        fullObj.value.epitok_uperhm.originalValue = result.data[0].epitok_uperhm;
      }
      if (fullObj.value.parakra_forou.originalValue === null || fullObj.value.parakra_forou.originalValue === undefined) {
        fullObj.value.parakra_forou.originalValue = result.data[0].parakra_forou;
      }
      if (fullObj.value.eisfora_n128_1975.originalValue === null || fullObj.value.eisfora_n128_1975.originalValue === undefined) {
        fullObj.value.eisfora_n128_1975.originalValue = result.data[0].eisfora_n128_1975;
      }
    }
  }
);

const loadPendingDebitsCredits = async () => {
  let result = await pgrestapi.get(`view_debits_credits_list?loan_account_id=eq.${props.loanaccountid}`);
  pendingDebitsCredits.value = result.data;
}

onMounted(async () => {
  await loadLoanAgreements();

  console.log('loading loan account with id ' + props.loanaccountid);
  await loadLoanAccount(props.loanaccountid as number);
  await loadPendingDebitsCredits();

  if (fullObj.value.enhm_kefal.originalValue == null) {
    fullObj.value.enhm_kefal.originalValue = 0;
  }
  if (fullObj.value.kathister_kefal.originalValue == null) {
    fullObj.value.kathister_kefal.originalValue = 0;
  }
  if (fullObj.value.logistikop_tokoi.originalValue == null) {
    fullObj.value.logistikop_tokoi.originalValue = 0;
  }
  if (fullObj.value.sumbatikoi_log_tokoi.originalValue == null) {
    fullObj.value.sumbatikoi_log_tokoi.originalValue = 0;
  }
  if (fullObj.value.yperhmerias_log_tokoi.originalValue == null) {
    fullObj.value.yperhmerias_log_tokoi.originalValue = 0;
  }
  if (fullObj.value.n2601_1998_log_tokoi.originalValue == null) {
    fullObj.value.n2601_1998_log_tokoi.originalValue = 0;
  }
  if (fullObj.value.mh_logistikop_tokoi.originalValue == null) {
    fullObj.value.mh_logistikop_tokoi.originalValue = 0;
  }
  if (fullObj.value.sumbatikoi_mh_log_tokoi.originalValue == null) {
    fullObj.value.sumbatikoi_mh_log_tokoi.originalValue = 0;
  }
  if (fullObj.value.yperhmerias_mh_log_tokoi.originalValue == null) {
    fullObj.value.yperhmerias_mh_log_tokoi.originalValue = 0;
  }
  if (fullObj.value.n2601_1998_mh_log_tokoi.originalValue == null) {
    fullObj.value.n2601_1998_mh_log_tokoi.originalValue = 0;
  }
  if (fullObj.value.eksoda.originalValue == null) {
    fullObj.value.eksoda.originalValue = 0;
  }
  if (fullObj.value.synoliko_poso.originalValue == null) {
    fullObj.value.synoliko_poso.originalValue = 0;
  }
  // if (fullObj.value.loan_capital_repayment_type.originalValue == null) {
  //   fullObj.value.loan_capital_repayment_type.originalValue = -1;
  // }

});

const close = async () => {
  emits("close");
};

const loadProducts = async () => {
  let result: PGRESTResult = new PGRESTResult();
  products.value = [];

  result = await pgrestapi.get("config_loan_account_product?order=product");

  if (result.error) {
    showError(result.error.message);

    return;
  }

  for (let i = 0; i < result.data.length; i++) {
    if (locale.value == "en") {
      products.value.push({
        value: result.data[i].id,
        label: result.data[i].english_trans,
      });
    } else if (locale.value == "el") {
      products.value.push({
        value: result.data[i].id,
        label: result.data[i].greek_trans,
      });
    }
  }
};

const loadCurrencies = async () => {
  let result: PGRESTResult = new PGRESTResult();
  currencies.value = [];

  result = await pgrestapi.get("config_currency?order=currency");

  if (result.error) {
    showError(result.error.message);

    return;
  }

  for (let i = 0; i < result.data.length; i++) {
    currencies.value.push({
      value: result.data[i].id,
      label: result.data[i].currency,
    });
  }
};

const save = async () => {
  let result: PGRESTResult = new PGRESTResult();

  // if (mainStore.loggedUser.user_type === UserType.Agent) {

  isLoading.value = true;
  await saveLoanAccountChanges();
  isLoading.value = false;

  emits("save");
  //   return;
  // }

  // let newFull: any = {};
  // for (let field of Object.keys(fullObj.value)) {
  //   newFull[field] = fullObj.value[field].originalValue;
  // }

  // let id = JSON.stringify(newFull.id);
  // delete newFull.id;
  // delete newFull.loan_agreement;

  // // Convert empty strings to NULL for DB
  // for (let key of Object.keys(newFull)) {
  //   if (newFull[key] === '')
  //     newFull[key] = null;
  // }

  // result = await pgrestapi.patch("loan_accounts?id=eq." + id, newFull);

  // if (result.error) {
  //   showError(result.error.message); // emits("fail-save");
  //   return;
  // }

  // emits("save");
};

const saveLoanAccountChanges = async () => {
  let result: PGRESTResult = new PGRESTResult();
  let timestamp: string = await (await pgrestapi.get("rpc/customnew")).data;
  let newFull: any = {};

  for (let field of Object.keys(fullObj.value)) {
    if ((originalObj as any).value[field] === null) {
      (originalObj as any).value[field] = "";

    }
    if ((fullObj as any).value[field].originalValue === null) {
      (fullObj as any).value[field].originalValue = "";
    }
    newFull[field] = fullObj.value[field].originalValue;
  }

  // console.log(JSON.stringify(originalObj.value));
  const report = ChangesetHelper.compare(originalObj.value, newFull);

  let madeChange = false;
  for (let changedField of report.changes) {
    madeChange = true;
    // console.log(
    //   `${changedField} -> Old:${originalObj.value[changedField]}, New:${newFull[changedField]}`
    // );
    if (
      fullObj.value[changedField].pendingValue === null ||
      fullObj.value[changedField].pendingValue === undefined
    ) {
      result = await pgrestapi.post_data("entity_loan_accounts_changes", {
        entity_id: originalObj.value.id,
        editor_user_id: mainStore.loggedUser.id,
        field: changedField,
        value: newFull[changedField],
      });

      if (mainStore.loggedUser.user_type !== UserType.Agent) {
        await onDecision({ accepted: true, db_change_id: result.data[0].id, field: changedField, value: newFull[changedField] } as Decision);
      }
    } else {
      if (mainStore.loggedUser.user_type === UserType.Agent) {
        result = await pgrestapi.patch(
          "entity_loan_accounts_changes?id=eq." +
          fullObj.value[changedField].pendingValue.id,
          {
            entity_id: originalObj.value.id,
            editor_user_id: mainStore.loggedUser.id,
            field: changedField,
            value: newFull[changedField],
            insert_timestamp: timestamp,
          }
        );
      } else {
        result = await pgrestapi.post_data("entity_loan_accounts_changes", {
          entity_id: originalObj.value.id,
          editor_user_id: mainStore.loggedUser.id,
          field: changedField,
          value: newFull[changedField],
        });

        await onDecision({ accepted: true, db_change_id: result.data[0].id, field: changedField, value: newFull[changedField] } as Decision);
      }
    }
  }

  //
  // Set to approve status PendingReview if changes were made by an agent
  //
  if (madeChange && originalObj.value.approve_status !== ChangeApprovalStatus.PendingApprove && mainStore.loggedUser.user_type === UserType.Agent) {
    await pgrestapi.patch(`loan_accounts?id=eq.${originalObj.value.id}`, { approve_status: ChangeApprovalStatus.PendingReview });
  }
};

const loadLoanAccount = async (loanaccountid: number) => {
  await loadProducts();
  await loadSelects();
  if (loanaccountid === -1) return;

  isLoading.value = true;

  let result: PGRESTResult = new PGRESTResult();

  result = await pgrestapi.get(`loan_accounts?id=eq.${loanaccountid}`);

  // console.log(
  //   `loan_accounts?id=eq.${loanaccountid}&select=*,loan_agreement:loan_agreement(*)`
  // );

  if (result.error) {
    showError(result.error.message);

    isLoading.value = false;

    return;
  }

  originalObj.value = JSON.parse(JSON.stringify(result.data[0]));
  fullObj.value = JSON.parse(JSON.stringify(result.data[0]));

  let result2: PGRESTResult = new PGRESTResult();
  result2 = await pgrestapi.get(`loan_agreement?select=katast_sumbashs,ar_sumbashs&id=eq.${fullObj.value.loan_agreement_id}`);
  loanAgreementLegalStatus.value = result2.data[0].katast_sumbashs;
  loanAgreementArSumb.value = result2.data[0].ar_sumbashs;
  await loadPendingChanges();
  await loadInterests();
  // await loadProducts();
  await loadCurrencies();
  data.value = result.data[0];
  isLoading.value = false;
};

const loadPendingChanges = async () => {
  let result: PGRESTResult = new PGRESTResult();
  let loanAgreement: PGRESTResult = new PGRESTResult();
  let currency: PGRESTResult = new PGRESTResult();
  let product: PGRESTResult = new PGRESTResult();
  let interest: PGRESTResult = new PGRESTResult();

  result = await pgrestapi.get(
    `entity_loan_accounts_changes?entity_id=eq.${props.loanaccountid}&approval_status=eq.0&select=id,field,value,insert_timestamp,editor:users!fk_user_editor(username)`
  );

  for (let field of Object.keys(fullObj.value)) {
    fullObj.value[field] = { field, originalValue: fullObj.value[field] };
  }

  // console.log(fullObj.value);

  hasPendingChanges.value = false;
  for (let pending of result.data) {
    hasPendingChanges.value = true;
    //Loan Agreement
    if (pending.field === "loan_agreement_id") {
      if (pending.value) {
        loanAgreement = await pgrestapi.get(
          `loan_agreement?select=ar_sumbashs&id=eq.` + pending.value
        );
        pending["pendingDisplayValue"] = loanAgreement.data[0].ar_sumbashs;
      }
      if ((originalObj as any).value[pending.field]) {
        loanAgreement = await pgrestapi.get(
          `loan_agreement?select=ar_sumbashs&id=eq.` +
          (originalObj as any).value[pending.field]
        );
        pending["originalDisplayValue"] = loanAgreement.data[0].ar_sumbashs;
      }
    }

    //Currency
    if (pending.field === "currency") {
      if (pending.value) {
        currency = await pgrestapi.get(
          `config_currency?select=currency&id=eq.` + pending.value
        );
        pending["pendingDisplayValue"] = currency.data[0].currency;
      }
      if ((originalObj as any).value[pending.field]) {
        currency = await pgrestapi.get(
          `config_currency?select=currency&id=eq.` +
          (originalObj as any).value[pending.field]
        );
        pending["originalDisplayValue"] = currency.data[0].currency;
      }
    }

    //Products
    if (pending.field === "product") {
      if (pending.value) {
        product = await pgrestapi.get(
          `config_product?select=product&id=eq.` + pending.value
        );
        pending["pendingDisplayValue"] = product.data[0].product;
      }
      if ((originalObj as any).value[pending.field]) {
        product = await pgrestapi.get(
          `config_product?select=product&id=eq.` +
          (originalObj as any).value[pending.field]
        );
        pending["originalDisplayValue"] = product.data[0].product;
      }
    }

    //Legal Status
    if (pending.field === "legal_status") {
      pending["pendingDisplayValue"] = legalStatusList.value.find((element: any) => {
        return element.value === pending.value;
      })?.label;
      pending["originalDisplayValue"] = legalStatusList.value.find((element: any) => {
        return element.value === (originalObj as any).value[pending.field];
      })?.label;
    }

    //Interests
    if (pending.field === "kathgor_epitok_bashs") {
      if (pending.value) {
        interest = await pgrestapi.get(
          `config_interest?select=interest&id=eq.` + pending.value
        );
        pending["pendingDisplayValue"] = interest.data[0].interest;
      }
      if ((originalObj as any).value[pending.field]) {
        interest = await pgrestapi.get(
          `config_interest?select=interest&id=eq.` +
          (originalObj as any).value[pending.field]
        );
        pending["originalDisplayValue"] = interest.data[0].interest;
      }
    }

    //Presentation of Certificate
    if (pending.field === "proskom_bebeos") {
      if (pending.value === "true") {
        pending["pendingDisplayValue"] = t("global.true");
      } else if (pending.value === "false") {
        pending["pendingDisplayValue"] = t("global.false");
      }

      if ((originalObj as any).value[pending.field] === true) {
        pending["originalDisplayValue"] = t("global.true");
      } else if ((originalObj as any).value[pending.field] === false) {
        pending["originalDisplayValue"] = t("global.false");
      }
    }

    //Status
    if (pending.field === "status") {
      pending["pendingDisplayValue"] = StatusList.find((element: any) => {
        return element.value === pending.value;
      })?.label;
      pending["originalDisplayValue"] = StatusList.find((element: any) => {
        return element.value === (originalObj as any).value[pending.field];
      })?.label;
    }

    pending["originalValue"] = originalObj.value[pending.field];
    fullObj.value[pending.field].pendingValue = pending;
  }
};

const loadLoanAgreements = async () => {
  let result: PGRESTResult = new PGRESTResult();
  StoreLoanAgreements.value = [];

  //result = await pgrestapi.get("loan_agreement?approve_status=eq.1");
  if (mainStore.loggedUser.user_type === 2) {
    result = await pgrestapi.get(`view_mosloanagreement_view1?&approve_status=eq.1&debtor_approve_status=eq.1&allowed_users=cs.{${mainStore.loggedUser.id}}`);
  }
  if (mainStore.loggedUser.user_type === 1) {
    result = await pgrestapi.get(`view_mosloanagreement_view1?&approve_status=eq.1&debtor_approve_status=eq.1&portfolio_allowed_users=cs.{${mainStore.loggedUser.id}}`);
  }
  if (mainStore.loggedUser.user_type === 0) {
    result = await pgrestapi.get(`view_mosloanagreement_view1?&approve_status=eq.1&debtor_approve_status=eq.1`);
  }

  if (result.error) {
    showError(result.error.message);

    return;
  }

  for (let i = 0; i < result.data.length; i++) {
    StoreLoanAgreements.value.push({
      value: result.data[i].id,
      label: result.data[i].ar_sumbashs,
    });
  }
};

const loadInterests = async () => {
  let result: PGRESTResult = new PGRESTResult();
  interests.value = [];

  result = await pgrestapi.get("config_interest?order=interest");

  if (result.error) {
    showError(result.error.message);

    return;
  }

  for (let i = 0; i < result.data.length; i++) {
    interests.value.push({
      value: result.data[i].id,
      label: result.data[i].interest,
    });
  }
};

const onDecision = async (decision: Decision) => {
  let result: PGRESTResult = new PGRESTResult();

  let timestamp: string = await (await pgrestapi.get("rpc/customnew")).data;

  if (decision.accepted) {
    result = await pgrestapi.patch(
      "entity_loan_accounts_changes?id=eq." + decision.db_change_id,
      {
        approval_status: ChangeApprovalStatus.Approved,
        approver_user_id: mainStore.loggedUser.id,
        decision_timestamp: timestamp,
      }
    );

    let obj: any = {};
    let field = decision.field as string;
    obj[field] = decision.value;

    if (obj[field] === "")
      obj[field] = null;

    result = await pgrestapi.patch("loan_accounts?id=eq." + originalObj.value.id, obj);
  } else {
    result = await pgrestapi.patch(
      "entity_loan_accounts_changes?id=eq." + decision.db_change_id,
      {
        approval_status: ChangeApprovalStatus.Rejected,
        approver_user_id: mainStore.loggedUser.id,
        decision_timestamp: timestamp,
      }
    );
  }

  // set to approve_status = 1 if no more pending changes
  await mosapi.updateEntityApproveStatus("loan_accounts", data.value.id as number);

  loadLoanAccount(props.loanaccountid as number);
};

const loadSelects = async () => {
  paymentTypeList.value = [
    {
      value: "xreolitiko",
      label: t("payment_type_list.xreolitiko"),
    },
    {
      value: "tokoxreolitiko",
      label: t("payment_type_list.tokoxreolitiko"),
    },
    {
      value: "balloon",
      label: t("payment_type_list.balloon"),
    },
  ];
};

const clickAcceptAll = async () => {
  isLoading.value = true;
  try {
    for (let key of Object.keys(fullObj.value)) {
      if (fullObj.value[key].pendingValue !== undefined) {
        console.log('accepting pending change for', key);
        await onDecision({ accepted: true, db_change_id: (fullObj as any).value[key].pendingValue.id, field: key, value: (fullObj as any).value[key].pendingValue.value } as Decision);
      }
    }
  } catch (ex) {
    console.log('Exception when accepting all:')
    console.log(ex);
  }
  isLoading.value = false;
}

const PresentationofCertificateList = [
  {
    value: true,
    label: t("global.true"),
  },
  {
    value: false,
    label: t("global.false"),
  },
];

const StatusList = [
  {
    value: "active",
    label: t("global.active"),
  },
  {
    value: "inactive",
    label: t("global.inactive"),
  },
];

const capitalRepaymentTypes = [
  {
    value: null,
    label: "",
  },
  {
    value: LoanRepaymentType.Tokoxreolytiko,
    label: t("payment_type_list.tokoxreolitiko"),
  },
  {
    value: LoanRepaymentType.XreolytikoIsoposo,
    label: t("payment_type_list.xreolitiko"),
  },
  {
    value: LoanRepaymentType.XreolytikoAnisoposo,
    label: t("payment_type_list.balloon"),
  },
];

const openDebitCreditType = (type: DebitCreditType) => {
  selectedDebitCreditType.value = type;
  showDebitsCredits.value = true;
}

const onCloseCredits = () => {
  showDebitsCredits.value = false;
}

const getDebitCreditTypeName = (type: DebitCreditType) => {
  switch (type) {
    case DebitCreditType.Debit:
      return "Debit";
    case DebitCreditType.Credit:
      return "Credit";
    case DebitCreditType.Installment:
      return "Installment";
    case DebitCreditType.Interest:
      return "Interest";
    case DebitCreditType.Exoda:
      return "Expense";
      case DebitCreditType.TokoiTokwn:
      return "Initial Interest";
  }
}

const onCloseDebitCredit = async () => {
  await loadPendingDebitsCredits();
}
</script>

<template>
  <div v-loading="isLoading">
    <div>
      <el-button @click="openDebitCreditType(DebitCreditType.Credit)">{{ $t('LoanAccountDetails.pistwseis') }}
        <el-icon
          v-if="pendingDebitsCredits && pendingDebitsCredits.length > 0 && pendingDebitsCredits[0].pending_credits > 0"
          :size="16" color="orange" style="padding-left: 3px;">
          <Warning />
        </el-icon>
      </el-button>
      <el-button v-if="fullObj?.product?.originalValue === 5" @click="openDebitCreditType(DebitCreditType.Debit)">{{
        $t('LoanAccountDetails.xrewseis') }}
        <el-icon
          v-if="pendingDebitsCredits && pendingDebitsCredits.length > 0 && pendingDebitsCredits[0].pending_debits > 0"
          :size="16" color="orange" style="padding-left: 3px;">
          <Warning />
        </el-icon>
      </el-button>
      <el-button @click="openDebitCreditType(DebitCreditType.Installment)">{{ $t('LoanAccountDetails.doseis') }}
        <el-icon
          v-if="pendingDebitsCredits && pendingDebitsCredits.length > 0 && pendingDebitsCredits[0].pending_installments > 0"
          :size="16" color="orange" style="padding-left: 3px;">
          <Warning />
        </el-icon>
      </el-button>
      <el-button @click="openDebitCreditType(DebitCreditType.Interest)">{{ $t('LoanAccountDetails.tokoi') }}
        <el-icon
          v-if="pendingDebitsCredits && pendingDebitsCredits.length > 0 && pendingDebitsCredits[0].pending_interests > 0"
          :size="16" color="orange" style="padding-left: 3px;">
          <Warning />
        </el-icon>
      </el-button>
      <el-button @click="openDebitCreditType(DebitCreditType.Exoda)">{{ $t('LoanAccountDetails.exoda') }}
        <el-icon
          v-if="pendingDebitsCredits && pendingDebitsCredits.length > 0 && pendingDebitsCredits[0].pending_exoda > 0"
          :size="16" color="orange" style="padding-left: 3px;">
          <Warning />
        </el-icon>
      </el-button>
    </div>

    <p />

    <el-form :model="data" :rules="rules" ref="ruleFormRef">

      <el-tag class="ml-2" style="margin: 12px; text-align:right; float: right;">
        {{ $t('LoanAccountDetails.legal_status') }} of {{ loanAgreementArSumb }}: {{ loanAgreementLegalStatus }}
      </el-tag>

      <AcceptAllPending v-show="hasPendingChanges && !props.view_only" @accept="clickAcceptAll" />

      <el-tabs type="card" class="demo-tabs" v-model="activeName">
        <el-tab-pane :label="$t('LoanAccountDetails.loan_account_details')" name="loan_agreement_details">
          <el-row :gutter="10">
            <el-col :span="24">
              <EntityField type="select" :label="$t('LoanAccountDetails.loan_agreement')"
                :object="fullObj.loan_agreement_id" :options="StoreLoanAgreements" @decision="onDecision"
                :is-disabled="props.view_only" />
            </el-col>
            <!--sssss-->
            <el-col :span="24">
              <EntityField type="input" :label="$t('LoanAccountDetails.loan_account')"
                :object="fullObj.ar_trexontos_logariasmou" @decision="onDecision" :is-disabled="props.view_only" />
            </el-col>
            <!--sssss-->
            <el-col :span="24">
              <EntityField type="input" :label="$t('LoanAccountDetails.original_loan_accound')"
                :object="fullObj.ar_arxikou_logariasmou" @decision="onDecision" :is-disabled="props.view_only" />
            </el-col>
            <el-col :span="24">
              <EntityField type="input" :label="$t('LoanAccountDetails.previous_loan_accound')"
                :object="fullObj.palaios_logariasmos" @decision="onDecision" :is-disabled="props.view_only" />
            </el-col>
            <el-col :span="24">
              <EntityField type="select" :object="fullObj.currency" :label="$t('LoanAccountDetails.currency')"
                :options="currencies" @decision="onDecision" :is-disabled="props.view_only" />
            </el-col>
            <el-col :span="24">
              <EntityField type="datepicker" :label="$t('LoanAccountDetails.original_end_date')"
                :object="fullObj.arxikh_hmer_lukshs" @decision="onDecision" :is-disabled="props.view_only" />
            </el-col>
            <el-col :span="24">
              <EntityField type="datepicker" :label="$t('LoanAccountDetails.end_date')" :object="fullObj.nea_hmer_lukshs"
                @decision="onDecision" :is-disabled="props.view_only" />
            </el-col>
            <el-col :span="24">
              <EntityField type="datepicker" :label="$t('LoanAccountDetails.disbursment_date')"
                :object="fullObj.hmer_ektam" @decision="onDecision" :is-disabled="props.view_only" />
            </el-col>
            <el-col :span="24">
              <EntityField type="datepicker" :label="$t('LoanAccountDetails.hmer_enarxis_xaritos')"
                :object="fullObj.hmer_enarxis_xaritos" @decision="onDecision" :is-disabled="props.view_only" />
            </el-col>

            <el-col :span="24">
              <EntityField type="datepicker" :label="$t('LoanAccountDetails.hmer_lixis_xaritos')"
                :object="fullObj.hmer_lixis_xaritos" @decision="onDecision" :is-disabled="props.view_only" />
            </el-col>

            <el-col :span="24">
              <EntityField type="datepicker" :label="$t('LoanAccountDetails.hmer_1is_dosis_tokon')"
                :object="fullObj.hmer_1is_dosis_tokon" @decision="onDecision" :is-disabled="props.view_only" />
            </el-col>

            <el-col :span="24">
              <EntityField type="money" :label="$t('LoanAccountDetails.disbursment_amount')" :object="fullObj.poso_ektam"
                @decision="onDecision" :is-disabled="props.view_only" />
            </el-col>

            <el-col :span="24">
              <EntityField type="select" :object="fullObj.status" :label="$t('LoanAccountDetails.status')"
                :options="StatusList" @decision="onDecision" :is-disabled="props.view_only" />
            </el-col>

            
            
            <el-col :span="24">
              <EntityField type="input" :label="$t('LoanAccountDetails.base_rate_value')" :object="fullObj.epitok_bashs"
                @decision="onDecision" :is-disabled="props.view_only" />
            </el-col>
            
            <el-col :span="24">
              <EntityField type="input" :label="$t('LoanAccountDetails.tax_withholding')" :object="fullObj.parakra_forou"
                @decision="onDecision" :is-disabled="props.view_only" />
            </el-col>
            <el-col :span="24">
              <EntityField type="select" :object="fullObj.proskom_bebeos"
                :label="$t('LoanAccountDetails.presentation_of_certificate')" :options="PresentationofCertificateList"
                @decision="onDecision" :is-disabled="props.view_only" />
            </el-col>
            <el-col :span="24" v-if="fullObj.proskom_bebeos?.originalValue">
              <EntityField type="datepicker" :label="$t('LoanAccountDetails.hmer_proskom')" :object="fullObj.hmer_proskom"
                @decision="onDecision" :is-disabled="props.view_only" />
            </el-col>
          </el-row>
        </el-tab-pane>

        <el-tab-pane :label="$t('LoanAccountDetails.balance')" name="balance">
          <el-row>
            <div>
              <div v-if="(parseFloat(fullObj.trexon_upol?.originalValue) !== (
                parseFloat(fullObj.enhm_kefal?.originalValue) +
                parseFloat(fullObj.kathister_kefal?.originalValue) +
                parseFloat(fullObj.sumbatikoi_log_tokoi?.originalValue) +
                parseFloat(fullObj.yperhmerias_log_tokoi?.originalValue) +
                parseFloat(fullObj.n2601_1998_log_tokoi?.originalValue) +
                parseFloat(fullObj.sumbatikoi_mh_log_tokoi?.originalValue) +
                parseFloat(fullObj.yperhmerias_mh_log_tokoi?.originalValue) +
                parseFloat(fullObj.n2601_1998_mh_log_tokoi?.originalValue) +
                parseFloat(fullObj.eksoda?.originalValue)


              ) && (

                  (parseFloat(fullObj.trexon_upol?.originalValue) > 0) ||
                  (parseFloat(fullObj.enhm_kefal?.originalValue) > 0) ||
                  (parseFloat(fullObj.kathister_kefal?.originalValue) > 0) ||
                  (parseFloat(fullObj.sumbatikoi_log_tokoi?.originalValue) > 0) ||
                  (parseFloat(fullObj.yperhmerias_log_tokoi?.originalValue) > 0) ||
                  (parseFloat(fullObj.n2601_1998_log_tokoi?.originalValue) > 0) ||
                  (parseFloat(fullObj.sumbatikoi_mh_log_tokoi?.originalValue) > 0) ||
                  (parseFloat(fullObj.yperhmerias_mh_log_tokoi?.originalValue) > 0) ||
                  (parseFloat(fullObj.n2601_1998_mh_log_tokoi?.originalValue) > 0) ||
                  (parseFloat(fullObj.eksoda?.originalValue) > 0)


                ))" class="el-tag el-tag--danger" style="padding: 15px; margin: 5px;">
                {{ $t("errors.loan_accounts_balance_error") }}
              </div>
              <div v-if="(parseFloat(fullObj.logistikop_tokoi?.originalValue)) !== (
                parseFloat(fullObj.sumbatikoi_log_tokoi?.originalValue) +
                parseFloat(fullObj.yperhmerias_log_tokoi?.originalValue) +
                parseFloat(fullObj.n2601_1998_log_tokoi?.originalValue)
              )
                && (
                  (parseFloat(fullObj.logistikop_tokoi?.originalValue) > 0) ||
                  (parseFloat(fullObj.sumbatikoi_log_tokoi?.originalValue) > 0) ||
                  (parseFloat(fullObj.yperhmerias_log_tokoi?.originalValue) > 0) ||
                  (parseFloat(fullObj.n2601_1998_log_tokoi?.originalValue) > 0)
                )

                " class="el-tag el-tag--danger" style="padding: 15px; margin: 5px;">
                {{ $t("errors.loan_accounts_balance_error_logistik") }}
              </div>
              <div v-if="(parseFloat(fullObj.mh_logistikop_tokoi?.originalValue)) !== (
                parseFloat(fullObj.sumbatikoi_mh_log_tokoi?.originalValue) +
                parseFloat(fullObj.yperhmerias_mh_log_tokoi?.originalValue) +
                parseFloat(fullObj.n2601_1998_mh_log_tokoi?.originalValue)
              )

                && (
                  (parseFloat(fullObj.mh_logistikop_tokoi?.originalValue) > 0) ||
                  (parseFloat(fullObj.sumbatikoi_mh_log_tokoi?.originalValue) > 0) ||
                  (parseFloat(fullObj.yperhmerias_mh_log_tokoi?.originalValue) > 0) ||
                  (parseFloat(fullObj.n2601_1998_mh_log_tokoi?.originalValue) > 0)
                )
                " class="el-tag el-tag--danger" style="padding: 15px; margin: 5px;">
                {{ $t("errors.loan_accounts_balance_error_mh_logistik") }}
              </div>
            </div>
            <el-row>
              <el-col :span="24">
                <EntityField type="datepicker" :label="$t('LoanAccountDetails.date_of_current_balance')"
                  :object="fullObj.date_of_current_balance" @decision="onDecision" :is-disabled="props.view_only" />
              </el-col>
              <el-col :span="24">
                <EntityField type="money" :label="$t('LoanAccountDetails.current_balance')" :object="fullObj.trexon_upol"
                  @decision="onDecision" :is-disabled="props.view_only" />
              </el-col>
              <el-col :span="24">
                <EntityField type="money" :label="$t('LoanAccountDetails.due_principal')" :object="fullObj.enhm_kefal"
                  @decision="onDecision" :is-disabled="props.view_only" />
              </el-col>
              <el-col :span="24">
                <EntityField type="money" :label="$t('LoanAccountDetails.overdue_principal')"
                  :object="fullObj.kathister_kefal" @decision="onDecision" :is-disabled="props.view_only" />
              </el-col>
              <el-col :span="24">
                <h3>{{ $t('LoanAccountDetails.logistikopoihmenoi') }}</h3>
              </el-col>
              <el-col :span="24">
                <EntityField type="money" :label="$t('LoanAccountDetails.on_balance_sheet_interest')"
                  :object="fullObj.logistikop_tokoi" @decision="onDecision" :is-disabled="props.view_only" />
              </el-col>
              <!--  sumbatikoi_log_tokoi
                    yperhmerias_log_tokoi
                    n2601_1998_log_tokoi -->
              <!-- TODO Check -->
              <el-col :span="24">
                <EntityField type="money" :label="$t('LoanAccountDetails.interest_on_due_balance')"
                  :object="fullObj.sumbatikoi_log_tokoi" @decision="onDecision" :is-disabled="props.view_only" />
              </el-col>
              <!-- TODO Check -->
              <el-col :span="24">
                <EntityField type="money" :label="$t('LoanAccountDetails.interest_on_overdue_balance')"
                  :object="fullObj.yperhmerias_log_tokoi" @decision="onDecision" :is-disabled="props.view_only" />
              </el-col>
              <!-- TODO Check -->
              <el-col :span="24">
                <EntityField type="money" :label="$t('LoanAccountDetails.interest_on_interest')"
                  :object="fullObj.n2601_1998_log_tokoi" @decision="onDecision" :is-disabled="props.view_only" />
              </el-col>
              <el-col :span="24">
                <h3>{{ $t('LoanAccountDetails.mhlogistikopoihmenoi') }}</h3>
              </el-col>
              <el-col :span="24">
                <EntityField type="money" :label="$t('LoanAccountDetails.off_balance_sheet_interest')"
                  :object="fullObj.mh_logistikop_tokoi" @decision="onDecision" :is-disabled="props.view_only" />
              </el-col>
              <el-col :span="24">
                <EntityField type="money" :label="$t('LoanAccountDetails.interest_on_due_balance')"
                  :object="fullObj.sumbatikoi_mh_log_tokoi" @decision="onDecision" :is-disabled="props.view_only" />
              </el-col>
              <el-col :span="24">
                <EntityField type="money" :label="$t('LoanAccountDetails.interest_on_overdue_balance')"
                  :object="fullObj.yperhmerias_mh_log_tokoi" @decision="onDecision" :is-disabled="props.view_only" />
              </el-col>
              <el-col :span="24">
                <EntityField type="money" :label="$t('LoanAccountDetails.interest_on_interest')"
                  :object="fullObj.n2601_1998_mh_log_tokoi" @decision="onDecision" :is-disabled="props.view_only" />
              </el-col>
              <el-col :span="24">
                <EntityField type="money" :label="$t('LoanAccountDetails.commissions_expenses')" :object="fullObj.eksoda"
                  @decision="onDecision" :is-disabled="props.view_only" />
              </el-col>
            </el-row>
            <el-col :span="24">
              <EntityField type="money" :label="$t('LoanAccountDetails.conditional_write_off_amount')"
                :object="fullObj.diegram_poso" @decision="onDecision" :is-disabled="props.view_only" />
            </el-col>
            <el-col :span="24">
              <EntityField type="money" :label="$t('LoanAccountDetails.accruals')" :object="fullObj.dedoul_tokoi"
                @decision="onDecision" :is-disabled="props.view_only" />
            </el-col>
            <el-col :span="24">
              <EntityField type="datepicker" :label="$t('LoanAccountDetails.1st_delay_date')"
                :object="fullObj.hmer_prwths_kathister" @decision="onDecision" :is-disabled="props.view_only" />
            </el-col>
            <el-col :span="24">
              <EntityField type="input" :label="$t('LoanAccountDetails.days_of_delay')" :object="fullObj.hmeres_kathister"
                @decision="onDecision" :is-disabled="props.view_only" />
            </el-col>
          </el-row>
        </el-tab-pane>
        <el-tab-pane :label="$t('LoanAccountDetails.pedia_upologismou')" name="pedia_upologismou">
         <el-col :span="24">
              <EntityField type="switch" :label="$t('LoanAccountDetails.kathisterimeno')" :object="fullObj.kathisterimeno"
                @decision="onDecision" :is-disabled="props.view_only" />
            </el-col>
            <el-col :span="24">
              <EntityField type="money" :label="$t('LoanAccountDetails.synoliko_poso')" :object="fullObj.synoliko_poso"
                @decision="onDecision" :is-disabled="props.view_only" />
            </el-col>
            <el-col :span="24">
              <EntityField type="select" :object="fullObj.loan_capital_repayment_type"
                :label="$t('LoanAccountDetails.loan_capital_repayment_type')" :options="capitalRepaymentTypes"
                @decision="onDecision" :is-disabled="props.view_only" />
            </el-col>
            <el-col :span="24">
              <EntityField type="input" :label="$t('LoanAccountDetails.loan_capital_repayment_frequency')"
                :object="fullObj.loan_capital_repayment_frequency" @decision="onDecision"
                :is-disabled="props.view_only" />
            </el-col>
            <el-col :span="24">
              <EntityField type="switch" :label="$t('LoanAccountDetails.loan_capital_repayment_epeteiako')"
                :object="fullObj.loan_capital_repayment_epeteiako" @decision="onDecision"
                :is-disabled="props.view_only" />
            </el-col>
            <el-col :span="24">
              <EntityField type="input" :label="$t('LoanAccountDetails.loan_interest_repayment_frequency')"
                :object="fullObj.loan_interest_repayment_frequency" @decision="onDecision"
                :is-disabled="props.view_only" />
            </el-col>
            <el-col :span="24">
              <EntityField type="switch" :label="$t('LoanAccountDetails.loan_interest_repayment_epeteiako')"
                :object="fullObj.loan_interest_repayment_epeteiako" @decision="onDecision"
                :is-disabled="props.view_only" />
            </el-col>

            <el-col :span="24">
              <EntityField type="datepicker" :label="$t('LoanAccountDetails.start_date')" :object="fullObj.hmer_enarkshs"
                @decision="onDecision" :is-disabled="props.view_only" />
            </el-col>
            <el-col :span="24">
              <EntityField type="select" :object="fullObj.product" :label="$t('LoanAccountDetails.product')"
                :options="products" @decision="onDecision" :is-disabled="props.view_only" />
            </el-col>
            <el-col :span="24">
              <EntityField type="select" :object="fullObj.kathgor_epitok_bashs"
                :label="$t('LoanAccountDetails.base_rate')" :options="interests" @decision="onDecision"
                :is-disabled="props.view_only" />
            </el-col>
            <el-col :span="24">
              <EntityField type="input" :label="$t('LoanAccountDetails.loan_interest_change_frequency')"
                :object="fullObj.loan_interest_change_frequency" @decision="onDecision" :is-disabled="props.view_only" />
            </el-col>
            <el-col :span="24">
              <EntityField type="switch" :label="$t('LoanAccountDetails.loan_interest_change_epeteiako')"
                :object="fullObj.loan_interest_change_epeteiako" @decision="onDecision" :is-disabled="props.view_only" />
            </el-col>
            <el-col :span="24">
              <EntityField type="switch" :label="$t('LoanAccountDetails.arnitika_epitokia')"
                :object="fullObj.arnitika_epitokia" @decision="onDecision" :is-disabled="props.view_only" />
            </el-col>
            <el-col :span="24">
              <EntityField type="input" :label="$t('LoanAccountDetails.margin')" :object="fullObj.perithorio"
                @decision="onDecision" :is-disabled="props.view_only" />
            </el-col>
            <el-col :span="24">
              <EntityField type="input" :label="$t('LoanAccountDetails.overude_rate')" :object="fullObj.epitok_uperhm"
                @decision="onDecision" :is-disabled="props.view_only" />
            </el-col>
            <el-col :span="24">
              <EntityField type="input" :label="$t('LoanAccountDetails.levy_N128/75')" :object="fullObj.eisfora_n128_1975"
                @decision="onDecision" :is-disabled="props.view_only" />
            </el-col>
            <hr>
          
          </el-tab-pane>
        <el-tab-pane v-if="fullObj.kathisterimeno?.originalValue" :label="$t('LoanAccountDetails.kathisterimeno')"
          name="kathisterimeno">
          <el-col :span="24">
            <EntityField type="money" :label="$t('LoanAccountDetails.kathisterimeno_enim_arx')"
              :object="fullObj.kathisterimeno_enim_arx" @decision="onDecision" :is-disabled="props.view_only" />
          </el-col>
          <el-col :span="24">
            <EntityField type="money" :label="$t('LoanAccountDetails.kathisterimenoKEF')"
              :object="fullObj.kathisterimenokef" @decision="onDecision" :is-disabled="props.view_only" />
          </el-col>
          <el-col :span="24">
            <EntityField type="datepicker" :label="$t('LoanAccountDetails.kathisterimenodate')"
              :object="fullObj.kathisterimenodate" @decision="onDecision" :is-disabled="props.view_only" />
          </el-col>
          <el-button @click="openDebitCreditType(DebitCreditType.TokoiTokwn)">{{ $t('LoanAccountDetails.tokoitokwn') }}
            <el-icon
              v-if="pendingDebitsCredits && pendingDebitsCredits.length > 0 && pendingDebitsCredits[0].pending_tokoi_tokwn > 0"
              :size="16" color="orange" style="padding-left: 3px;">
              <Warning />
            </el-icon>
          </el-button>
        </el-tab-pane>
        <el-row>
          <el-col :span="24">
            <el-form-item class="btn-wrapper-components">
              <el-button v-if="!props.view_only" type="primary" @click="submitForm(ruleFormRef)">{{ $t('generic.save')
              }}</el-button>
              <el-button @click="close">{{ $t('generic.close') }}</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-tabs>
    </el-form>

    <el-dialog v-if="showDebitsCredits" v-model="showDebitsCredits" @update:model-value="onCloseCredits"
      :title="getDebitCreditTypeName(selectedDebitCreditType) + 's List'" width="80%" @close="onCloseDebitCredit">
      <DebitCreditList :loan_account_id="(props.loanaccountid as number)" :view_only="false"
        :type="selectedDebitCreditType" />
    </el-dialog>


  </div>
</template>

<style scoped></style>
