<script lang="ts" setup>
import { ref, onMounted, watch } from "vue";
import { useMainStore } from "../store/main";
import { storeToRefs } from "pinia";
import { useRouter } from "vue-router";
import { useMOSAPIStore } from "../store/mos_api";
import { usePGRESTAPIStore } from "../store/pgrest_api";
import PGRESTResult from "../classes/PGRESTResult";
import { UserFilled } from "@element-plus/icons-vue";
import { useCookies } from "vue3-cookies";
import UserType from "../enums/UserType";
import { useI18n } from "vue-i18n";
import UserDetails from "../components/UserDetails.vue";

let openMenu = ref(false);
let detailsVisible = ref(false);
const { cookies } = useCookies();
const router = useRouter();
const mainStore = useMainStore();
const mosapiStore = useMOSAPIStore();
const pgrestapi = usePGRESTAPIStore();
const { locale } = useI18n({ useScope: "global" });

let { globalLoanAgreementID, loggedUser } = storeToRefs(mainStore);

let portfolios = ref<any>([]);
let debtors = ref<any>([]);
let loan_agreements = ref<any>([]);

let filterPortfolio = ref<any>("");
let filterDebtor = ref<any>("");
let filterLoanAgreement = ref<any>("");
let userCookie: any = cookies.get("userCookie");
let loanAgreementsLoaded = false;
let lastEditUserID = ref<number>(-1);

function logout() {
  mainStore.unsetUser();
  mosapiStore.unsetToken();
  pgrestapi.unsetToken();

  cookies.remove("dataCookie");
  cookies.remove("data2Cookie");
  cookies.remove("menuListCookie");
  cookies.remove("menuCookie");
  cookies.remove("userCookie");
  cookies.remove("menuOrderCookie");
  cookies.remove("menuIconsCookie");
  globalLoanAgreementID.value = "";
  router.push("/login");
}

// TODO:  if current page is one of summary_check_ak/al/kod, redirect to the proper
//        type when loan agreement changes
watch(globalLoanAgreementID, async (val) => {
  if (mainStore.loggedUser.username === '') return;

  if (!loanAgreementsLoaded) {
    loanAgreementsLoaded = true;
    await loadLoanAgreements();
  }

  // console.log("MainHeader, global loan agreement changed => " + val);
  for (let loan_agreement of loan_agreements.value) {
    // console.log("checking '" + loan_agreement.id + "'-'" + val + "'");
    if (loan_agreement.id + "" === val) {
      filterLoanAgreement.value = loan_agreement.ar_sumbashs;
      // console.log("MainHeader, set filterLoanAgreement to " + loan_agreement.ar_sumbashs);
      break;
    }
  }
});

onMounted(async () => {
  // console.log("mounted main header start");
  locale.value = loggedUser.value.language;
  await loadPortfolios();
  await loadDebtors();
  if (!loanAgreementsLoaded) {
    loanAgreementsLoaded = true;
    await loadLoanAgreements();
  }
  // console.log("mounted main header end");
});

const loadPortfolios = async () => {
  let result: PGRESTResult = new PGRESTResult();

  let addedFilter = "";

  if (globalLoanAgreementID.value !== "") {
    addedFilter += `&loan_agreement_ids=cs.{${globalLoanAgreementID.value}}`;
  } else if (filterDebtor.value !== "") {
    addedFilter += `&debtor_ids=cs.{${filterDebtor.value}}`;
  }

  if (mainStore.loggedUser.user_type === UserType.Agent) {
    addedFilter += `&debtor_allowed_users=cs.{${mainStore.loggedUser.id}}`;
  } else if (mainStore.loggedUser.user_type === UserType.Supervisor) {
    addedFilter += `&allowed_users=cs.{${mainStore.loggedUser.id}}`;
  }
  else if (mainStore.loggedUser.user_type === UserType.Digitizer) {
    addedFilter += `&debtor_allowed_users=cs.{${mainStore.loggedUser.id}}`;
  }

  result = await pgrestapi.get("view_mosportfolios_view1?" + addedFilter);

  portfolios.value = result.data;
};

const loadDebtors = async () => {
  let result: PGRESTResult = new PGRESTResult();

  let addedFilter = "";

  if (globalLoanAgreementID.value !== "") {
    addedFilter += `&loan_agreement_ids=cs.{${globalLoanAgreementID.value}}`;
  } else if (filterPortfolio.value !== "") {
    addedFilter += `&portfolio=eq.${filterPortfolio.value}`;
  }

  if (mainStore.loggedUser.user_type === UserType.Agent) {
    addedFilter += `&allowed_users=cs.{${mainStore.loggedUser.id}}`;
  } else if (mainStore.loggedUser.user_type === UserType.Supervisor) {
    addedFilter += `&portfolio_allowed_users=cs.{${mainStore.loggedUser.id}}`;
  } else if (mainStore.loggedUser.user_type === UserType.Digitizer) {
    addedFilter += `&allowed_users=cs.{${mainStore.loggedUser.id}}`;
  }
  result = await pgrestapi.get("view_mosdebtors_view1?" + addedFilter);

  debtors.value = result.data;
};

const loadLoanAgreements = async () => {
  let result: PGRESTResult = new PGRESTResult();

  let addedFilter = "";

  if (mainStore.loggedUser.user_type === UserType.Agent) {
    addedFilter += `&select=*,debtor!inner(id,portfolio!inner(id)))`;

    if (filterPortfolio.value !== "") {
      addedFilter += `&debtor.portfolio.id=eq.${filterPortfolio.value}`;
    } else if (filterDebtor.value !== "") {
      addedFilter += `&debtor.id=eq.${filterDebtor.value}`;
    }

    addedFilter += `&debtor.allowed_users=cs.{${mainStore.loggedUser.id}}`;
  } else if (mainStore.loggedUser.user_type === UserType.Supervisor) {
    addedFilter += `&select=*,debtor!inner(id,portfolio!inner(id)))`;

    if (filterPortfolio.value !== "") {
      addedFilter += `&debtor.portfolio.id=eq.${filterPortfolio.value}`;
    } else if (filterDebtor.value !== "") {
      addedFilter += `&debtor.id=eq.${filterDebtor.value}`;
    }

    addedFilter += `&debtor.portfolio.allowed_users=cs.{${mainStore.loggedUser.id}}`;
  } else if (mainStore.loggedUser.user_type === UserType.Digitizer) {
    addedFilter += `&debtor.id=eq.-1`;

  }
  else {
    if (filterPortfolio.value !== "") {
      addedFilter += `&select=*,debtor!inner(vat_number)&debtor.portfolio=eq.${filterPortfolio.value}`;
    }

    if (filterDebtor.value !== "") {
      addedFilter += `&debtor_id=eq.${filterDebtor.value}`;
    }
  }
  result = await pgrestapi.get("loan_agreement?" + addedFilter);

  loan_agreements.value = result.data;
};

const portfolioChanged = async (val: any) => {
  mainStore.globalPortfolioID = filterPortfolio.value;
  // console.log("portfolio changed to => " + JSON.stringify(filterPortfolio.value));
  await loadDebtors();
  await loadLoanAgreements();
};

const debtorChanged = async (val: any) => {
  mainStore.globalDebtorID = filterDebtor.value;
  // console.log("debtor changed to => " + filterDebtor.value);

  await loadPortfolios();
  await loadLoanAgreements();
};

const loanAgreementChanged = async (val: any) => {
  // console.log("loan agreement changed to => " + filterLoanAgreement.value);

  mainStore.globalLoanAgreementID = filterLoanAgreement.value;

  await loadPortfolios();
  await loadDebtors();
};

const handleSelectLoanAgreement = async (loan_agreement_id: string) => {
  // console.log("redirecting1");
  router.replace({
    name: "Summary Check",
    query: { loan_agreement_id },
  });
};

const handleCommand = (command: string | number | object) => {
  switch (command) {
    case "logout":
      logout();
      break;
    case "userDetails":
      // console.log("User details");
      break;
  }
};
//console.log('user id = ' + JSON.stringify(mainStore.loggedUser.id))
const changeLanguage = async (lang: string) => {
  let result: PGRESTResult = new PGRESTResult();
  locale.value = lang;
  result = await pgrestapi.patch("users?id=eq." + mainStore.loggedUser.id, {
    language: locale.value,
  });
  loggedUser.value.language = locale.value as any;

  cookies.set(
    "userCookie",
    JSON.stringify({
      user: loggedUser.value,
    })
  );
};

const togglemenu = async () => {
  mainStore.iscollapsed = !mainStore.iscollapsed;
};

const ToggleUserInfo = async () => {
  lastEditUserID.value = mainStore.loggedUser.id as number;
  detailsVisible.value = true;
};

const onSaveUser = () => {
  // console.log("on save clear");
  lastEditUserID.value = -1;
  detailsVisible.value = false;
};

const onCloseUser = () => {
  // console.log("on close clear");
  lastEditUserID.value = -1;
  detailsVisible.value = false;
};

// const testNotify = async () => {
//   console.log('notifying')
//   await mosapiStore.notify({
//     channel: "print_barcode",
//     data: `~MDEL
// ^W70
// ^Q40,2
// ^H1
// ^P1
// ^L
// BQ, 75, 115, 2, 10, 70, 0, 1, 123456789012345678901234
// E`,
//   });
// }
</script>

<template>
  <div>
    <el-row align="middle" justify="center" class="navbar">
      <!-- <el-col :span="4"> hello </el-col> -->
      <el-col :span="2" style="padding-left: 20px">
        <!-- Hamburger Menu -->
        <div id="sidemenu">
          <button
            class="sidemenu__btn"
            @click="(openMenu = !openMenu), togglemenu()"
            :class="{ active: openMenu }"
          >
            <span class="top"></span>
            <span class="mid"></span>
            <span class="bottom"></span>
          </button>
          <!--<button
            class=""
            @click="testNotify"
          >
            <span class="bottom"></span>
          </button>-->

        </div>
      </el-col>
      <el-col :span="22">
        <el-row align="middle" justify="center">
          <el-col :span="5">
            <el-select
              v-model="filterPortfolio"
              placeholder="Portfolio"
              size="large"
              @change="portfolioChanged"
              clearable
              filterable
            >
              <el-option
                v-for="item in portfolios"
                :key="item.id"
                :label="item.portf_name"
                :value="item.id"
              />
            </el-select>
          </el-col>
          <el-col :span="5">
            <el-select
              v-model="filterDebtor"
              placeholder="Debtor"
              size="large"
              @change="debtorChanged"
              clearable
              filterable
            >
              <el-option
                v-for="item in debtors"
                :key="item.id"
                :label="item.vat_number + ' (' + item.fullname + ' / ' + item.cid + ')'"
                :value="item.id"
              />
            </el-select>
          </el-col>
          <el-col :span="5">
            <el-select
              v-model="filterLoanAgreement"
              placeholder="Loan Agreement"
              size="large"
              @change="loanAgreementChanged"
              clearable
              filterable
            >
              <el-option
                v-for="item in loan_agreements"
                :key="item.id"
                :label="item.ar_sumbashs"
                :value="item.id"
              />
            </el-select>
          </el-col>
          <el-col :span="5">
            <el-dropdown @command="handleCommand">
              <el-button circle type="primary" class="btn-blue">
                <el-icon>
                  <UserFilled />
                </el-icon>
              </el-button>
              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item disabled>{{
                    mainStore.loggedUser.username +
                    " (" +
                    UserType[mainStore.loggedUser.user_type] +
                    ")"
                  }}</el-dropdown-item>
                  <el-dropdown-item
                    @click="ToggleUserInfo()"
                    divided
                    command="userDetails"
                    >{{ $t("generic.userdetails") }}
                  </el-dropdown-item>
                  <el-dropdown-item divided>
                    <img
                      v-if="locale !== 'en'"
                      height="20"
                      src="../assets/lang_en.png"
                      @click="changeLanguage('en')"
                    />
                    <img
                      v-if="locale !== 'el'"
                      height="20"
                      src="../assets/lang_el.png"
                      @click="changeLanguage('el')"
                    />
                  </el-dropdown-item>
                  <el-dropdown-item divided command="logout">Logout</el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
  </div>

  <el-dialog
    v-model="detailsVisible"
    @update:model-value="onCloseUser"
    title="User Information"
  >
    <UserDetails
      :userid="lastEditUserID"
      :allow-type-change="false"
      @close="onCloseUser"
      @save="onSaveUser"
    />
  </el-dialog>
</template>

<style scoped>
.navbar {
  height: 70px;
  background: #98a4cb;
  position: fixed;
  z-index: 99999;
  width: 100%;
}

.menu-wrapper {
  width: 40px;
  height: auto;
  cursor: pointer;
  position: relative;
}

#sidemenu .sidemenu__btn {
  display: block;
  width: 50px;
  height: 50px;
  background: transparent;
  border: none;
  position: relative;
  z-index: 100;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
  outline: none;
}

#sidemenu .sidemenu__btn span {
  display: block;
  width: 20px;
  height: 2px;
  margin: auto;
  background: white;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  transition: all 0.4s ease;
}

#sidemenu .sidemenu__btn span.top {
  transform: translateY(-8px);
}

#sidemenu .sidemenu__btn span.bottom {
  transform: translateY(8px);
}
/* 
#sidemenu .sidemenu__btn.active .top {
  transform: rotate(-45deg);
}

#sidemenu .sidemenu__btn.active .mid {
  transform: translateX(-20px) rotate(360deg);
  opacity: 0;
}

#sidemenu .sidemenu__btn.active .bottom {
  transform: rotate(45deg);
} */

#sidemenu .sidemenu__wrapper {
  padding-top: 50px;
}

#sidemenu .sidemenu__list {
  padding-top: 50px;
  list-style: none;
  padding: 0;
  margin: 0;
}

#sidemenu .sidemenu__item a {
  text-decoration: none;
  line-height: 1.6em;
  font-size: 1.6em;
  padding: 0.5em;
  display: block;
  color: white;
  transition: 0.4s ease;
}

#sidemenu .sidemenu__item a:hover {
  background: lightgrey;
  color: dimgrey;
}

.translateX-enter {
  transform: translateX(-200px);
  opacity: 0;
}

.translateX-enter-active,
.translateX-leave-active {
  transform-origin: top left 0;
  transition: 0.2s ease;
}

.translateX-leave-to {
  transform: translateX(-200px);
  opacity: 0;
}
</style>
