<script lang="ts" setup>
import { ref, onMounted } from "vue";
import { useRoute } from "vue-router";
import { useRouter } from "vue-router";
import { usePGRESTAPIStore } from "../store/pgrest_api";
import PGRESTResult from "../classes/PGRESTResult";
import { ElMessage } from "element-plus";
import { Edit, Delete, Notebook, User, Plus, RefreshLeft, Back } from "@element-plus/icons-vue";
import AddEdit_LoanAgreementEktokismos from "../components/AddEdit_LoanAgreementEktokismos.vue";
import UserType from "../enums/UserType";
import { useMainStore } from "../store/main";
import { useSort } from "../composables/sort";
import { useI18n } from "vue-i18n";
import LoanAccount from "../classes/DB_Entities/LoanAccount";
import { DateTime } from "luxon";
import { useGenericMethodsVariables } from "../composables/genericMethodsVariables";
const { showError } = useGenericMethodsVariables();

const mainStore = useMainStore();
const router = useRouter();
const route = useRoute();
const pgrestapi = usePGRESTAPIStore();
const { t } = useI18n({ useScope: "global" });
const data = ref(new LoanAccount());
let dataList = ref([]);
let dataCount = ref(0);
let isLoading = ref(false);
let lastPage = ref(0);
let selectedRow = ref(-1);
let DeleteDialogVisible = ref(false);
let DeleteSelectedRow = ref(0);
let deleteVisible = ref(false);
let addEditVisible = ref(false);
let ar_sumbashs = ref<string>("");
let isNewEntity = ref<boolean>(false);

const { onSortChange, sortColumn, sortOrder } = useSort();

const handleClickEdit = (row_id: number) => {
  selectedRow.value = row_id;
  isNewEntity.value = false;
  addEditVisible.value = true;
};

const deleteHandleClick = (row_id: number) => {
  DeleteSelectedRow.value = row_id;
  deleteVisible.value = true;
  DeleteEktokismo(row_id);
};

const handleClickAdd = () => {
  isNewEntity.value = true; /*δε θα εχει approval*/
  console.log("handleClickAdd isNewEntity.value:   -->  " + isNewEntity.value);
  if (selectedRow.value < 0) {
    selectedRow.value--;
  } else {
    selectedRow.value = -1;
  }
  addEditVisible.value = true;
};

const DeleteEktokismo = async (row_id: number) => {
  let result: PGRESTResult = new PGRESTResult();

  let id = JSON.stringify(row_id);

  result = await pgrestapi.delete_data("ektokismoi_loan_agreement?id=eq." + id);

  if (result.error) {
    showError(result.error.message);

    DeleteDialogVisible.value = false;
    return;
  }

  onDelete();
};

onMounted(async () => {
  sortColumn.value = "id";
  sortOrder.value = "asc";
  loadPage(1);

  

  //route.params.loan_agreement_id
});

const loadPage = async (page: number) => {
  isLoading.value = true;
  lastPage.value = page;
  page = page - 1;
  let filterURL = `?select=*,loan_agreement!inner(*)&loan_agreement_id=eq.${route.params.loan_agreement_id}`;
  if (!(sortOrder.value === null || sortOrder.value === "")) {
    filterURL += `&order=${sortColumn.value}.${sortOrder.value}`;
  }

  let result: PGRESTResult = await pgrestapi.getPaginated(
    "ektokismoi_loan_agreement" + filterURL,
    page
  );

  let result2 = await pgrestapi.get("loan_agreement?id=eq." + route.params.loan_agreement_id);
  ar_sumbashs.value = result2.data[0].ar_sumbashs;

  // console.log(JSON.stringify(result.data));
  if (result.error) {
    showError(result.error.message);;

    return;
  }

  if (result.headers)
    dataCount.value = parseInt(result.headers["content-range"].split("/")[1]);

    for(let item of result.data){
    if(item.start_date){
       item.start_date = DateTime.fromFormat((item.start_date) as string, "yyyy-MM-dd").setLocale('en-gb').toLocaleString();
    }
    if (item.end_date) {
      item.end_date = DateTime.fromFormat((item.end_date) as string, "yyyy-MM-dd").setLocale('en-gb').toLocaleString();
    }
  }

  dataList.value = result.data;
  for (let i = 0; i < result.data.length; i++) {
    (dataList.value[i] as any).total_rate =
      result.data[i].total_due_rate + result.data[i].overdue_rate;
    (dataList.value[i] as any).total_rate =
      Math.round((dataList.value[i] as any).total_rate * 10000) / 10000;
  }
  isLoading.value = false;
};

const onSave = async () => {
  ElMessage({
    showClose: true,
    message: "Interest updated",
    type: "success",
    duration: 1000,
  });

  addEditVisible.value = false;
  selectedRow.value = -1;

  await loadPage(lastPage.value);
};

const onDelete = async () => {
  selectedRow.value = -1;
  ElMessage({
    showClose: true,
    message: "Interest Deleted Successfully",
    type: "success",
    duration: 1000,
  });

  DeleteDialogVisible.value = false;
  await loadPage(lastPage.value);
};

const onFailSave = async () => {
  selectedRow.value = -1;
  ElMessage({
    showClose: true,
    message: "Interest failed to update",
    type: "error",
    duration: 2000,
  });

  addEditVisible.value = false;
};

const localSortChange = async (sortObj: any) => {
  onSortChange(sortObj);
  await loadPage(lastPage.value);
};

const onCloseAddEdit = () => {
  addEditVisible.value = false;
  selectedRow.value = -1;
  isNewEntity.value = false;
};

const onClickBack = () => {
  router.back();
};

</script>

<template>
  <div class="content-wrapper" v-loading="isLoading">
    <el-row :gutter="10">
      <el-col :span="12">
        <h3 class="heading-page">{{ $t("LoanAgreementView.ektokismos") }}</h3>
      </el-col>
    </el-row>
    <div>
      <el-row :gutter="10" style="margin: 10px; margin-top: 15px">
        <el-col :span="1" class=""><el-button link type="primary" size="small" class="btn-add" @click="onClickBack"
            style="float: right; margin: 2.5px" :icon="Back" /></el-col>
        <el-col :span="11" class="realestate-add-new-btn"> </el-col>
        <el-col :span="12" class="realestate-add-new-btn">
          <el-button link type="primary" size="small" class="btn-add" @click="loadPage(lastPage)"
            style="float: right; margin: 2.5px">
            <el-icon>
              <RefreshLeft />
            </el-icon>
          </el-button>
          <el-button link type="primary" size="small"
            class="btn-add" style="float: right; margin: 2.5px" @click="handleClickAdd()">
            <el-icon>
              <Plus />
            </el-icon>
          </el-button>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <h3 style="float: right;"> {{$t('LoanAgreementView.loan_agreement') + ': '}} {{ ar_sumbashs }} </h3>
        </el-col>
      </el-row>
    </div>

    <el-table v-loading="isLoading" :data="dataList" stripe border table-layout="fixed" sortable="custom"
      @sort-change="localSortChange" size="small" class="ektokismosTable">
      <el-table-column sortable fixed prop="start_date" :label="$t('LoanAgreementEktokismosView.start_date')" />
      <el-table-column sortable fixed prop="end_date" :label="$t('LoanAgreementEktokismosView.end_date')" />
      <el-table-column sortable fixed prop="base_rate_value"
        :label="$t('LoanAgreementEktokismosView.base_rate_value')" />
      <el-table-column sortable fixed prop="total_rate" :label="$t('LoanAgreementEktokismosView.total_rate')" />
      <el-table-column sortable fixed prop="due_principal" :label="$t('LoanAgreementEktokismosView.due_principal')" />
      <el-table-column sortable fixed prop="interest_on_due_principal"
        :label="$t('LoanAgreementEktokismosView.interest_on_due_principal')" />
      <el-table-column sortable fixed prop="overdue_principal"
        :label="$t('LoanAgreementEktokismosView.overdue_principal')" />
      <el-table-column sortable fixed prop="overdue_interest"
        :label="$t('LoanAgreementEktokismosView.overdue_interest')" />

      <el-table-column sortable fixed prop="interest_on_overdue_amount"
        :label="$t('LoanAgreementEktokismosView.interest_on_overdue_amount')" />

      <el-table-column sortable fixed prop="bank_percent" :label="$t('LoanAgreementEktokismosView.bank_percent')" />

      <el-table-column fixed="right" :label="$t('global.operations')">
        <template #default="scope">
          <el-button link type="primary" size="small" class="btn-detail" @click="handleClickEdit(scope.row.id)">
            <el-icon>
              <Edit />
            </el-icon>
          </el-button>
          <!--v-if="mainStore.loggedUser.user_type === UserType.Administrator" -->
          <el-button link type="primary" size="small"
            class="btn-delete" @click="DeleteDialogVisible = true; DeleteSelectedRow = scope.row.id;" :icon="Delete" />
        </template>
      </el-table-column>
    </el-table>

    <div>
      <el-pagination small layout="prev, pager, next" :page-size="10" :total="dataCount" @current-change="loadPage" />
    </div>

    <el-dialog v-if="addEditVisible" v-model="addEditVisible" @update:model-value="onCloseAddEdit"
      :title="isNewEntity ? 'Add Interest' : 'Interest Details'">
      <AddEdit_LoanAgreementEktokismos :loan_agreement_id="(route.params.loan_agreement_id as any)"
        :interestid="selectedRow" :newEntity="isNewEntity" @close="onCloseAddEdit" @save="onSave" />
    </el-dialog>

    <el-dialog v-model="DeleteDialogVisible" title="Interest Calculation Delete" width="50%">
      <span>Are you sure you want to delete this Interest Calculation?</span>
      <template #footer #default="scope">
        <span class="dialog-footer">
          <el-button @click="DeleteDialogVisible = false">{{$t('generic.cancel')}}</el-button>
          <el-button type="primary" @click="deleteHandleClick(DeleteSelectedRow)">{{$t('generic.delete')}}
          </el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<style scoped>

</style>
