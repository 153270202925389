import DebitCreditType from "../../enums/DebitCreditType";
import ITableName from "../../interfaces/ITableName";
import LoanAccount from "./LoanAccount";

class DebitCredit implements ITableName {
  id: number = -1;
  loan_account_id: string = "";
  date: string = "";
  amount?: number;
  status: number = 0;
  type: DebitCreditType = DebitCreditType.Credit;
  perigrafi?: string = "";

  static getTableNameStatic(): string {
    return "debits_credits";
  }

  getTableName(): string {
    return DebitCredit.getTableNameStatic();
  }
}

export default DebitCredit;
