<script lang="ts" setup>
import { ref, onMounted } from "vue";
import { useMainStore } from "../store/main";
import { usePGRESTAPIStore } from "../store/pgrest_api";
import PGRESTResult from "../classes/PGRESTResult";
import { ElMessage } from "element-plus";
import { Edit, Delete, Plus } from "@element-plus/icons-vue";
import UserType from "../enums/UserType";
import Client from "../classes/DB_Entities/Client";
import Portfolio from "../classes/DB_Entities/Portfolio";
import SummaryCheck from "../classes/DB_Entities/SummaryCheck";
import Approval from "../classes/DB_Entities/Approval";
import { useI18n } from "vue-i18n";
import Debtor from "../classes/DB_Entities/Debtor";
import LoanAgreement from "../classes/DB_Entities/LoanAgreement";
import RealEstate from "../classes/DB_Entities/RealEstate";
import RealEstateCollateral from "../classes/DB_Entities/RealEstateCollateral";
import LoanAccount from "../classes/DB_Entities/LoanAccount";
import Guarantee from "../classes/DB_Entities/Guarantee";
import GreekStateGuarantees from "../classes/DB_Entities/GreekStateGuarantees";
import Auction from "../classes/DB_Entities/Auction";
import RestOfCollaterals from "../classes/DB_Entities/RestOfCollaterals";
//import RegisterOfBond from "../classes/DB_Entities/RegisterOfBond";
import ROB_Bond from "../classes/DB_Entities/ROB_Bond";
import ROB_Participating_Bank from "../classes/DB_Entities/ROB_Participating_Bank";
import ROB_Title from "../classes/DB_Entities/ROB_Title";

const { t } = useI18n({ useScope: "global" });
const mainStore = useMainStore();
const pgrestapi = usePGRESTAPIStore();

let filterEntity = ref<string>("");
let filterURL: string = "";
let dataList = ref([]);
let dataCount = ref(0);
let isLoading = ref(false);
let lastPage = ref(0);
let selectedEntity = ref<string>("");
let entitiesList = [
  {
    label: "Approvals",
    value: Approval.getTableNameStatic(),
  },
  {
    label: "Auctions",
    value: Auction.getTableNameStatic(),
  },
  {
    label: "Clients",
    value: Client.getTableNameStatic(),
  },
  {
    label: "Debtors",
    value: Debtor.getTableNameStatic(),
  },
  {
    label: "Greek State Guarantees",
    value: GreekStateGuarantees.getTableNameStatic(),
  },
  {
    label: "Guarantees",
    value: Guarantee.getTableNameStatic(),
  },
  {
    label: "Loan Accounts",
    value: LoanAccount.getTableNameStatic(),
  },
  {
    label: "Loan Agreements",
    value: LoanAgreement.getTableNameStatic(),
  },
  {
    label: "Portfolios",
    value: Portfolio.getTableNameStatic(),
  },
  {
    label: "Real Estate",
    value: RealEstate.getTableNameStatic(),
  },
  {
    label: "Real Estate Collateral",
    value: RealEstateCollateral.getTableNameStatic(),
  },
  {
    label: "Register of Bonds",
    value: ROB_Bond.getTableNameStatic(),
  },
  {
    label: "Register of Bonds Banks",
    value: ROB_Participating_Bank.getTableNameStatic(),
  },
  {
    label: "Register of Bonds Titles",
    value: ROB_Title.getTableNameStatic(),
  },
  {
    label: "Rest of Collateral",
    value: RestOfCollaterals.getTableNameStatic(),
  },
  {
    label: "Summary Check",
    value: SummaryCheck.getTableNameStatic(),
  },
];
let timer = ref<any>(null);
let filterEditor = ref<string>("");
let filterFieldname = ref<string>("");
let filterApprover = ref<string>("");
let filterStatus = ref<string>("");
const statusOptions = [
  {
    value: "0",
    label: "approval_status_null",
  },
  {
    value: "1",
    label: "approval_status_accepted",
  },
  {
    value: "2",
    label: "approval_status_rejected",
  },
];
onMounted(async () => { });

const loadPage = async (page: number) => {
  isLoading.value = true;

  lastPage.value = page;
  page = page - 1;
  
  if (filterEntity.value === "mosclients") {
    filterURL = "," + filterEntity.value + "!inner(entity:clientname)";
  } else if (filterEntity.value === "portfolio") {
    filterURL = "," + filterEntity.value + "!inner(entity:portf_name)";
  } else if (filterEntity.value === "summary_check_xtra") {
    filterURL = "," + filterEntity.value + "!inner(entity:loan_agreement_id)";
  } else if (filterEntity.value === "approvals") {
    filterURL = "," + filterEntity.value + "!inner(entity:loan_agreement_id)";
  } else if (filterEntity.value === "debtor") {
    filterURL = "," + filterEntity.value + "!inner(entity:cid)";
  } else if (filterEntity.value === "loan_agreement") {
    filterURL = "," + filterEntity.value + "!inner(entity:ar_sumbashs)";
  } else if (filterEntity.value === "loan_accounts") {
    filterEntity.value = "loan_account";
    filterURL = "," + filterEntity.value + "s!inner(entity:ar_trexontos_logariasmou)";
  } else if (filterEntity.value === "real_estate") {
    filterURL = "," + filterEntity.value + "!inner(entity:arithmos_akinitou_diax)";
  } else if (filterEntity.value === "re_collaterals") {
    filterURL = "," + filterEntity.value + "!inner(entity:ar_kalummatos)";
  } else if (filterEntity.value === "guarantees") {
    filterURL = "," + filterEntity.value + "!inner(entity:debtor_cid)";
  } else if (filterEntity.value === "auctions") {
    filterURL = "," + filterEntity.value + "!inner(entity:debtor_id)";
  } else if (filterEntity.value === "rest_of_collaterals") {
    filterURL = "," + filterEntity.value + "!inner(entity:ar_kalimatos)";
  } else if (filterEntity.value === "greek_state_guarantees") {
    filterURL = "," + filterEntity.value + "!inner(entity:loan_agreement_id)";
  } else if (filterEntity.value === "rob_bonds") {
    filterURL = "," + filterEntity.value + "!inner(entity:rob_title_id)";
  } else if
    (filterEntity.value === "rob_participating_banks") {
    filterURL = "," + filterEntity.value + "!inner(entity:register_of_bond_id)";
  } else if
    (filterEntity.value === "rob_titles") {
    filterURL = "," + filterEntity.value + "!inner(entity:rob_participating_bank_id)";
  } else {
    filterURL = "";
  }

  let innerFilterUrl = "";

  if (filterEditor.value !== "") {
    let filtereditorresult: PGRESTResult = await pgrestapi.getPaginated(
      `users?select=id&username=eq.${filterEditor.value}`,
      page
    );
    if (filtereditorresult.data.length > 0) {
      innerFilterUrl += `&editor_user_id=eq.${filtereditorresult.data[0].id}`;
    } else {
      innerFilterUrl += `&editor_user_id=eq.0`;
    }
  }
  if (filterStatus.value !== "") {
    innerFilterUrl += `&approval_status=eq.${filterStatus.value}`;
  }
  if (filterFieldname.value !== "") {
    innerFilterUrl += `&field=ilike.*${filterFieldname.value}*`;
  }
  if (filterApprover.value !== "") {
    let filterapproverresult: PGRESTResult = await pgrestapi.getPaginated(
      `users?select=id&username=eq.${filterApprover.value}`,
      page
    );
    if (filterapproverresult.data.length > 0) {
      innerFilterUrl += `&approver_user_id=eq.${filterapproverresult.data[0].id}`;
    } else {
      innerFilterUrl += `&approver_user_id=eq.0`;
    }
  }
  if (filterEntity.value !== "") {
    let result: PGRESTResult = await pgrestapi.getPaginated(
      `entity_${filterEntity.value}_changes?select=*,editor:fk_user_editor(username),approver:fk_user_approver(username)${filterURL}${innerFilterUrl}&order=insert_timestamp.desc`,
      page
    );

    console.log(
      `entity_${filterEntity.value}_changes?select=*,editor:fk_user_editor(username),approver:fk_user_approver(username)${filterURL}${innerFilterUrl}&order=insert_timestamp.desc`
    );

    if (result.error) {
      ElMessage({
        showClose: true,
        message: result.error.message,
        type: "error",
      });

      return;
    }
    if (result.headers)
      dataCount.value = parseInt(result.headers["content-range"].split("/")[1]);

    // console.log('result data:');
    // console.log(JSON.stringify(result.data));
    for (let i = 0; i < result.data.length; i++) {
      if (result.data[i].approval_status == 1) {
        result.data[i].approval_status = "Accepted";
      } else if (result.data[i].approval_status == 2) {
        result.data[i].approval_status = "Rejected";
      } else {
        result.data[i].approval_status = "-";
      }
      if (filterEntity.value === "summary_check_xtra") {
        let result2: PGRESTResult = await pgrestapi.getPaginated(
          `loan_agreement?select=ar_sumbashs&id=eq.${result.data[i].summary_check_xtra.entity}`,
          page
        );
        result.data[i].entity = result2.data[0].ar_sumbashs;
      }
      if (filterEntity.value === "approvals") {
        let result2: PGRESTResult = await pgrestapi.getPaginated(
          `loan_agreement?select=ar_sumbashs&id=eq.${result.data[i].approvals.entity}`,
          page
        );
        result.data[i].entity = result2.data[0].ar_sumbashs;
      }
      if (filterEntity.value === "greek_state_guarantees") {
        let result2: PGRESTResult = await pgrestapi.getPaginated(
          `loan_agreement?select=ar_sumbashs&id=eq.${result.data[i].greek_state_guarantees.entity}`,
          page
        );
        result.data[i].entity = result2.data[0].ar_sumbashs;
      }
      if (filterEntity.value === "auctions") {
        let result2: PGRESTResult = await pgrestapi.getPaginated(
          `debtor?select=cid&id=eq.${result.data[i].auctions.entity}`,
          page
        );
        result.data[i].entity = result2.data[0].cid;
      }
      if (filterEntity.value === "loan_account") {
        result.data[i].entity = result.data[i].loan_accounts.entity;
      }
      if (filterEntity.value === "rob_bonds") {
        let result1b: PGRESTResult = await pgrestapi.getPaginated(
          `rob_titles?select=rob_participating_bank_id&id=eq.${result.data[i].rob_bonds.entity}`,
          page
        );
        let result1c: PGRESTResult = await pgrestapi.getPaginated(
          `rob_participating_banks?select=register_of_bond_id&id=eq.${result1b.data[0].rob_participating_bank_id}`,
          page
        );
        let result1d: PGRESTResult = await pgrestapi.getPaginated(
          `register_of_bonds?select=loan_agreement_id&id=eq.${result1c.data[0].register_of_bond_id}`,
          page
        );
        // console.log(`rob_titles?select=register_of_bond_id&id=eq.${result1b.data[0].rob_participating_bank_id}`)
        // console.log(result1d.data);
        let result2: PGRESTResult = await pgrestapi.getPaginated(
          `loan_agreement?select=ar_sumbashs&id=eq.${result1d.data[0].loan_agreement_id}`,
          page
        );
        result.data[i].entity = result2.data[0].ar_sumbashs;
      }
      if (filterEntity.value === "rob_titles") {
        let result1c: PGRESTResult = await pgrestapi.getPaginated(
          `rob_participating_banks?select=register_of_bond_id&id=eq.${result.data[0].rob_participating_bank_id.entity}`,
          page
        );
        let result1d: PGRESTResult = await pgrestapi.getPaginated(
          `register_of_bonds?select=loan_agreement_id&id=eq.${result1c.data[0].register_of_bond_id}`,
          page
        );
        // console.log(`rob_titles?select=register_of_bond_id&id=eq.${result1b.data[0].rob_participating_bank_id}`)
        console.log(result1d.data);
        let result2: PGRESTResult = await pgrestapi.getPaginated(
          `loan_agreement?select=ar_sumbashs&id=eq.${result1d.data[0].loan_agreement_id}`,
          page
        );
        result.data[i].entity = result2.data[0].ar_sumbashs;
      }

      //console.log(result.data[i].entity);
    }

    dataList.value = result.data;

    if (result.headers)
      dataCount.value = parseInt(result.headers["content-range"].split("/")[1]);

    for (let i = 0; i < result.data.length; i++) {
      if (result.data[i].approval_status == 1) { result.data[i].approval_status = "Accepted"; }
      else if (result.data[i].approval_status == 2) { result.data[i].approval_status = "Rejected"; }
      else { result.data[i].approval_status = "-"; }

      if (filterEntity.value === 'summary_check_xtra') {
        let result2: PGRESTResult = await pgrestapi.getPaginated(
          `loan_agreement?select=ar_sumbashs&id=eq.${result.data[i].summary_check_xtra.entity}`,
          page
        );
        result.data[i].entity = result2.data[0].ar_sumbashs;
      }
      if (filterEntity.value === 'approvals') {
        let result2: PGRESTResult = await pgrestapi.getPaginated(
          `loan_agreement?select=ar_sumbashs&id=eq.${result.data[i].approvals.entity}`,
          page
        );
        result.data[i].entity = result2.data[0].ar_sumbashs;
      }
      if (filterEntity.value === 'greek_state_guarantees') {
        let result2: PGRESTResult = await pgrestapi.getPaginated(
          `loan_agreement?select=ar_sumbashs&id=eq.${result.data[i].greek_state_guarantees.entity}`,
          page
        );
        result.data[i].entity = result2.data[0].ar_sumbashs;
      }
      if (filterEntity.value === 'auctions') {

        let result2: PGRESTResult = await pgrestapi.getPaginated(
          `debtor?select=cid&id=eq.${result.data[i].auctions.entity}`,
          page
        );
        result.data[i].entity = result2.data[0].cid;
      }
      if (filterEntity.value === 'loan_account') {
        result.data[i].entity = result.data[i].loan_accounts.entity;
      }
      if (filterEntity.value === 'rob_bonds') {
        let result1b: PGRESTResult = await pgrestapi.getPaginated(
          `rob_titles?select=rob_participating_bank_id&id=eq.${result.data[i].rob_bonds.entity}`,
          page
        );
        let result1c: PGRESTResult = await pgrestapi.getPaginated(
          `rob_participating_banks?select=register_of_bond_id&id=eq.${result1b.data[0].rob_participating_bank_id}`,
          page
        );
        let result1d: PGRESTResult = await pgrestapi.getPaginated(
          `register_of_bonds?select=loan_agreement_id&id=eq.${result1c.data[0].register_of_bond_id}`,
          page
        );
        let result2: PGRESTResult = await pgrestapi.getPaginated(
          `loan_agreement?select=ar_sumbashs&id=eq.${result1d.data[0].loan_agreement_id}`,
          page
        );
        result.data[i].entity = result2.data[0].ar_sumbashs;
      }
      if (filterEntity.value === 'rob_titles') {
        let result1c: PGRESTResult = await pgrestapi.getPaginated(
          `rob_participating_banks?select=register_of_bond_id&id=eq.${result.data[i].rob_titles.entity}`,
          page
        );
        let result1d: PGRESTResult = await pgrestapi.getPaginated(
          `register_of_bonds?select=loan_agreement_id&id=eq.${result1c.data[0].register_of_bond_id}`,
          page
        );
        let result2: PGRESTResult = await pgrestapi.getPaginated(
          `loan_agreement?select=ar_sumbashs&id=eq.${result1d.data[0].loan_agreement_id}`,
          page
        );
        result.data[i].entity = result2.data[0].ar_sumbashs;
      }
      if (filterEntity.value === 'rob_participating_banks') {
        let result1d: PGRESTResult = await pgrestapi.getPaginated(
          `register_of_bonds?select=loan_agreement_id&id=eq.${result.data[0].rob_participating_banks.entity}`,
          page
        );
        let result2: PGRESTResult = await pgrestapi.getPaginated(
          `loan_agreement?select=ar_sumbashs&id=eq.${result1d.data[0].loan_agreement_id}`,
          page
        );
        result.data[i].entity = result2.data[0].ar_sumbashs;
      }
    }

    dataList.value = result.data;
  }
  isLoading.value = false;
};

const filterEntityChanged = async (value: string) => {
  if (filterEntity.value === "") return;
  for (let item of entitiesList) {
    if (item.value === value) {
      selectedEntity.value = item.label;
      break;
    }
  }
  loadPage(1);
};
const filterChanged = async (value: string) => {
  if (timer.value !== 0) {
    clearTimeout(timer.value);
  }

  timer.value = setTimeout(() => {
    loadPage(1);

    timer.value = 0;
  }, 500);
};
</script>

<template>
  <div class="content-wrapper">
    <el-row :gutter="10">
      <el-col :span="12">
        <h3 class="heading-page">
          Entity Changes {{ selectedEntity !== "" ? " - " + selectedEntity : "" }}
        </h3>
      </el-col>
      <el-col :span="1" :offset="11" class="user-add-new-btn"> </el-col>
    </el-row>

    <div>
      <div style="margin: 30px">
        <el-row :gutter="20">
          <el-col :span="6" style="padding-left: 0px">
            <el-input v-model="filterEditor" class="client-input" :placeholder="$t('EntityChangesView.editor')"
              @input="filterChanged" />
          </el-col>
          <el-col :span="6">
            <el-input v-model="filterFieldname" class="client-input" :placeholder="$t('EntityChangesView.fieldname')"
              @input="filterChanged" />
          </el-col>
          <el-col :span="6">
            <el-input v-model="filterApprover" class="client-input" :placeholder="$t('EntityChangesView.approver')"
              @input="filterChanged" />
          </el-col>
          <el-col :span="6">
            <el-select clearable v-model="filterStatus" class="m-2 client-input"
              :placeholder="$t('EntityChangesView.approval_status')" value-key="id" @change="filterChanged">
              <el-option v-for="item in statusOptions" :key="item.value" :label="$t('loan_account.' + item.label)"
                :value="item.value" />
            </el-select>
          </el-col>
        </el-row>
      </div>
    </div>

    <div>
      <el-row :gutter="20" style="margin: 10px; margin-top: 15px">
        <el-col :span="6">
          <el-select clearable v-model="filterEntity" class="client-input"
            :placeholder="$t('EntityChangesView.select_entity_type')" @change="filterEntityChanged">
            <el-option v-for="client in entitiesList" :label="client.label" :value="client.value" />
          </el-select>
        </el-col>
      </el-row>
    </div>

    <el-table v-loading="isLoading" :data="dataList" stripe border table-layout="fixed" :cell-style="{
      padding: '0',
      height: '15px',
    }" size="small">
      <el-table-column fixed prop="editor.username" :label="$t('EntityChangesView.editor')" />
      <el-table-column v-if="
        (filterEntity !== 'summary_check_xtra')
        && (filterEntity !== 'auctions')
        && (filterEntity !== 'approvals')
        && (filterEntity !== 'loan_account')
        && (filterEntity !== 'greek_state_guarantees')
        && (filterEntity !== 'rob_bonds')
        && (filterEntity !== 'rob_titles')
        && (filterEntity !== 'rob_participating_banks')
      " fixed :prop="filterEntity + '.entity'" :label="$t('EntityChangesView.entity')" />
      <el-table-column v-if="(filterEntity === 'loan_account')" fixed :prop="filterEntity + 's.entity'"
        :label="$t('EntityChangesView.entity')" />
      <el-table-column v-if="
        (filterEntity === 'summary_check_xtra')
        || (filterEntity === 'auctions')
        || (filterEntity === 'approvals')
        || (filterEntity === 'greek_state_guarantees')
        || (filterEntity === 'rob_bonds')
        || (filterEntity === 'rob_titles')
        || (filterEntity === 'rob_participating_banks')
      " fixed prop="entity" :label="$t('EntityChangesView.entity')" />
      <el-table-column fixed prop="field" :label="$t('EntityChangesView.field')" />
      <el-table-column fixed prop="value" :label="$t('EntityChangesView.value')" />
      <el-table-column fixed prop="approval_status" :label="$t('EntityChangesView.approval_status')" />
      <el-table-column fixed prop="insert_timestamp" :label="$t('EntityChangesView.inserted_at')" />
      <el-table-column fixed prop="decision_timestamp" :label="$t('EntityChangesView.decision_at')" />
      <el-table-column fixed prop="approver.username" :label="$t('EntityChangesView.approver')" />
    </el-table>
    <div>
      <el-pagination small layout="prev, pager, next" :page-size="10" :total="dataCount" @current-change="loadPage" />
    </div>
  </div>
</template>

<style scoped>

</style>
