<script lang="ts" setup>
import { ref, watch, onMounted, reactive } from "vue";
import { usePGRESTAPIStore } from "../store/pgrest_api";
import { useMOSAPIStore } from "../store/mos_api";
import PGRESTResult from "../classes/PGRESTResult";
import ChangeApprovalStatus from "../enums/ChangeApprovalStatus";
import LoanAgreement from "../classes/DB_Entities/LoanAgreement";
import { useMainStore } from "../store/main";
import type { TabsPaneContext, FormRules, FormInstance } from "element-plus";
import Decision from "../classes/Decision";
import ChangesetHelper from "changeset-helper";
import EntityField from "./EntityField.vue";
import { DateTime } from "luxon";
import { useI18n } from "vue-i18n";
import { useGenericMethodsVariables } from "../composables/genericMethodsVariables";
import AcceptAllPending from "./AcceptAllPending.vue";
import UserType from "../enums/UserType";

import {
  Delete,
} from "@element-plus/icons-vue";
const { showError } = useGenericMethodsVariables();

const { locale } = useI18n({ useScope: "global" });
const props = defineProps({
  rowid: Number,
  view_only: Boolean
});
const interests = ref<any>([]);
let isDenounced = ref(false);
let entity_changes_id = ref<number>(-1);
let temp_pending_diatagi_pl_id = ref<number>(-1);
let temp_pending_hmeres_poso_id = ref<number>(-1);
let not_user_changes = ref<boolean>(false);
let not_user_changesPayment = ref<boolean>(false);
let not_user_changesHmerPoso = ref<boolean>(false);
let hasValueHmerTropop = ref<boolean>(false);
let hasEntityHmerTropop = ref<boolean>(false);
let hasValueDiatagi = ref<boolean>(false);
let hasEntityDiatagi = ref<boolean>(false);
let hasValueHmerPoso = ref<boolean>(false);
let hasEntityHmerPoso = ref<boolean>(false);

let hmer_tropop_CHANGED = false;
let hmer_tropop_CHANGED_POST = false;
let hmer_tropop_CHANGED_PATCH = false;

let diatagi_pl_hmek_hmep_ex_per_CHANGED = false;
let diatagi_pl_hmek_hmep_ex_per_CHANGED_PATCH = false;
let diatagi_pl_hmek_hmep_ex_per_CHANGED_POST = false;

let hmer_poso_ofeiles_CHANGED = false;
let hmer_poso_ofeiles_CHANGED_PATCH = false;
let hmer_poso_ofeiles_CHANGED_POST = false;

const { t } = useI18n({ useScope: "global" });
let isLoading = ref(false);
const mainStore = useMainStore();
const mosapi = useMOSAPIStore();
const ruleFormRef = ref<FormInstance>();
const emits = defineEmits(["save", "close", "fail-save"]);
let fullObj = ref<any>(new LoanAgreement());
const data = ref(new LoanAgreement());
const agents = ref<any>([]);
const products = ref<any>([]);
const currencies = ref<any>([]);
const pgrestapi = usePGRESTAPIStore();
const paymentTypeList = ref<any>([]);
let originalObj = ref<any>(new LoanAgreement());
let SuspensionRequestMargin = ref<string>("");
let hmer_tropo_sumb_temp: any = "";
let payment_order_temp: any = "";
let hmer_poso_ofeilhs_temp: any = "";
const activeName = ref("agreement_details");
const clientFullName = ref("");
const clientCID = ref("");
const clientTaxNbr = ref("");
// const statusList = ref<any>([]);
const contractStatusList = ref<any>([]);
let hasPendingChanges = ref<boolean>(false);

const Date_DD_MM_YYYY = (rule: any, value: string, callback: any) => {
  // console.log(rule);

  if (/d{2}[-]d{2}[-]d{4}/.test(value)) {
    // console.log("---ERROR---");
    return callback(new Error("This input must contain only letters"));
  } else {
    // console.log("all ok?");
    callback();
  }
};
const year_base_rates = [
  {
    value: 360,
    label: "360",
  },
  {
    value: "365",
    label: "365",
  },
  {
    value: "366",
    label: "366",
  },
];
const rules = reactive<FormRules>({
  hmer_tropop: [
    {
      validator: Date_DD_MM_YYYY,
      type: "string",
      trigger: "blur",
      required: true,
    },
  ],
});

const dynamicValidateForm = reactive<{
  hmeres_tropop: DateItem[];
}>({
  hmeres_tropop: [],
});

const dynamicValidateFormOriginal = reactive<{
  hmeres_tropop: any;
}>({
  hmeres_tropop: [],
});

interface DateItem {
  hmer_tropop: string;
}

const dynamicValidateFormPayment = reactive<{
  diatagi_pl_hmek_hmep_ex_per: DiatagiPliromis[];
}>({
  diatagi_pl_hmek_hmep_ex_per: [],
});

const dynamicValidateFormPaymentOriginal = reactive<{
  diatagi_pl_hmek_hmep_ex_per: DiatagiPliromis[];
}>({
  diatagi_pl_hmek_hmep_ex_per: [],
});

interface DiatagiPliromis {
  hmek: string;
  poso_diatag_plhr: string;
  hmep: string;
  exoda: string;
  //Περιθωριο ανακοπης - αυτοματοποιημενο πεδιο
  deut_hmep: string;
  deut_exoda: string;
  //Περιθωριο ανακοπης 2 - αυτοματοποιημενο πεδιο
  aithsh_anastolhs: boolean;
  //Περιθωριο αιτησης αναστολης - αυτοματοποιημενο πεδιο
  pros_diatg_ektel_plhr: boolean;
  telesidikh_apof: string;
}

const dynamicValidateFormHmerPoso = reactive<{
  hmer_poso_ofeilhs: HmerPoso[];
}>({
  hmer_poso_ofeilhs: [],
});

const dynamicValidateFormHmerPosoOriginal = reactive<{
  hmer_poso_ofeilhs: HmerPoso[];
}>({
  hmer_poso_ofeilhs: [],
});

interface HmerPoso {
  hmeres: string;
  poso: string;
}

const addArrangementDate = () => {
  dynamicValidateForm.hmeres_tropop.push({
    hmer_tropop: "",
  });
};

const addPaymentOrder = () => {
  dynamicValidateFormPayment.diatagi_pl_hmek_hmep_ex_per.push({
    hmek: "",
    poso_diatag_plhr:"",
    hmep: "",
    exoda: "",
    deut_hmep:"",
    deut_exoda:"",
    aithsh_anastolhs: false,
    pros_diatg_ektel_plhr: false,
    telesidikh_apof:"",
  });
};

const addHmeresPoso = () => {
  dynamicValidateFormHmerPoso.hmer_poso_ofeilhs.push({
    hmeres: "",
    poso: "",
  });
};

onMounted(async () => {
  dynamicValidateFormPayment.diatagi_pl_hmek_hmep_ex_per = [];
  await loadLoanAgreement(props.rowid as number);
});

const loadInterests = async () => {
  let result: PGRESTResult = new PGRESTResult();
  interests.value = [];

  result = await pgrestapi.get("config_interest?order=interest");

  if (result.error) {
    showError(result.error.message);

    return;
  }

  for (let i = 0; i < result.data.length; i++) {
    interests.value.push({
      value: result.data[i].id,
      label: result.data[i].interest,
    });
  }
};
const loadSelects = async () => {
  paymentTypeList.value = [
    {
      value: "xreolitiko",
      label: t("payment_type_list.xreolitiko"),
    },
    {
      value: "tokoxreolitiko",
      label: t("payment_type_list.tokoxreolitiko"),
    },
    {
      value: "balloon",
      label: t("payment_type_list.balloon"),
    },
  ];
  contractStatusList.value = [
    {
      value: "performing",
      label: t("loan_agreement.performing"),
    },
    {
      value: "non_performing",
      label: t("loan_agreement.non_performing"),
    },
    {
      value: "denounced",
      label: t("loan_agreement.denounced"),
    },
  ];
};

const close = async () => {
  emits("close");
};

const save = async () => {
  let result: PGRESTResult = new PGRESTResult();

  console.log(dynamicValidateFormPayment.diatagi_pl_hmek_hmep_ex_per);

  if (dynamicValidateFormPayment.diatagi_pl_hmek_hmep_ex_per.length > 0) {
    for ( let i = 0; i < dynamicValidateFormPayment.diatagi_pl_hmek_hmep_ex_per.length; i++) {
      if (fullObj.value.diatagi_pl_hmek_hmep_ex_per.originalValue === null || fullObj.value.diatagi_pl_hmek_hmep_ex_per.originalValue === undefined)
        fullObj.value.diatagi_pl_hmek_hmep_ex_per.originalValue = [];

      fullObj.value.diatagi_pl_hmek_hmep_ex_per.originalValue.push(
        dynamicValidateFormPayment.diatagi_pl_hmek_hmep_ex_per[i]
      );
      
    }
    console.log('1');
  }
  console.log(dynamicValidateFormHmerPoso.hmer_poso_ofeilhs);
  if (dynamicValidateFormHmerPoso.hmer_poso_ofeilhs) {
    for (let i = 0; i < dynamicValidateFormHmerPoso.hmer_poso_ofeilhs.length; i++) {
      if (fullObj.value.hmer_poso_ofeilhs.originalValue === null)
        fullObj.value.hmer_poso_ofeilhs.originalValue = [];

      fullObj.value.hmer_poso_ofeilhs.originalValue.push(
        dynamicValidateFormHmerPoso.hmer_poso_ofeilhs[i]
      );
    }
  }
  

  console.log(dynamicValidateFormHmerPoso.hmer_poso_ofeilhs);

  isLoading.value = true;
  await saveLoanAgreementChanges();
  isLoading.value = false;
  emits("save");
};

const saveLoanAgreementChanges = async () => {
  let hasChanges = false;
  let result: PGRESTResult = new PGRESTResult();
  let timestamp: string = await (await pgrestapi.get("rpc/customnew")).data;
  let newFull: any = {};
  let updateToPendingApprovals = false;
  /****************ΠΕΔΙΟ 1 hmeres_tropop*****************/
  if (hasValueHmerTropop.value) {
    /*1: εχει τιμη στη βαση*/
    // console.log("1: εχει τιμη στη βαση");
    if (hasEntityHmerTropop.value) {
      /*2: εχει entity*/
      // console.log("2: εχει entity");
      if (hmer_tropo_sumb_temp.length !== dynamicValidateForm.hmeres_tropop.length) {
        /*3a: patch εχουν αλλο μεγεθος*/
        // console.log("---OK---3a: patch εχουν αλλο μεγεθος");
        hmer_tropop_CHANGED = true;
        hmer_tropop_CHANGED_PATCH = true;
        hmer_tropop_CHANGED_POST = false;
      } else {
        for (let i = 0; i < hmer_tropo_sumb_temp.length; i++) {
          if (
            dynamicValidateForm.hmeres_tropop[i].hmer_tropop !==
            hmer_tropo_sumb_temp[i].hmer_tropop
          ) {
            /*3b: εχει αλλαγη PATCH*/
            // console.log("---OK---3b: εχει αλλαγη PATCH " + i);
            hmer_tropop_CHANGED = true;
            hmer_tropop_CHANGED_PATCH = true;
            hmer_tropop_CHANGED_POST = false;
            break;
          }
        }
      }
    } else {
      /*5: δεν εχει entity*/
      // console.log("5: δεν εχει entity");
      /*εχουν τα πεδια διαφορα απο τη βαση; */
      if (
        dynamicValidateForm.hmeres_tropop.length !==
        dynamicValidateFormOriginal.hmeres_tropop.length
      ) {
        /*6a: post entity*/
        hmer_tropop_CHANGED = true;
        hmer_tropop_CHANGED_PATCH = false;
        hmer_tropop_CHANGED_POST = true;
        // console.log("6a: post entity");
      } else {
        for (let i = 0; i < dynamicValidateForm.hmeres_tropop.length; i++) {
          if (
            dynamicValidateForm.hmeres_tropop[i].hmer_tropop !==
            dynamicValidateFormOriginal.hmeres_tropop[i].row1.hmer_tropop
          ) {
            /*6b: εχει αλλαγη θελει post entity*/
            // console.log("---ok---6b: εχει αλλαγη θελει post entity " + i);
            hmer_tropop_CHANGED = true;
            hmer_tropop_CHANGED_PATCH = false;
            hmer_tropop_CHANGED_POST = true;
            break;
          }
        }
      }
    }
  } else {
    /*8: δεν εχει τιμη στη βαση*/
    // console.log("8: δεν εχει τιμη στη βαση");
    if (hasEntityHmerTropop.value) {
      /*9:εχει entity*/
      // console.log("9:εχει entity");
      if (hmer_tropo_sumb_temp.length !== dynamicValidateForm.hmeres_tropop.length) {
        /*10a: patch εχουν αλλο μεγεθος*/
        // console.log("---OK---10a: patch εχουν αλλο μεγεθος");
        hmer_tropop_CHANGED = true;
        hmer_tropop_CHANGED_PATCH = true;
        hmer_tropop_CHANGED_POST = false;
      } else {
        for (let i = 0; i < hmer_tropo_sumb_temp.length; i++) {
          if (
            dynamicValidateForm.hmeres_tropop[i].hmer_tropop !==
            hmer_tropo_sumb_temp[i].hmer_tropop
          ) {
            /*10b: εχει αλλαγη PATCH*/
            // console.log("---OK---10b: εχει αλλαγη PATCH " + i);
            hmer_tropop_CHANGED = true;
            hmer_tropop_CHANGED_PATCH = true;
            hmer_tropop_CHANGED_POST = false;
            break;
          }
        }
      }
    } else {
      /*12: δεν εχει entity*/
      // console.log("12: δεν εχει entity");
      if (dynamicValidateForm.hmeres_tropop.length > 0) {
        /*post έχει νέες εγγραφές*/
        // console.log("---OK---13: post έχει νέες εγγραφές");
        hmer_tropop_CHANGED = true;
        hmer_tropop_CHANGED_PATCH = false;
        hmer_tropop_CHANGED_POST = true;
      }
    }
  }
  /****************ΠΕΔΙΟ 1 hmeres_tropop*****************/
  /****************ΠΕΔΙΟ 2 diatagi_pl_hmek_hmep_ex_per *****************/
  if (hasValueDiatagi.value) {
    /*1: εχει τιμη στη βαση*/
    // console.log("1: εχει τιμη στη βαση");
    if (hasEntityDiatagi.value) {
      /*2: εχει entity*/
      // console.log("2: εχει entity");
      if (
        payment_order_temp.length !==
        dynamicValidateFormPayment.diatagi_pl_hmek_hmep_ex_per.length
      ) {
        /*3a: patch εχουν αλλο μεγεθος*/
        // console.log("3a: patch εχουν αλλο μεγεθος");
        diatagi_pl_hmek_hmep_ex_per_CHANGED = true;
        diatagi_pl_hmek_hmep_ex_per_CHANGED_PATCH = true;
        diatagi_pl_hmek_hmep_ex_per_CHANGED_POST = false;
      } else {
        for (let i = 0; i < payment_order_temp.length; i++) {
          if (
            dynamicValidateFormPayment.diatagi_pl_hmek_hmep_ex_per[i].hmek !==
            payment_order_temp[i].hmek
          ) {
            /*3b: εχει αλλαγη PATCH*/
            // console.log("3b: εχει αλλαγη PATCH " + i);
            diatagi_pl_hmek_hmep_ex_per_CHANGED = true;
            diatagi_pl_hmek_hmep_ex_per_CHANGED_PATCH = true;
            diatagi_pl_hmek_hmep_ex_per_CHANGED_POST = false;
            break;
          }
          if (
            dynamicValidateFormPayment.diatagi_pl_hmek_hmep_ex_per[i].hmep !==
            payment_order_temp[i].hmep
          ) {
            /*3b: εχει αλλαγη PATCH*/
            // console.log("3b: εχει αλλαγη PATCH " + i);
            diatagi_pl_hmek_hmep_ex_per_CHANGED = true;
            diatagi_pl_hmek_hmep_ex_per_CHANGED_PATCH = true;
            diatagi_pl_hmek_hmep_ex_per_CHANGED_POST = false;
            break;
          }
          if (
            dynamicValidateFormPayment.diatagi_pl_hmek_hmep_ex_per[i].exoda !==
            payment_order_temp[i].exoda
          ) {
            /*3b: εχει αλλαγη PATCH*/
            // console.log("3b: εχει αλλαγη PATCH " + i);
            diatagi_pl_hmek_hmep_ex_per_CHANGED = true;
            diatagi_pl_hmek_hmep_ex_per_CHANGED_PATCH = true;
            diatagi_pl_hmek_hmep_ex_per_CHANGED_POST = false;
            break;
          }
          if (
            dynamicValidateFormPayment.diatagi_pl_hmek_hmep_ex_per[i].poso_diatag_plhr !==
            payment_order_temp[i].poso_diatag_plhr
          ) {
            /*3b: εχει αλλαγη PATCH*/
            // console.log("3b: εχει αλλαγη PATCH " + i);
            diatagi_pl_hmek_hmep_ex_per_CHANGED = true;
            diatagi_pl_hmek_hmep_ex_per_CHANGED_PATCH = true;
            diatagi_pl_hmek_hmep_ex_per_CHANGED_POST = false;
            break;
          }
          if (
            dynamicValidateFormPayment.diatagi_pl_hmek_hmep_ex_per[i].deut_hmep !==
            payment_order_temp[i].deut_hmep
          ) {
            /*3b: εχει αλλαγη PATCH*/
            // console.log("3b: εχει αλλαγη PATCH " + i);
            diatagi_pl_hmek_hmep_ex_per_CHANGED = true;
            diatagi_pl_hmek_hmep_ex_per_CHANGED_PATCH = true;
            diatagi_pl_hmek_hmep_ex_per_CHANGED_POST = false;
            break;
          }
          if (
            dynamicValidateFormPayment.diatagi_pl_hmek_hmep_ex_per[i].deut_exoda !==
            payment_order_temp[i].deut_exoda
          ) {
            /*3b: εχει αλλαγη PATCH*/
            // console.log("3b: εχει αλλαγη PATCH " + i);
            diatagi_pl_hmek_hmep_ex_per_CHANGED = true;
            diatagi_pl_hmek_hmep_ex_per_CHANGED_PATCH = true;
            diatagi_pl_hmek_hmep_ex_per_CHANGED_POST = false;
            break;
          }
          if (
            dynamicValidateFormPayment.diatagi_pl_hmek_hmep_ex_per[i].aithsh_anastolhs !==
            payment_order_temp[i].aithsh_anastolhs
          ) {
            /*3b: εχει αλλαγη PATCH*/
            // console.log("3b: εχει αλλαγη PATCH " + i);
            diatagi_pl_hmek_hmep_ex_per_CHANGED = true;
            diatagi_pl_hmek_hmep_ex_per_CHANGED_PATCH = true;
            diatagi_pl_hmek_hmep_ex_per_CHANGED_POST = false;
            break;
          }
          if (
            dynamicValidateFormPayment.diatagi_pl_hmek_hmep_ex_per[i].pros_diatg_ektel_plhr !==
            payment_order_temp[i].pros_diatg_ektel_plhr
          ) {
            /*3b: εχει αλλαγη PATCH*/
            // console.log("3b: εχει αλλαγη PATCH " + i);
            diatagi_pl_hmek_hmep_ex_per_CHANGED = true;
            diatagi_pl_hmek_hmep_ex_per_CHANGED_PATCH = true;
            diatagi_pl_hmek_hmep_ex_per_CHANGED_POST = false;
            break;
          }
          if (
            dynamicValidateFormPayment.diatagi_pl_hmek_hmep_ex_per[i].telesidikh_apof !==
            payment_order_temp[i].telesidikh_apof
          ) {
            /*3b: εχει αλλαγη PATCH*/
            // console.log("3b: εχει αλλαγη PATCH " + i);
            diatagi_pl_hmek_hmep_ex_per_CHANGED = true;
            diatagi_pl_hmek_hmep_ex_per_CHANGED_PATCH = true;
            diatagi_pl_hmek_hmep_ex_per_CHANGED_POST = false;
            break;
          }
        }
      }
    } else {
      /*5: δεν εχει entity*/
      // console.log("-5: δεν εχει entity");
      // console.log(
      //   "length " + dynamicValidateFormPayment.diatagi_pl_hmek_hmep_ex_per.length
      // );
      // console.log(
      //   "length original: " +
      //   dynamicValidateFormPaymentOriginal.diatagi_pl_hmek_hmep_ex_per.length
      // );
      /*εχουν τα πεδια διαφορα απο τη βαση; */
      if (
        dynamicValidateFormPayment.diatagi_pl_hmek_hmep_ex_per.length !==
        dynamicValidateFormPaymentOriginal.diatagi_pl_hmek_hmep_ex_per.length
      ) {
        /*6a: post entity*/
        diatagi_pl_hmek_hmep_ex_per_CHANGED = true;
        diatagi_pl_hmek_hmep_ex_per_CHANGED_PATCH = false;
        diatagi_pl_hmek_hmep_ex_per_CHANGED_POST = true;
        // console.log("6a: post entity");
      } else {
        for (
          let i = 0;
          i < dynamicValidateFormPayment.diatagi_pl_hmek_hmep_ex_per.length;
          i++
        ) {
          if (
            dynamicValidateFormPayment.diatagi_pl_hmek_hmep_ex_per[i].hmek !=
            (dynamicValidateFormPaymentOriginal.diatagi_pl_hmek_hmep_ex_per[i] as any)
              .row1.hmek
          ) {
            // console.log("iteration i:" + i);
            // console.log(dynamicValidateFormPayment.diatagi_pl_hmek_hmep_ex_per[i].hmek);
            // console.log(
            //   (dynamicValidateFormPaymentOriginal.diatagi_pl_hmek_hmep_ex_per[i] as any)
            //     .row1.hmek
            // );
            /*6b: εχει αλλαγη θελει post entity*/
            // console.log("-6b: εχει αλλαγη θελει post entity " + i);
            diatagi_pl_hmek_hmep_ex_per_CHANGED = true;
            diatagi_pl_hmek_hmep_ex_per_CHANGED_PATCH = false;
            diatagi_pl_hmek_hmep_ex_per_CHANGED_POST = true;
            break;
          }
          if (
            dynamicValidateFormPayment.diatagi_pl_hmek_hmep_ex_per[i].hmep !=
            (dynamicValidateFormPaymentOriginal.diatagi_pl_hmek_hmep_ex_per[i] as any)
              .row1.hmep
          ) {
            /*6b: εχει αλλαγη θελει post entity*/
            // console.log("--6b: εχει αλλαγη θελει post entity " + i);
            diatagi_pl_hmek_hmep_ex_per_CHANGED = true;
            diatagi_pl_hmek_hmep_ex_per_CHANGED_PATCH = false;
            diatagi_pl_hmek_hmep_ex_per_CHANGED_POST = true;
            break;
          }
          if (
            dynamicValidateFormPayment.diatagi_pl_hmek_hmep_ex_per[i].exoda !=
            (dynamicValidateFormPaymentOriginal.diatagi_pl_hmek_hmep_ex_per[i] as any)
              .row1.exoda
          ) {
            /*6b: εχει αλλαγη θελει post entity*/
            // console.log("---6b: εχει αλλαγη θελει post entity " + i);
            diatagi_pl_hmek_hmep_ex_per_CHANGED = true;
            diatagi_pl_hmek_hmep_ex_per_CHANGED_PATCH = false;
            diatagi_pl_hmek_hmep_ex_per_CHANGED_POST = true;
            break;
          }
          if (
            dynamicValidateFormPayment.diatagi_pl_hmek_hmep_ex_per[i].poso_diatag_plhr !=
            (dynamicValidateFormPaymentOriginal.diatagi_pl_hmek_hmep_ex_per[i] as any)
              .row1.poso_diatag_plhr
          ) {
            /*6b: εχει αλλαγη θελει post entity*/
            // console.log("---6b: εχει αλλαγη θελει post entity " + i);
            diatagi_pl_hmek_hmep_ex_per_CHANGED = true;
            diatagi_pl_hmek_hmep_ex_per_CHANGED_PATCH = false;
            diatagi_pl_hmek_hmep_ex_per_CHANGED_POST = true;
            break;
          }
          if (
            dynamicValidateFormPayment.diatagi_pl_hmek_hmep_ex_per[i].deut_hmep !=
            (dynamicValidateFormPaymentOriginal.diatagi_pl_hmek_hmep_ex_per[i] as any)
              .row1.deut_hmep
          ) {
            /*6b: εχει αλλαγη θελει post entity*/
            // console.log("---6b: εχει αλλαγη θελει post entity " + i);
            diatagi_pl_hmek_hmep_ex_per_CHANGED = true;
            diatagi_pl_hmek_hmep_ex_per_CHANGED_PATCH = false;
            diatagi_pl_hmek_hmep_ex_per_CHANGED_POST = true;
            break;
          }
          if (
            dynamicValidateFormPayment.diatagi_pl_hmek_hmep_ex_per[i].deut_exoda !=
            (dynamicValidateFormPaymentOriginal.diatagi_pl_hmek_hmep_ex_per[i] as any)
              .row1.deut_exoda
          ) {
            /*6b: εχει αλλαγη θελει post entity*/
            // console.log("---6b: εχει αλλαγη θελει post entity " + i);
            diatagi_pl_hmek_hmep_ex_per_CHANGED = true;
            diatagi_pl_hmek_hmep_ex_per_CHANGED_PATCH = false;
            diatagi_pl_hmek_hmep_ex_per_CHANGED_POST = true;
            break;
          }
          if (
            dynamicValidateFormPayment.diatagi_pl_hmek_hmep_ex_per[i].aithsh_anastolhs !=
            (dynamicValidateFormPaymentOriginal.diatagi_pl_hmek_hmep_ex_per[i] as any)
              .row1.aithsh_anastolhs
          ) {
            /*6b: εχει αλλαγη θελει post entity*/
            // console.log("---6b: εχει αλλαγη θελει post entity " + i);
            diatagi_pl_hmek_hmep_ex_per_CHANGED = true;
            diatagi_pl_hmek_hmep_ex_per_CHANGED_PATCH = false;
            diatagi_pl_hmek_hmep_ex_per_CHANGED_POST = true;
            break;
          }
          if (
            dynamicValidateFormPayment.diatagi_pl_hmek_hmep_ex_per[i].pros_diatg_ektel_plhr !=
            (dynamicValidateFormPaymentOriginal.diatagi_pl_hmek_hmep_ex_per[i] as any)
              .row1.pros_diatg_ektel_plhr
          ) {
            /*6b: εχει αλλαγη θελει post entity*/
            // console.log("---6b: εχει αλλαγη θελει post entity " + i);
            diatagi_pl_hmek_hmep_ex_per_CHANGED = true;
            diatagi_pl_hmek_hmep_ex_per_CHANGED_PATCH = false;
            diatagi_pl_hmek_hmep_ex_per_CHANGED_POST = true;
            break;
          }
          if (
            dynamicValidateFormPayment.diatagi_pl_hmek_hmep_ex_per[i].telesidikh_apof !=
            (dynamicValidateFormPaymentOriginal.diatagi_pl_hmek_hmep_ex_per[i] as any)
              .row1.telesidikh_apof
          ) {
            /*6b: εχει αλλαγη θελει post entity*/
            // console.log("---6b: εχει αλλαγη θελει post entity " + i);
            diatagi_pl_hmek_hmep_ex_per_CHANGED = true;
            diatagi_pl_hmek_hmep_ex_per_CHANGED_PATCH = false;
            diatagi_pl_hmek_hmep_ex_per_CHANGED_POST = true;
            break;
          }
        }
      }
    }
  } else {
    /*8: δεν εχει τιμη στη βαση*/
    // console.log("8: δεν εχει τιμη στη βαση");
    if (hasEntityDiatagi.value) {
      /*9:εχει entity*/
      // console.log("9:εχει entity");
      if (
        payment_order_temp.length !==
        dynamicValidateFormPayment.diatagi_pl_hmek_hmep_ex_per.length
      ) {
        /*10a: patch εχουν αλλο μεγεθος*/
        // console.log("10a: patch εχουν αλλο μεγεθος");
        diatagi_pl_hmek_hmep_ex_per_CHANGED = true;
        diatagi_pl_hmek_hmep_ex_per_CHANGED_PATCH = true;
        diatagi_pl_hmek_hmep_ex_per_CHANGED_POST = false;
      } else {
        for (let i = 0; i < payment_order_temp.length; i++) {
          if (
            dynamicValidateFormPayment.diatagi_pl_hmek_hmep_ex_per[i].hmek !==
            payment_order_temp[i].hmek
          ) {
            /*10b: εχει αλλαγη PATCH*/
            // console.log("10b: εχει αλλαγη PATCH " + i);
            diatagi_pl_hmek_hmep_ex_per_CHANGED = true;
            diatagi_pl_hmek_hmep_ex_per_CHANGED_PATCH = true;
            diatagi_pl_hmek_hmep_ex_per_CHANGED_POST = false;
            break;
          }
          if (
            dynamicValidateFormPayment.diatagi_pl_hmek_hmep_ex_per[i].hmep !==
            payment_order_temp[i].hmep
          ) {
            /*10b: εχει αλλαγη PATCH*/
            // console.log("10b: εχει αλλαγη PATCH " + i);
            diatagi_pl_hmek_hmep_ex_per_CHANGED = true;
            diatagi_pl_hmek_hmep_ex_per_CHANGED_PATCH = true;
            diatagi_pl_hmek_hmep_ex_per_CHANGED_POST = false;
            break;
          }
          if (
            dynamicValidateFormPayment.diatagi_pl_hmek_hmep_ex_per[i].exoda !==
            payment_order_temp[i].exoda
          ) {
            /*10b: εχει αλλαγη PATCH*/
            // console.log("10b: εχει αλλαγη PATCH " + i);
            diatagi_pl_hmek_hmep_ex_per_CHANGED = true;
            diatagi_pl_hmek_hmep_ex_per_CHANGED_PATCH = true;
            diatagi_pl_hmek_hmep_ex_per_CHANGED_POST = false;
            break;
          }
          if (
            dynamicValidateFormPayment.diatagi_pl_hmek_hmep_ex_per[i].poso_diatag_plhr !==
            payment_order_temp[i].poso_diatag_plhr
          ) {
            /*10b: εχει αλλαγη PATCH*/
            // console.log("10b: εχει αλλαγη PATCH " + i);
            diatagi_pl_hmek_hmep_ex_per_CHANGED = true;
            diatagi_pl_hmek_hmep_ex_per_CHANGED_PATCH = true;
            diatagi_pl_hmek_hmep_ex_per_CHANGED_POST = false;
            break;
          }
          if (
            dynamicValidateFormPayment.diatagi_pl_hmek_hmep_ex_per[i].deut_hmep !==
            payment_order_temp[i].deut_hmep
          ) {
            /*10b: εχει αλλαγη PATCH*/
            // console.log("10b: εχει αλλαγη PATCH " + i);
            diatagi_pl_hmek_hmep_ex_per_CHANGED = true;
            diatagi_pl_hmek_hmep_ex_per_CHANGED_PATCH = true;
            diatagi_pl_hmek_hmep_ex_per_CHANGED_POST = false;
            break;
          }
          if (
            dynamicValidateFormPayment.diatagi_pl_hmek_hmep_ex_per[i].deut_exoda !==
            payment_order_temp[i].deut_exoda
          ) {
            /*10b: εχει αλλαγη PATCH*/
            // console.log("10b: εχει αλλαγη PATCH " + i);
            diatagi_pl_hmek_hmep_ex_per_CHANGED = true;
            diatagi_pl_hmek_hmep_ex_per_CHANGED_PATCH = true;
            diatagi_pl_hmek_hmep_ex_per_CHANGED_POST = false;
            break;
          }
          if (
            dynamicValidateFormPayment.diatagi_pl_hmek_hmep_ex_per[i].aithsh_anastolhs !==
            payment_order_temp[i].aithsh_anastolhs
          ) {
            /*10b: εχει αλλαγη PATCH*/
            // console.log("10b: εχει αλλαγη PATCH " + i);
            diatagi_pl_hmek_hmep_ex_per_CHANGED = true;
            diatagi_pl_hmek_hmep_ex_per_CHANGED_PATCH = true;
            diatagi_pl_hmek_hmep_ex_per_CHANGED_POST = false;
            break;
          }
          if (
            dynamicValidateFormPayment.diatagi_pl_hmek_hmep_ex_per[i].pros_diatg_ektel_plhr !==
            payment_order_temp[i].pros_diatg_ektel_plhr
          ) {
            /*10b: εχει αλλαγη PATCH*/
            // console.log("10b: εχει αλλαγη PATCH " + i);
            diatagi_pl_hmek_hmep_ex_per_CHANGED = true;
            diatagi_pl_hmek_hmep_ex_per_CHANGED_PATCH = true;
            diatagi_pl_hmek_hmep_ex_per_CHANGED_POST = false;
            break;
          }
          if (
            dynamicValidateFormPayment.diatagi_pl_hmek_hmep_ex_per[i].telesidikh_apof !==
            payment_order_temp[i].telesidikh_apof
          ) {
            /*10b: εχει αλλαγη PATCH*/
            // console.log("10b: εχει αλλαγη PATCH " + i);
            diatagi_pl_hmek_hmep_ex_per_CHANGED = true;
            diatagi_pl_hmek_hmep_ex_per_CHANGED_PATCH = true;
            diatagi_pl_hmek_hmep_ex_per_CHANGED_POST = false;
            break;
          }
        }
      }
    } else {
      /*12: δεν εχει entity*/
      // console.log("12: δεν εχει entity");
      if (dynamicValidateFormPayment.diatagi_pl_hmek_hmep_ex_per.length > 0) {
        /*post έχει νέες εγγραφές*/
        // console.log("13: post έχει νέες εγγραφές");
        diatagi_pl_hmek_hmep_ex_per_CHANGED = true;
        diatagi_pl_hmek_hmep_ex_per_CHANGED_PATCH = false;
        diatagi_pl_hmek_hmep_ex_per_CHANGED_POST = true;
      }
    }
  }
  /****************ΠΕΔΙΟ 2 diatagi_pl_hmek_hmep_ex_per *****************/
  /****************ΠΕΔΙΟ 3 hmer_poso_ofeilhs *****************/
  if (hasValueHmerPoso.value) {
    /*1: εχει τιμη στη βαση*/
    // console.log("#1: εχει τιμη στη βαση");
    if (hasEntityHmerPoso.value) {
      /*2: εχει entity*/
      // console.log("#2: εχει entity");
      if (
        hmer_poso_ofeilhs_temp.length !==
        dynamicValidateFormHmerPoso.hmer_poso_ofeilhs.length
      ) {
        /*3a: patch εχουν αλλο μεγεθος*/
        // console.log("#3a: patch εχουν αλλο μεγεθος");
        hmer_poso_ofeiles_CHANGED = true;
        hmer_poso_ofeiles_CHANGED_PATCH = true;
        hmer_poso_ofeiles_CHANGED_POST = false;
      } else {
        for (let i = 0; i < hmer_poso_ofeilhs_temp.length; i++) {
          if (
            dynamicValidateFormHmerPoso.hmer_poso_ofeilhs[i].hmeres !==
            hmer_poso_ofeilhs_temp[i].hmeres
          ) {
            /*3b: εχει αλλαγη PATCH*/
            // console.log("#3b: εχει αλλαγη PATCH " + i);
            hmer_poso_ofeiles_CHANGED = true;
            hmer_poso_ofeiles_CHANGED_PATCH = true;
            hmer_poso_ofeiles_CHANGED_POST = false;
            break;
          }
          if (
            dynamicValidateFormHmerPoso.hmer_poso_ofeilhs[i].poso !==
            hmer_poso_ofeilhs_temp[i].poso
          ) {
            /*3b: εχει αλλαγη PATCH*/
            // console.log("#3b: εχει αλλαγη PATCH " + i);
            hmer_poso_ofeiles_CHANGED = true;
            hmer_poso_ofeiles_CHANGED_PATCH = true;
            hmer_poso_ofeiles_CHANGED_POST = false;
            break;
          }
        }
      }
    } else {
      /*5: δεν εχει entity*/
      // console.log("#5: δεν εχει entity");
      // console.log("length " + dynamicValidateFormHmerPoso.hmer_poso_ofeilhs.length);
      // console.log(
      //   "length original: " + dynamicValidateFormHmerPosoOriginal.hmer_poso_ofeilhs.length
      // );
      /*εχουν τα πεδια διαφορα απο τη βαση; */
      if (
        dynamicValidateFormHmerPoso.hmer_poso_ofeilhs.length !==
        dynamicValidateFormHmerPosoOriginal.hmer_poso_ofeilhs.length
      ) {
        /*6a: post entity*/
        hmer_poso_ofeiles_CHANGED = true;
        hmer_poso_ofeiles_CHANGED_PATCH = false;
        hmer_poso_ofeiles_CHANGED_POST = true;
        // console.log("#6a: post entity");
      } else {
        for (let i = 0; i < dynamicValidateFormHmerPoso.hmer_poso_ofeilhs.length; i++) {
          if (
            dynamicValidateFormHmerPoso.hmer_poso_ofeilhs[i].hmeres !=
            (dynamicValidateFormHmerPosoOriginal.hmer_poso_ofeilhs[i] as any).row1.hmeres
          ) {
            // console.log("iteration i:" + i);
            // console.log(dynamicValidateFormHmerPoso.hmer_poso_ofeilhs[i].hmeres);
            // console.log(
            //   (dynamicValidateFormHmerPosoOriginal.hmer_poso_ofeilhs[i] as any).row1
            //     .hmeres
            // );
            /*6b: εχει αλλαγη θελει post entity*/
            // console.log("#6b: εχει αλλαγη θελει post entity " + i);
            hmer_poso_ofeiles_CHANGED = true;
            hmer_poso_ofeiles_CHANGED_PATCH = false;
            hmer_poso_ofeiles_CHANGED_POST = true;
            break;
          }
          if (
            dynamicValidateFormHmerPoso.hmer_poso_ofeilhs[i].poso !=
            (dynamicValidateFormHmerPosoOriginal.hmer_poso_ofeilhs[i] as any).row1.poso
          ) {
            /*6b: εχει αλλαγη θελει post entity*/
            // console.log("#6b: εχει αλλαγη θελει post entity " + i);
            hmer_poso_ofeiles_CHANGED = true;
            hmer_poso_ofeiles_CHANGED_PATCH = false;
            hmer_poso_ofeiles_CHANGED_POST = true;
            break;
          }
        }
      }
    }
  } else {
    /*8: δεν εχει τιμη στη βαση*/
    // console.log("#8: δεν εχει τιμη στη βαση");
    if (hasEntityDiatagi.value) {
      /*9:εχει entity*/
      // console.log("#9:εχει entity");
      if (
        hmer_poso_ofeilhs_temp.length !==
        dynamicValidateFormHmerPoso.hmer_poso_ofeilhs.length
      ) {
        /*10a: patch εχουν αλλο μεγεθος*/
        // console.log("#10a: patch εχουν αλλο μεγεθος");
        hmer_poso_ofeiles_CHANGED = true;
        hmer_poso_ofeiles_CHANGED_PATCH = true;
        hmer_poso_ofeiles_CHANGED_POST = false;
      } else {
        for (let i = 0; i < hmer_poso_ofeilhs_temp.length; i++) {
          if (
            dynamicValidateFormHmerPoso.hmer_poso_ofeilhs[i].hmeres !==
            hmer_poso_ofeilhs_temp[i].hmeres
          ) {
            /*10b: εχει αλλαγη PATCH*/
            // console.log("#10b: εχει αλλαγη PATCH " + i);
            hmer_poso_ofeiles_CHANGED = true;
            hmer_poso_ofeiles_CHANGED_PATCH = true;
            hmer_poso_ofeiles_CHANGED_POST = false;
            break;
          }
          if (
            dynamicValidateFormHmerPoso.hmer_poso_ofeilhs[i].poso !==
            hmer_poso_ofeilhs_temp[i].poso
          ) {
            /*10b: εχει αλλαγη PATCH*/
            // console.log("#10b: εχει αλλαγη PATCH " + i);
            hmer_poso_ofeiles_CHANGED = true;
            hmer_poso_ofeiles_CHANGED_PATCH = true;
            hmer_poso_ofeiles_CHANGED_POST = false;
            break;
          }
        }
      }
    } else {
      /*12: δεν εχει entity*/
      // console.log("#12: δεν εχει entity");
      if (dynamicValidateFormHmerPoso.hmer_poso_ofeilhs.length > 0) {
        /*post έχει νέες εγγραφές*/
        // console.log("#13: post έχει νέες εγγραφές");
        hmer_poso_ofeiles_CHANGED = true;
        hmer_poso_ofeiles_CHANGED_PATCH = false;
        hmer_poso_ofeiles_CHANGED_POST = true;
      }
    }
  }
  /****************ΠΕΔΙΟ 3 hmer_poso_ofeilhs *****************/
  /*-----------------------------Δημιουργια New FULL*/
  for (let field of Object.keys(fullObj.value)) {
    if ((originalObj as any).value[field] === null) {
      (originalObj as any).value[field] = "";
    }
    if (
      (fullObj as any).value[field].originalValue === null ||
      (fullObj as any).value[field].originalValue === undefined
    ) {
      (fullObj as any).value[field].originalValue = "";
    }
    if (field === "hmer_tropop_sumbashs") {
      if (
        hmer_tropop_CHANGED &&
        (hmer_tropop_CHANGED_PATCH || hmer_tropop_CHANGED_POST)
      ) {
        newFull[field] = JSON.stringify(dynamicValidateForm.hmeres_tropop);
        // console.log("*************************");
        // console.log(JSON.stringify(dynamicValidateForm.hmeres_tropop));
      }
    } else if (field === "diatagi_pl_hmek_hmep_ex_per") {
      if (
        diatagi_pl_hmek_hmep_ex_per_CHANGED &&
        (diatagi_pl_hmek_hmep_ex_per_CHANGED_PATCH ||
          diatagi_pl_hmek_hmep_ex_per_CHANGED_POST)
      ) {
        newFull[field] = JSON.stringify(
          dynamicValidateFormPayment.diatagi_pl_hmek_hmep_ex_per
        );
      }
    } else if (field === "hmer_poso_ofeilhs") {
      if (
        hmer_poso_ofeiles_CHANGED &&
        (hmer_poso_ofeiles_CHANGED_PATCH || hmer_poso_ofeiles_CHANGED_POST)
      ) {
        newFull[field] = JSON.stringify(dynamicValidateFormHmerPoso.hmer_poso_ofeilhs);
      }
    } else {
      newFull[field] = (fullObj as any).value[field].originalValue;
    }
  }
  /*Δημιουργια New FULL-----------------------------*/
  // console.log("newfull");
  // console.log(newFull);

  if (hmer_tropop_CHANGED && hmer_tropop_CHANGED_PATCH) {
    //to do αν το newFull['hmer_tropop_sumbashs'] ειναι κενο να σβησω το entity

    if (mainStore.loggedUser.user_type === UserType.Agent) {
      result = await pgrestapi.patch(
        "entity_loan_agreement_changes?id=eq." + entity_changes_id.value,
        {
          entity_id: originalObj.value.id,
          editor_user_id: mainStore.loggedUser.id,
          field: "hmer_tropop_sumbashs",
          value: newFull["hmer_tropop_sumbashs"],
          insert_timestamp: timestamp,
        }
      );

      updateToPendingApprovals = true;
    } else {
      result = await pgrestapi.post_data("entity_loan_agreement_changes", {
        entity_id: originalObj.value.id,
        editor_user_id: mainStore.loggedUser.id,
        field: "hmer_tropop_sumbashs",
        value: newFull["hmer_tropop_sumbashs"],
      });

      await onDecision({ accepted: true, db_change_id: result.data[0].id, field: "hmer_tropop_sumbashs", value: newFull["hmer_tropop_sumbashs"] } as Decision, false);
    }
  } else if (hmer_tropop_CHANGED && hmer_tropop_CHANGED_POST) {
    result = await pgrestapi.post_data("entity_loan_agreement_changes", {
      entity_id: originalObj.value.id,
      editor_user_id: mainStore.loggedUser.id,
      field: "hmer_tropop_sumbashs",
      value: newFull["hmer_tropop_sumbashs"],
    });

    if (mainStore.loggedUser.user_type !== UserType.Agent) {
      await onDecision({ accepted: true, db_change_id: result.data[0].id, field: "hmer_tropop_sumbashs", value: newFull["hmer_tropop_sumbashs"] } as Decision, false);
    } else {
      updateToPendingApprovals = true;
    }
  }

  if (diatagi_pl_hmek_hmep_ex_per_CHANGED && diatagi_pl_hmek_hmep_ex_per_CHANGED_PATCH) {
    //to do αν το newFull['hmer_tropop_sumbashs'] ειναι κενο να σβησω το entity
    if (mainStore.loggedUser.user_type === UserType.Agent) {
      result = await pgrestapi.patch(
        "entity_loan_agreement_changes?id=eq." + temp_pending_diatagi_pl_id.value,
        {
          entity_id: originalObj.value.id,
          editor_user_id: mainStore.loggedUser.id,
          field: "diatagi_pl_hmek_hmep_ex_per",
          value: newFull["diatagi_pl_hmek_hmep_ex_per"],
          insert_timestamp: timestamp,
        }
      );
      updateToPendingApprovals = true;
    } else {
      result = await pgrestapi.post_data("entity_loan_agreement_changes", {
        entity_id: originalObj.value.id,
        editor_user_id: mainStore.loggedUser.id,
        field: "diatagi_pl_hmek_hmep_ex_per",
        value: newFull["diatagi_pl_hmek_hmep_ex_per"],
      });
      await onDecision({ accepted: true, db_change_id: result.data[0].id, field: "diatagi_pl_hmek_hmep_ex_per", value: newFull["diatagi_pl_hmek_hmep_ex_per"] } as Decision, false);
    }
  } else if (
    diatagi_pl_hmek_hmep_ex_per_CHANGED &&
    diatagi_pl_hmek_hmep_ex_per_CHANGED_POST
  ) {
    result = await pgrestapi.post_data("entity_loan_agreement_changes", {
      entity_id: originalObj.value.id,
      editor_user_id: mainStore.loggedUser.id,
      field: "diatagi_pl_hmek_hmep_ex_per",
      value: newFull["diatagi_pl_hmek_hmep_ex_per"],
    });

    if (mainStore.loggedUser.user_type !== UserType.Agent) {
      await onDecision({ accepted: true, db_change_id: result.data[0].id, field: "diatagi_pl_hmek_hmep_ex_per", value: newFull["diatagi_pl_hmek_hmep_ex_per"] } as Decision, false);
    } else {
      updateToPendingApprovals = true;
    }
  }

  if (hmer_poso_ofeiles_CHANGED && hmer_poso_ofeiles_CHANGED_PATCH) {
    //to do αν το newFull['hmer_tropop_sumbashs'] ειναι κενο να σβησω το entity
    if (mainStore.loggedUser.user_type === UserType.Agent) {
      result = await pgrestapi.patch(
        "entity_loan_agreement_changes?id=eq." + temp_pending_hmeres_poso_id.value,
        {
          entity_id: originalObj.value.id,
          editor_user_id: mainStore.loggedUser.id,
          field: "hmer_poso_ofeilhs",
          value: newFull["hmer_poso_ofeilhs"],
          insert_timestamp: timestamp,
        }
      );
      updateToPendingApprovals = true;
    } else {
      result = await pgrestapi.post_data("entity_loan_agreement_changes", {
        entity_id: originalObj.value.id,
        editor_user_id: mainStore.loggedUser.id,
        field: "hmer_poso_ofeilhs",
        value: newFull["hmer_poso_ofeilhs"],
      });
      await onDecision({ accepted: true, db_change_id: result.data[0].id, field: "hmer_poso_ofeilhs", value: newFull["hmer_poso_ofeilhs"] } as Decision, false);
    }
  } else if (hmer_poso_ofeiles_CHANGED && hmer_poso_ofeiles_CHANGED_POST) {
    result = await pgrestapi.post_data("entity_loan_agreement_changes", {
      entity_id: originalObj.value.id,
      editor_user_id: mainStore.loggedUser.id,
      field: "hmer_poso_ofeilhs",
      value: newFull["hmer_poso_ofeilhs"],
    });

    if (mainStore.loggedUser.user_type !== UserType.Agent) {
      await onDecision({ accepted: true, db_change_id: result.data[0].id, field: "hmer_poso_ofeilhs", value: newFull["hmer_poso_ofeilhs"] } as Decision, false);
    } else {
      updateToPendingApprovals = true;
    }
  }

  // console.log('compare1:', JSON.stringify(originalObj.value))
  // console.log('compare2:', JSON.stringify(newFull))
  const report = ChangesetHelper.compare(originalObj.value, newFull);

  /*****************  ΕΠΕΙΔΗ Η COMPARE ΟΝΟΜΑΖΕΙ ΤΑ ΠΕΔΙΑ ΑΛΛΙΩΣ,
   *******           ΠΡΟΣΠΕΡΑΣΕ ΤΑ ΚΑΙ ΧΕΙΡΙΣΟΥ ΤΑ ΜΟΝΟΣ          ****************/
  for (let changedField of report.additions) {
    if (changedField.includes("hmer_tropop_sumbashs")) {
      changedField = "hmer_tropop_sumbashs";
      if (hmer_tropop_CHANGED) continue;
      hmer_tropop_CHANGED = true;
    }
    if (changedField.includes("diatagi_pl_hmek_hmep_ex_per")) {
      changedField = "diatagi_pl_hmek_hmep_ex_per";
      if (diatagi_pl_hmek_hmep_ex_per_CHANGED) continue;
      diatagi_pl_hmek_hmep_ex_per_CHANGED = true;
    }
    if (changedField.includes("hmer_poso_ofeilhs")) {
      changedField = "hmer_poso_ofeilhs";
      if (hmer_poso_ofeiles_CHANGED) continue;
      hmer_poso_ofeiles_CHANGED = true;
    }
    if (changedField === "status" && newFull[changedField] !== "completed") {
      result = await pgrestapi.patch("loan_agreement?id=eq." + originalObj.value.id, {
        status: newFull[changedField],
      });
      continue;
    }

    hasChanges = true;

    // if (mainStore.loggedUser.user_type === UserType.Agent) {
    if (
      fullObj.value[changedField].pendingValue === null ||
      fullObj.value[changedField].pendingValue === undefined
    ) {
      result = await pgrestapi.post_data("entity_loan_agreement_changes", {
        entity_id: originalObj.value.id,
        editor_user_id: mainStore.loggedUser.id,
        field: changedField,
        value: newFull[changedField],
      });

      if (mainStore.loggedUser.user_type !== UserType.Agent) {
        await onDecision({ accepted: true, db_change_id: result.data[0].id, field: changedField, value: newFull[changedField] } as Decision, false);
      } else {
        updateToPendingApprovals = true;
      }
    } else {
      if (mainStore.loggedUser.user_type === UserType.Agent) {
        result = await pgrestapi.patch(
          "entity_loan_agreement_changes?id=eq." +
          fullObj.value[changedField].pendingValue.id,
          {
            entity_id: originalObj.value.id,
            editor_user_id: mainStore.loggedUser.id,
            field: changedField,
            value: newFull[changedField],
            insert_timestamp: timestamp,
          }
        );
        updateToPendingApprovals = true;
      } else {
        result = await pgrestapi.post_data("entity_loan_agreement_changes", {
          entity_id: originalObj.value.id,
          editor_user_id: mainStore.loggedUser.id,
          field: changedField,
          value: newFull[changedField],
        });

        await onDecision({ accepted: true, db_change_id: result.data[0].id, field: changedField, value: newFull[changedField] } as Decision, false);
      }
    }
  }

  for (let changedField of report.changes) {
    // Skip entity change entry if status field and switching to completed, update directly
    if (changedField.includes("diatagi_pl_hmek_hmep_ex_per")) {
      changedField = "diatagi_pl_hmek_hmep_ex_per";
      if (diatagi_pl_hmek_hmep_ex_per_CHANGED) continue;
      diatagi_pl_hmek_hmep_ex_per_CHANGED = true;
    }

    if (changedField.includes("hmer_tropop_sumbashs")) {
      changedField = "hmer_tropop_sumbashs";
      if (hmer_tropop_CHANGED) continue;
      hmer_tropop_CHANGED = true;
    }

    if (changedField.includes("hmer_poso_ofeilhs")) {
      changedField = "hmer_poso_ofeilhs";
      if (hmer_poso_ofeiles_CHANGED) continue;
      hmer_poso_ofeiles_CHANGED = true;
      console.log("αλλαξε το ημερ ποσο changes");
    }

    if (changedField === "status" && newFull[changedField] !== "completed") {
      result = await pgrestapi.patch("loan_agreement?id=eq." + originalObj.value.id, {
        status: newFull[changedField],
      });
      continue;
    }

    hasChanges = true;

    if (
      fullObj.value[changedField].pendingValue === null ||
      fullObj.value[changedField].pendingValue === undefined
    ) {
      // console.log('adding entity change for', changedField, newFull[changedField]);

      result = await pgrestapi.post_data("entity_loan_agreement_changes", {
        entity_id: originalObj.value.id,
        editor_user_id: mainStore.loggedUser.id,
        field: changedField,
        value: newFull[changedField],
      });

      if (mainStore.loggedUser.user_type !== UserType.Agent) {
        await onDecision({ accepted: true, db_change_id: result.data[0].id, field: changedField, value: newFull[changedField] } as Decision, false);
      } else {
        updateToPendingApprovals = true;
      }
    } else {
      // console.log('updating entity change for', changedField, newFull[changedField]);

      if (mainStore.loggedUser.user_type === UserType.Agent) {
        result = await pgrestapi.patch(
          "entity_loan_agreement_changes?id=eq." +
          fullObj.value[changedField].pendingValue.id,
          {
            entity_id: originalObj.value.id,
            editor_user_id: mainStore.loggedUser.id,
            field: changedField,
            value: newFull[changedField],
            insert_timestamp: timestamp,
          }
        );
        updateToPendingApprovals = true;
      } else {
        result = await pgrestapi.post_data("entity_loan_agreement_changes", {
          entity_id: originalObj.value.id,
          editor_user_id: mainStore.loggedUser.id,
          field: changedField,
          value: newFull[changedField],
        });

        await onDecision({ accepted: true, db_change_id: result.data[0].id, field: changedField, value: newFull[changedField] } as Decision, false);
      }
    }
  }

  if (
    hasChanges &&
    (originalObj.value.status === "unchecked" || originalObj.value.status === "completed")
  ) {
    result = await pgrestapi.patch("loan_agreement?id=eq." + originalObj.value.id, {
      status: "in_progress",
    });
  }

  //
  // Set to approve status PendingReview if changes were made by an agent
  //
  if (updateToPendingApprovals && originalObj.value.approve_status !== ChangeApprovalStatus.PendingApprove && mainStore.loggedUser.user_type === UserType.Agent) {
    await pgrestapi.patch(`loan_agreement?id=eq.${originalObj.value.id}`, { approve_status: ChangeApprovalStatus.PendingReview });
  }

  resetData();
};

const resetData = async () => {
  dynamicValidateForm.hmeres_tropop = [];
  dynamicValidateFormPayment.diatagi_pl_hmek_hmep_ex_per = [];
  dynamicValidateFormPaymentOriginal.diatagi_pl_hmek_hmep_ex_per = [];
  dynamicValidateFormHmerPoso.hmer_poso_ofeilhs = [];
  dynamicValidateFormHmerPosoOriginal.hmer_poso_ofeilhs = [];
  hmer_tropop_CHANGED = false;
  hmer_tropop_CHANGED_POST = false;
  hmer_tropop_CHANGED_PATCH = false;
  diatagi_pl_hmek_hmep_ex_per_CHANGED = false;
  diatagi_pl_hmek_hmep_ex_per_CHANGED_PATCH = false;
  diatagi_pl_hmek_hmep_ex_per_CHANGED_POST = false;
  hmer_poso_ofeiles_CHANGED = false;
  hmer_poso_ofeiles_CHANGED_PATCH = false;
  hmer_poso_ofeiles_CHANGED_POST = false;
  clientFullName.value = "";
  clientCID.value = "";
  clientTaxNbr.value = "";
};

const loadLoanAgreement = async (rowid: number) => {
  resetData();
  isDenounced.value = false;

  hasEntityHmerTropop.value = false;
  hasValueHmerTropop.value = false;
  hasValueDiatagi.value = false;
  hasEntityDiatagi.value = false;
  hasValueHmerPoso.value = false;
  hasEntityHmerPoso.value = false;

  await loadProducts();
  await loadSelects();
  await loadInterests();
  if (rowid === -1) return;

  isLoading.value = true;

  let result: PGRESTResult = new PGRESTResult();
  let resultClient: PGRESTResult = new PGRESTResult();

  result = await pgrestapi.get("loan_agreement?id=eq." + rowid);

  if (result.error) {
    showError(result.error.message);

    isLoading.value = false;
    return;
  }
  // console.log("εδω");
  // console.log(result.data[0].debtor_id);
  if (result.data.length > 0) {
    resultClient = await pgrestapi.get("debtor?id=eq." + result.data[0].debtor_id);
    clientFullName.value = resultClient.data[0].fullname;
    clientCID.value = resultClient.data[0].cid;
    clientTaxNbr.value = resultClient.data[0].vat_number;
  }

  //clientFullName
  if (result.data[0]?.hmer_tropop_sumbashs?.length === 0) {
    hasValueHmerTropop.value = false;
  } else {
    if (JSON.stringify(result.data[0].hmer_tropop_sumbashs) === "[{}]") {
      hasValueHmerTropop.value = false;
    }
    // console.log(JSON.stringify(result.data[0].hmer_tropop_sumbashs));
  }
  if (
    JSON.stringify(result.data[0].hmer_tropop_sumbashs) === "" ||
    JSON.stringify(result.data[0].hmer_tropop_sumbashs) === undefined ||
    JSON.stringify(result.data[0].hmer_tropop_sumbashs) === null ||
    JSON.stringify(result.data[0].hmer_tropop_sumbashs) === "[]" ||
    JSON.stringify(result.data[0].hmer_tropop_sumbashs) === "[{}]" ||
    JSON.stringify(result.data[0].hmer_tropop_sumbashs) === "{}"
  ) {
    hasValueHmerTropop.value = false;
  } else {
    hasValueHmerTropop.value = true;
  }
  if (result.data[0]?.diatagi_pl_hmek_hmep_ex_per?.length === 0) {
    hasValueDiatagi.value = false;
  } else {
    if (JSON.stringify(result.data[0].diatagi_pl_hmek_hmep_ex_per) === "[{}]") {
      hasValueDiatagi.value = false;
    }
    // console.log(JSON.stringify(result.data[0].diatagi_pl_hmek_hmep_ex_per));
  }
  if (
    JSON.stringify(result.data[0].diatagi_pl_hmek_hmep_ex_per) === "" ||
    JSON.stringify(result.data[0].diatagi_pl_hmek_hmep_ex_per) === undefined ||
    JSON.stringify(result.data[0].diatagi_pl_hmek_hmep_ex_per) === null ||
    JSON.stringify(result.data[0].diatagi_pl_hmek_hmep_ex_per) === "[]" ||
    JSON.stringify(result.data[0].diatagi_pl_hmek_hmep_ex_per) === "[{}]" ||
    JSON.stringify(result.data[0].diatagi_pl_hmek_hmep_ex_per) === "{}"
  ) {
    hasValueDiatagi.value = false;
  } else {
    hasValueDiatagi.value = true;
  }
  if (result.data[0].hmer_poso_ofeilhs?.length === 0) {
    hasValueHmerPoso.value = false;
  } else {
    if (JSON.stringify(result.data[0].hmer_poso_ofeilhs) === "[{}]") {
      hasValueHmerPoso.value = false;
    }
    // console.log(JSON.stringify(result.data[0].hmer_poso_ofeilhs));
  }
  if (
    JSON.stringify(result.data[0].hmer_poso_ofeilhs) === "" ||
    JSON.stringify(result.data[0].hmer_poso_ofeilhs) === undefined ||
    JSON.stringify(result.data[0].hmer_poso_ofeilhs) === null ||
    JSON.stringify(result.data[0].hmer_poso_ofeilhs) === "[]" ||
    JSON.stringify(result.data[0].hmer_poso_ofeilhs) === "[{}]" ||
    JSON.stringify(result.data[0].hmer_poso_ofeilhs) === "{}"
  ) {
    hasValueHmerPoso.value = false;
  } else {
    hasValueHmerPoso.value = true;
  }
  originalObj.value = JSON.parse(JSON.stringify(result.data[0]));
  fullObj.value = JSON.parse(JSON.stringify(result.data[0]));

  data.value = result.data[0];
  await loadPendingChanges();

  //αρχικοποιησε τα dynamic με αυτα της βασης αν υπαρχουν
  if (!hasEntityHmerTropop.value) {
    loadHmerTropop();
  }
  if (!hasEntityHmerPoso.value) {
    loadHmerPoso();
  }
  if (!hasEntityDiatagi.value) {
    loadDiatagi();
  }

  await loadAgents();
  await loadProducts();
  await loadCurrencies();
  isLoading.value = false;
};

const loadHmerTropop = async () => {
  if (originalObj.value.hmer_tropop_sumbashs === null) {
    return;
  }

  for (let i = 0; i < originalObj.value.hmer_tropop_sumbashs.length; i++) {
    dynamicValidateForm.hmeres_tropop.push({
      hmer_tropop: originalObj.value.hmer_tropop_sumbashs[i].hmer_tropop,
    });
  }
  for (let row1 of originalObj.value.hmer_tropop_sumbashs) {
    dynamicValidateFormOriginal.hmeres_tropop.push({
      row1,
    } as any);
  }
};

const loadHmerPoso = async () => {
  if (originalObj.value.hmer_poso_ofeilhs === null) {
    return;
  }
  for (let i = 0; i < originalObj.value.hmer_poso_ofeilhs.length; i++) {
    // console.log("originalObj.value.hmer_poso_ofeilhs");
    // console.log(originalObj.value.hmer_poso_ofeilhs);
    dynamicValidateFormHmerPoso.hmer_poso_ofeilhs.push({
      hmeres: originalObj.value.hmer_poso_ofeilhs[i].hmeres,
      poso: originalObj.value.hmer_poso_ofeilhs[i].poso,
    });
  }

  if (originalObj.value?.hmer_poso_ofeilhs !== undefined) {
    for (let row1 of originalObj.value.hmer_poso_ofeilhs) {
      dynamicValidateFormHmerPosoOriginal.hmer_poso_ofeilhs.push({
        row1,
      } as any);
    }
  }
};

const loadDiatagi = async () => {
  if (originalObj.value.diatagi_pl_hmek_hmep_ex_per === null) {
    return;
  }

  console.log(originalObj.value.diatagi_pl_hmek_hmep_ex_per);

  for (let i = 0; i < originalObj.value.diatagi_pl_hmek_hmep_ex_per.length; i++) {
    // console.log("originalObj.value.diatagi_pl_hmek_hmep_ex_per");
    // console.log(originalObj.value.diatagi_pl_hmek_hmep_ex_per);
    dynamicValidateFormPayment.diatagi_pl_hmek_hmep_ex_per.push({
      hmek: originalObj.value.diatagi_pl_hmek_hmep_ex_per[i].hmek,
      poso_diatag_plhr: originalObj.value.diatagi_pl_hmek_hmep_ex_per[i].poso_diatag_plhr,
      hmep: originalObj.value.diatagi_pl_hmek_hmep_ex_per[i].hmep,
      exoda: originalObj.value.diatagi_pl_hmek_hmep_ex_per[i].exoda,
      deut_hmep: originalObj.value.diatagi_pl_hmek_hmep_ex_per[i].deut_hmep,
      deut_exoda: originalObj.value.diatagi_pl_hmek_hmep_ex_per[i].deut_exoda,
      aithsh_anastolhs: originalObj.value.diatagi_pl_hmek_hmep_ex_per[i].aithsh_anastolhs,
      pros_diatg_ektel_plhr: originalObj.value.diatagi_pl_hmek_hmep_ex_per[i].pros_diatg_ektel_plhr,
      telesidikh_apof: originalObj.value.diatagi_pl_hmek_hmep_ex_per[i].telesidikh_apof
    });
  }
  for (let row1 of originalObj.value.diatagi_pl_hmek_hmep_ex_per) {
    dynamicValidateFormPaymentOriginal.diatagi_pl_hmek_hmep_ex_per.push({
      row1,
    } as any);
  }
};

const loadPendingChanges = async () => {
  let result: PGRESTResult = new PGRESTResult();
  let product: PGRESTResult = new PGRESTResult();
  let currency: PGRESTResult = new PGRESTResult();
  let agent: PGRESTResult = new PGRESTResult();
  let interest: PGRESTResult = new PGRESTResult();
  entity_changes_id.value = -1;
  temp_pending_diatagi_pl_id.value = -1;
  temp_pending_hmeres_poso_id.value = -1;

  not_user_changes.value = false;
  not_user_changesPayment.value = false;
  not_user_changesHmerPoso.value = false;

  result = await pgrestapi.get(
    `entity_loan_agreement_changes?entity_id=eq.${props.rowid}&approval_status=eq.0&select=id,field,value,insert_timestamp,editor:users!fk_user_editor(username)`
  );

  // console.log(JSON.stringify(result.data))

  for (let field of Object.keys(fullObj.value)) {
    (fullObj as any).value[field] = {
      field,
      originalValue: (fullObj as any).value[field],
    };
    if (field === "diatagi_pl_hmek_hmep_ex_per") {
      if ((fullObj as any).value[field].originalValue?.length > 0) {
        SuspensionRequestMargin.value = DateTime.fromFormat(
          (fullObj as any).value[field].originalValue[0].hmep,
          "yyyy-MM-dd"
        )
          .plus({ days: 15 })
          .toLocaleString();
      }
    }
  }

  hasPendingChanges.value = false;
  for (let pending of result.data) {
    hasPendingChanges.value = true;
    // if (pending.field === "status") {
    //   pending["pendingDisplayValue"] = statusList.value.find((element: any) => {
    //     return element.value === pending.value;
    //   })?.label;
    //   pending["originalDisplayValue"] = statusList.value.find((element: any) => {
    //     return element.value === (originalObj as any).value[pending.field];
    //   })?.label;
    // }

    // console.log(pending.field);

    //Products
    if (pending.field === "product") {
      if (pending.value) {
        product = await pgrestapi.get(
          `config_product?select=product&id=eq.` + pending.value
        );
        pending["pendingDisplayValue"] = product.data[0].product;
      }
      if ((originalObj as any).value[pending.field]) {
        product = await pgrestapi.get(
          `config_product?select=product&id=eq.` +
          (originalObj as any).value[pending.field]
        );
        pending["originalDisplayValue"] = product.data[0].product;
      }
    }

    if (pending.field === "tropos_apopl") {
      pending["pendingDisplayValue"] = paymentTypeList.value.find((element: any) => {
        return element.value === pending.value;
      })?.label;
      pending["originalDisplayValue"] = paymentTypeList.value.find((element: any) => {
        return element.value === (originalObj as any).value[pending.field];
      })?.label;
    }
    //Currency
    if (pending.field === "currency") {
      if (pending.value) {
        currency = await pgrestapi.get(
          `config_currency?select=currency&id=eq.` + pending.value
        );
        pending["pendingDisplayValue"] = currency.data[0].currency;
      }
      if ((originalObj as any).value[pending.field]) {
        currency = await pgrestapi.get(
          `config_currency?select=currency&id=eq.` +
          (originalObj as any).value[pending.field]
        );
        pending["originalDisplayValue"] = currency.data[0].currency;
      }
    }

    //Interests
    if (pending.field === "kathgor_epitok_bashs") {
      if (pending.value) {
        interest = await pgrestapi.get(
          `config_interest?select=interest&id=eq.` + pending.value
        );
        pending["pendingDisplayValue"] = interest.data[0].interest;
      }
      if ((originalObj as any).value[pending.field]) {
        interest = await pgrestapi.get(
          `config_interest?select=interest&id=eq.` +
          (originalObj as any).value[pending.field]
        );
        pending["originalDisplayValue"] = interest.data[0].interest;
      }
    }

    //Agents
    if (pending.field === "agent") {
      if (pending.value) {
        agent = await pgrestapi.get(`config_agent?select=agent&id=eq.` + pending.value);
        pending["pendingDisplayValue"] = agent.data[0].agent;
      }
      if ((originalObj as any).value[pending.field]) {
        agent = await pgrestapi.get(
          `config_agent?select=agent&id=eq.` + (originalObj as any).value[pending.field]
        );
        pending["originalDisplayValue"] = agent.data[0].agent;
      }
    }

    if (pending.field === "katast_sumbashs") {
      pending["pendingDisplayValue"] = contractStatusList.value.find((element: any) => {
        return element.value === pending.value;
      })?.label;
      pending["originalDisplayValue"] = contractStatusList.value.find((element: any) => {
        return element.value === (originalObj as any).value[pending.field];
      })?.label;
    }

    pending["originalValue"] = (originalObj as any).value[pending.field];
    (fullObj as any).value[pending.field].pendingValue = pending;
    /***************************************** */
    if (pending.field === "hmer_tropop_sumbashs") {
      hasEntityHmerTropop.value = true; /*εχει entity στη βαση*/

      entity_changes_id.value = (fullObj as any).value[
        pending.field
      ].pendingValue.id; /*κραταμε το id του entity*/

      hmer_tropo_sumb_temp = JSON.parse(
        (fullObj as any).value[pending.field].pendingValue.value
      ); /*ειναι οι τιμες του entity στη βαση*/

      let editor_changes = (fullObj as any).value[pending.field].pendingValue.editor;
      if (mainStore.loggedUser.username !== editor_changes.username) {
        not_user_changes.value = true; /*ειναι δικες του οι αλλαγες; */
      }

      for (let i = 0; i < hmer_tropo_sumb_temp.length; i++) {
        dynamicValidateForm.hmeres_tropop.push({
          hmer_tropop: hmer_tropo_sumb_temp[i].hmer_tropop,
        });
      }

      if (originalObj.value.hmer_tropop_sumbashs !== null) {
        for (let row1 of originalObj.value.hmer_tropop_sumbashs) {
          dynamicValidateFormOriginal.hmeres_tropop.push({
            row1,
          } as any);
        }
      }
    }
    /***************************************** */
    if (pending.field === "hmer_poso_ofeilhs") {
      // console.log(pending.field);
      hasEntityHmerPoso.value = true; /*εχει entity στη βαση*/

      temp_pending_hmeres_poso_id.value = (fullObj as any).value[
        pending.field
      ].pendingValue.id;

      hmer_poso_ofeilhs_temp = JSON.parse(
        (fullObj as any).value[pending.field].pendingValue.value
      );

      let editor_changesHmerPoso = (fullObj as any).value[pending.field].pendingValue
        .editor;
      if (mainStore.loggedUser.username !== editor_changesHmerPoso.username) {
        not_user_changesHmerPoso.value = true;
      }

      for (let i = 0; i < hmer_poso_ofeilhs_temp.length; i++) {
        dynamicValidateFormHmerPoso.hmer_poso_ofeilhs.push({
          hmeres: hmer_poso_ofeilhs_temp[i]?.hmeres,
          poso: hmer_poso_ofeilhs_temp[i]?.poso,
        });
        // console.log("^^^^^^^");
        // console.log(dynamicValidateFormHmerPoso.hmer_poso_ofeilhs);
      }

      if (originalObj.value.hmer_poso_ofeilhs !== null) {
        for (let row1 of originalObj.value.hmer_poso_ofeilhs) {
          dynamicValidateFormHmerPosoOriginal.hmer_poso_ofeilhs.push({
            row1,
          } as any);
        }
      }
    }

    /***************************************** */
    if (pending.field === "diatagi_pl_hmek_hmep_ex_per") {
      hasEntityDiatagi.value = true; /*εχει entity στη βαση*/
      temp_pending_diatagi_pl_id.value = (fullObj as any).value[
        pending.field
      ].pendingValue.id; /*κραταμε το id του entity*/

      payment_order_temp = JSON.parse(
        (fullObj as any).value[pending.field].pendingValue.value
      );

      let editor_changesPayment = (fullObj as any).value[pending.field].pendingValue
        .editor;
      if (mainStore.loggedUser.username !== editor_changesPayment.username) {
        not_user_changesPayment.value = true; /*ειναι δικες του οι αλλαγες; */
      }

      for (let i = 0; i < payment_order_temp.length; i++) {
        dynamicValidateFormPayment.diatagi_pl_hmek_hmep_ex_per.push({
          hmek: payment_order_temp[i].hmek,
          poso_diatag_plhr: payment_order_temp[i].poso_diatag_plhr,
          hmep: payment_order_temp[i].hmep,
          exoda: payment_order_temp[i].exoda,
          deut_hmep: payment_order_temp[i].deut_hmep,
          deut_exoda: payment_order_temp[i].deut_exoda,
          aithsh_anastolhs: payment_order_temp[i].aithsh_anastolhs,
          pros_diatg_ektel_plhr: payment_order_temp[i].pros_diatg_ektel_plhr,
          telesidikh_apof: payment_order_temp[i].telesidikh_apof,
        });
      }
      // console.log(dynamicValidateFormPayment.diatagi_pl_hmek_hmep_ex_per);
      if (originalObj.value.diatagi_pl_hmek_hmep_ex_per !== null) {
        for (let row1 of originalObj.value.diatagi_pl_hmek_hmep_ex_per) {
          dynamicValidateFormPaymentOriginal.diatagi_pl_hmek_hmep_ex_per.push({
            row1,
          } as any);
        }
      }
    }
  }
  // console.log(JSON.stringify(fullObj.value))
};

const loadAgents = async () => {
  let result: PGRESTResult = new PGRESTResult();
  agents.value = [];

  result = await pgrestapi.get("config_agent?order=agent");

  if (result.error) {
    showError(result.error.message);

    return;
  }

  for (let i = 0; i < result.data.length; i++) {
    agents.value.push({
      value: result.data[i].id,
      label: result.data[i].agent,
    });
  }
};

const loadProducts = async () => {
  let result: PGRESTResult = new PGRESTResult();
  products.value = [];

  result = await pgrestapi.get("config_product?order=product");

  if (result.error) {
    showError(result.error.message);

    return;
  }

  for (let i = 0; i < result.data.length; i++) {
    if (locale.value == "en") {
      products.value.push({
        value: result.data[i].id,
        label: result.data[i].english_trans,
      });
    } else if (locale.value == "el") {
      products.value.push({
        value: result.data[i].id,
        label: result.data[i].greek_trans,
      });
    }
  }
};

const loadCurrencies = async () => {
  let result: PGRESTResult = new PGRESTResult();
  currencies.value = [];

  result = await pgrestapi.get("config_currency?order=currency");

  if (result.error) {
    showError(result.error.message);

    return;
  }

  for (let i = 0; i < result.data.length; i++) {
    currencies.value.push({
      value: result.data[i].id,
      label: result.data[i].currency,
    });
  }
};

const onDecision = async (decision: Decision, reload_data: boolean = true) => {
  let result: PGRESTResult = new PGRESTResult();
  let timestamp: string = await (await pgrestapi.get("rpc/customnew")).data;

  if (decision.accepted) {
    result = await pgrestapi.patch(
      "entity_loan_agreement_changes?id=eq." + decision.db_change_id,
      {
        approval_status: ChangeApprovalStatus.Approved,
        approver_user_id: mainStore.loggedUser.id,
        decision_timestamp: timestamp,
      }
    );

    let obj: any = {};
    let field = decision.field as string;
    obj[field] = decision.value;

    if (field === "diatagi_pl_hmek_hmep_ex_per" || field === "hmer_tropop_sumbashs" || field === "hmer_poso_ofeilhs") {
      if (obj[field] === "[{}]") {
        obj[field] = "[]";
      }
      obj[field] = JSON.parse(obj[field]);
    }

    if (obj[field] === "")
      obj[field] = null;

    result = await pgrestapi.patch("loan_agreement?id=eq." + originalObj.value.id, obj);
  } else {
    result = await pgrestapi.patch(
      "entity_loan_agreement_changes?id=eq." + decision.db_change_id,
      {
        approval_status: ChangeApprovalStatus.Rejected,
        approver_user_id: mainStore.loggedUser.id,
        decision_timestamp: timestamp,
      }
    );
  }

  // set to approve_status = Approved if no more pending changes
  await mosapi.updateEntityApproveStatus("loan_agreement", data.value.id as number);

  if (reload_data) {
    loadLoanAgreement(props.rowid as number);
    resetData();
  }
};
const handleClick = (tab: TabsPaneContext, event: Event) => { };

const removeHmerTropopSymbashs = (item: DateItem) => {
  const index = dynamicValidateForm.hmeres_tropop.indexOf(item);
  if (index !== -1) {
    dynamicValidateForm.hmeres_tropop.splice(index, 1);
  }
};
const removePaymentOrder = (item: DiatagiPliromis) => {
  const index = dynamicValidateFormPayment.diatagi_pl_hmek_hmep_ex_per.indexOf(item);
  if (index !== -1) {
    dynamicValidateFormPayment.diatagi_pl_hmek_hmep_ex_per.splice(index, 1);
  }
};
const removeHmeresPosoOrder = (item: HmerPoso) => {
  const index = dynamicValidateFormHmerPoso.hmer_poso_ofeilhs.indexOf(item);
  if (index !== -1) {
    dynamicValidateFormHmerPoso.hmer_poso_ofeilhs.splice(index, 1);
  }
};

const submitForm = async (formEl: FormInstance | undefined) => {
  if (!formEl) return;
  // if (
  //   (isDenounced.value) &&
  //   parseFloat(fullObj.value.denouncement_amount.originalValue) !== (
  //     parseFloat(fullObj.value.kefaleo.originalValue) +
  //     parseFloat(fullObj.value.log_tokoi.originalValue) +
  //     parseFloat(fullObj.value.mh_log_tokoi.originalValue) +
  //     parseFloat(fullObj.value.promithies_exoda.originalValue) +
  //     parseFloat(fullObj.value.tokoi_promithion_exodon.originalValue)
  //   )) {
  //   console.log('error')
  //   ElMessage({
  //     showClose: true,
  //     message: t('errors.denounce_amounts_wrong'),
  //     type: "error",
  //   });
  //   return
  // }

  await formEl.validate((valid, fields) => {
    if (valid) {
      // console.log("submit!");
      save();
    } else {
      console.log("error submit!", fields);
    }
  });
};

const clickAcceptAll = async () => {
  isLoading.value = true;
  try {
    for (let key of Object.keys(fullObj.value)) {
      if (fullObj.value[key].pendingValue !== undefined) {
        // console.log('accepting pending change for', key);
        await onDecision({ accepted: true, db_change_id: (fullObj as any).value[key].pendingValue.id, field: key, value: (fullObj as any).value[key].pendingValue.value } as Decision);
      }
    }
  } catch (ex) {
    // console.log('Exception when accepting all:')
    console.log(ex);
  }
  isLoading.value = false;
}
</script>

<template>
  <div v-loading="isLoading">
    <el-row>
      <el-col :span="24" style="display:flex; justify-content: end;">
        <table class="clientdetails">
          <tr>
            <th>Full name</th>
            <th>CID</th>
            <th>Tax Reg. Nbr</th>
          </tr>
          <tr>
            <td>{{ clientFullName }}</td>
            <td>{{ clientCID }}</td>
            <td>{{ clientTaxNbr }}</td>
          </tr>
        </table>
      </el-col>
    </el-row>

    <AcceptAllPending v-show="hasPendingChanges && !props.view_only" @accept="clickAcceptAll" />

    <el-form :model="dynamicValidateForm" :rules="rules" ref="ruleFormRef">
      <el-tabs v-model="activeName" class="demo-tabs" @tab-click="handleClick">
        <el-tab-pane label="Agreement Details" name="agreement_details">
          <el-row :gutter="10">
            <!-- <el-col :span="24">
                  <EntityField type="select" :object="fullObj.status" :label="$t('LoanAgreementDetails.status')"
                    :options="statusList" :denounced="isDenounced" @decision="onDecision" />
                </el-col> -->
            <el-col :span="24">
              <EntityField type="input" :label="$t('LoanAgreementDetails.loan_agreement')" :object="fullObj.ar_sumbashs"
                :is-disabled="true" @decision="onDecision" :denounced="isDenounced" />
            </el-col>
            <el-col :span="24">
              <EntityField type="input" :label="$t('LoanAgreementDetails.loan_agreement_fao')"
                :object="fullObj.ar_sumbashs_fao" @decision="onDecision" :denounced="isDenounced"
                :is-disabled="props.view_only" />
            </el-col>
            <el-col :span="24">
              <EntityField type="select" :object="fullObj.katast_sumbashs"
                :label="$t('LoanAgreementDetails.legal_status')" :options="contractStatusList" @decision="onDecision"
                :denounced="isDenounced" :is-disabled="props.view_only" />
            </el-col>
            <el-col :span="24">
              <EntityField type="select" :object="fullObj.product" :label="$t('LoanAgreementDetails.product')"
                :options="products" @decision="onDecision" :denounced="isDenounced" :is-disabled="props.view_only" />
            </el-col>
            <el-col :span="24">
              <EntityField type="select" :object="fullObj.currency" :label="$t('LoanAgreementDetails.currency')"
                :options="currencies" @decision="onDecision" :denounced="isDenounced" :is-disabled="props.view_only" />
            </el-col>
            <el-col :span="24">
              <EntityField type="money" :label="$t('LoanAgreementDetails.total_amount')"
                :object="fullObj.synol_poso_sumbashs" @decision="onDecision" :denounced="isDenounced"
                :is-disabled="props.view_only" />
            </el-col>

            <el-col :span="24">
              <EntityField type="datepicker" :label="$t('LoanAgreementDetails.date_of_agreement')"
                :object="fullObj.hmer_sumbashs" @decision="onDecision" :denounced="isDenounced"
                :is-disabled="props.view_only" />
            </el-col>
            <el-col :span="24" style="margin-bottom: 10px">
              <div>
                <table id="customers">
                  <tr>
                    <th>
                      {{ $t("LoanAgreementDetails.date_of_additional_arrangement") }}
                    </th>
                  </tr>
                  <tr>
                    <td>
                      <EntityField type="json_additional_arrangement" label=" " :object="fullObj.hmer_tropop_sumbashs"
                        @decision="onDecision" :denounced="isDenounced" style="padding: 5px"
                        :is-disabled="props.view_only" />
                      <el-form-item v-for="(item, index) in dynamicValidateForm.hmeres_tropop" prop="hmer_tropop"
                        style="border: 0px !important;">
                        <el-col :span="20">
                          <el-date-picker type="date" v-model="item.hmer_tropop" value-format="YYYY-MM-DD"
                            placeholder="Pick a date" style="width: 100%" format="DD-MM-YYYY" required="true"
                            :disabled="not_user_changes" />
                        </el-col>
                        <el-col :span="4" v-if="mainStore.loggedUser.user_type < 2 || !not_user_changes">
                          <el-button link type="primary" size="small" class="json-btn-delete"
                            @click.prevent="removeHmerTropopSymbashs(item)" :icon="Delete" />
                        </el-col>
                      </el-form-item>
                      <el-button v-if="mainStore.loggedUser.user_type < 2 || !not_user_changes" style="float: right"
                        @click="addArrangementDate">{{ $t('LoanAgreementDetails.new_amendment_date') }}</el-button>
                    </td>
                  </tr>
                </table>
              </div>
            </el-col>
            <el-col :span="24">
              <EntityField type="select" :object="fullObj.agent" :label="$t('LoanAgreementDetails.agent')"
                :options="agents" @decision="onDecision" :denounced="isDenounced" :is-disabled="props.view_only" />
            </el-col>
            <el-col :span="24">
              <EntityField type="datepicker" :label="$t('LoanAgreementDetails.arxikh_hmer_lukshs')"
                :object="fullObj.arxikh_hmer_lukshs" @decision="onDecision" :is-disabled="props.view_only" />
            </el-col>
            <el-col :span="24">
              <EntityField type="datepicker" :label="$t('LoanAgreementDetails.nea_hmer_lukshs')"
                :object="fullObj.nea_hmer_lukshs" @decision="onDecision" :is-disabled="props.view_only" />
            </el-col>
            <el-col :span="24">
              <EntityField type="datepicker" :label="$t('LoanAccountDetails.start_date_of_grace_period')"
                :object="fullObj.hmer_enark_period_xaritos" @decision="onDecision" :is-disabled="props.view_only" />
            </el-col>
            <el-col :span="24">
              <EntityField type="datepicker" :label="$t('LoanAccountDetails.end_date_of_grace_period')"
                :object="fullObj.hmer_liksis_period_xaritos" @decision="onDecision" :is-disabled="props.view_only" />
            </el-col>
            <el-col :span="24">
              <EntityField type="select" :object="fullObj.tropos_apopl" :label="$t('LoanAccountDetails.payment_type')"
                :options="paymentTypeList" @decision="onDecision" :is-disabled="props.view_only" />
            </el-col>
            <el-col :span="24">
              <EntityField type="input" :label="$t('LoanAccountDetails.principal_repayment_schedule')"
                :object="fullObj.program_apopl_kefal" @decision="onDecision" :is-disabled="props.view_only" />
            </el-col>
            <el-col :span="24">
              <EntityField type="datepicker" :label="$t('LoanAccountDetails.1st_principal_payment_date')"
                :object="fullObj.hmer_prwths_doshs_kefal" @decision="onDecision" :is-disabled="props.view_only" />
            </el-col>
            <el-col :span="24" v-if="fullObj.tropos_apopl?.originalValue == 'balloon'">
              <EntityField type="input" :label="$t('LoanAccountDetails.number_of_principal_payments')"
                :object="fullObj.plithos_doseon_kefal" @decision="onDecision" :is-disabled="props.view_only" />
            </el-col>
            <el-col :span="24" style="padding-bottom: 15px;" v-if="fullObj.tropos_apopl?.originalValue == 'balloon'">
              <table id="customers">
                <tr>
                  <th>
                    {{ $t("LoanAgreementDetails.hmer_kai_poso_ofeilhs") }}
                  </th>
                </tr>
                <tr>
                  <td>

                    <EntityField type="json_hmer_poso_ofeilhs" label=" " :object="fullObj.hmer_poso_ofeilhs"
                      @decision="onDecision" style="padding: 5px" :is-disabled="props.view_only" />
                    <el-form-item v-for="(item, index) in dynamicValidateFormHmerPoso.hmer_poso_ofeilhs"
                      prop="hmer_poso_ofeilhs" style="border: 0px !important;">
                      <el-col :span="11">
                        <el-date-picker v-model="item.hmeres" value-format="YYYY-MM-DD"
                          :placeholder="$t('LoanAccountDetails.date_of_principal_payment')" style="width: 100%"
                          format="DD-MM-YYYY" :disabled="not_user_changesHmerPoso" />
                      </el-col>
                      <el-col :span="11">
                        <el-input v-model="item.poso" :placeholder="$t('LoanAccountDetails.amount_of_principal_payment')"
                          style="width: 100%" :disabled="not_user_changesHmerPoso" />
                      </el-col>

                      <el-col :span="2" v-if="mainStore.loggedUser.user_type < 2 || !not_user_changesHmerPoso">

                        <el-button link type="primary" size="small" class="json-btn-delete"
                          @click.prevent="removeHmeresPosoOrder(item)" :icon="Delete" />

                      </el-col>
                    </el-form-item>
                    <el-button v-if="mainStore.loggedUser.user_type < 2 || !not_user_changesHmerPoso" style="float: right"
                      @click="addHmeresPoso">{{
                        $t('LoanAgreementDetails.date_amount_repayment')
                      }}</el-button>

                  </td>
                </tr>
              </table>
            </el-col>
            <el-col :span="24">
              <EntityField type="input" :label="$t('LoanAccountDetails.interest_repayment_schedule')"
                :object="fullObj.program_apopl_tokon" @decision="onDecision" :is-disabled="props.view_only" />
            </el-col>
            <el-col :span="24">
              <EntityField type="datepicker" :label="$t('LoanAccountDetails.1st_interest_payment_date')"
                :object="fullObj.hmer_protis_doshs_tokon" @decision="onDecision" :is-disabled="props.view_only" />
            </el-col>
            <el-col :span="24">
              <EntityField type="select" :label="$t('LoanAgreementDetails.vasi_ektokismou')"
                :object="fullObj.vasi_ektokismou" :options="year_base_rates" @decision="onDecision"
                :is-disabled="props.view_only" />
            </el-col>
            <el-col :span="24">
              <EntityField type="select" :label="$t('LoanAgreementDetails.kathgor_epitok_bashs')"
                :object="fullObj.kathgor_epitok_bashs" :options="interests" @decision="onDecision"
                :is-disabled="props.view_only" />
            </el-col>
            <el-col :span="24">
              <EntityField type="input" :label="$t('LoanAgreementDetails.perithorio')" :object="fullObj.perithorio"
                @decision="onDecision" :is-disabled="props.view_only" />
            </el-col>
            <el-col :span="24">
              <EntityField type="input" :label="$t('LoanAgreementDetails.epitok_uperhm')" :object="fullObj.epitok_uperhm"
                @decision="onDecision" :is-disabled="props.view_only" />
            </el-col>
            <el-col :span="24">
              <EntityField type="input" :label="$t('LoanAgreementDetails.eisfora_n128_1975')"
                :object="fullObj.eisfora_n128_1975" @decision="onDecision" :is-disabled="props.view_only" />
            </el-col>
            <el-col :span="24">
              <EntityField type="input" :label="$t('LoanAgreementDetails.parakra_forou')" :object="fullObj.parakra_forou"
                @decision="onDecision" :is-disabled="props.view_only" />
            </el-col>
            <el-col :span="24">
              <EntityField type="switch" :label="$t('LoanAccountDetails.prepayment_right')"
                :object="fullObj.dikaioma_propl" @decision="onDecision" :is-disabled="props.view_only" />
            </el-col>
            <el-col :span="24" v-if="fullObj.dikaioma_propl?.originalValue == true">
              <EntityField type="input" :label="$t('LoanAgreementDetails.oros_propliromis')"
                :object="fullObj.oros_propliromis" @decision="onDecision" :is-disabled="props.view_only" />
            </el-col>
            <el-col :span="24">
              <EntityField type="input" :label="$t('LoanAgreementDetails.comments')" :object="fullObj.comments"
                @decision="onDecision" :denounced="isDenounced" :is-disabled="props.view_only" />
            </el-col>
          </el-row>
        </el-tab-pane>
        <el-tab-pane label="Denounced" name="denounced"
          v-if="(fullObj as any).katast_sumbashs?.originalValue === 'denounced'">
          <!--START OF DENOUNCED FIELDS-->
          <el-row v-if="(fullObj as any).katast_sumbashs?.originalValue === 'denounced'">
            <el-col :span="24">
              <EntityField type="datepicker" :label="$t('LoanAgreementDetails.initiation_of_legal_actions')"
                :object="fullObj.hmer_enarx_dikast_energ" @decision="onDecision" :is-disabled="props.view_only" />
            </el-col>
          </el-row>
          <!-- ΑΡΧΗ ΑΡΙΘΜΗΤΙΚΑ ΠΕΔΙΑ -->
          <div class="">
            <div v-if="parseFloat(fullObj.denouncement_amount?.originalValue) !==
                parseFloat(fullObj.kefaleo?.originalValue) +
                parseFloat(fullObj.log_tokoi?.originalValue) +
                parseFloat(fullObj.mh_log_tokoi?.originalValue) +
                parseFloat(fullObj.promithies_exoda?.originalValue) +
                parseFloat(fullObj.tokoi_promithion_exodon?.originalValue) &&
                (parseFloat(fullObj.denouncement_amount?.originalValue) > 0 ||
                  parseFloat(fullObj.kefaleo?.originalValue) > 0 ||
                  parseFloat(fullObj.log_tokoi?.originalValue) > 0 ||
                  parseFloat(fullObj.mh_log_tokoi?.originalValue) > 0 ||
                  parseFloat(fullObj.promithies_exoda?.originalValue) > 0 ||
                  parseFloat(fullObj.tokoi_promithion_exodon?.originalValue) > 0)
                " class="el-tag el-tag--danger" style="padding: 15px; margin: 5px;">
              {{ $t("errors.denounce_amounts_wrong") }}
            </div>
            <el-row v-if="(fullObj as any).katast_sumbashs.originalValue === 'denounced'">
              <el-col :span="24">
                <EntityField type="input" :label="$t('LoanAgreementDetails.denouncedamount')"
                  :object="fullObj.denouncement_amount" @decision="onDecision" :is-disabled="props.view_only" />
              </el-col>
            </el-row>
            <el-row v-if="(fullObj as any).katast_sumbashs.originalValue === 'denounced'">
              <el-col :span="24">
                <EntityField type="input" :label="$t('LoanAgreementDetails.kefaleo')" :object="fullObj.kefaleo"
                  @decision="onDecision" :is-disabled="props.view_only" />
              </el-col>
            </el-row>
            <el-row v-if="(fullObj as any).katast_sumbashs.originalValue === 'denounced'">
              <el-col :span="24">
                <EntityField type="input" :label="$t('LoanAgreementDetails.log_tokoi')" :object="fullObj.log_tokoi"
                  @decision="onDecision" :is-disabled="props.view_only" />
              </el-col>
            </el-row>
            <el-row v-if="(fullObj as any).katast_sumbashs.originalValue === 'denounced'">
              <el-col :span="24">
                <EntityField type="input" :label="$t('LoanAgreementDetails.mh_log_tokoi')" :object="fullObj.mh_log_tokoi"
                  @decision="onDecision" :is-disabled="props.view_only" />
              </el-col>
            </el-row>
            <el-row v-if="(fullObj as any).katast_sumbashs.originalValue === 'denounced'">
              <el-col :span="24">
                <EntityField type="input" :label="$t('LoanAgreementDetails.promithies_exoda')"
                  :object="fullObj.promithies_exoda" @decision="onDecision" :is-disabled="props.view_only" />
              </el-col>
            </el-row>
            <el-row v-if="(fullObj as any).katast_sumbashs.originalValue === 'denounced'">
              <el-col :span="24">
                <EntityField type="input" :label="$t('LoanAgreementDetails.tokoi_promithion_exodon')"
                  :object="fullObj.tokoi_promithion_exodon" @decision="onDecision" :is-disabled="props.view_only" />
              </el-col>
            </el-row>
          </div>
          <!-- ΤΕΛΟΣ ΑΡΙΘΜΗΤΙΚΑ ΠΕΔΙΑ -->
          <el-row v-if="(fullObj as any).katast_sumbashs.originalValue === 'denounced'">
            <el-col :span="24">
              <EntityField type="datepicker" :label="$t('LoanAgreementDetails.serving_of_legal_actions')"
                :object="fullObj.hmer_epidosis_dikast_energ" @decision="onDecision" :is-disabled="props.view_only" />
            </el-col>
          </el-row>
          <el-row v-if="(fullObj as any).katast_sumbashs.originalValue === 'denounced'">
            <el-col :span="24">
              <EntityField type="input" :label="$t('LoanAgreementDetails.serving_fees')" :object="fullObj.exoda_epidosis"
                @decision="onDecision" :is-disabled="props.view_only" />
            </el-col>
          </el-row>
          <el-row>
            <el-col>
              <EntityField type="switch" :label="$t('LoanAgreementDetails.payment_order')"
                :object="fullObj.ekdosi_diatagis" @decision="onDecision" :is-disabled="props.view_only" />
            </el-col>
          </el-row>
          <el-row v-if="(fullObj as any).katast_sumbashs.originalValue === 'denounced'">
            <el-col :span="24">
              <div>
                <table id="customers">
                  <tr>
                    <th>{{ $t("LoanAgreementDetails.paymentorders") }}</th>
                  </tr>
                  <tr>
                    <td>
                      <EntityField type="jsonbdiatagipliromis" label=" " :object="fullObj.diatagi_pl_hmek_hmep_ex_per"
                        @decision="onDecision" :is-disabled="props.view_only" />
                      <div v-for="(
                              item, index
                            ) in dynamicValidateFormPayment.diatagi_pl_hmek_hmep_ex_per">
                        <el-row style="padding: 10px">
                          <p>Payment Order #{{ index + 1 }}</p>
                        </el-row>
                        <el-row :gutter="5" style="padding: 10px; align-items: center;" class="row-bg">
                        <el-col :span="22">

                          <el-row>

                          <el-col :span="12">
                            <el-form-item :label="$t('LoanAgreementDetails.issue_date')">
                            <el-date-picker v-model="item.hmek" value-format="YYYY-MM-DD" placeholder="Issue Date"
                              style="width: 100%; margin: 5px;" format="DD-MM-YYYY" :disabled="not_user_changesPayment" />
                            </el-form-item>
                          </el-col>
                          <el-col :span="12">
                            <el-form-item :label="$t('LoanAgreementDetails.poso_diatagis_plhr')">
                            <el-input v-model="item.poso_diatag_plhr" :placeholder="$t('LoanAgreementDetails.poso_diatagis_plhr')" style="width: 100%; margin: 5px;"
                              :disabled="not_user_changesPayment" />
                            </el-form-item>
                          </el-col>
                          <el-col :span="12">
                            <el-form-item :label="$t('LoanAgreementDetails.first_serving_date')">
                            <el-date-picker v-model="item.hmep" value-format="YYYY-MM-DD" :placeholder="$t('LoanAgreementDetails.first_serving_date')"
                              style="width: 100%; margin: 5px;" format="DD-MM-YYYY" :disabled="not_user_changesPayment" />
                            </el-form-item>
                          </el-col>
                          <el-col :span="12">
                            <el-form-item :label="$t('LoanAgreementDetails.first_serving_fees')">
                            <el-input v-model="item.exoda" :placeholder="$t('LoanAgreementDetails.first_serving_fees')" style="width: 100%; margin: 5px;"
                              :disabled="not_user_changesPayment" />
                            </el-form-item>
                          </el-col>
                          <el-col :span="12">
                            <el-form-item :label="$t('LoanAgreementDetails.second_serving_date')">
                            <el-date-picker v-model="item.deut_hmep" value-format="YYYY-MM-DD" :placeholder="$t('LoanAgreementDetails.second_serving_date')"
                              style="width: 100%; margin: 5px;" format="DD-MM-YYYY" :disabled="not_user_changesPayment" />
                            </el-form-item>
                          </el-col>
                          <el-col :span="12">
                            <el-form-item :label="$t('LoanAgreementDetails.first_serving_fees')">
                            <el-input v-model="item.deut_exoda" :placeholder="$t('LoanAgreementDetails.first_serving_fees')" style="width: 100%; margin: 5px;"
                              :disabled="not_user_changesPayment" />
                            </el-form-item>
                          </el-col>
                            <el-col :span="8" style="margin: 5px;">
                              <el-form-item>
                            <label class="select-label"><span>{{ $t('LoanAgreementDetails.aitisi_anastolis') }}</span></label>
                            <el-switch v-model="item.aithsh_anastolhs" :active-text="$t('components.switch.yes')" :inactive-text="$t('components.switch.no')" :disabled="not_user_changesPayment"/>
                          </el-form-item>
                          </el-col>
                          <el-col :span="8" style="margin: 5px;">
                            <el-form-item>
                            <label class="select-label"><span>{{$t('LoanAgreementDetails.pros_diatg_ektel_plhr')}}</span></label>
                            <el-switch v-model="item.pros_diatg_ektel_plhr" :active-text="$t('components.switch.yes')" :inactive-text="$t('components.switch.no')" :disabled="not_user_changesPayment"/>
                          </el-form-item>
                          </el-col>
                          <el-col :span="7" style="margin: 6.5px;">
                            <el-form-item :label="$t('LoanAgreementDetails.telesidikh_apof')" style="margin-top: 0px !important; margin-bottom: 18px !important; margin-top: 18px !important;">
                            <el-input v-model="item.telesidikh_apof" :placeholder="$t('LoanAgreementDetails.telesidikh_apof')" style="width: 100%; margin: 5px;"
                              :disabled="not_user_changesPayment" />
                            </el-form-item>
                          </el-col>

                        </el-row>

                        </el-col>

                          <el-col :span="2" v-if="mainStore.loggedUser.user_type < 2 ||
                            !not_user_changesPayment
                            " style="display: flex; justify-content: center;">
                            <el-button link type="primary" size="small" class="json-btn-delete"
                              @click.prevent="removePaymentOrder(item)" :icon="Delete" />
                          </el-col>


                        </el-row>
                      </div>
                      <el-button v-if="mainStore.loggedUser.user_type < 2 || !not_user_changesPayment
                        " style="float: right" @click="addPaymentOrder">{{$t('LoanAgreementDetails.new_payment_order')}}</el-button>
                    </td>
                  </tr>
                </table>
              </div>
            </el-col>
          </el-row>
          <hr />
          <!-- <el-row v-if="(fullObj as any).katast_sumbashs.originalValue === 'denounced'">
            <el-col :span="24">
              <EntityField type="switch" :label="$t('LoanAgreementDetails.suspension_request')"
                :object="fullObj.aitisi_anastolis" @decision="onDecision" :is-disabled="props.view_only" />
            </el-col>
          </el-row> -->
          <!-- <el-row style="margin: 5px" v-if="(fullObj as any).katast_sumbashs.originalValue === 'denounced'">
            <el-col :span="24">
              {{ $t("LoanAgreementDetails.suspension_request_margin") }}:
              {{ SuspensionRequestMargin }}
            </el-col>
          </el-row> -->
          <el-row v-if="(fullObj as any).katast_sumbashs.originalValue === 'denounced'" style="margin-top: 20px;">
            <el-col :span="24">
              <EntityField type="switch" :label="$t('LoanAgreementDetails.confiscation')" :object="fullObj.katasxesi"
                @decision="onDecision" :is-disabled="props.view_only" />
            </el-col>
          </el-row>
          <el-row
            v-if="((fullObj as any).katast_sumbashs.originalValue === 'denounced' && ((fullObj as any).katasxesi.originalValue === true))">
            <el-col :span="24">
              <EntityField type="input" :label="$t('LoanAgreementDetails.appraisal_by_a_certified_appraiser')"
                :object="fullObj.ektimisi" @decision="onDecision" :is-disabled="props.view_only" />
              <EntityField type="input" :label="$t('LoanAgreementDetails.appraisal_fees')"
                :object="fullObj.exoda_ektimisis" @decision="onDecision" :is-disabled="props.view_only" />
              <EntityField type="datepicker" :label="$t('LoanAgreementDetails.date_of_serving_of_confiscation_report')"
                :object="fullObj.hmer_epid_ekth_katasx" @decision="onDecision" :is-disabled="props.view_only" />
              <EntityField type="input" :label="$t('LoanAgreementDetails.serving_fees')"
                :object="fullObj.exoda_epidosis_ekth_katasx" @decision="onDecision" :is-disabled="props.view_only" />
              <EntityField type="input" :label="$t('LoanAgreementDetails.confiscation_amount')"
                :object="fullObj.poso_katasxesis" @decision="onDecision" :is-disabled="props.view_only" />
              <EntityField type="input" :label="$t('LoanAgreementDetails.in_the_hands_of_a_third_party')"
                :object="fullObj.eis_xeiras_tritou" @decision="onDecision" :is-disabled="props.view_only" />

              <EntityField type="datepicker" :label="$t('LoanAgreementDetails.date_of_upcoming_auction')"
                :object="fullObj.hmer_eperx_pleist" @decision="onDecision" :is-disabled="props.view_only" />
              <EntityField type="datepicker" :label="$t('LoanAgreementDetails.process_interruption_margin')"
                :object="fullObj.perithorio_anakopis" @decision="onDecision" :is-disabled="props.view_only" />
              <EntityField type="datepicker" :label="$t('LoanAgreementDetails.suspension_request_from')"
                :object="fullObj.perithorio_anastolis_apo" @decision="onDecision" :is-disabled="props.view_only" />
              <EntityField type="datepicker" :label="$t('LoanAgreementDetails.suspension_request_to')"
                :object="fullObj.perithorio_anastolis_eos" @decision="onDecision" :is-disabled="props.view_only" />
              <EntityField type="switch" :label="$t('LoanAgreementDetails.auction')" :object="fullObj.auction"
                @decision="onDecision" />
            </el-col>
          </el-row>
        </el-tab-pane>
        <!--END OF DENOUNCED FIELDS-->
      </el-tabs>

      <el-row>
        <el-col :span="24">
          <el-form-item class="btn-wrapper-components">
            <el-button v-if="!props.view_only" type="primary" @click="submitForm(ruleFormRef)">{{
              $t('generic.save')
            }}</el-button>
            <el-button @click="close">{{ $t('generic.close') }}</el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<style scoped>
#customers {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

.clientdetails {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 50%;
  margin-bottom: 20px;

}

#customers td,
#customers th,
.clientdetails td,
.clientdetails th {
  border: 1px solid #ddd;
  padding: 8px;
}

#customers tr:nth-child(even),
.clientdetails tr:nth-child(even) {
  background-color: #f2f2f2;
}

#customers th,
.clientdetails th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #98a4cb;
  color: white;
}
</style>
