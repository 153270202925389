<script lang="ts" setup>

// Vue stuff
import { ref, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";

// Libs
import { DateTime } from "luxon";

// Stores
import { useMainStore } from "../store/main";
import { usePGRESTAPIStore } from "../store/pgrest_api";

// Composables
import { useGenericMethodsVariables } from "../composables/genericMethodsVariables";

// Classes
import AmountEntryExtra from '../classes/AmountEntryExtra';
import LoanAccount from '../classes/LoanAccount';
import LoanAccountEntity from '../classes/DB_Entities/LoanAccount';
import PGRESTResult from "../classes/PGRESTResult";

// Enums
import LoanRepaymentType from '../enums/LoanRepaymentType';
import RepaymentFrequency from '../enums/Frequency';
import LoanAccountEventType from '../enums/LoanAccountEventType';
import DebitCredit from "../classes/DB_Entities/DebitCredit";
import DebitCreditType from "../enums/DebitCreditType";

import { PDFDocument } from 'pdf-lib';
import fontkit from '@pdf-lib/fontkit';
import LoanAgreement from "../classes/DB_Entities/LoanAgreement";
import UserType from "../enums/UserType";
import { useI18n } from "vue-i18n";


// Interfaces
interface EnhmeroAmountEntry {
    startDate: string;
    endDate?: string;
    amount: number;
    interest: number;
    dedouleymenoi_tokoi: number;
    tokoi_periodou: number;
    usedPistwsh?: number;
    valeurDate?: string;
}
const { locale } = useI18n({ useScope: "global" });
const { t } = useI18n({ useScope: "global" });
const route = useRoute();
const router = useRouter();

const mainStore = useMainStore();
const pgrestapi = usePGRESTAPIStore();

const { showError } = useGenericMethodsVariables();
// Refs

let isLoading = ref(false);
// let tempLoanAccountId=1;
let tempLoanAccountId = 10;

let euroFormat = new Intl.NumberFormat('el-GR', { style: 'currency', currency: 'EUR' });
let loanAccount = ref(new LoanAccount());
let clientName = '';
let clientAddress = '';
let loanAccountNumber = ref('');
let docStartingDate = '';
let docEndingDate = '';
let loanAgreementNumber = ref('');
let kathisterimeno_arxika = ref('');
let loan_product = ref('');
let startingDate = ref('');
let synolikoPoso = ref(0);
let loanCapitalRepaymentType = ref('');
let interestRepaymentEpeteiako = ref('');
let arnitika_epitokia = ref('');
let interestChangeIsEpeteiako = ref('');
let calculateArgies = ref('');
let base_rate = ref('');
let fromDate = ref('');
let toDate = ref('');

const testCase_00 = async () => {

    let useDB = true;

    if (useDB) {

        let loanAccountData: LoanAccountEntity = (await pgrestapi.get("loan_accounts?id=eq." + tempLoanAccountId)).data[0] as LoanAccountEntity;
        let loanAgreementDataResult: PGRESTResult = new PGRESTResult();
        loanAgreementDataResult = await pgrestapi.get(`loan_agreement?id=eq.${loanAccountData.loan_agreement_id}`);
        let clientDataResult: PGRESTResult = new PGRESTResult();
        clientDataResult = await pgrestapi.get(`debtor?id=eq.${loanAgreementDataResult.data[0].debtor_id}`);

        loanAgreementNumber.value = loanAgreementDataResult.data[0].ar_sumbashs;
        clientName = clientDataResult.data[0].fullname;
        clientAddress = clientDataResult.data[0].address + ' ' + clientDataResult.data[0].address_number + ', ' + clientDataResult.data[0].city + ', ' + clientDataResult.data[0].postcode;
        console.log('loanAccountData: ' + JSON.stringify(loanAccountData));
        console.log('loanAccountData AGREEMENT: ' + JSON.stringify(loanAgreementDataResult));
        if (loanAccountData.hmer_enarkshs !== null && (loanAccountData.hmer_enarkshs as string) !== '') {
            loanAccount.value.loanStartingDate = loanAccountData.hmer_enarkshs as string;
            docStartingDate = loanAccountData.hmer_enarkshs as string;
        }
        else if (loanAgreementDataResult.data[0].hmer_sumbashs !== null && loanAgreementDataResult.data[0].hmer_sumbashs !== '') {
            loanAccount.value.loanStartingDate = loanAgreementDataResult.data[0].hmer_sumbashs as string;
            docStartingDate = loanAgreementDataResult.data[0].hmer_sumbashs as string;
        }
        if (loanAccountData.synoliko_poso !== null) {
            loanAccount.value.loanCapitalAmount = loanAccountData.synoliko_poso as number;
        }
        else if (loanAgreementDataResult.data[0].synol_poso_sumbashs !== null) {
            loanAccount.value.loanCapitalAmount = loanAgreementDataResult.data[0].synol_poso_sumbashs as number;
        }
        if (loanAccountData.loan_capital_repayment_type !== null) {
            loanAccount.value.loanCapitalRepaymentType = loanAccountData.loan_capital_repayment_type as number;
        }
        else if (loanAgreementDataResult.data[0].tropos_apopl !== null) {
            if (loanAgreementDataResult.data[0].tropos_apopl === 'xreolitiko') {
                loanAccount.value.loanCapitalRepaymentType = 2;/*δεν ξερω το νουμερο που εβαλες για το χρεολυτικο και πρεπει να μπουν τα σωστα για ολα*/
            }
            else if (loanAgreementDataResult.data[0].tropos_apopl === 'tokoxreolitiko') {
                loanAccount.value.loanCapitalRepaymentType = 2;/*δεν ξερω το νουμερο που εβαλες για το τοκοχρεολυτικο και πρεπει να μπουν τα σωστα για ολα*/
            }
            else if (loanAgreementDataResult.data[0].tropos_apopl === 'balloon') {
                loanAccount.value.loanCapitalRepaymentType = 2;/*δεν ξερω το νουμερο που εβαλες για το τοκοχρεολυτικο και πρεπει να μπουν τα σωστα για ολα*/
            }
        }
        if (loanAccountData.loan_capital_repayment_frequency !== null) {
            loanAccount.value.loanCapitalRepaymentFrequency = loanAccountData.loan_capital_repayment_frequency as number;
        }
        else if (loanAgreementDataResult.data[0].program_apopl_kefal !== null) {
            loanAccount.value.loanCapitalRepaymentType = loanAgreementDataResult.data[0].program_apopl_kefal as number;
        }
        loanAccount.value.loanRepaymentEpeteiako = loanAccountData.loan_capital_repayment_epeteiako as boolean;
        if (loanAccountData.loan_interest_repayment_frequency !== null) {
            loanAccount.value.interestRepaymentFrequency = loanAccountData.loan_interest_repayment_frequency as number;
        }
        else if (loanAgreementDataResult.data[0].program_apopl_tokon !== null) {
            loanAccount.value.interestRepaymentFrequency = loanAgreementDataResult.data[0].program_apopl_tokon;
        }
        loanAccount.value.interestRepaymentEpeteiako = loanAccountData.loan_interest_repayment_epeteiako as boolean;
        loanAccountNumber.value = loanAccountData.ar_trexontos_logariasmou as string;

        //return;

        //vasi_ektokismou
        //kathgor_epitok_bashs
        //perithorio
        //epitok_uperhm
        //eisfora_n128_1975
        //plithos_doseon_kefal

        await loadDebitsCredits(tempLoanAccountId);

    } else {
        loanAccount.value.loanStartingDate = '2021-01-01';
        loanAccount.value.loanCapitalAmount = 4680000;

        // loanAccount.value.eventsList.push({ date: '2024-02-01', processed: false, type: LoanAccountEventType.InterestChange, amount: 116000, });

        loanAccount.value.eventsList.push({ date: '2021-06-30', processed: false, type: LoanAccountEventType.Dosh, amount: 116000, perigrafi: "" });
        loanAccount.value.eventsList.push({ date: '2021-12-31', processed: false, type: LoanAccountEventType.Dosh, amount: 116000, perigrafi: "" });
        loanAccount.value.eventsList.push({ date: '2022-06-30', processed: false, type: LoanAccountEventType.Dosh, amount: 99500, perigrafi: "" });
        loanAccount.value.eventsList.push({ date: '2022-12-31', processed: false, type: LoanAccountEventType.Dosh, amount: 99500, perigrafi: "" });
        loanAccount.value.eventsList.push({ date: '2023-06-30', processed: false, type: LoanAccountEventType.Dosh, amount: 104000, perigrafi: "" });
        loanAccount.value.eventsList.push({ date: '2023-12-31', processed: false, type: LoanAccountEventType.Dosh, amount: 104000, perigrafi: "" });
        loanAccount.value.eventsList.push({ date: '2022-03-10', processed: false, type: LoanAccountEventType.Pistwsh, amount: 3016, perigrafi: "" });
        loanAccount.value.eventsList.push({ date: '2022-04-01', processed: false, type: LoanAccountEventType.Pistwsh, amount: 449990, perigrafi: "" });
        loanAccount.value.eventsList.push({ date: '2022-04-26', processed: false, type: LoanAccountEventType.Pistwsh, amount: 75000, perigrafi: "" });
        loanAccount.value.eventsList.push({ date: '2022-07-25', processed: false, type: LoanAccountEventType.Pistwsh, amount: 137734, perigrafi: "" });
        loanAccount.value.eventsList.push({ date: '2022-09-30', processed: false, type: LoanAccountEventType.Pistwsh, amount: 79255, perigrafi: "" });
        loanAccount.value.eventsList.push({ date: '2022-12-27', processed: false, type: LoanAccountEventType.Pistwsh, amount: 89995, perigrafi: "" });
        loanAccount.value.eventsList.push({ date: '2023-04-04', processed: false, type: LoanAccountEventType.Pistwsh, amount: 149420, perigrafi: "" });

        loanAccount.value.eventsList.push({ date: '2021-05-31', processed: false, type: LoanAccountEventType.Exodo, amount: 5000, perigrafi: "" })

        loanAccount.value.loanCapitalRepaymentType = LoanRepaymentType.XreolytikoAnisoposo;
        loanAccount.value.loanCapitalRepaymentFrequency = RepaymentFrequency.SemiAnnually;
        loanAccount.value.loanRepaymentEpeteiako = false;
        loanAccount.value.interestRepaymentFrequency = RepaymentFrequency.SemiAnnually;
        loanAccount.value.interestRepaymentEpeteiako = false;
    }

    loanAccount.value.Initialize();

    loanAccount.value.ProcessEvents();
}

const testCase_01 = () => {
    loanAccount.value.loanStartingDate = '2023-01-16';
    loanAccount.value.loanCapitalAmount = 10000;
    loanAccount.value.loanCapitalRepaymentType = LoanRepaymentType.XreolytikoAnisoposo;
    loanAccount.value.loanCapitalRepaymentFrequency = RepaymentFrequency.SemiAnnually;
    loanAccount.value.loanRepaymentEpeteiako = false;
    loanAccount.value.interestRepaymentFrequency = RepaymentFrequency.Quarterly;
    loanAccount.value.interestRepaymentEpeteiako = false;

    loanAccount.value.eventsList.push({ date: '2023-03-31', processed: false, type: LoanAccountEventType.Tokos, amount: 116000, perigrafi: "" });
    loanAccount.value.eventsList.push({ date: '2023-06-30', processed: false, type: LoanAccountEventType.Tokos, amount: 116000, perigrafi: "" });
    loanAccount.value.eventsList.push({ date: '2023-09-30', processed: false, type: LoanAccountEventType.Tokos, amount: 116000, perigrafi: "" });
    loanAccount.value.eventsList.push({ date: '2023-12-31', processed: false, type: LoanAccountEventType.Tokos, amount: 116000, perigrafi: "" });

    loanAccount.value.eventsList.push({ date: '2023-02-16', type: LoanAccountEventType.Dosh, amount: 10000 / 12., processed: false, perigrafi: "" });
    loanAccount.value.eventsList.push({ date: '2023-03-16', type: LoanAccountEventType.Dosh, amount: 10000 / 12., processed: false, perigrafi: "" });
    loanAccount.value.eventsList.push({ date: '2023-04-16', type: LoanAccountEventType.Dosh, amount: 10000 / 12., processed: false, perigrafi: "" });
    loanAccount.value.eventsList.push({ date: '2023-05-16', type: LoanAccountEventType.Dosh, amount: 10000 / 12., processed: false, perigrafi: "" });
    loanAccount.value.eventsList.push({ date: '2023-06-16', type: LoanAccountEventType.Dosh, amount: 10000 / 12., processed: false, perigrafi: "" });
    loanAccount.value.eventsList.push({ date: '2023-07-16', type: LoanAccountEventType.Dosh, amount: 10000 / 12., processed: false, perigrafi: "" });
    loanAccount.value.eventsList.push({ date: '2023-08-16', type: LoanAccountEventType.Dosh, amount: 10000 / 12., processed: false, perigrafi: "" });
    loanAccount.value.eventsList.push({ date: '2023-09-16', type: LoanAccountEventType.Dosh, amount: 10000 / 12., processed: false, perigrafi: "" });
    loanAccount.value.eventsList.push({ date: '2023-10-16', type: LoanAccountEventType.Dosh, amount: 10000 / 12., processed: false, perigrafi: "" });
    loanAccount.value.eventsList.push({ date: '2023-11-16', type: LoanAccountEventType.Dosh, amount: 10000 / 12., processed: false, perigrafi: "" });
    loanAccount.value.eventsList.push({ date: '2023-12-16', type: LoanAccountEventType.Dosh, amount: 10000 / 12., processed: false, perigrafi: "" });
    loanAccount.value.eventsList.push({ date: '2024-01-16', type: LoanAccountEventType.Dosh, amount: 10000 / 12., processed: false, perigrafi: "" });
}

const testCase_02 = () => {
    loanAccount.value.loanStartingDate = '2015-10-01';
    loanAccount.value.loanCapitalAmount = 4363392.86;
    loanAccount.value.loanCapitalRepaymentType = LoanRepaymentType.XreolytikoAnisoposo;
    loanAccount.value.loanCapitalRepaymentFrequency = RepaymentFrequency.SemiAnnually;
    loanAccount.value.loanRepaymentEpeteiako = true;
    loanAccount.value.interestRepaymentFrequency = RepaymentFrequency.Quarterly;
    loanAccount.value.interestRepaymentEpeteiako = true;

    loanAccount.value.eventsList.push({ date: '2016-01-01', processed: false, type: LoanAccountEventType.Tokos, amount: 116000, perigrafi: "" });
    loanAccount.value.eventsList.push({ date: '2016-04-01', processed: false, type: LoanAccountEventType.Tokos, amount: 116000, perigrafi: "" });
    loanAccount.value.eventsList.push({ date: '2016-07-01', processed: false, type: LoanAccountEventType.Tokos, amount: 116000, perigrafi: "" });
    loanAccount.value.eventsList.push({ date: '2016-10-01', processed: false, type: LoanAccountEventType.Tokos, amount: 116000, perigrafi: "" });
    loanAccount.value.eventsList.push({ date: '2017-01-01', processed: false, type: LoanAccountEventType.Tokos, amount: 116000, perigrafi: "" });
    loanAccount.value.eventsList.push({ date: '2017-04-01', processed: false, type: LoanAccountEventType.Tokos, amount: 116000, perigrafi: "" });
    loanAccount.value.eventsList.push({ date: '2017-07-01', processed: false, type: LoanAccountEventType.Tokos, amount: 116000, perigrafi: "" });
    loanAccount.value.eventsList.push({ date: '2017-10-01', processed: false, type: LoanAccountEventType.Tokos, amount: 116000, perigrafi: "" });
    loanAccount.value.eventsList.push({ date: '2018-01-01', processed: false, type: LoanAccountEventType.Tokos, amount: 116000, perigrafi: "" });
    loanAccount.value.eventsList.push({ date: '2018-04-01', processed: false, type: LoanAccountEventType.Tokos, amount: 116000, perigrafi: "" });
    loanAccount.value.eventsList.push({ date: '2018-07-01', processed: false, type: LoanAccountEventType.Tokos, amount: 116000, perigrafi: "" });
    loanAccount.value.eventsList.push({ date: '2018-10-01', processed: false, type: LoanAccountEventType.Tokos, amount: 116000, perigrafi: "" });
    loanAccount.value.eventsList.push({ date: '2019-01-01', processed: false, type: LoanAccountEventType.Tokos, amount: 116000, perigrafi: "" });
    loanAccount.value.eventsList.push({ date: '2019-04-01', processed: false, type: LoanAccountEventType.Tokos, amount: 116000, perigrafi: "" });
    loanAccount.value.eventsList.push({ date: '2019-07-01', processed: false, type: LoanAccountEventType.Tokos, amount: 116000, perigrafi: "" });
    loanAccount.value.eventsList.push({ date: '2019-10-01', processed: false, type: LoanAccountEventType.Tokos, amount: 116000, perigrafi: "" });
    loanAccount.value.eventsList.push({ date: '2020-01-01', processed: false, type: LoanAccountEventType.Tokos, amount: 116000, perigrafi: "" });
    loanAccount.value.eventsList.push({ date: '2020-04-01', processed: false, type: LoanAccountEventType.Tokos, amount: 116000, perigrafi: "" });
    loanAccount.value.eventsList.push({ date: '2020-07-01', processed: false, type: LoanAccountEventType.Tokos, amount: 116000, perigrafi: "" });
    loanAccount.value.eventsList.push({ date: '2020-10-01', processed: false, type: LoanAccountEventType.Tokos, amount: 116000, perigrafi: "" });
    loanAccount.value.eventsList.push({ date: '2021-01-01', processed: false, type: LoanAccountEventType.Tokos, amount: 116000, perigrafi: "" });
    loanAccount.value.eventsList.push({ date: '2021-04-01', processed: false, type: LoanAccountEventType.Tokos, amount: 116000, perigrafi: "" });
    loanAccount.value.eventsList.push({ date: '2021-07-01', processed: false, type: LoanAccountEventType.Tokos, amount: 116000, perigrafi: "" });
    loanAccount.value.eventsList.push({ date: '2021-10-01', processed: false, type: LoanAccountEventType.Tokos, amount: 116000, perigrafi: "" });
    loanAccount.value.eventsList.push({ date: '2022-01-01', processed: false, type: LoanAccountEventType.Tokos, amount: 116000, perigrafi: "" });
    loanAccount.value.eventsList.push({ date: '2022-04-01', processed: false, type: LoanAccountEventType.Tokos, amount: 116000, perigrafi: "" });
    loanAccount.value.eventsList.push({ date: '2022-07-01', processed: false, type: LoanAccountEventType.Tokos, amount: 116000, perigrafi: "" });
    loanAccount.value.eventsList.push({ date: '2022-10-01', processed: false, type: LoanAccountEventType.Tokos, amount: 116000, perigrafi: "" });
    loanAccount.value.eventsList.push({ date: '2023-01-01', processed: false, type: LoanAccountEventType.Tokos, amount: 116000, perigrafi: "" });
    loanAccount.value.eventsList.push({ date: '2023-04-01', processed: false, type: LoanAccountEventType.Tokos, amount: 116000, perigrafi: "" });

    loanAccount.value.eventsList.push({ date: '2015-12-30', type: LoanAccountEventType.Dosh, amount: 969642.84, processed: false, perigrafi: "" });
    loanAccount.value.eventsList.push({ date: '2016-06-30', type: LoanAccountEventType.Dosh, amount: 161607.14, processed: false, perigrafi: "" });
    loanAccount.value.eventsList.push({ date: '2016-12-30', type: LoanAccountEventType.Dosh, amount: 161607.14, processed: false, perigrafi: "" });
    loanAccount.value.eventsList.push({ date: '2017-06-30', type: LoanAccountEventType.Dosh, amount: 161607.14, processed: false, perigrafi: "" });
    loanAccount.value.eventsList.push({ date: '2017-12-30', type: LoanAccountEventType.Dosh, amount: 161607.14, processed: false, perigrafi: "" });
    loanAccount.value.eventsList.push({ date: '2018-06-30', type: LoanAccountEventType.Dosh, amount: 161607.14, processed: false, perigrafi: "" });
    loanAccount.value.eventsList.push({ date: '2018-12-30', type: LoanAccountEventType.Dosh, amount: 161607.14, processed: false, perigrafi: "" });
    loanAccount.value.eventsList.push({ date: '2019-06-30', type: LoanAccountEventType.Dosh, amount: 161607.14, processed: false, perigrafi: "" });
    loanAccount.value.eventsList.push({ date: '2019-12-30', type: LoanAccountEventType.Dosh, amount: 161607.14, processed: false, perigrafi: "" });
    loanAccount.value.eventsList.push({ date: '2020-06-30', type: LoanAccountEventType.Dosh, amount: 161607.14, processed: false, perigrafi: "" });
    loanAccount.value.eventsList.push({ date: '2020-12-30', type: LoanAccountEventType.Dosh, amount: 161607.14, processed: false, perigrafi: "" });
    loanAccount.value.eventsList.push({ date: '2021-06-30', type: LoanAccountEventType.Dosh, amount: 161607.14, processed: false, perigrafi: "" });
    loanAccount.value.eventsList.push({ date: '2021-12-30', type: LoanAccountEventType.Dosh, amount: 161607.14, processed: false, perigrafi: "" });
    loanAccount.value.eventsList.push({ date: '2022-06-30', type: LoanAccountEventType.Dosh, amount: 161607.14, processed: false, perigrafi: "" });
    loanAccount.value.eventsList.push({ date: '2022-12-30', type: LoanAccountEventType.Dosh, amount: 161607.14, processed: false, perigrafi: "" });
}

const testCase_03 = () => {
    loanAccount.value.loanStartingDate = '2021-12-01';
    loanAccount.value.loanCapitalAmount = 0;
    loanAccount.value.loanCapitalRepaymentType = LoanRepaymentType.XreolytikoAnisoposo;
    loanAccount.value.loanCapitalRepaymentFrequency = RepaymentFrequency.SemiAnnually;
    loanAccount.value.loanRepaymentEpeteiako = true;
    loanAccount.value.interestRepaymentFrequency = RepaymentFrequency.Quarterly;
    loanAccount.value.interestRepaymentEpeteiako = true;

    // loanAccount.value.eventsList.push({  date: '2021-12-31', type: LoanAccountEventType.Dosh, amount: 193488.95, processed: false });

    let newEntry = new AmountEntryExtra(loanAccount.value.perithorio, loanAccount.value.eisfora, loanAccount.value.bash_ektokismou, loanAccount.value.pososto_tokwn_yperhmerias)
    newEntry.startDate = '2021-12-31';
    newEntry.endDate = '2022-06-30';
    newEntry.valeurDate = '2022-06-30';

    loanAccount.value.enhmeroKefalaioList.push(newEntry);

    let newEntry2 = new AmountEntryExtra(loanAccount.value.perithorio, loanAccount.value.eisfora, loanAccount.value.bash_ektokismou, loanAccount.value.pososto_tokwn_yperhmerias)
    newEntry2.startDate = '2022-06-30';
    newEntry2.endDate = '2022-12-31';
    newEntry2.valeurDate = '2022-12-31';
    
    newEntry2.amount = 193488.95;
    newEntry2.dedouleymenoi_tokoi = 16523;
    loanAccount.value.kathysterhmenoKefalaio.push(newEntry2);

    // loanAccount.value.RecalculateTokoiPediodouKathysterhmenouKefalaiou();

    loanAccount.value.eventsList.push({ date: '2022-06-30', processed: false, type: LoanAccountEventType.KathysterhmenoKefalaio, amount: 116000, periodos_index: 0, perigrafi: "", });
}

const loadDebitsCredits = async (loan_account_id: number) => {
    let result = await pgrestapi.get("debits_credits?loan_account_id=eq." + loan_account_id + "&status=eq.1"); // Load only approved entries status=1

    let items = result.data as DebitCredit[];
    for (let item of items) {
        switch (item.type) {
            case DebitCreditType.Credit:
                loanAccount.value.eventsList.push({ date: item.date, processed: false, type: LoanAccountEventType.Pistwsh, amount: item.amount as number, perigrafi: item.perigrafi as string, });
                break;

            case DebitCreditType.Installment:
                if (loanAccount.value.loanCapitalRepaymentType !== LoanRepaymentType.Tokoxreolytiko) {
                    if (loanAccount.value.isOmologiako && loanAccount.value.calculateArgies && loanAccount.value.isArgia(item.date)) {
                        let epom = loanAccount.value.EpomenhErgasimh(item.date);

                        if (DateTime.fromISO(epom).month !== DateTime.fromISO(item.date).month || DateTime.fromISO(epom).year !== DateTime.fromISO(item.date).year) {
                            epom = loanAccount.value.ProhgoumenhErgasimh(item.date);
                        }

                        item.date = epom;
                    }

                    loanAccount.value.eventsList.push({ date: item.date, processed: false, type: LoanAccountEventType.Dosh, amount: item.amount as number, perigrafi: item.perigrafi as string, });
                }
                break;

            case DebitCreditType.Exoda:
                loanAccount.value.eventsList.push({ date: item.date, processed: false, type: LoanAccountEventType.Exodo, amount: item.amount as number, perigrafi: item.perigrafi as string, });
                break;

            // case DebitCreditType.Debit:
            //     loanAccount.value.eventsList.push({ date: item.date, processed: false, type: LoanAccountEventType.Xrewsh, amount: item.amount as number, perigrafi: item.perigrafi as string, });
            //     break;
            case DebitCreditType.TokoiTokwn:
                let found = false;
                for (let i = 0; i < loanAccount.value.kathisterimenotokoiArxika.length; ++i) {
                    if (loanAccount.value.kathisterimenotokoiArxika[i].date === item.date) {
                        found = true;
                        loanAccount.value.kathisterimenotokoiArxika[i].amount += item.amount;
                        loanAccount.value.kathisterimenotokoiArxika[i].perigrafi += ' ' + item.perigrafi;
                        break;
                    }
                }
                if (!found)
                    loanAccount.value.kathisterimenotokoiArxika.push(item);

                break;
        }
    }
}

// const FixDate = (date: string): string => {
//     return (date >= (DateTime.now().toISODate() as string) ? DateTime.now().toISODate() as string : date);
// }

// TODO discuss when we should stop normally
const loadInterestRates = async (config_interest_id: number) => {
    /*GET automato_epitokio h oxi*/
    let getautomato = await pgrestapi.get(`config_interest?select=automato_epitokio,interest&id=eq.${config_interest_id}`);
    base_rate.value = getautomato.data[0].interest;
    if (!getautomato.data[0].automato_epitokio) {

    
    console.log('AUTOMATO'+ JSON.stringify(getautomato));
    let nextDate = loanAccount.value.loanStartingDate;
    let dates = [];
    console.log('5555');
    do {
        dates.push(`'${nextDate}'`);
        loanAccount.value.eventsList.push({ type: LoanAccountEventType.InterestChange, date: nextDate, amount: 0, processed: false, perigrafi: "" });
        nextDate = AmountEntryExtra.getNextFrequencyDate(DateTime.fromISO(nextDate), loanAccount.value.interestChangeFrequency, loanAccount.value.interestChangeIsEpeteiako).toISODate() as string;
        console.log('5555 ' + nextDate + ' ' + loanAccount.value.loanStartingDate)
    } while (nextDate <= DateTime.now().toISODate());

    // for (let i = 0; i < loanAccount.value.eventsList.length; i++) {
    //     if (loanAccount.value.eventsList[i].type === LoanAccountEventType.InterestChange) {
    //         loanAccount.value.eventsList[i].date = loanAccount.value.FixDateExtra(loanAccount.value.eventsList[i].date as string, false)[0];
    //     }
    // }

    console.log(JSON.stringify(dates));
    let result = await pgrestapi.get(`interest_rates?select=date:interest_value_date,interest:interest_value&interest_category=eq.${config_interest_id}&order=interest_value_date.asc`);
    loanAccount.value.interestRateList = result.data as any[];
    console.log('555 interests**' + JSON.stringify(loanAccount.value.interestRateList));
    }
    else {
        let nextDate = loanAccount.value.loanStartingDate;
        let dates = [];
        let result = await pgrestapi.get(`interest_rates?select=date:interest_value_date,interest:interest_value&interest_category=eq.${config_interest_id}&interest_value_date=gte.${nextDate}&order=interest_value_date.asc`);
        console.log('AUTOMATO result' + JSON.stringify(result))
        loanAccount.value.interestRateList = result.data as any[];
        for (let i=0;i<loanAccount.value.interestRateList.length;i++) {
            loanAccount.value.eventsList.push({ type: LoanAccountEventType.InterestChange, date: loanAccount.value.interestRateList[i].date, amount: 0, processed: false, perigrafi: "" });
        }
    }
}

const calculateDoseis = () => {
    if (loanAccount.value.loanCapitalRepaymentType !== LoanRepaymentType.Tokoxreolytiko) {
        return;
    }

    // Calculate doseis based on tokoi frequency

    let nextDate = loanAccount.value.loanStartingDate;

    do {
        // console.log('Setting up tokous events - Loop', nextDate)

        if ((AmountEntryExtra.getNextFrequencyDate(DateTime.fromISO(nextDate), loanAccount.value.interestRepaymentFrequency, loanAccount.value.interestRepaymentEpeteiako).toISODate() as string) === DateTime.now().toISODate()) {
            loanAccount.value.isTodayEventEnhmero = true;
        }

        nextDate = loanAccount.value.FixDate(AmountEntryExtra.getNextFrequencyDate(DateTime.fromISO(nextDate), loanAccount.value.interestRepaymentFrequency, loanAccount.value.interestRepaymentEpeteiako).toISODate() as string);
        loanAccount.value.eventsList.push({ type: LoanAccountEventType.Dosh, date: nextDate, amount: 0, processed: false, perigrafi: "" });
    } while (nextDate < DateTime.now().toISODate());
}

const checkAccess = async (loan_account_id: number): Promise<boolean> => {
    let result = await pgrestapi.get("view_mosloanaccounts_view1?id=eq." + loan_account_id);

    if (result.data.length === 0) {
        showError('Δεν βρέθηκε το δάνειο');
        return false;
    }

    // Admin full access
    if (mainStore.loggedUser.user_type === UserType.Administrator) {
        return true;
    }

    if (mainStore.loggedUser.user_type === UserType.Supervisor) {
        if (!result.data[0].portfolio_allowed_users.includes(mainStore.loggedUser.id)) {
            showError('Δεν έχετε πρόσβαση στο δάνειο');
            return false;
        }

        return true;
    }

    console.log(result.data[0].debtor_allowed_users);
    console.log('my id', mainStore.loggedUser.id)
    if (mainStore.loggedUser.user_type === UserType.Agent) {
        if (!result.data[0].debtor_allowed_users.includes(mainStore.loggedUser.id)) {
            showError('Δεν έχετε πρόσβαση στο δάνειο');
            return false;
        }

        return true;
    }

    showError('Δεν έχετε πρόσβαση στο δάνειο');
    return false;
}

const loadLoanAccountData = async (loan_account_id: number) => {
    isLoading.value = true;

    // Stop processing if we don't have access
    if (!(await checkAccess(loan_account_id))) {
        isLoading.value = false;
        return;
    }

    let loanAccountData: LoanAccountEntity = (await pgrestapi.get("loan_accounts?id=eq." + loan_account_id)).data[0] as LoanAccountEntity;
    let loanAgreementDataResult: PGRESTResult = new PGRESTResult();
    let clientDataResult: PGRESTResult = new PGRESTResult();

    loanAgreementDataResult = await pgrestapi.get(`loan_agreement?id=eq.${loanAccountData.loan_agreement_id}`);
    let loan_agreement: LoanAgreement = loanAgreementDataResult.data[0] as LoanAgreement;

    clientDataResult = await pgrestapi.get(`debtor?id=eq.${loan_agreement.debtor_id}`);

    loanAgreementNumber.value = loan_agreement.ar_sumbashs;

    clientName = clientDataResult.data[0].fullname;
    clientAddress = clientDataResult.data[0].address + ' ' + clientDataResult.data[0].address_number + ', ' + clientDataResult.data[0].city + ', ' + clientDataResult.data[0].postcode;
    loanAccount.value.arnitika_epitokia = loanAccountData.arnitika_epitokia as boolean;
    if (loanAccount.value.arnitika_epitokia) arnitika_epitokia.value = 'Ναι'; else arnitika_epitokia.value='Όχι';
    
    // console.log('loanAccountData: ' + JSON.stringify(loanAccountData));
    // console.log('loanAccountData AGREEMENT: ' + JSON.stringify(loanAgreementDataResult));
    if (loanAccountData.hmer_enarkshs !== null && (loanAccountData.hmer_enarkshs as string) !== '') {
        loanAccount.value.loanStartingDate = loanAccountData.hmer_enarkshs as string;
        docStartingDate = loanAccountData.hmer_enarkshs as string;
    } else if (loan_agreement.hmer_sumbashs !== null && loan_agreement.hmer_sumbashs !== '') {
        loanAccount.value.loanStartingDate = loan_agreement.hmer_sumbashs as string;
        docStartingDate = loan_agreement.hmer_sumbashs as string;
    } else {
        throw new Error('Δεν υπάρχει ημερομηνία έναρξης δανείου');
    }
    kathisterimeno_arxika.value = 'Όχι';
    startingDate.value =  loanAccount.value.loanStartingDate;
    if (loanAccountData.kathisterimeno === true) {
        kathisterimeno_arxika.value = "Ναι"
        loanAccount.value.kathisterimenoARXIKA = loanAccountData.kathisterimeno;
        loanAccount.value.kathisterimeno_enim_arx = loanAccountData.kathisterimeno_enim_arx as number;

        if (loanAccountData.kathisterimenodate !== null) {
            loanAccount.value.kathisterimenodate = loanAccountData.kathisterimenodate as string;
            startingDate.value = loanAccount.value.kathisterimenodate;
        }
        else if (loanAccount.value.loanStartingDate !== null) {
            loanAccount.value.kathisterimenodate = loanAccount.value.loanStartingDate;
            startingDate.value = loanAccount.value.kathisterimenodate;
        }
        else if (loan_agreement.hmer_sumbashs !== null) {
            loanAccount.value.kathisterimenodate = loan_agreement.hmer_sumbashs as string;
        }
        else {
            throw new Error('Δεν υπάρχει ημερομηνία έναρξης καθυστέρημενου δανείου');
        }

    }
    else { loanAccount.value.kathisterimenoARXIKA = false; }
    

    if (loanAccountData.kathisterimenokef !== null) {
        loanAccount.value.kathisterimenokef = loanAccountData.kathisterimenokef as number;
    }
    if (loanAccountData.kathisterimenotok !== null) {
        loanAccount.value.kathisterimenotok = loanAccountData.kathisterimenotok as number;
    }


    if (loanAccountData.nea_hmer_lukshs !== null)
        loanAccount.value.loanEndingDate = loanAccountData.nea_hmer_lukshs as string;
    else if (loanAccountData.arxikh_hmer_lukshs !==null)
        loanAccount.value.loanEndingDate = loanAccountData.arxikh_hmer_lukshs as string;
    else if (loan_agreement.arxikh_hmer_lukshs !== null)
        loanAccount.value.loanEndingDate = loan_agreement.arxikh_hmer_lukshs as string;
    else {
        throw new Error('Δεν υπάρχει ημερομηνία λήξης δανείου');
    }



    if (loanAccountData.synoliko_poso !== null) {
        loanAccount.value.loanCapitalAmount = loanAccountData.synoliko_poso as number;
    } else if (loan_agreement.synol_poso_sumbashs !== null) {
        loanAccount.value.loanCapitalAmount = loan_agreement.synol_poso_sumbashs as number;
    }
    synolikoPoso.value=loanAccount.value.loanCapitalAmount;
    if (loanAccountData.loan_capital_repayment_type !== null) {
        loanAccount.value.loanCapitalRepaymentType = loanAccountData.loan_capital_repayment_type as number;
       switch(loanAccount.value.loanCapitalRepaymentType){
                case LoanRepaymentType.XreolytikoIsoposo:
                loanCapitalRepaymentType.value =t('payment_type_list.xreolitiko') ;
                        break;
                case LoanRepaymentType.Tokoxreolytiko:
                loanCapitalRepaymentType.value =t('payment_type_list.tokoxreolitiko') ;
                        break;
                case LoanRepaymentType.XreolytikoAnisoposo:
                loanCapitalRepaymentType.value =t('payment_type_list.balloon') ;
                        break;
       }
        

    } else if (loan_agreement.tropos_apopl !== null) {
        if (loan_agreement.tropos_apopl === 'xreolitiko') {
            loanAccount.value.loanCapitalRepaymentType = LoanRepaymentType.XreolytikoIsoposo;/*δεν ξερω το νουμερο που εβαλες για το χρεολυτικο και πρεπει να μπουν τα σωστα για ολα*/ // TODO: Check LoanRepaymentType -- ti ginetai me isoposa/anisoposa akribws
            loanCapitalRepaymentType.value = t('payment_type_list.xreolitiko') ;
        } else if (loan_agreement.tropos_apopl === 'tokoxreolitiko') {
            loanAccount.value.loanCapitalRepaymentType = LoanRepaymentType.Tokoxreolytiko;/*δεν ξερω το νουμερο που εβαλες για το τοκοχρεολυτικο και πρεπει να μπουν τα σωστα για ολα*/
            loanCapitalRepaymentType.value = t('payment_type_list.tokoxreolitiko') ;
        } else if (loan_agreement.tropos_apopl === 'balloon') {
            loanAccount.value.loanCapitalRepaymentType = LoanRepaymentType.XreolytikoAnisoposo;/*δεν ξερω το νουμερο που εβαλες για το τοκοχρεολυτικο και πρεπει να μπουν τα σωστα για ολα*/
            loanCapitalRepaymentType.value = t('payment_type_list.balloon') ;
        }
    }
    if (loanAccountData.loan_capital_repayment_frequency !== null) {
        loanAccount.value.loanCapitalRepaymentFrequency = loanAccountData.loan_capital_repayment_frequency as number;
    } else if (loan_agreement.program_apopl_kefal !== null) {
        loanAccount.value.loanCapitalRepaymentFrequency = loan_agreement.program_apopl_kefal as number;
    }

    loanAccount.value.loanRepaymentEpeteiako = loanAccountData.loan_capital_repayment_epeteiako as boolean;

    if (loanAccountData.loan_interest_repayment_frequency !== null) {
        loanAccount.value.interestRepaymentFrequency = loanAccountData.loan_interest_repayment_frequency as number;
    } else if (loan_agreement.program_apopl_tokon !== null) {
        loanAccount.value.interestRepaymentFrequency = loan_agreement.program_apopl_tokon as number;
    }
    let config_product = await pgrestapi.get("config_loan_account_product?id=eq." + loanAccountData.product);

    if (config_product.data.length === 0) {
        throw new Error("Δεν έχει οριστεί τύπος δανειακού λογαριασμού.")
        return;
    }
    loan_product.value = config_product.data[0].product;
    loanAccount.value.calculateArgies = (config_product.data[0].calculate_argies);
    if(loanAccount.value.calculateArgies) calculateArgies.value='Ναι'; else calculateArgies.value='Όχι';
    loanAccount.value.isOmologiako = (config_product.data[0].omologiako);
    loanAccount.value.isAllhloxreo = (config_product.data[0].allhloxreo);
    
    loanAccount.value.interestRepaymentEpeteiako = loanAccountData.loan_interest_repayment_epeteiako as boolean;
    if (loanAccount.value.interestRepaymentEpeteiako){interestRepaymentEpeteiako.value='Επετειακός'}
    else {interestRepaymentEpeteiako.value='Ημερολογιακός'}
    loanAccountNumber.value = loanAccountData.ar_trexontos_logariasmou as string;

    loanAccount.value.interestChangeFrequency = loanAccountData.loan_interest_change_frequency as number;
    loanAccount.value.interestChangeIsEpeteiako = loanAccountData.loan_interest_change_epeteiako as boolean;
    if(loanAccount.value.interestChangeIsEpeteiako) interestChangeIsEpeteiako.value='Επετειακά'; else interestChangeIsEpeteiako.value='Ημερολογιακά';
    loanAccount.value.perithorio = loanAccountData.perithorio as number;
    loanAccount.value.eisfora = loanAccountData.eisfora_n128_1975 as number;

    if (loan_agreement.vasi_ektokismou === null) {
        throw new Error('Δεν υπάρχει βάση εκτοκισμού στη σύμβαση');
    }

    loanAccount.value.bash_ektokismou = loan_agreement.vasi_ektokismou as number;
    loanAccount.value.pososto_tokwn_yperhmerias = loanAccountData.epitok_uperhm as number;

    if (loanAccount.value.loanCapitalRepaymentType === LoanRepaymentType.Tokoxreolytiko) {
        loanAccount.value.interestRepaymentEpeteiako = true;
    }

    console.log(loanAccountData.loan_interest_change_frequency, loanAccountData.loan_interest_change_epeteiako, loanAccountData.kathgor_epitok_bashs)
    if (loanAccountData.loan_interest_change_frequency !== null && loanAccountData.loan_interest_change_epeteiako !== null && loanAccountData.kathgor_epitok_bashs !== undefined) {
        console.log('loading interest rates')
        await loadInterestRates(loanAccountData.kathgor_epitok_bashs);
    }

    //return;

    //vasi_ektokismou
    //kathgor_epitok_bashs
    //perithorio
    //epitok_uperhm
    //eisfora_n128_1975
    //plithos_doseon_kefal

    // await loadArgies();
    await loadDebitsCredits(loan_account_id);
    await calculateDoseis();

    loanAccount.value.Initialize();

    loanAccount.value.ProcessEvents();

    isLoading.value = false;
}

const doPDF = async () => {
    try {
        const pdfDoc = await PDFDocument.create();
        pdfDoc.registerFontkit(fontkit);
        let page = pdfDoc.addPage([595.28, 841.89]);
        const url = 'https://pdf-lib.js.org/assets/ubuntu/Ubuntu-R.ttf'
        const urlBold = 'https://pdf-lib.js.org/assets/ubuntu/Ubuntu-B.ttf'
        const fontBytes = await fetch(url).then((res) => res.arrayBuffer())
        const fontBytesBold = await fetch(urlBold).then((res) => res.arrayBuffer())
        const ubuntuFont = await pdfDoc.embedFont(fontBytes);
        const ubuntuFontBold = await pdfDoc.embedFont(fontBytesBold);
        const fontSize = 10;
        let selidaYpoloipo = 0;
        let selidaDate = '';
        let maxLineAddress = 30;

        let textX = 50;
        let textY = 718;
        let innerX = 65;
        let lineHeight = 18;
        let lineThickness = 2;
        let initialPageY = 758;
        let currentDate = DateTime.now().toFormat('dd/MM/yyyy');
        let j = 0;
        let positionStart = 0;
        let positionEnd = maxLineAddress;
        docEndingDate = currentDate;
        page.drawText('Αθήνα, ' + currentDate, { font: ubuntuFont, x: textX, y: textY, size: fontSize });
        /* Η διευθυνση και η επωνυμια πρεπει καπως να περιοριστουν στη σελιδα clientName*/
        if (clientName.length <= maxLineAddress) {
            page.drawText(clientName, { font: ubuntuFont, x: textX + 270, y: textY, size: fontSize });
        }
        else {
            for (j = 0; j < 3; j++) {

                positionEnd = clientName.substring(positionEnd, positionEnd + maxLineAddress).lastIndexOf(' ');
                if (positionEnd < 0) { positionEnd = maxLineAddress * (j + 1) };
                page.drawText(clientName.substring(positionStart, positionEnd), { font: ubuntuFont, x: textX + 270, y: textY - j * lineHeight, size: fontSize });
                positionStart = positionEnd;

            }
        }
        textY -= lineHeight * (j + 1);
        positionStart = 0;
        positionEnd = maxLineAddress;
        if (clientAddress.length <= maxLineAddress) {
            page.drawText(clientAddress, { font: ubuntuFont, x: textX + 270, y: textY, size: fontSize });
        }
        else {
            for (j = 0; j < 3; j++) {

                positionEnd = clientAddress.substring(positionEnd, positionEnd + maxLineAddress).lastIndexOf(' ');
                if (positionEnd < 0) { positionEnd = maxLineAddress };
                page.drawText(clientAddress.substring(j * maxLineAddress, (j + 1) * maxLineAddress), { font: ubuntuFont, x: textX + 270, y: textY - j * lineHeight, size: fontSize });
                positionStart = positionEnd;

            }
        }
        textY -= lineHeight - 20;
        page.drawText('Παρατίθενται ακολούθως κινήσεις του: ', { font: ubuntuFont, x: textX, y: textY, size: fontSize }); textY -= lineHeight;
        page.drawText('Δανειακού Λογαριασμού: ' + loanAccountNumber.value, { font: ubuntuFont, x: textX, y: textY, size: fontSize }); textY -= lineHeight;
        page.drawText('Περίοδος από: ' + docStartingDate + ' έως ' + docEndingDate, { font: ubuntuFont, x: textX, y: textY, size: fontSize }); textY -= lineHeight;
        page.drawText('Αρ. Σύμβασης ' + loanAgreementNumber.value, { font: ubuntuFont, x: textX, y: textY, size: fontSize }); textY -= lineHeight;
        textY -= lineHeight;

        page.drawText('Ημερομηνία', { font: ubuntuFontBold, x: innerX, y: textY, size: 9 });
        page.drawText('Χρεώσεις', { font: ubuntuFontBold, x: innerX + 100, y: textY, size: 9 });
        page.drawText('Πιστώσεις', { font: ubuntuFontBold, x: innerX + 200, y: textY, size: 9 });
        page.drawText('Λεπτομερής περιγραφή κίνησης', { font: ubuntuFontBold, x: innerX + 300, y: textY, size: 9 });
        page.drawLine({ start: { x: 25, y: textY - 8 }, end: { x: 595.28 - 25, y: textY - 8 }, thickness: lineThickness, opacity: 0.75, });
        textY = textY - lineHeight - lineThickness;
        let initialDate = loanAccount.value.listaKinhsewn[0].date;
        if (fromDate.value === undefined || fromDate.value ==='') {fromDate.value = loanAccount.value.listaKinhsewn[0].date};
        if (toDate.value === undefined || toDate.value ==='') {toDate.value = DateTime.now().toISODate()};
        for (let i = 0; i < loanAccount.value.listaKinhsewn.length - 1; i++) {
            if(loanAccount.value.listaKinhsewn[i].date < fromDate.value || loanAccount.value.listaKinhsewn[i].date> toDate.value) continue;
            //length - 1 για να μην παρει το τελικο υπολοιπο
            //loanAccount.value.listaKinhsewn
            if (textY < 50) {
                page.drawText(selidaDate, { font: ubuntuFont, x: innerX, y: textY, size: 9 });
                page.drawText(formatEuroCurrency(selidaYpoloipo), { font: ubuntuFont, x: innerX + 100, y: textY, size: 9 });
                page.drawText('Μεταφορά οφειλής σε επόμενη σελίδα', { font: ubuntuFont, x: innerX + 300, y: textY, size: 9 });
                page = pdfDoc.addPage([595.28, 841.89]);
                textY = initialPageY;
                page.drawText('Ημερομηνία', { font: ubuntuFontBold, x: innerX, y: textY, size: 9 });
                page.drawText('Χρεώσεις', { font: ubuntuFontBold, x: innerX + 100, y: textY, size: 9 });
                page.drawText('Πιστώσεις', { font: ubuntuFontBold, x: innerX + 200, y: textY, size: 9 });
                page.drawText('Λεπτομερής περιγραφή κίνησης', { font: ubuntuFontBold, x: innerX + 300, y: textY, size: 9 });
                page.drawLine({ start: { x: 25, y: textY - 8 }, end: { x: 595.28 - 25, y: textY - 8 }, thickness: lineThickness, opacity: 0.75, });
                textY = initialPageY - lineHeight - lineThickness;
                page.drawText(selidaDate, { font: ubuntuFont, x: innerX, y: textY, size: 9 });
                page.drawText(formatEuroCurrency(selidaYpoloipo), { font: ubuntuFont, x: innerX + 100, y: textY, size: 9 });
                page.drawText('Μεταφορά οφειλής από προηγούμενη σελίδα', { font: ubuntuFont, x: innerX + 300, y: textY, size: 9 });
                textY -= lineHeight;
            }
            selidaDate = loanAccount.value.listaKinhsewn[i].date;

            page.drawText(loanAccount.value.listaKinhsewn[i].date, { font: ubuntuFont, x: innerX, y: textY, size: 9 });
            if (loanAccount.value.listaKinhsewn[i].xrewsh > 0) {
                page.drawText(formatEuroCurrency(loanAccount.value.listaKinhsewn[i].xrewsh), { font: ubuntuFont, x: innerX + 100, y: textY, size: 9 });
                selidaYpoloipo += loanAccount.value.listaKinhsewn[i].xrewsh;
            }
            else if (loanAccount.value.listaKinhsewn[i].pistwsh > 0) {
                page.drawText(formatEuroCurrency(loanAccount.value.listaKinhsewn[i].pistwsh), { font: ubuntuFont, x: innerX + 200, y: textY, size: 9 });
                selidaYpoloipo -= loanAccount.value.listaKinhsewn[i].pistwsh;
            }

            page.drawText(loanAccount.value.listaKinhsewn[i].perigrafh.replace('Καθυστερημένου', 'Καθυστ.'), { font: ubuntuFont, x: innerX + 300, y: textY, size: 9 });
            textY -= lineHeight;
        }

        if (textY >= 50) {
            page.drawText(selidaDate, { font: ubuntuFont, x: innerX, y: textY, size: 9 });
            page.drawText(formatEuroCurrency(selidaYpoloipo), { font: ubuntuFont, x: innerX + 100, y: textY, size: 9 });
            page.drawText('Υπόλοιπο οφειλής', { font: ubuntuFont, x: innerX + 300, y: textY, size: 9 });
            textY -= lineHeight; textY -= lineHeight;
            page.drawText('πλέον δεδουλευμένων τόκων έως ' + currentDate + ' συνολικού ποσού ' + formatEuroCurrency(loanAccount.value.latestDedoul) + '.', { font: ubuntuFont, x: innerX, y: textY, size: 9 });
        }
        else {
            page = pdfDoc.addPage([595.28, 841.89]);
            textY = initialPageY;
            page.drawText('πλέον δεδουλευμένων τόκων έως ' + currentDate + ' συνολικού ποσού ' + formatEuroCurrency(loanAccount.value.latestDedoul) + '.', { font: ubuntuFont, x: innerX, y: textY, size: 9 });
        }

        let pdfData = await pdfDoc.saveAsBase64({ dataUri: true });

        let blob = await (await fetch(pdfData)).blob();
        const blobUrl = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = blobUrl;
        link.download = `LoanCalculations_${DateTime.now().toISODate()}_${loanAccountNumber.value}.pdf`;
        link.click();
    } catch (ex) {
        console.log(ex);
    }
}

// TODO: Check if user has access to loan account
onMounted(async () => {
    // console.log('onMounted - got loan_account_id: ' + route.params.loan_account_id);

    try {
        await loadArgies();
        await loadLoanAccountData(parseInt(route.params.loan_account_id as string));
    } catch (ex: any) {
        isLoading.value = false;
        showError(ex.message, 2500);
    }
});

const formatEuroCurrency = (value: number) => {
    return euroFormat.format(value);
}

const backToLoanAccountsView = () => {
    router.push({ name: 'Loan Accounts' });
}

const loadArgies = async () => {
    let result = await pgrestapi.get("config_argies?select=hmer_argias&order=hmer_argias.asc");
    let items = result.data as any[];

    loanAccount.value.argiesList = [];

    for (let item of items) {
        loanAccount.value.argiesList.push(item.hmer_argias);
    }
}

</script>

<template>
    <div class="content-wrapper" v-loading="isLoading">
        <!-- <a download="PDF Title" :href="pdfData">Download PDF document</a> -->
        <el-button style=" background-color: lightgrey; color: black" @click="backToLoanAccountsView">&#x2190; To Loan
            Accounts List</el-button>
        <p />
        <el-row :gutter="10">
            <el-col :span="1"> <span class="top5">{{t('ektokismos.apodate')}}</span></el-col>
            <el-col :span="3">
               
                <el-date-picker
          format="DD-MM-YYYY"
          value-format="YYYY-MM-DD"
          v-model="fromDate"
          type="date"
          placeholder="From date"
          style="width: 100%; border-bottom: 3px solid #e3e3e3"
        />
    </el-col>
    <el-col :span="1"> <span class="top5">{{t('ektokismos.todate')}}</span></el-col>
<el-col :span="3">
       
        <el-date-picker
          format="DD-MM-YYYY"
          value-format="YYYY-MM-DD"
          v-model="toDate"
          type="date"
          placeholder="To date"
          style="width: 100%; border-bottom: 3px solid #e3e3e3"
        />
    </el-col><el-col :span="16">
        <el-button style="float: right; background-color: lightgrey; color: black" @click="doPDF">Download PDF</el-button>
    </el-col>
    </el-row>
       
      
        <p />
        
        <table id="ektokismos_info">
            <thead>
                <tr><td colspan="4" class="infotitleheader" >Πληροφορίες υπολογισμών</td></tr>
            </thead>
            <tr>
                <td class="infotitle">Δανειακός Λογαριασμός:</td>
                <td>{{ loanAccountNumber }}</td>
                <td class="infotitle">Καθυστερημένο αρχικά:</td>
                <td>{{ kathisterimeno_arxika }}</td>
            </tr>
            <tr>
                <td class="infotitle">Σύμβαση:</td>
                <td>{{ loanAgreementNumber }}</td>
                <td class="infotitle">Συνολικό ποσό</td>
                <td>{{formatEuroCurrency(synolikoPoso)}}</td>
            </tr>
            <tr>
                <td class="infotitle">Προϊόν</td>
                <td>{{loan_product}}</td>
                <td class="infotitle">Έναρξη</td>
                <td>{{startingDate}}</td>
                
            </tr>
            <tr>
                <td class="infotitle">Δόσεις:</td>
                <td>{{loanAccount.loanCapitalRepaymentFrequency}}</td>
                <td class="infotitle">Capital Repayment type:</td>
                <td>{{ loanCapitalRepaymentType }}</td>
           </tr> 
           <tr>
                <td class="infotitle">Δόσεις κεφαλαίου</td>
                <td>{{ interestRepaymentEpeteiako }}</td>
                <td class="infotitle">Αλλαγή επιτοκίου</td>
                <td>{{ interestChangeIsEpeteiako }}</td>
                
            </tr>
            <tr>
                <td class="infotitle">Συχνότητα εκτοκισμού Κεφαλαίου</td>
                <td>{{loanAccount.interestRepaymentFrequency}}</td>
                <td class="infotitle">Συχνότητα αλλαγής επιτοκίου</td>
                <td>{{ loanAccount.interestChangeFrequency }}</td>
                
            </tr>
           
            <tr>
                <td class="infotitle">Αργίες</td>
                <td>{{ calculateArgies }}</td>
                <td class="infotitle">Αρνητικά επιτόκια</td>
                <td>{{arnitika_epitokia}}</td>
            </tr>
            <tr>
                <td class="infotitle">Επιτόκιο βάσης</td>
                <td>{{base_rate}}</td>
                <td class="infotitle">Περιθώριο</td>
                <td>{{ loanAccount.perithorio }}%</td>
            </tr>
            <tr>
                <td class="infotitle">Υπερημερία</td>
                <td>{{loanAccount.pososto_tokwn_yperhmerias}}%</td>
                <td class="infotitle">Εισφορά N128/75</td>
                <td>{{ loanAccount.eisfora }}%</td>
            </tr>
        </table>
        <p />
        <p />

        <b>Ενήμερο Κεφάλαιο</b>
        <p />
        <el-table :data="loanAccount.enhmeroKefalaioList" stripe border table-layout="fixed" size="small">            
            <el-table-column prop="startDate" label="Από" />
            <el-table-column prop="endDate" label="Έως" />            
            <el-table-column label="Ημέρες">
                <template #default="scope">
                    {{ DateTime.fromISO(scope.row.endDate).diff(DateTime.fromISO(scope.row.startDate), 'days').days }}
                </template>
            </el-table-column>
            <el-table-column prop="valeurDate" label="Valeur" />
            <!-- <el-table-column label="ΑΡΓΙΕΣ">
                <template #default="scope">
                    {{ 
                    scope.row.valeurDate !==scope.row.endDate ? "ΑΡΓΙΑ" : "-"
                    }}
                </template>
            </el-table-column> -->
            <el-table-column label="Επιτόκιο">
                <template #default="scope">
                    {{ Number(scope.row.interest / 100).toLocaleString('el', { style: 'percent', minimumFractionDigits: 4 })
                    }}
                </template>
            </el-table-column>
            <el-table-column label="Περιθώριο">
                <template #default="scope">
                    {{ Number(scope.row.perithorio / 100).toLocaleString('el', {
                        style: 'percent', minimumFractionDigits: 2
                    }) }}
                </template>
            </el-table-column>
            <el-table-column label="Εισφορά">
                <template #default="scope">
                    {{ Number(scope.row.eisfora / 100).toLocaleString('el', { style: 'percent', minimumFractionDigits: 2 })
                    }}
                </template>
            </el-table-column>
            <!-- <el-table-column label="Ποσοστό Υπερημερίας">
                <template #default="scope">
                    {{ Number(scope.row.pososto_tokwn_yperhmerias / 100).toLocaleString('el', { style: 'percent', minimumFractionDigits: 2 })
                    }}
                </template>
            </el-table-column> -->
            <el-table-column label="Ενήμερο Κεφάλαιο">
                <template #default="scope">
                    {{ formatEuroCurrency(scope.row.amount) }}
                </template>
            </el-table-column>
            <el-table-column label="Δόση">
                <template #default="scope">
                    <span v-if="scope.row.doshAmount !== 0">{{ formatEuroCurrency(scope.row.doshAmount) }}</span>
                </template>
            </el-table-column>
            <el-table-column label="Πίστωση">
                <template #default="scope">
                    <span v-if="scope.row.usedPistwsh && scope.row.usedPistwsh !== 0">{{
                        formatEuroCurrency(scope.row.usedPistwsh) }}</span>
                </template>
            </el-table-column>
            <el-table-column label="Δεδουλευμένοι Τόκοι">
                <template #default="scope">
                    {{ formatEuroCurrency(scope.row.dedouleymenoi_tokoi) }}
                </template>
            </el-table-column>
            <el-table-column label="Τόκοι Περιόδου">
                <template #default="scope">
                    {{ formatEuroCurrency(scope.row.tokoi_periodou) }}
                </template>
            </el-table-column>
            <el-table-column label="Ofeilh Index">
                <template #default="scope">
                    <span v-if="scope.row.ofeilhIndex !== 0">{{ scope.row.ofeilhIndex }}</span>
                </template>
            </el-table-column>
            <!--<el-table-column label="Δόση Κεφαλαίου">
                <template #default="scope">
                    {{ formatEuroCurrency(scope.row.dosh) }}
                </template>
            </el-table-column>
            <el-table-column label="Πίστωση έναντι ενήμ. κεφαλαίου">
                <template #default="scope">
                    {{ formatEuroCurrency(scope.row.pistwsh_enanti_enhm) }}
                </template>
            </el-table-column>
            
             -->
            <el-table-column prop="periodos_index" label="periodos" />
        </el-table>

        <p />
        <b>Καθυστερημένο Κεφάλαιο</b>
        <p />
        <el-table :data="loanAccount.kathysterhmenoKefalaio" stripe border table-layout="fixed" size="small">
            <el-table-column prop="startDate" label="Από" />
            <el-table-column prop="endDate" label="Έως">
                <template #default="scope">
                    <b v-if="scope.row.isEndDate">
                        {{ scope.row.endDate }}
                    </b>
                </template>
            </el-table-column>
            <el-table-column label="Ημέρες">
                <template #default="scope">
                    {{ DateTime.fromISO(scope.row.endDate).diff(DateTime.fromISO(scope.row.startDate), 'days').days }}
                </template>
            </el-table-column>
            <el-table-column prop="valeurDate" label="Valeur">
                <template #default="scope">
                    <b v-if="scope.row.isEndDate">
                        {{ scope.row.valeurDate }}
                    </b>
                </template>
            </el-table-column>
            <!-- <el-table-column label="ΑΡΓΙΕΣ">
            <template #default="scope">
                    {{ 
                    scope.row.valeurDate !==scope.row.endDate ? "ΑΡΓΙΑ" : "-"
                    }}
                </template>
            </el-table-column> -->
            <el-table-column label="Επιτόκιο">
                <template #default="scope">
                    {{ Number(scope.row.interest / 100).toLocaleString('el', { style: 'percent', minimumFractionDigits: 4 })
                    }}
                </template>
            </el-table-column>
            <el-table-column label="Περιθώριο">
                <template #default="scope">
                    {{ Number(scope.row.perithorio / 100).toLocaleString('el', {
                        style: 'percent', minimumFractionDigits: 2
                    }) }}
                </template>
            </el-table-column>
            <el-table-column label="Εισφορά">
                <template #default="scope">
                    {{ Number(scope.row.eisfora / 100).toLocaleString('el', { style: 'percent', minimumFractionDigits: 2 })
                    }}
                </template>
            </el-table-column>
            <el-table-column label="Ποσοστό Υπερημερίας">
                <template #default="scope">
                    {{ Number(scope.row.pososto_tokwn_yperhmerias / 100).toLocaleString('el', {
                        style: 'percent',
                        minimumFractionDigits: 2
                    })
                    }}
                </template>
            </el-table-column>
            <el-table-column label="Καθυστερημένο Κεφάλαιο">
                <template #default="scope">
                    {{ formatEuroCurrency(scope.row.amount) }}
                </template>
            </el-table-column>
            <el-table-column label="Πίστωση">
                <template #default="scope">
                    <span v-if="scope.row.usedPistwsh && scope.row.usedPistwsh !== 0">{{
                        formatEuroCurrency(scope.row.usedPistwsh) }}</span>
                </template>
            </el-table-column>
            <el-table-column label="Δεδουλευμένοι Τόκοι">
                <template #default="scope">
                    {{ formatEuroCurrency(scope.row.dedouleymenoi_tokoi) }}
                </template>
            </el-table-column>
            <el-table-column label="Τόκοι Περιόδου">
                <template #default="scope">
                    {{ formatEuroCurrency(scope.row.tokoi_periodou) }}
                </template>
            </el-table-column>
            <el-table-column label="Ofeilh Index">
                <template #default="scope">
                    <span v-if="scope.row.ofeilhIndex !== 0">{{ scope.row.ofeilhIndex }}</span>
                </template>
            </el-table-column>
            <el-table-column prop="periodos_index" label="periodos" />
            <!-- <el-table-column prop="dummytext" label="dummy" /> -->
        </el-table>

        <p />
        <b>Καθυστερημένοι Τόκοι</b>
        <p />
        <el-table :data="loanAccount.kathysterhmenoiTokoi" stripe border table-layout="fixed" size="small">
            <el-table-column prop="startDate" label="Από" />
            <el-table-column prop="endDate" label="Έως" />


            <el-table-column label="Ημέρες">
                <template #default="scope">
                    {{ DateTime.fromISO(scope.row.endDate).diff(DateTime.fromISO(scope.row.startDate), 'days').days }}
                </template>
            </el-table-column>
            <el-table-column prop="valeurDate" label="Valeur" />
            <!-- <el-table-column label="ΑΡΓΙΕΣ">
            <template #default="scope">
                    {{ 
                    scope.row.valeurDate !==scope.row.endDate ? "ΑΡΓΙΑ" : "-"
                    }}
                </template>
            </el-table-column> -->
            <el-table-column label="Επιτόκιο">
                <template #default="scope">
                    {{ Number(scope.row.interest / 100).toLocaleString('el', { style: 'percent', minimumFractionDigits: 4 })
                    }}
                </template>
            </el-table-column>
            <el-table-column label="Περιθώριο">
                <template #default="scope">
                    {{ Number(scope.row.perithorio / 100).toLocaleString('el', {
                        style: 'percent', minimumFractionDigits: 2
                    }) }}
                </template>
            </el-table-column>
            <el-table-column label="Εισφορά">
                <template #default="scope">
                    {{ Number(scope.row.eisfora / 100).toLocaleString('el', { style: 'percent', minimumFractionDigits: 2 })
                    }}
                </template>
            </el-table-column>
            <el-table-column label="Ποσοστό Υπερημερίας">
                <template #default="scope">
                    {{ Number(scope.row.pososto_tokwn_yperhmerias / 100).toLocaleString('el', {
                        style: 'percent',
                        minimumFractionDigits: 2
                    })
                    }}
                </template>
            </el-table-column>
            <el-table-column label="Καθυστερημένοι τόκοι">
                <template #default="scope">
                    {{ formatEuroCurrency(scope.row.amount) }}
                </template>
            </el-table-column>
            <el-table-column label="Πίστωση">
                <template #default="scope">
                    <span v-if="scope.row.usedPistwsh && scope.row.usedPistwsh !== 0">{{
                        formatEuroCurrency(scope.row.usedPistwsh) }}</span>
                </template>
            </el-table-column>
            <el-table-column label="Δεδουλευμένοι Τόκοι">
                <template #default="scope">
                    {{ formatEuroCurrency(scope.row.dedouleymenoi_tokoi) }}
                </template>
            </el-table-column>
            <el-table-column label="Τόκοι Περιόδου">
                <template #default="scope">
                    {{ formatEuroCurrency(scope.row.tokoi_periodou) }}
                </template>
            </el-table-column>
            <el-table-column label="Ofeilh Index">
                <template #default="scope">
                    <span v-if="scope.row.ofeilhIndex !== 0">{{ scope.row.ofeilhIndex }}</span>
                </template>
            </el-table-column>

            <el-table-column prop="periodos_index" label="periodos" />
            <!-- <el-table-column prop="dummytext" label="dummy" /> -->
        </el-table>

        <p />
        <b>Κινήσεις</b>
        <p />
        <el-table :data="loanAccount.listaKinhsewn" stripe border table-layout="fixed" size="small">
            <el-table-column prop="date" label="Ημερονηνία">
                <template #default="scope">
                    {{ DateTime.fromISO(scope.row.date).toFormat('dd/MM/yyyy') }}
                </template>
            </el-table-column>
            <el-table-column label="Χρεώσεις">
                <template #default="scope">
                    <!-- {{ scope.row.xrewsh }} -->
                    <span v-if="scope.row.xrewsh && scope.row.xrewsh !== 0">{{ formatEuroCurrency(scope.row.xrewsh)
                    }}</span>
                </template>
            </el-table-column>
            <el-table-column label="Πιστώσεις">
                <template #default="scope">
                    <span v-if="scope.row.pistwsh && scope.row.pistwsh !== 0">{{ formatEuroCurrency(scope.row.pistwsh)
                    }}</span>
                </template>
            </el-table-column>
            <el-table-column prop="perigrafh" label="Λεπτομερής περιγραφή κίνησης" />
        </el-table>
        πλέον δεδουλευμένων τόκων έως {{ DateTime.now().toFormat('dd/MM/yyyy') }} συνολικού ποσού {{
            formatEuroCurrency(loanAccount.latestDedoul) }} EUR.
</div></template>

<style scoped></style>