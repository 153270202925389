<script lang="ts" setup>
import { ref, watch, onMounted } from "vue";
import { useRouter } from "vue-router";
import { usePGRESTAPIStore } from "../store/pgrest_api";
import PGRESTResult from "../classes/PGRESTResult";
import { Avatar, Bell, Edit, Delete, Document, Notebook, Plus, RefreshLeft, DataLine, Watch, CircleCheck, CircleClose, Lock, View } from "@element-plus/icons-vue";
import LoanAgreementDetails from "../components/LoanAgreementDetails.vue";
import { ElMessage } from "element-plus";
import { storeToRefs } from "pinia";
import { useMainStore } from "../store/main";
import AddLoanAgreement from "../components/AddLoanAgreement.vue";
import UserType from "../enums/UserType";
import { useSort } from "../composables/sort";
import { useI18n } from "vue-i18n";
import LoanAgreement from "../classes/DB_Entities/LoanAgreement";
import { useGenericMethodsVariables } from "../composables/genericMethodsVariables";
import DocumentsList from "../components/DocumentsList.vue";
import EntityType from "../enums/EntityType";
import DocumentFolders from "../enums/DocumentFolders";

let product_type = ref<any>();
const { showError } = useGenericMethodsVariables();
const { entityApprove } = useGenericMethodsVariables();
const { locale } = useI18n({ useScope: "global" });
const { t } = useI18n({ useScope: "global" });
const products = ref<any>([]);
const router = useRouter();
const pgrestapi = usePGRESTAPIStore();
const mainStore = useMainStore();
const { onSortChange, sortColumn, sortOrder } = useSort();
const { globalPortfolioID } = storeToRefs(mainStore);
const { globalDebtorID } = storeToRefs(mainStore);
const { globalLoanAgreementID } = storeToRefs(mainStore);
const statusOptions = [
  {
    value: "denounced",
    label: t("loan_agreement.denounced"),
  },
  {
    value: "non_performing",
    label: t("loan_agreement.non_performing"),
  },
  {
    value: "performing",
    label: t("loan_agreement.performing"),
  },
];
let filterPending = ref(false);
let dataList = ref([]);
let dataCount = ref(0);
let isLoading = ref(false);
let lastPage = ref(0);
let detailsVisible = ref(false);
let selectedRow = ref(0);
let activeLang = ref<string>("en");
const DeleteDialogVisible = ref(false);
let DeleteSelectedRow = ref(0);
let deleteVisible = ref(false);
let ΑddLoanAgreementVisible = ref(false);
let filterContractNumber = ref<string>("");
let filterFullName = ref<string>("");
let filterTaxRegistration = ref<string>("");
let filterProduct = ref<string>("");
let filterStatus = ref<string>("");
let timer = ref<any>(null);
let filterPendingNew = ref(false);
let filterHideRejected = ref(true);
let view_only = ref(false);

let showDocumentsList = ref(false);
let selectedDocumentEntity = ref<any>(undefined);

watch(globalPortfolioID, async () => {
  if (mainStore.loggedUser.username !== '') // Do not load if user logged out (changes the value of globalLoanAgreementID to "")
    await loadPage(1);
});

watch(globalDebtorID, async () => {
  if (mainStore.loggedUser.username !== '') // Do not load if user logged out (changes the value of globalLoanAgreementID to "")
    await loadPage(1);
});

watch(globalLoanAgreementID, async () => {
  if (mainStore.loggedUser.username !== '') // Do not load if user logged out (changes the value of globalLoanAgreementID to "")
    await loadPage(1);
});

watch(locale, async () => {
  await loadProducts();
  await loadPage(1);
});

const handleClickAdd = () => {
  ΑddLoanAgreementVisible.value = true;
};

const handleClick = (row: any) => {
  view_only.value = !isEditable(row);
  selectedRow.value = row.id;
  detailsVisible.value = true;
};

const deleteHandleClick = (row_id: number) => {
  DeleteSelectedRow.value = row_id;
  deleteVisible.value = true;
  DeleteLoanAgreement(row_id);
};

const onAdd = async () => {
  ElMessage({
    showClose: true,
    message: "Loan agreement added Successfully",
    type: "success",
    duration: 1000,
  });

  ΑddLoanAgreementVisible.value = false;

  loadPage(lastPage.value);
};

const DeleteLoanAgreement = async (row_id: number) => {
  let result: PGRESTResult = new PGRESTResult();

  let id = JSON.stringify(row_id);

  result = await pgrestapi.delete_data("la_liksiario?loan_agreement_id=eq." + id);
  if (result.error) {
    showError(result.error.message);

    DeleteDialogVisible.value = false;
    return;
  }

  result = await pgrestapi.delete_data("ektokismoi_loan_agreement?loan_agreement_id=eq." + id);
  if (result.error) {
    showError(result.error.message);

    DeleteDialogVisible.value = false;
    return;
  }

  result = await pgrestapi.delete_data("la_monitoring?loan_agreement_id=eq." + id);
  if (result.error) {
    showError(result.error.message);

    DeleteDialogVisible.value = false;
    return;
  }

  result = await pgrestapi.delete_data("summary_check_xtra?loan_agreement_id=eq." + id);
  if (result.error) {
    showError(result.error.message);

    DeleteDialogVisible.value = false;
    return;
  }
  result = await pgrestapi.delete_data("loan_agreement?id=eq." + id);

  if (result.error) {
    showError(result.error.message);

    DeleteDialogVisible.value = false;
    return;
  }

  onDelete();
};

onMounted(async () => {
  await loadProducts();
  loadPage(1);
});
const getTranslation = (row: any, column: any) => {
  return t("loan_agreement." + row[column.property]);
};
const getTranslationLegalStatus = (row: any, column: any) => {
  return t("legal_status_list." + row[column.property]);
};
const loadPage = async (page: number) => {
  activeLang.value = locale.value as string;
  isLoading.value = true;

  lastPage.value = page;
  page = page - 1;

  let filterUrl = "";

  if (filterContractNumber.value !== "") {
    filterUrl += `&ar_sumbashs=ilike.*${filterContractNumber.value}*`;
  }

  if (filterPending.value) {
    filterUrl += "&pending_approvals=gt.0";
  }

  if (filterFullName.value !== "") {
    filterUrl += `&fullname=ilike.*${filterFullName.value}*`;
  }

  if (filterTaxRegistration.value !== "") {
    filterUrl += `&vat_number=ilike.*${filterTaxRegistration.value}*`;
  }

  if (filterPendingNew.value) {
    filterUrl += "&approve_status=eq.0";
  }

  if (filterHideRejected.value) {
    filterUrl += "&approve_status=neq.2";
  }

  if (filterProduct.value !== "") {
    if (activeLang.value === "en") {
      filterUrl += `&product_id=eq.${filterProduct.value}`;
    } else if (activeLang.value === "el") {
      filterUrl += `&product_id=eq.${filterProduct.value}`;
    }
  }

  if (filterStatus.value !== "") {
    filterUrl += `&katast_sumbashs=eq.${filterStatus.value}`;
  }
  if (sortOrder.value !== "") {
    // if (filterUrl === "") {
    //   filterUrl = "?";
    // }
    filterUrl += `&order=${sortColumn.value}.${sortOrder.value}`;
  }

  let addedFilter = "";

  if (mainStore.globalPortfolioID !== "") {
    addedFilter += "&debtorportfolio=eq." + mainStore.globalPortfolioID;
  }

  if (mainStore.globalDebtorID !== "") {
    addedFilter += "&debtorid=eq." + mainStore.globalDebtorID;
  }

  if (mainStore.globalLoanAgreementID !== "") {
    addedFilter += "&id=eq." + mainStore.globalLoanAgreementID;
  }

  if (mainStore.loggedUser.user_type == UserType.Agent) {
    addedFilter += `&allowed_users=cs.{${mainStore.loggedUser.id}}`;
  } else if (mainStore.loggedUser.user_type == UserType.Supervisor) {
    addedFilter += `&portfolio_allowed_users=cs.{${mainStore.loggedUser.id}}`;
  }

  let result: PGRESTResult = await pgrestapi.getPaginated(
    "view_mosloanagreement_view1?select=*,entity_loan_agreement_changes(*,users!fk_user_editor!inner(*))&entity_loan_agreement_changes.approval_status=eq.0" +
    addedFilter +
    filterUrl +
    "&order=ar_sumbashs",
    page
  );

  if (result.error) {
    showError(result.error.message);

    return;
  }

  if (result.headers)
    dataCount.value = parseInt(result.headers["content-range"].split("/")[1]);

  dataList.value = result.data;

  updateEntriesForDenouncedPendingChange();

  isLoading.value = false;
};

const updateEntriesForDenouncedPendingChange = () => {
  dataList.value.forEach((item: any) => {
    let found = item.entity_loan_agreement_changes.find((change: any) => {
      return (change.approval_status === 0 && change.field === 'katast_sumbashs' && change.value === 'denounced');
    });

    item.denouncedProposedBy = (found !== undefined) ? found.users.username : '';
  });
}

const onSave = async () => {
  selectedRow.value = -1;
  ElMessage({
    showClose: true,
    message: "Loan Agreement updated",
    type: "success",
    duration: 1000,
  });

  detailsVisible.value = false;
  loadPage(lastPage.value);
};

const onDelete = async () => {
  ElMessage({
    showClose: true,
    message: "Loan Agreement Deleted Successfully",
    type: "success",
    duration: 1000,
  });

  DeleteDialogVisible.value = false;
  loadPage(lastPage.value);
};

const onFailSave = async () => {
  selectedRow.value = -1;
  ElMessage({
    showClose: true,
    message: "Loan Agreement failed to update",
    type: "error",
    duration: 2000,
  });

  detailsVisible.value = false;
};

const filterChanged = async (value: string) => {
  if (timer.value !== 0) {
    clearTimeout(timer.value);
  }

  timer.value = setTimeout(() => {
    loadPage(1);

    timer.value = 0;
  }, 500);
};

const handleLoanAgreementClick = async (loan_agreement_id: number) => {

  let result: PGRESTResult = await pgrestapi.get(`loan_agreement?approve_status=eq.1&id=eq.` + loan_agreement_id);

  if (result.data[0]) {

    let result2: PGRESTResult = await pgrestapi.get(
      "config_product?select=type&id=eq." +
      result.data[0].product
    );

    if (result2.data[0]) {
      product_type.value = result2.data[0].type;
    }

    // console.log("redirecting");
    if (product_type.value == "summary_check_KOD") {
      router.push({
        name: "Summary Check KOD",
        params: { loan_agreement_id: loan_agreement_id },
      });
    } else if (product_type.value == "summary_check_OD") {
      router.push({
        name: "Summary Check OD",
        params: { loan_agreement_id: loan_agreement_id },
      });
    } else if (product_type.value == "summary_check_another_loan") {
      router.push({
        name: "Summary Check AL",
        params: { loan_agreement_id: loan_agreement_id },
      });
    }

  } else {

    ElMessage({
      showClose: true,
      message: 'Σφάλμα, Για να δημιουργηθεί  το summary Check θα πρέπει το loan agreement να μην έχει κατάσταση έγκρισης σε εκκρεμότητα.',
      type: 'error',
    })

  }


};

const handleEktokismosLoanAgreement = (loan_agreement_id: number) => {
  router.push({
    name: "Loan_Agreement_Ektokismos",
    params: { loan_agreement_id },
  });
};
const pendingFilterChange = async () => {
  await loadPage(1);
};

const localSortChange = async (sortObj: any) => {
  onSortChange(sortObj);
  await loadPage(lastPage.value);
};

const onClose = () => {
  detailsVisible.value = false;
  selectedRow.value = -1;
};

const loadProducts = async () => {
  let result: PGRESTResult = new PGRESTResult();

  result = await pgrestapi.get("config_product?order=product");

  if (result.error) {
    showError(result.error.message);

    return;
  }
  products.value = [];
  for (let i = 0; i < result.data.length; i++) {
    if (locale.value == "en") {
      products.value.push({
        value: result.data[i].id,
        label: result.data[i].english_trans,
      });
    } else if (locale.value == "el") {
      products.value.push({
        value: result.data[i].id,
        label: result.data[i].greek_trans,
      });
    }
  }

  // console.log(products);
};

const approve = async (entity_id: number, state: boolean) => {

  let CreateSummaryCheckRow = ref<any>([]);
  //ΑΝ ΓΙΝΕΙ ΘΕΤΙΚΟ APPROVE ΤΟΤΕ ΔΗΜΙΟΥΡΓΗΣΕ ΣΤΟΝ ΠΙΝΑΚΑ SUMMARY CHECK XTRA ΕΓΓΡΑΦΗ ME TO ΣΧΕΤΙΚΟ LOAN AGREEMENT
  if (state == true) {

    CreateSummaryCheckRow.value.push({
      loan_agreement_id: entity_id,
      skopos_ekdosis: null,
      paratasi_diarkeias: null,
      ekxoriseis_elegxos_sumvasis: null,
      ekxoriseis_elegxos_epidosis: null,
      ekxoriseis_eispraxi_gegen_apait: null,
      lixi_ekxorimenon_asf: null,
      promitheies_adraneias: null,
      promitheies_anadoxis: null,
      promitheies_diaxeirisis: null,
      promitheies_tropopoihshs: null,
      promitheies_waiver_fee_ekpr_omolog: null,
      promitheies_waiver_fee_omolog: null,
      upoxreoseis_plirof_oikon_stoixeia: null,
      gegonota_kataggelias: null,
      xronos_paragrafis: null,
      paratiriseis: null,
      summary_check_date: null,
    });

    let result2 = await pgrestapi.post_data(
      "summary_check_xtra",
      CreateSummaryCheckRow.value
    );

    if (result2.error) {
      showError(result2.error.message); // emits("fail-save");
      return;
    }
  }

  let fv = await entityApprove(LoanAgreement.getTableNameStatic(), entity_id, state);

  if (fv) {
    ElMessage({
      showClose: true,
      message: `Loan agreement updated`,
      type: "success",
      duration: 1500,
    });
  } else {
    ElMessage({
      showClose: true,
      message: `Loan agreement update failed`,
      type: "error",
      duration: 1500,
    });
  }

  await loadPage(lastPage.value);
};

const isEditable = (row: any) => {
  return (row.debtor_approve_status === 1);
}

const handleDocumentsListClick = (row: any) => {
  selectedRow.value = row.id;
  selectedDocumentEntity.value = row;
  showDocumentsList.value = true;
}
</script>

<template>
  <div class="content-wrapper" v-loading="isLoading">
    <el-row :gutter="10">
      <el-col :span="12">
        <h3 class="heading-page">
          {{ $t("LoanAgreementView.loan_agreements") }} ({{ dataCount }})
        </h3>
      </el-col>
    </el-row>

    <div style="margin: 30px">
      <el-row :gutter="20">
        <el-col :span="6">
          <el-input v-model="filterFullName" class="client-input" :placeholder="$t('DebtorDetailsView.company_fullname')"
            @input="filterChanged" />
        </el-col>
        <el-col :span="6">
          <el-input v-model="filterTaxRegistration" class="client-input"
            :placeholder="$t('LoanAgreementView.tax_registration_nbr')" @input="filterChanged" />
        </el-col>
        <el-col :span="6">
          <el-select clearable v-model="filterProduct" class="m-2 client-input"
            :placeholder="$t('LoanAgreementView.product')" value-key="id" @change="filterChanged" style="width: 100%">
            <el-option v-for="item in products" :key="item.value" :label="item.label" :value="item.value" />
          </el-select>
        </el-col>
        <el-col :span="6">
          <el-select clearable v-model="filterStatus" class="m-2 client-input"
            :placeholder="$t('LoanAgreementView.status')" value-key="id" @change="filterChanged" style="width: 100%">
            <el-option v-for="item in statusOptions" :key="item.value" :label="$t('loan_agreement.' + item.value)"
              :value="item.value" />
          </el-select>
        </el-col>
      </el-row>
    </div>

    <div>
      <el-row :gutter="10" style="margin: 10px; margin-top: 15px">
        <el-col :span="12" class="realestate-add-new-btn">
          <el-switch v-model="filterPending" size="small" :active-text="$t('global.pending_changes')" inactive-text=""
            @change="pendingFilterChange()" style="float: left" />
          <el-switch v-model="filterPendingNew" size="small" :active-text="$t('global.pending_changes_new_entities')"
            inactive-text="" @change="pendingFilterChange()" style="float: left" />
          <el-switch v-model="filterHideRejected" size="small" :active-text="$t('global.hide_rejected_entities')"
            inactive-text="" @change="pendingFilterChange()" style="float: left" />
        </el-col>
        <el-col :span="12" class="realestate-add-new-btn">
          <el-button link type="primary" size="small" class="btn-add" @click="loadPage(lastPage)"
            style="float: right; margin: 2.5px" :icon="RefreshLeft" />
          <el-button link type="primary" size="small" class="btn-add" style="float: right; margin: 2.5px"
            @click="handleClickAdd()" :icon="Plus" />
        </el-col>
      </el-row>
    </div>

    <el-table :data="dataList" stripe border table-layout="fixed" sortable="custom" @sort-change="localSortChange"
      size="small">
      <el-table-column sortable fixed prop="ar_sumbashs" :label="$t('LoanAgreementView.loan_agreement')" />
      <el-table-column sortable fixed prop="fullname" :label="$t('DebtorDetailsView.company_fullname')" />
      <el-table-column sortable fixed prop="vat_number" :label="$t('LoanAgreementView.tax_registration_nbr')" />

      <el-table-column v-if="activeLang === 'el'" fixed prop="greek_trans" :label="$t('LoanAgreementView.product')"
        sortable />
      <el-table-column v-if="activeLang === 'en'" fixed prop="english_trans" :label="$t('LoanAgreementView.product')"
        sortable />

      <el-table-column sortable fixed :formatter="getTranslationLegalStatus" prop="katast_sumbashs"
        :label="$t('LoanAgreementView.legal_status')" />
      <el-table-column sortable fixed prop="portf_name" label="Portfolio" />

      <el-table-column sortable fixed prop="pending_approvals" :label="$t('global.pending_approvals')" />

      <el-table-column fixed="right" :label="$t('global.operations')" width="280">
        <template #default="scope">
          <!-- <el-popover v-if="scope.row.entity_loan_agreement_changes[0]" placement="top-start" title="Denounced"
            :width="355" trigger="hover"
            :content="$t('global.ChangedToDenounced') + scope.row.entity_loan_agreement_changes[0].users.username">
            <template #reference>
              <el-button v-if="scope.row.entity_loan_agreement_changes[0].value == 'denounced'" :icon="Lock" circle
                size="small" style="background-color: #D93025; border: 0px; color: white" />
            </template>
          </el-popover> -->
          <el-popover v-if="scope.row.denouncedProposedBy !== ''" placement="top-start" title="Denounced" :width="355"
            trigger="hover" :content="$t('global.ChangedToDenounced') + scope.row.denouncedProposedBy">
            <template #reference>
              <el-button v-if="scope.row.denouncedProposedBy !== ''" :icon="Lock" circle size="small"
                style="background-color: #D93025; border: 0px; color: white" />
            </template>
          </el-popover>

          <el-tooltip class="box-item" effect="dark" :content="t('ektokismos.ektokismos')" placement="top">
            <el-button :icon="DataLine" circle size="small" @click="handleEktokismosLoanAgreement(scope.row.id)" />
          </el-tooltip>
          <el-tooltip class="box-item" effect="dark" content="Documents List" placement="top">
            <el-button link type="primary" size="small" class="btn-detail" @click="handleDocumentsListClick(scope.row)"
              :icon="Document" />
          </el-tooltip>
          <el-button type="primary" circle size="small" :icon="isEditable(scope.row) ? Edit : View"
            @click="handleClick(scope.row)" />
          <el-button :icon="Notebook" circle size="small" type="primary" style="background-color: #4286d5; border: 0px"
            @click="handleLoanAgreementClick(scope.row.id)" />
          <el-button v-if="mainStore.loggedUser.user_type === UserType.Administrator" type="danger" size="small"
            :icon="Delete" circle @click="DeleteDialogVisible = true; DeleteSelectedRow = scope.row.id;" />
          <el-tooltip v-if="scope.row.approve_status == 0 && mainStore.loggedUser.user_type === UserType.Agent"
            effect="dark" content="Pending Supervisor approval" placement="top">
            <el-button size="small" type="primary" class="btn-warn" :icon="Watch" circle />
          </el-tooltip>
          <span v-if="mainStore.loggedUser.user_type < UserType.Agent &&
            scope.row.approve_status === 0
            ">
            <el-button class="btn-accept" type="primary" circle size="small" @click="approve(scope.row.id, true)"
              :icon="CircleCheck" />
            <el-button class="btn-reject" type="primary" circle size="small" @click="approve(scope.row.id, false)"
              :icon="CircleClose" />
          </span>
          <el-popover v-if="scope.row.pending_approvals > 0" placement="top-start" title="" :width="155" trigger="hover"
            :content="$t('global.pending_changes')">
            <template #reference>
              <el-button :icon="Bell" circle size="small" style="background-color: coral; border: 0px; color: white" />
            </template>
          </el-popover>
          <el-popover v-if="scope.row.debtor_approve_status !== 1" placement="top-start" title="" :width="205"
            trigger="hover" :content="$t('global.debtor_not_approved')">
            <template #reference>
              <el-button :icon="Avatar" circle size="small"
                style="background-color: blueviolet; border: 0px; color: white" />
            </template>
          </el-popover>

        </template>
      </el-table-column>
    </el-table>

    <div>
      <el-pagination small layout="prev, pager, next" :page-size="10" :total="dataCount" @current-change="loadPage" />
    </div>

    <el-dialog v-if="detailsVisible" v-model="detailsVisible" @update:model-value="onClose"
      :title="t('LoanAgreementView.la_details')" width="70%">
      <LoanAgreementDetails :rowid="selectedRow" @save="onSave" @close="onClose" @fail-save="onFailSave"
        :view_only="view_only" />
    </el-dialog>

    <el-dialog v-if="ΑddLoanAgreementVisible" v-model="ΑddLoanAgreementVisible"
      :title="t('LoanAgreementView.add_loan_agreement')">
      <AddLoanAgreement @close="ΑddLoanAgreementVisible = false" @save="onAdd" />
    </el-dialog>

    <!-- TODO: Translations -->
    <el-dialog v-if="showDocumentsList" v-model="showDocumentsList"
      :title="`Loan Agreement / ${selectedDocumentEntity.ar_sumbashs} - Documents List`" width="700">
      <DocumentsList v-if="showDocumentsList" :entity_type="EntityType.LoanAgreement"
        :entity_id="selectedDocumentEntity.id" :row_id="selectedRow"
        :allowed_folders="[DocumentFolders.ConventionalDocuments, DocumentFolders.LegalActions, DocumentFolders.Collaterals, DocumentFolders.Rollovers]" />
    </el-dialog>

    <el-dialog v-model="DeleteDialogVisible" :title="t('LoanAgreementView.delete_la')" width="50%">
      <span>{{ $t('LoanAgreementView.delete_la_check') }}</span>
      <template #footer #default="scope">
        <span class="dialog-footer">
          <el-button @click="DeleteDialogVisible = false">{{ $t('generic.cancel') }}</el-button>
          <el-button type="primary" @click="deleteHandleClick(DeleteSelectedRow)">{{ $t('generic.delete') }}
          </el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<style scoped></style>
