<script lang="ts" setup>
import { ref, watch, onMounted } from "vue";
import { usePGRESTAPIStore } from "../store/pgrest_api";
import PGRESTResult from "../classes/PGRESTResult";
import { Avatar, Bell, Collection, Edit, Delete, Document, Plus, RefreshLeft, Coin, Money, Watch, CircleCheck, CircleClose, View } from "@element-plus/icons-vue";
import { ElMessage } from "element-plus";
import LoanAccountDetails from "../components/LoanAccountDetails.vue";
import { storeToRefs } from "pinia";
import { useMainStore } from "../store/main";
import AddLoanAccount from "../components/AddLoanAccount.vue";
import UserType from "../enums/UserType";
import { useSort } from "../composables/sort";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { useViewOptions } from "../composables/viewOptions";
import LoanAccount from "../classes/DB_Entities/LoanAccount";
import { useGenericMethodsVariables } from "../composables/genericMethodsVariables";

const { entityApprove, showError } = useGenericMethodsVariables();
const { locale } = useI18n({ useScope: "global" });
const pgrestapi = usePGRESTAPIStore();
const mainStore = useMainStore();
const products = ref<any>([]);
const { globalPortfolioID } = storeToRefs(mainStore);
const { globalDebtorID } = storeToRefs(mainStore);
const { globalLoanAgreementID } = storeToRefs(mainStore);
const { onSortChange, sortColumn, sortOrder } = useSort();
const router = useRouter();
const { filters } = useViewOptions(LoanAccount.getTableNameStatic(), {
  filterPending: false,
  filterLoanAccountCurrentNumber: "",
  filterFullname: "",
  filterVAT: "",
  filterProduct: "",
});

let filterPendingNew = ref(false);
let filterHideRejected = ref(true);
let dataList = ref([]);
let dataCount = ref(0);
let isLoading = ref(false);
let lastPage = ref(0);
let detailsVisible = ref(false);
let selectedRow = ref(0);
const DeleteDialogVisible = ref(false);
let DeleteSelectedRow = ref(0);
let deleteVisible = ref(false);

let ΑddLoanAccountVisible = ref(false);
let timer = ref<any>(null);
let activeLang = ref<string>("en");
let viewOnly = ref(false);

watch(globalPortfolioID, async () => {
  if (mainStore.loggedUser.username !== '') // Do not load if user logged out (changes the value of globalLoanAgreementID to "")
    await loadPage(1);
});

watch(globalDebtorID, async () => {
  if (mainStore.loggedUser.username !== '') // Do not load if user logged out (changes the value of globalLoanAgreementID to "")
    await loadPage(1);
});

watch(globalLoanAgreementID, async () => {
  if (mainStore.loggedUser.username !== '') // Do not load if user logged out (changes the value of globalLoanAgreementID to "")
    await loadPage(1);
});

watch(locale, async () => {
  await loadProducts();
  await loadPage(1);
});

const handleClick = (row: any) => {
  viewOnly.value = !isEditable(row);
  selectedRow.value = row.id;
  detailsVisible.value = true;
};

const handlePliromi = (loan_account_id: number) => {
  router.push({
    name: "Pliromes",
    params: { loan_account_id },
  });
};

const handleClickEktokismos = (loan_account_id: number) => {
  router.push({
    name: "Loan_Account_Ektokismos",
    params: { loan_account_id },
  });
};

const deleteHandleClick = (row_id: number) => {
  DeleteSelectedRow.value = row_id;
  deleteVisible.value = true;
  DeleteLoanAccounts(row_id);
};

const DeleteLoanAccounts = async (row_id: number) => {
  let result: PGRESTResult = new PGRESTResult();

  let id = JSON.stringify(row_id);

  result = await pgrestapi.delete_data("loan_accounts?id=eq." + id);

  if (result.error) {
    showError(result.error.message);

    DeleteDialogVisible.value = false;
    return;
  }

  onDelete();
};

onMounted(async () => {
  await loadProducts();
  loadPage(1);
});

const loadPage = async (page: number) => {
  activeLang.value = locale.value as string;
  isLoading.value = true;

  lastPage.value = page;
  page = page - 1;

  let filterUrl = "";

  if (filters.value.filterLoanAccountCurrentNumber !== "") {
    filterUrl += `&ar_trexontos_logariasmou=ilike.*${filters.value.filterLoanAccountCurrentNumber}*`;
  }

  if (filters.value.filterPending) {
    filterUrl += "&pending_approvals=gt.0";
  }

  if (filters.value.filterFullname !== "") {
    filterUrl += `&fullname=ilike.*${filters.value.filterFullname}*`;
  }

  if (filters.value.filterVAT !== "") {
    filterUrl += `&vat_number=ilike.*${filters.value.filterVAT}*`;
  }

  if (filters.value.filterProduct !== "") {
    filterUrl += `&product_id=eq.${filters.value.filterProduct}`;
  }

  if (sortOrder.value !== "") {
    filterUrl += `&order=${sortColumn.value}.${sortOrder.value}`;
  }

  if (filterPendingNew.value) {
    filterUrl += "&approve_status=eq.0";
  }

  if (filterHideRejected.value) {
    filterUrl += "&approve_status=neq.2";
  }

  let selectedPortfolio = "";
  let selectedDebtor = "";
  let selectedLoanAgreement = "";
  let addedFilter = "";

  if (mainStore.globalPortfolioID !== "") {
    selectedPortfolio = mainStore.globalPortfolioID;
    addedFilter += "&portfolio=eq." + selectedPortfolio;
  }

  if (mainStore.globalDebtorID !== "") {
    selectedDebtor = mainStore.globalDebtorID;
    addedFilter += "&debtor_id=eq." + selectedDebtor;
  }

  if (mainStore.globalLoanAgreementID !== "") {
    selectedLoanAgreement = mainStore.globalLoanAgreementID;
    addedFilter += "&loan_agreement_id=eq." + selectedLoanAgreement;
  }

  if (mainStore.loggedUser.user_type == UserType.Agent) {
    addedFilter += `&debtor_allowed_users=cs.{${mainStore.loggedUser.id}}`;
  } else if (mainStore.loggedUser.user_type == UserType.Supervisor) {
    addedFilter += `&portfolio_allowed_users=cs.{${mainStore.loggedUser.id}}`;
  }

  let result: PGRESTResult = await pgrestapi.getPaginated(
    "view_mosloanaccounts_view1?select=*" + addedFilter + filterUrl,
    page
  );

  if (result.error) {
    showError(result.error.message);

    return;
  }

  if (result.headers)
    dataCount.value = parseInt(result.headers["content-range"].split("/")[1]);

  dataList.value = result.data;

  isLoading.value = false;
};

const onSave = async () => {
  selectedRow.value = -1;
  ElMessage({
    showClose: true,
    message: "Loan Account updated",
    type: "success",
    duration: 1000,
  });

  detailsVisible.value = false;

  loadPage(lastPage.value);
};

const onDelete = async () => {
  ElMessage({
    showClose: true,
    message: "Loan Account Deleted Successfully",
    type: "success",
    duration: 1000,
  });

  DeleteDialogVisible.value = false;
  loadPage(lastPage.value);
};

const onFailSave = async () => {
  selectedRow.value = -1;
  ElMessage({
    showClose: true,
    message: "Loan Account failed to update",
    type: "error",
    duration: 2000,
  });

  detailsVisible.value = false;
};

const onAdd = async () => {
  ElMessage({
    showClose: true,
    message: "Loan account added Successfully",
    type: "success",
    duration: 1000,
  });

  ΑddLoanAccountVisible.value = false;

  loadPage(lastPage.value);
};

const handleClickAdd = () => {
  ΑddLoanAccountVisible.value = true;
};

const filterChanged = async (value: string) => {
  if (timer.value !== 0) {
    clearTimeout(timer.value);
  }

  timer.value = setTimeout(() => {
    loadPage(1);

    timer.value = 0;
  }, 500);
};

const localSortChange = async (sortObj: any) => {
  onSortChange(sortObj);
  await loadPage(lastPage.value);
};

const pendingFilterChange = async () => {
  await loadPage(1);
};

const onClose = () => {
  detailsVisible.value = false;
  selectedRow.value = -1;
};

const loadProducts = async () => {
  let result: PGRESTResult = new PGRESTResult();

  result = await pgrestapi.get("config_loan_account_product?order=product");

  if (result.error) {
    showError(result.error.message);

    return;
  }
  products.value = [];
  for (let i = 0; i < result.data.length; i++) {
    if (locale.value == "en") {
      products.value.push({
        value: result.data[i].id,
        label: result.data[i].english_trans,
      });
    } else if (locale.value == "el") {
      products.value.push({
        value: result.data[i].id,
        label: result.data[i].greek_trans,
      });
    }
  }
};

const approve = async (entity_id: number, state: boolean) => {
  let fv = await entityApprove(LoanAccount.getTableNameStatic(), entity_id, state);

  if (fv) {
    ElMessage({
      showClose: true,
      message: `Loan account updated`,
      type: "success",
      duration: 1500,
    });
  } else {
    ElMessage({
      showClose: true,
      message: `Loan account update failed`,
      type: "error",
      duration: 1500,
    });
  }

  await loadPage(lastPage.value);
};

const isEditable = (row: any) => {
  return (row.debtor_approve_status === 1 && row.loan_agreement_approve_status === 1);
}

const handleEktokismo = (loan_account_id: number) => {
  router.push(`/la_calculations/${loan_account_id}`);
};
</script>

<template>
  <div div class="content-wrapper" v-loading="isLoading">
    <el-row :gutter="10">
      <el-col :span="12">
        <h3 class="heading-page">
          {{ $t("LoanAccountsView.loan_accounts") }} ({{ dataCount }})
        </h3>
      </el-col>
    </el-row>

    <div style="margin: 30px">
      <el-row :gutter="20">
        <el-col :span="6">
          <el-input v-model="filters.filterLoanAccountCurrentNumber" class="client-input"
            :placeholder="$t('LoanAccountsView.loanaccount')" @input="filterChanged" />
        </el-col>
        <el-col :span="6">
          <el-input v-model="filters.filterFullname" class="client-input" :placeholder="$t('DebtorView.company_name')"
            @input="filterChanged" />
        </el-col>
        <el-col :span="6">
          <el-input v-model="filters.filterVAT" class="client-input" :placeholder="$t('DebtorView.tax_registration_nbr')"
            @input="filterChanged" />
        </el-col>
        <el-col :span="6">
          <el-select clearable v-model="filters.filterProduct" class="m-2 client-input"
            :placeholder="$t('LoanAgreementDetails.product')" value-key="id" @change="filterChanged" style="width: 100%">
            <el-option v-for="item in products" :key="item.value" :label="item.label" :value="item.value" />
          </el-select>
        </el-col>
      </el-row>
    </div>
    <div>
      <el-row :gutter="10" style="margin: 10px; margin-top: 15px">
        <el-col :span="12" class="realestate-add-new-btn">
          <el-switch v-model="filters.filterPending" size="small" :active-text="$t('global.pending_changes')"
            inactive-text="" @change="pendingFilterChange()" style="float: left" />
          <el-switch v-model="filterPendingNew" size="small" :active-text="$t('global.pending_changes_new_entities')"
            inactive-text="" @change="pendingFilterChange()" style="float: left" />
          <el-switch v-model="filterHideRejected" size="small" :active-text="$t('global.hide_rejected_entities')"
            inactive-text="" @change="pendingFilterChange()" style="float: left" />
        </el-col>
        <el-col :span="12" class="realestate-add-new-btn">
          <el-button link type="primary" size="small" class="btn-add" @click="loadPage(lastPage)"
            style="float: right; margin: 2.5px" :icon="RefreshLeft" />
          <el-button link type="primary" size="small" class="btn-add" style="float: right; margin: 2.5px"
            @click="handleClickAdd()" :icon="Plus" />
        </el-col>
      </el-row>
    </div>

    <el-table :data="dataList" stripe border table-layout="fixed" sortable="custom" @sort-change="localSortChange"
      size="small">
      <el-table-column fixed prop="ar_trexontos_logariasmou" :label="$t('LoanAccountsView.loanaccount')" sortable />
      <el-table-column fixed prop="ar_sumbashs" :label="$t('LoanAccountsView.loan_agreement')" sortable />
      <el-table-column fixed prop="fullname" :label="$t('DebtorView.company_name')" sortable />
      <el-table-column fixed prop="vat_number" :label="$t('DebtorView.tax_registration_nbr')" sortable />
      <el-table-column v-if="activeLang === 'el'" fixed prop="greek_trans" :label="$t('LoanAgreementView.product')"
        sortable />
      <el-table-column v-if="activeLang === 'en'" fixed prop="english_trans" :label="$t('LoanAgreementView.product')"
        sortable />
      <el-table-column fixed prop="portf_name" :label="$t('DebtorDetailsView.portfolio')" sortable />
      <el-table-column sortable fixed prop="pending_approvals" :label="$t('global.pending_approvals')" />
      <el-table-column fixed="right" :label="$t('global.operations')" width="280">
        <template #default="scope">
          <el-tooltip effect="dark" :content="$t('LoanAccountsView.loan_calculations')" placement="left">
            <el-button style="background-color: lightcyan" size="small" @click="handleEktokismo(scope.row.id)"
              :icon="Collection" circle />
          </el-tooltip>

          <!-- <el-button size="small" @click="handlePliromi(scope.row.id)" :icon="Coin" circle /> -->
          <!-- <el-button size="small" :icon="Money" circle @click="handleClickEktokismos(scope.row.id)" /> -->
          <el-button size="small" type="primary" :icon="isEditable(scope.row) ? Edit : View" circle
            @click="handleClick(scope.row)" />
          <el-button v-if="mainStore.loggedUser.user_type === UserType.Administrator" size="small" type="danger"
            :icon="Delete" circle @click="DeleteDialogVisible = true; DeleteSelectedRow = scope.row.id;" />
          <el-tooltip v-if="scope.row.approve_status == 0 && mainStore.loggedUser.user_type === UserType.Agent"
            effect="dark" content="Pending Supervisor approval" placement="top">
            <el-button size="small" type="primary" class="btn-warn" :icon="Watch" circle />
          </el-tooltip>
          <span v-if="mainStore.loggedUser.user_type < UserType.Agent &&
            scope.row.approve_status === 0
            ">
            <el-button class="btn-accept" type="primary" circle size="small" @click="approve(scope.row.id, true)"
              :icon="CircleCheck" />
            <el-button class="btn-reject" type="primary" circle size="small" @click="approve(scope.row.id, false)"
              :icon="CircleClose" />
          </span>
          <el-popover v-if="scope.row.pending_approvals > 0" placement="top-start" title="" :width="155" trigger="hover"
            :content="$t('global.pending_changes')">
            <template #reference>
              <el-button :icon="Bell" circle size="small" style="background-color: coral; border: 0px; color: white" />
            </template>
          </el-popover>
          <el-popover v-if="scope.row.debtor_approve_status !== 1" placement="top-start" title="" :width="205"
            trigger="hover" :content="$t('global.debtor_not_approved')">
            <template #reference>
              <el-button :icon="Avatar" circle size="small"
                style="background-color: blueviolet; border: 0px; color: white" />
            </template>
          </el-popover>
          <el-popover v-if="scope.row.loan_agreement_approve_status !== 1" placement="top-start" title="" :width="265"
            trigger="hover" :content="$t('global.loan_agreement_not_approved')">
            <template #reference>
              <el-button :icon="Document" circle size="small"
                style="background-color: coral; border: 0px; color: white" />
            </template>
          </el-popover>
        </template>
      </el-table-column>
    </el-table>

    <div>
      <el-pagination small layout="prev, pager, next" :page-size="10" :total="dataCount" @current-change="loadPage" />
    </div>

    <el-dialog v-if="detailsVisible" v-model="detailsVisible" @update:model-value="onClose" title="Loan Account Details">
      <LoanAccountDetails :loanaccountid="selectedRow" @save="onSave" @close="onClose" @fail-save="onFailSave"
        :view_only="viewOnly" />
    </el-dialog>

    <el-dialog v-if="ΑddLoanAccountVisible" v-model="ΑddLoanAccountVisible" title="Add Loan Account">
      <AddLoanAccount @close="ΑddLoanAccountVisible = false" @save="onAdd" />
    </el-dialog>

    <el-dialog v-model="DeleteDialogVisible" title="Loan Accounts Delete" width="50%">
      <span>Are you sure you want to delete this Loan Account?</span>
      <template #footer #default="scope">
        <span class="dialog-footer">
          <el-button @click="DeleteDialogVisible = false">{{ $t('generic.cancel') }}</el-button>
          <el-button type="primary" @click="deleteHandleClick(DeleteSelectedRow)">{{ $t('generic.delete') }}
          </el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<style scoped></style>
