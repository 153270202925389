<script lang="ts" setup>
import { ref, watch, onBeforeMount, onMounted } from "vue";
import { usePGRESTAPIStore } from "../store/pgrest_api";
import PGRESTResult from "../classes/PGRESTResult";
import { ElMessage } from "element-plus";
import { Avatar, Bell, Edit, Delete, Document, Plus, RefreshLeft, CircleCheck, CircleClose, View, Watch } from "@element-plus/icons-vue";
import { storeToRefs } from "pinia";
import { useMainStore } from "../store/main";
import AddEdit_CustomField from "../components/AddEdit_CustomField.vue";
import UserType from "../enums/UserType";
import { useSort } from "../composables/sort";
import { useI18n } from "vue-i18n";
import { useGenericMethodsVariables } from "../composables/genericMethodsVariables";

const { showError, showSuccess } = useGenericMethodsVariables();

const { t } = useI18n({ useScope: "global" });
const { onSortChange, sortColumn, sortOrder } = useSort();
const pgrestapi = usePGRESTAPIStore();
const mainStore = useMainStore();
const DisableDialogVisible = ref(false);
const { globalPortfolioID } = storeToRefs(mainStore);
const { globalDebtorID } = storeToRefs(mainStore);
const { globalLoanAgreementID } = storeToRefs(mainStore);

let filterPending = ref(false);
let dataList = ref<any>([]);
let dataCount = ref(0);
let isLoading = ref(false);
let lastPage = ref(0);
let selectedRow = ref(0);
let addEditVisible = ref(false);
let DisableSelectedRow = ref(0);
let disableVisible = ref(false);
let isNewEntity = ref(false);
let viewOnly = ref(false);

let timer = ref<any>(null);

watch(globalPortfolioID, async () => {
  if (mainStore.loggedUser.username !== '') // Do not load if user logged out (changes the value of globalLoanAgreementID to "")
  await loadPage(1);
});

watch(globalDebtorID, async () => {
  if (mainStore.loggedUser.username !== '') // Do not load if user logged out (changes the value of globalLoanAgreementID to "")
  await loadPage(1);
});

watch(globalLoanAgreementID, async () => {
  if (mainStore.loggedUser.username !== '') // Do not load if user logged out (changes the value of globalLoanAgreementID to "")
  await loadPage(1);
});

onBeforeMount(() => {
  isLoading.value = true;
})

onMounted(async () => {
  isLoading.value = false;
  loadPage(1);
});

const onAdd = async () => {
  ElMessage({
    showClose: true,
    message: "Custom Fields added Successfully",
    type: "success",
    duration: 1000,
  });

  addEditVisible.value = false;

  loadPage(lastPage.value);
};

const loadPage = async (page: number) => {
  isLoading.value = true;

  lastPage.value = page;
  page = page - 1;

  

  let result: PGRESTResult = await pgrestapi.getPaginated(
    "custom_fields",
    page
  );

  if (result.error) {
    showError(result.error.message);

    return;
  }

  if (result.headers)
    dataCount.value = parseInt(result.headers["content-range"].split("/")[1]);

  dataList.value = result.data;
  isLoading.value = false;
};

const onSave = async () => {
  selectedRow.value = -1;
  ElMessage({
    showClose: true,
    message: "Custom Field updated",
    type: "success",
    duration: 1000,
  });

  addEditVisible.value = false;

  loadPage(lastPage.value);
};

const onFailSave = async () => {
  selectedRow.value = -1;
  ElMessage({
    showClose: true,
    message: "Custom Field failed to update",
    type: "error",
    duration: 2000,
  });

  addEditVisible.value = false;
};

const handleClickAdd = () => {
  addEditVisible.value = true;
  isNewEntity.value = true;
  selectedRow.value = -1;
};

const handleClickEdit = (row: any) => {
  selectedRow.value = row.id;
  isNewEntity.value = false;
  addEditVisible.value = true;
};

const handleClickDisable = (row_id: number) => {
  DisableDialogVisible.value = true;
  DisableSelectedRow.value = row_id;
};

const disableSelectedCustomField = async () => {
  let result: PGRESTResult = new PGRESTResult();

  result = await pgrestapi.patch(
    "custom_field?id=eq." + DisableSelectedRow.value,
    {status:0}
  );

  if (result.error) {
    showError(result.error.message);
    return;
  }

  onDisable();
};

const onDisable = async () => {
  
  showSuccess("Custom Field Disabled Successfully", 1000);

  DisableDialogVisible.value = false;
  DisableSelectedRow.value = -1;
  loadPage(lastPage.value);
};

const onCloseAddEdit = () => {
  addEditVisible.value = false;
  selectedRow.value = -1;
};


</script>

<template>
  <div class="content-wrapper" v-loading="isLoading">
    <el-row :gutter="10">
      <el-col :span="12">
        <h3 class="heading-page">
          {{ $t("CustomField.CustomField") }} ({{ dataCount }})
        </h3>
      </el-col>
    </el-row>


    <div>
      <el-row :gutter="10" style="margin: 10px; margin-top: 15px">
        <el-col :span="12" class="realestate-add-new-btn">
        </el-col>
        <el-col :span="12" class="realestate-add-new-btn">
          <el-button link type="primary" size="small" class="btn-add" @click="loadPage(lastPage)"
            style="float: right; margin: 2.5px" :icon="RefreshLeft" />
          <el-button link type="primary" size="small" class="btn-add" style="float: right; margin: 2.5px"
            @click="handleClickAdd" :icon="Plus" />
        </el-col>
      </el-row>
    </div>

    <el-table :data="dataList" stripe border table-layout="fixed" sortable="custom" size="small">
      <el-table-column sortable fixed prop="name_el" :label="$t('CustomField.name_el')" />
      <el-table-column sortable fixed prop="name_en" :label="$t('CustomField.name_en')" />
      <el-table-column sortable fixed prop="type" :label="$t('CustomField.type')" />
      <el-table-column sortable fixed prop="status" :label="$t('CustomField.status')" />
      <el-table-column fixed="right" :label="$t('global.operations')" width="280">
        <template #default="scope">
          <el-button link type="primary" size="small" class="btn-detail" @click="handleClickEdit(scope.row)"
            :icon="Edit" />
          <el-tooltip v-if="scope.row.approve_status == 0 && mainStore.loggedUser.user_type === UserType.Agent"
            effect="dark" content="Pending Supervisor approval" placement="top">
            <el-button size="small" type="primary" class="btn-warn" :icon="Watch" circle />
          </el-tooltip>
        <!--  <el-button v-if="mainStore.loggedUser.user_type === UserType.Administrator" link type="primary" size="small"
            class="btn-delete" @click="handleClickDisable(scope.row.id)" :icon="Delete" />        -->
          
        </template>
      </el-table-column>
    </el-table>

    <div>
      <el-pagination small layout="prev, pager, next" :page-size="10" :total="dataCount" @current-change="loadPage" />
    </div>

    <el-dialog v-if="addEditVisible" v-model="addEditVisible" @update:model-value="onCloseAddEdit"
      :title="isNewEntity ? 'Add Custom Field' : 'Custom Field Details'">
      <AddEdit_CustomField :customfieldid="selectedRow" :new-entity="isNewEntity" @close="onCloseAddEdit" @save="onAdd"
        :view_only="viewOnly" @fail-save="onFailSave" />
    </el-dialog>

    <el-dialog v-model="DisableDialogVisible" title="Custom Field Disable" width="50%">
      <span>Are you sure you want to disable this Custom Field?</span>
      <template #footer #default="scope">
        <span class="dialog-footer">
          <el-button @click="DisableDialogVisible = false">{{ $t('generic.cancel') }}</el-button>
          <el-button type="primary" @click="disableSelectedCustomField()">{{ $t('generic.delete') }}
          </el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<style scoped>

</style>
