import AmountEntryExtra from '../classes/AmountEntryExtra';
import LoanAccountEventType from '../enums/LoanAccountEventType';
import LoanRepaymentType from '../enums/LoanRepaymentType';
import Frequency from '../enums/Frequency';
import LoanAccountEventEntry from '../interfaces/LoanAccountEventEntry';

import { DateTime } from 'luxon';

import InterestEntry from '../interfaces/InterestEntry';

interface Kinhsh {
    date: string;
    xrewsh: number;
    pistwsh: number;
    perigrafh: string;
}

class LoanAccount {

    public argiesList: string[] = [];
    public loanStartingDate: string = '1970-01-01';
    public loanEndingDate: string = '1970-01-01';

    // Loan capital related details
    public loanCapitalAmount: number = 0;
    public loanCapitalRepaymentType: LoanRepaymentType = LoanRepaymentType.XreolytikoAnisoposo;
    public loanCapitalRepaymentFrequency = Frequency.SemiAnnually;
    public loanRepaymentEpeteiako = false;
    public kathisterimenoARXIKA = false;
    public kathisterimenodate: string = '1970-01-01';
    public kathisterimenokef: number = 0;
    public kathisterimenotok: number = 0;
    public kathisterimenotokoiArxika: any[] = [];
    public kathisterimeno_enim_arx: number = 0;
    public arnitika_epitokia: boolean = false;
    // Interest change related details
    public interestRateList: InterestEntry[] = [];
    public interestChangeFrequency = Frequency.Monthly;
    public interestChangeIsEpeteiako = true;

    public seiraKathKef: number = 0;
    public seiraKathTok: number = 0;
    // Interest repayment related details
    public interestRepaymentFrequency = Frequency.SemiAnnually;
    public interestRepaymentEpeteiako = false;

    // Enhmero Kefalaio
    public enhmeroKefalaioList: AmountEntryExtra[] = [];

    // Kathysterhmeno kefalaio
    public kathysterhmenoKefalaio: AmountEntryExtra[] = [];

    // Kathysterhmenoi Tokoi
    public kathysterhmenoiTokoi: AmountEntryExtra[] = [];

    // Lista apo events
    public eventsList: LoanAccountEventEntry[] = [];

    public nextAvailableTokoiTokwnPeriodosIndex: number = 0;
    public nextAvailableKathysterhmenoKefalaioPeriodosIndex: number = 0;
    public nextOfeilhIndex: number = 0;

    public listaKinhsewn: Kinhsh[] = [];
    public hasKathysterhmenousTokous: boolean = false;
    public kathysterhmenoiTokoiFirstDate: string = '1970-01-01';

    public latestDedoul: number = 0;
    public isTodayEventEnhmero: boolean = false;
    public isTodayEventKathKef: boolean = false;

    public currentExoda: number = 0;

    public finalDate = '';
    public finalYpoloipo: number = 0;

    public pmtInitial: number = 0;
    public pmtPeriods: number = 0;

    public perithorio: number = 0;
    public eisfora: number = 0;
    public bash_ektokismou: number = 360;
    public pososto_tokwn_yperhmerias: number = 0;

    public calculateArgies: boolean = false;
    public isOmologiako: boolean = false;
    public isAllhloxreo: boolean = false;

    public currentEnhmXreos: number = 0;
    public currentKathKefXreos: number = 0;
    public currentKathTokXreos: number = 0;

    public FixDate(date: string): string {
        return (date >= (DateTime.now().toISODate() as string) ? DateTime.now().toISODate() as string : date);
    }

    public isArgia(date: string) {
        return this.argiesList.includes(date) || DateTime.fromISO(date).weekday === 6 || DateTime.fromISO(date).weekday === 7;
    }

    public EpomenhErgasimh(date: string): string { //, goToNextMonth: boolean): string {
        let fv = date;
        let done = false;
        let dt = DateTime.fromISO(date);

        while (true) {
            let nextDate = dt.plus({ days: 1 });

            if (!this.isArgia(nextDate.toISODate() as string)) {
                fv = nextDate.toISODate() as string;
                break;
            }
            dt = dt.plus({ days: 1 });
        }

        return fv;
    }

    public ProhgoumenhErgasimh(date: string): string { //, goToNextMonth: boolean): string {
        let fv = date;
        let done = false;
        let dt = DateTime.fromISO(date);

        while (true) {
            let nextDate = dt.minus({ days: 1 });

            if (!this.isArgia(nextDate.toISODate() as string)) {
                fv = nextDate.toISODate() as string;
                break;
            }
            dt = dt.minus({ days: 1 });
        }

        return fv;
    }

    public FixDateExtra(date: string, goBackInTime: boolean): [string, boolean] {
        let originalDate = date;
        if (date > (DateTime.now().toISODate() as string))
            date = DateTime.now().toISODate() as string;

        let done = false;

        while (!done) {
            if (this.argiesList.includes(date) || DateTime.fromISO(date).weekday === 6 || DateTime.fromISO(date).weekday === 7) {
                // if (this.argiesList.includes(date))
                //     console.log('Ημερομηνία', date, 'είναι αργία');
                // if (DateTime.fromISO(date).weekday === 6)
                //     console.log('Ημερομηνία', date, 'είναι Σάββατο');
                // if (DateTime.fromISO(date).weekday === 7)
                //     console.log('Ημερομηνία', date, 'είναι Κυριακή');
                date = DateTime.fromISO(date).plus({ days: 1 }).toISODate() as string;

            } else {
                // console.log(originalDate, '->', date);
                done = true;
            }
        }

        // Same year, return it
        if (date.substring(0, 4) === originalDate.substring(0, 4) || !goBackInTime)
            return [date, false];

        date = originalDate;
        done = false;
        while (!done) {
            if (this.argiesList.includes(date) || DateTime.fromISO(date).weekday === 6 || DateTime.fromISO(date).weekday === 7) {
                // if (this.argiesList.includes(date))
                //     console.log('Ημερομηνία', date, 'είναι αργία');
                // if (DateTime.fromISO(date).weekday === 6)
                //     console.log('Ημερομηνία', date, 'είναι Σάββατο');
                // if (DateTime.fromISO(date).weekday === 7)
                //     console.log('Ημερομηνία', date, 'είναι Κυριακή');
                date = DateTime.fromISO(date).minus({ days: 1 }).toISODate() as string;
            } else {
                // console.log(originalDate, '->', date);
                done = true;
            }
        }
        return [date, true];
    }

    private SortEvents() {
        this.eventsList.sort((a: LoanAccountEventEntry, b: LoanAccountEventEntry): number => {
            if (a.date < b.date) {
                return -1;
            } else if (a.date > b.date) {
                return 1;
            } else {
                if (a.type === LoanAccountEventType.InterestChange) {
                    return -1;
                } else if (b.type === LoanAccountEventType.InterestChange) {
                    return 1;
                } else if (a.type === LoanAccountEventType.Tokos) {
                    return -1;
                } else if (b.type === LoanAccountEventType.Tokos) {
                    return 1;
                } else if (a.type === LoanAccountEventType.KathysterhmenoKefalaio) {
                    return -1;
                } else if (b.type === LoanAccountEventType.KathysterhmenoKefalaio) {
                    return 1;
                }
                else if (a.type === LoanAccountEventType.TokoiKathysterhmenwnTokwn && b.type === LoanAccountEventType.TokoiKathysterhmenwnTokwn) {
                    if (a.processed) // ((a.periodos_index as number) > (b.periodos_index as number))
                        return -1;
                    else
                        return 1;
                }
                else if (a.type === LoanAccountEventType.TokoiKathysterhmenwnTokwn) {
                    return -1;
                } else if (b.type === LoanAccountEventType.TokoiKathysterhmenwnTokwn) {
                    return 1;
                }
                else if (a.type === LoanAccountEventType.Xrewsh) {
                    return -1;
                } else if (b.type === LoanAccountEventType.Xrewsh) {
                    return 1;
                } else if (a.type === LoanAccountEventType.Dosh) {
                    return -1;
                } else if (b.type === LoanAccountEventType.Dosh) {
                    return 1;
                } else if (a.type === LoanAccountEventType.Exodo) {
                    return -1;
                } else if (b.type === LoanAccountEventType.Exodo) {
                    return 1;
                } else if (a.type === LoanAccountEventType.Pistwsh) {
                    return -1;
                } else if (b.type === LoanAccountEventType.Pistwsh) {
                    return 1;
                }

                return 0;
            }
        });
    }

    private HandleTokos(tokos: LoanAccountEventEntry) {

        // if (tokos.date > DateTime.now().toISODate()) {
        //     return;
        // }

        if (this.enhmeroKefalaioList.length === 0) {
            return;
        }

        // let fromDate = AmountEntryExtra.getPreviousFrequencyDate(DateTime.fromISO(tokos.hiddenDate as string), this.interestRepaymentFrequency, this.interestRepaymentEpeteiako);
        let fromDate = AmountEntryExtra.getPreviousFrequencyDate(DateTime.fromISO(tokos.date), this.interestRepaymentFrequency, this.interestRepaymentEpeteiako);
        if (fromDate < DateTime.fromISO(this.loanStartingDate)) {
            fromDate = DateTime.fromISO(this.loanStartingDate);
        }

        if (this.isOmologiako && this.calculateArgies && this.isArgia(fromDate.toISODate() as string)) {
            let epom = this.EpomenhErgasimh(fromDate.toISODate() as string);

            if (DateTime.fromISO(epom).month !== DateTime.fromISO(fromDate.toISODate() as string).month || DateTime.fromISO(epom).year !== DateTime.fromISO(fromDate.toISODate() as string).year) {
                epom = this.ProhgoumenhErgasimh(fromDate.toISODate() as string);
            }

            fromDate = DateTime.fromISO(epom);
        }

        // fromDate = DateTime.fromISO(this.FixDateExtra(fromDate.toISODate() as string, false)[0]);

        let index = -1;
        for (let i = 0; i < this.enhmeroKefalaioList.length; ++i) {
            let item = this.enhmeroKefalaioList[i];
            if (item.endDate === tokos.date) {
                index = i;
                break;
            }
        }

        if (index !== -1) {
            for (let i = 0; i < this.enhmeroKefalaioList.length; ++i) {
                let item = this.enhmeroKefalaioList[i];
                if (item.startDate >= (fromDate.toISODate() as string) && (item.endDate as string) <= tokos.date) {
                    this.enhmeroKefalaioList[index].tokoi_periodou = this.enhmeroKefalaioList[index].tokoi_periodou + item.dedouleymenoi_tokoi;
                }
            }

            let newEntry = new AmountEntryExtra(this.perithorio, this.eisfora, this.bash_ektokismou, this.pososto_tokwn_yperhmerias);
            newEntry.interest = this.GetInterestRateForDate(tokos.date);
            newEntry.startDate = tokos.date;
            newEntry.amount = this.enhmeroKefalaioList[index].tokoi_periodou;
            newEntry.endDate = this.FixDate(AmountEntryExtra.getTokosPlus6Months(DateTime.fromISO(this.enhmeroKefalaioList[index].endDate as string), Frequency.SemiAnnually, true).toISODate() as string);
            newEntry.valeurDate = this.FixDate(AmountEntryExtra.getTokosPlus6Months(DateTime.fromISO(this.enhmeroKefalaioList[index].endDate as string), Frequency.SemiAnnually, true).toISODate() as string);
            // newEntry.interest = InterestRateHelper.GetInterestRateForDate(tokos.date);

            newEntry.CalculateDedouleymenousTokous(true);

            newEntry.dummytext = 'tokos ' + JSON.stringify(tokos);

            if (newEntry.amount >= 0.005) {
                this.hasKathysterhmenousTokous = true;
                if (this.kathysterhmenoiTokoiFirstDate === '1970-01-01') { this.kathysterhmenoiTokoiFirstDate = newEntry.startDate; }
            }
            newEntry.ofeilhIndex = ++this.nextOfeilhIndex;
            this.currentKathTokXreos += newEntry.amount;
            console.log('currentKathTokXreos handleTokos1 ' + this.currentKathTokXreos);
            this.enhmeroKefalaioList[index].ofeilhIndex = newEntry.ofeilhIndex;
            // console.log('666 pushing1', JSON.stringify(newEntry))
            this.kathysterhmenoiTokoi.push(newEntry);
            return;
        }

        for (let i = 0; i < this.enhmeroKefalaioList.length; ++i) {
            let item = this.enhmeroKefalaioList[i];
            if (item.endDate === undefined || item.endDate > tokos.date) {

                let newEntry = new AmountEntryExtra(this.perithorio, this.eisfora, this.bash_ektokismou, this.pososto_tokwn_yperhmerias);
                newEntry.interest = this.GetInterestRateForDate(tokos.date);
                newEntry.startDate = tokos.date;
                newEntry.amount = item.amount;

                if (item.endDate !== undefined) {
                    newEntry.endDate = this.FixDate(item.endDate as string);
                    newEntry.valeurDate = this.FixDate(item.endDate as string);
                    newEntry.CalculateDedouleymenousTokous(false);
                }

                item.endDate = this.FixDate(tokos.date);
                item.valeurDate = this.FixDate(tokos.date);
                if (this.loanCapitalRepaymentType !== LoanRepaymentType.Tokoxreolytiko) {
                    item.CalculateDedouleymenousTokous(false);
                } else {
                    let rate = this.GetInterestRateForDate(DateTime.fromISO(tokos.date).startOf('year').toISODate() as string);
                    rate = (rate + this.eisfora + this.perithorio) / 100;
                    if (this.interestRepaymentFrequency === Frequency.Monthly)
                        rate = rate / 12;
                    else if (this.interestRepaymentFrequency === Frequency.Quarterly)
                        rate = rate / 4;
                    else if (this.interestRepaymentFrequency === Frequency.SemiAnnually)
                        rate = rate / 2;

                    // console.log('rate', rate, 'nper', nper, 'pv', pv, 'current enhm', currentEnhmero);

                    if (tokos.date === DateTime.now().toISODate()) {
                        let j = this.eventsList.length - 1
                        for (; j >= 0; --j) {
                            let item_ = this.eventsList[j];

                            if (item_.processed && item_.type === LoanAccountEventType.Dosh) {
                                if (item_.date <= DateTime.now().toISODate() && item_.date <= tokos.date) {
                                    break;
                                }
                            }
                        }

                        // found previous dosh event
                        if (j >= 0) {
                            let previousDoshDate = this.eventsList[j].date;

                            // previous dosh date + frequency !== event tokos date -> Do not calculate full dedouleymenous
                            if ((AmountEntryExtra.getNextFrequencyDate(DateTime.fromISO(previousDoshDate), this.interestRepaymentFrequency, this.interestRepaymentEpeteiako).toISODate() as string) !== tokos.date) {
                                let daysDedoul = DateTime.fromISO(tokos.date).diff(DateTime.fromISO(previousDoshDate), 'days').days;
                                let divider = 1;
                                if (this.interestRepaymentFrequency === Frequency.Monthly)
                                    divider = 12;
                                else if (this.interestRepaymentFrequency === Frequency.Quarterly)
                                    divider = 4;
                                else if (this.interestRepaymentFrequency === Frequency.SemiAnnually)
                                    divider = 2;

                                item.dedouleymenoi_tokoi = item.amount * rate * (daysDedoul / (360 / divider));//daysMonth);
                            } else {
                                item.dedouleymenoi_tokoi = item.amount * rate;
                            }
                        } else { // No previous doseis
                            item.dedouleymenoi_tokoi = item.amount * rate;
                        }
                    }
                }

                item.tokoi_periodou = 0;

                let sum = 0;
                for (let j = 0; j < this.enhmeroKefalaioList.length; ++j) {
                    let item2 = this.enhmeroKefalaioList[j];
                    if (item2.startDate >= (fromDate.toISODate() as string) && (item2.endDate as string) <= tokos.date) {
                        item.tokoi_periodou = item.tokoi_periodou + item2.dedouleymenoi_tokoi;
                        item2.ignore = true;
                        sum += item2.dedouleymenoi_tokoi
                    }
                }

                let kathtokoidays = DateTime.fromISO(tokos.date as string).diff(DateTime.fromISO(this.kathysterhmenoiTokoiFirstDate), 'days').days;
                if (item.tokoi_periodou > 0) this.AddKinhsh(tokos.date, item.tokoi_periodou, 0, (this.hasKathysterhmenousTokous &&
                    kathtokoidays > 90 && this.kathysterhmenoiTokoiFirstDate !== '1970-01-01'
                    ? 'Μη λογιστικοποιημένοι ' : '') + 'Τόκοι Ενήμερου Κεφαλαίου');
                this.enhmeroKefalaioList[this.enhmeroKefalaioList.length - 1].ofeilhIndex = ++this.nextOfeilhIndex;
                // newEntry.ofeilhIndex = this.nextOfeilhIndex;
                this.enhmeroKefalaioList.push(newEntry);

                let newEntry2 = new AmountEntryExtra(this.perithorio, this.eisfora, this.bash_ektokismou, this.pososto_tokwn_yperhmerias);
                newEntry2.interest = this.GetInterestRateForDate(tokos.date);
                newEntry2.startDate = tokos.date;
                newEntry2.amount = sum;
                newEntry2.endDate = this.FixDate(AmountEntryExtra.getTokosPlus6Months(DateTime.fromISO(tokos.date as string), Frequency.SemiAnnually, true).toISODate() as string);
                newEntry2.valeurDate = this.FixDate(AmountEntryExtra.getTokosPlus6Months(DateTime.fromISO(tokos.date as string), Frequency.SemiAnnually, true).toISODate() as string);

                // 30/12 -> 31/12
                if (!this.interestRepaymentEpeteiako && newEntry2.endDate.endsWith('12-30')) {
                    newEntry2.endDate = newEntry2.endDate.replace('12-30', '12-31');
                }
                if (!this.interestRepaymentEpeteiako && newEntry2.valeurDate.endsWith('12-30')) {
                    newEntry2.valeurDate = newEntry2.valeurDate.replace('12-30', '12-31');
                }
                if (this.isOmologiako && this.calculateArgies && this.isArgia(newEntry2.endDate)) {
                    let epom = this.EpomenhErgasimh(newEntry2.endDate);

                    if (DateTime.fromISO(epom).month !== DateTime.fromISO(newEntry2.endDate).month || DateTime.fromISO(epom).year !== DateTime.fromISO(newEntry2.endDate).year) {
                        epom = this.ProhgoumenhErgasimh(newEntry2.endDate);
                    }

                    newEntry2.endDate = epom;
                }       

                newEntry2.CalculateDedouleymenousTokous(true);

                newEntry2.periodos_index = this.nextAvailableTokoiTokwnPeriodosIndex++;
                newEntry2.dummytext = 'tokos ' + JSON.stringify(tokos);

                if (newEntry2.amount >= 0.005) {
                    this.hasKathysterhmenousTokous = true;
                    if (this.kathysterhmenoiTokoiFirstDate === '1970-01-01') {
                        this.kathysterhmenoiTokoiFirstDate = newEntry2.startDate;
                    }
                }

                newEntry2.ofeilhIndex = this.nextOfeilhIndex;
                // newEntry.ofeilhIndex;
                // if (newEntry2.endDate !== newEntry2.startDate) {
                /*
                Check if argies 
                */
                if (this.calculateArgies) {
                    let epomenhErgasimh = this.EpomenhErgasimh(tokos.date);
                    let sameMonth = true;

                    if (DateTime.fromISO(epomenhErgasimh).month !== DateTime.fromISO(tokos.date).month && DateTime.fromISO(epomenhErgasimh).year !== DateTime.fromISO(tokos.date).year) {
                        sameMonth = false;
                    }

                    if (!this.isOmologiako || (this.isOmologiako && sameMonth)) {
                        if (this.isArgia(tokos.date)) {
                            let mellontikoiTokoi = 0;

                            let filteredEvents = this.eventsList.filter((evt: LoanAccountEventEntry) => !evt.processed && evt.date >= tokos.date && evt.date < epomenhErgasimh && (evt.type === LoanAccountEventType.KathysterhmenoKefalaio || evt.type === LoanAccountEventType.TokoiKathysterhmenwnTokwn));

                            for (let evt of filteredEvents) {
                                switch (evt.type) {
                                    case LoanAccountEventType.KathysterhmenoKefalaio:
                                        let kathKefEggrafes = this.kathysterhmenoKefalaio.filter((entry: AmountEntryExtra) => entry.periodos_index === evt.periodos_index);
                                        for (let kathKef of kathKefEggrafes) {
                                            mellontikoiTokoi += kathKef.dedouleymenoi_tokoi;
                                        }
                                        break;

                                    case LoanAccountEventType.TokoiKathysterhmenwnTokwn:
                                        let kathTokEggrafes = this.kathysterhmenoiTokoi.filter((entry: AmountEntryExtra) => entry.periodos_index === evt.periodos_index);
                                        for (let kathTok of kathTokEggrafes) {
                                            let days = DateTime.fromISO(kathTok.endDate as string).diff(DateTime.fromISO(kathTok.startDate), 'days').days;
                                            mellontikoiTokoi += kathTok.amount * days * (kathTok.interest / 100 + kathTok.GetPerithorio() + kathTok.GetEisfora() + kathTok.pososto_tokwn_yperhmerias / 100) / kathTok.GetDivisibleDays();
                                        }
                                        break;
                                }
                            }

                            let synolikhOfeilh = this.currentKathTokXreos + this.currentExoda + mellontikoiTokoi + newEntry2.amount;
                            let eventsPistwsewn = this.eventsList.filter((evt: LoanAccountEventEntry) => evt.date >= tokos.date && evt.date <= epomenhErgasimh && evt.type === LoanAccountEventType.Pistwsh);
                            let eventsExoda = this.eventsList.filter((evt: LoanAccountEventEntry) => evt.date >= tokos.date && evt.date <= epomenhErgasimh && evt.type === LoanAccountEventType.Exodo);

                            for (let exodo of eventsExoda) {
                                synolikhOfeilh += exodo.amount;
                            }

                            for (let pistwsh of eventsPistwsewn) {
                                synolikhOfeilh -= pistwsh.amount;
                            }
                            if (synolikhOfeilh <= 0) {
                                let newEntry0 = new AmountEntryExtra(this.perithorio, this.eisfora, this.bash_ektokismou, this.pososto_tokwn_yperhmerias);
                                newEntry0.startDate = tokos.date;
                                newEntry0.endDate = epomenhErgasimh;
                                newEntry0.interest = this.GetInterestRateForDate(tokos.date);
                                newEntry0.dedouleymenoi_tokoi = 0;
                                newEntry0.amount = newEntry2.amount;
                                newEntry0.ofeilhIndex = newEntry2.ofeilhIndex;
                                newEntry0.periodos_index = newEntry2.periodos_index;
                                newEntry0.ignoreDedoul = true;

                                newEntry2.startDate = epomenhErgasimh;
                                // newEntry2.dedouleymenoi_tokoi = 0;
                                this.seiraKathTok++;
                                newEntry0.dummytext += ' apo 0TOK ** ' + this.seiraKathTok + ' ';
                                this.kathysterhmenoiTokoi.push(newEntry0);
                            }
                        }
                    }
                    else {
                        //omologiako

                    }
                }
                this.kathysterhmenoiTokoi.push(newEntry2);
                this.currentKathTokXreos += newEntry2.amount;
                console.log('currentKathTokXreos handleTokos2 ' + this.currentKathTokXreos);
                // }
                // console.log('777x HandleTOKOS' + newEntry2.endDate + ' ' + tokos.date + ' ' + newEntry2.periodos_index)
                if (
                    (AmountEntryExtra.getTokosPlus6Months(DateTime.fromISO(tokos.date as string), Frequency.SemiAnnually, true).toISODate() as string) <= DateTime.now().toISODate()
                    // || newEntry2.endDate <=DateTime.now().toISODate()
                ) {
                    // console.log('777x - Mpike if' + newEntry2.periodos_index + ' ' + newEntry2.endDate)
                    this.eventsList.push({
                        type: LoanAccountEventType.TokoiKathysterhmenwnTokwn,
                        date: newEntry2.endDate,
                        amount: 0,
                        processed: false,
                        periodos_index: newEntry2.periodos_index,
                        perigrafi: ""
                    });
                }
                // console.log('ALL EV' + JSON.stringify(this.eventsList));
                break;
            }
        }
    }

    private HandleXrewsh(xrewsh: LoanAccountEventEntry) {
        let currentEnhmero: number = 0;
        if (this.enhmeroKefalaioList.length === 0) {
            currentEnhmero = xrewsh.amount;
            this.currentEnhmXreos = xrewsh.amount;
            console.log('currentEnhmXreos handleXrewsh 1: ' + this.currentEnhmXreos);
        } else {
            currentEnhmero = this.enhmeroKefalaioList[this.enhmeroKefalaioList.length - 1].amount;
            this.currentEnhmXreos = this.enhmeroKefalaioList[this.enhmeroKefalaioList.length - 1].amount;
            console.log('currentEnhmXreos handleXrewsh 2: ' + this.currentEnhmXreos);
        }

        let newEntry = new AmountEntryExtra(this.perithorio, this.eisfora, this.bash_ektokismou, this.pososto_tokwn_yperhmerias);
        newEntry.interest = this.GetInterestRateForDate(xrewsh.date);
        newEntry.startDate = xrewsh.date;
        newEntry.amount = currentEnhmero + xrewsh.amount;
        this.enhmeroKefalaioList.push(newEntry);

        this.AddKinhsh(xrewsh.date, xrewsh.amount, 0, xrewsh.perigrafi);
    }


    private HandleExodo(exodo: LoanAccountEventEntry) {
        this.currentExoda += exodo.amount;
        if ((exodo.perigrafi === null) || (exodo.perigrafi === '')) { exodo.perigrafi = ''; } else { exodo.perigrafi = ': ' + exodo.perigrafi }
        this.AddKinhsh(exodo.date, exodo.amount, 0, 'Εξοδα ' + exodo.perigrafi);
    }

    private HandleDosh(dosh: LoanAccountEventEntry) {
        let temp_tokoi_periodou: number = 0;
        // if (dosh.date > DateTime.now().toISODate()) {return;}
        let currentEnhmero = this.enhmeroKefalaioList[this.enhmeroKefalaioList.length - 1].amount;
        if (this.loanCapitalRepaymentType === LoanRepaymentType.Tokoxreolytiko) {

            // console.log('rate1', DateTime.fromISO(dosh.date).startOf('year').toISODate() as string);
            let rate = this.GetInterestRateForDate(DateTime.fromISO(dosh.date).startOf('year').toISODate() as string);
            rate += this.perithorio + this.eisfora;
            rate = rate / 100;
            let nper = DateTime.fromISO(this.loanEndingDate).diff(DateTime.fromISO(this.loanStartingDate), 'months').months / this.interestRepaymentFrequency;
            let pv = currentEnhmero;

            if (this.interestRepaymentFrequency === Frequency.Monthly)
                rate = rate / 12;
            else if (this.interestRepaymentFrequency === Frequency.Quarterly)
                rate = rate / 4;
            else if (this.interestRepaymentFrequency === Frequency.SemiAnnually)
                rate = rate / 2;
            // console.log('rate', rate, 'nper', nper, 'pv', pv, 'current enhm', currentEnhmero);
            if (this.pmtInitial === 0) {
                this.pmtInitial = -LoanAccount.PMT(rate, nper, pv);
                this.pmtPeriods = nper;
            }

            let tokoi_periodou = currentEnhmero * rate;
            // console.log('rate pmtResult', this.pmtInitial, 'tokoi_periodou', tokoi_periodou);
            dosh.amount = this.pmtInitial - tokoi_periodou;
            temp_tokoi_periodou = tokoi_periodou;
            --this.pmtPeriods;
        } else if (this.loanCapitalRepaymentType === LoanRepaymentType.XreolytikoIsoposo) {
            let nper = DateTime.fromISO(this.loanEndingDate).diff(DateTime.fromISO(this.loanStartingDate), 'months').months / this.interestRepaymentFrequency;

            if (this.pmtInitial === 0) {
                this.pmtPeriods = nper;
                this.pmtInitial = -1;
            }

            dosh.amount = currentEnhmero / this.pmtPeriods;

            --this.pmtPeriods;
        }

        let enhmerwmenoKefalaioWithSameStartingDay = this.enhmeroKefalaioList.find((enhmeroEntry: AmountEntryExtra) => enhmeroEntry.startDate === dosh.date);

        // Se periptwsh pou yparxei enhmerwmeno kefalaio pou 3ekinaei thn idia mera, afairoume apo ayto (dhmiourgh8hke apo kapoia allh energeia)
        if (enhmerwmenoKefalaioWithSameStartingDay !== undefined) {
            if (dosh.amount > currentEnhmero) { dosh.amount = currentEnhmero; }
            enhmerwmenoKefalaioWithSameStartingDay.amount -= dosh.amount;
            enhmerwmenoKefalaioWithSameStartingDay.doshAmount = dosh.amount;
            if (this.loanCapitalRepaymentType === LoanRepaymentType.Tokoxreolytiko) {
                enhmerwmenoKefalaioWithSameStartingDay.dedouleymenoi_tokoi = temp_tokoi_periodou;
            }

        } else { // alliws dhmiourgoume nea periodo enhmerwmenou
            if (dosh.date <= DateTime.now().toISODate()) {
                this.enhmeroKefalaioList[this.enhmeroKefalaioList.length - 1].endDate = dosh.date;
                this.enhmeroKefalaioList[this.enhmeroKefalaioList.length - 1].valeurDate = dosh.date;

                this.enhmeroKefalaioList[this.enhmeroKefalaioList.length - 1].CalculateDedouleymenousTokous(false);
                if (this.loanCapitalRepaymentType === LoanRepaymentType.Tokoxreolytiko) {
                    this.enhmeroKefalaioList[this.enhmeroKefalaioList.length - 1].dedouleymenoi_tokoi = temp_tokoi_periodou;
                }
            }

            let newEntry = new AmountEntryExtra(this.perithorio, this.eisfora, this.bash_ektokismou, this.pososto_tokwn_yperhmerias);
            newEntry.interest = this.GetInterestRateForDate(dosh.date);
            newEntry.startDate = dosh.date;
            if (dosh.amount > currentEnhmero) { dosh.amount = currentEnhmero; }
            newEntry.amount = currentEnhmero - dosh.amount;
            this.currentEnhmXreos = newEntry.amount;
            console.log('currentEnhmXreos handleDosh 1: ' + this.currentEnhmXreos);
            newEntry.doshAmount = dosh.amount;

            if (newEntry.amount >= 0 && dosh.date <= DateTime.now().toISODate()) {
                this.enhmeroKefalaioList.push(newEntry);
            }
        }


        let addKathysterhmenoKefalaio = this.kathysterhmenoKefalaio.length > 0 ? this.kathysterhmenoKefalaio[this.kathysterhmenoKefalaio.length - 1].amount : 0;

        let existingKathysterhmenoKefalaio = this.kathysterhmenoKefalaio.find((entry: AmountEntryExtra) => entry.startDate === dosh.date);

        if (existingKathysterhmenoKefalaio) {
            existingKathysterhmenoKefalaio.amount += dosh.amount;
            existingKathysterhmenoKefalaio.CalculateDedouleymenousTokous(true);
            return;
        }

        let newEntry = new AmountEntryExtra(this.perithorio, this.eisfora, this.bash_ektokismou, this.pososto_tokwn_yperhmerias);
        newEntry.interest = this.GetInterestRateForDate(dosh.date);
        newEntry.type = LoanAccountEventType.Dosh;
        newEntry.startDate = dosh.date;
        newEntry.endDate = this.FixDate(AmountEntryExtra.getNextFrequencyDate(DateTime.fromISO(dosh.date), Frequency.SemiAnnually, false).toISODate() as string);
        newEntry.valeurDate = this.FixDate(AmountEntryExtra.getNextFrequencyDate(DateTime.fromISO(dosh.date), Frequency.SemiAnnually, false).toISODate() as string);

        newEntry.amount = dosh.amount + addKathysterhmenoKefalaio;
        newEntry.periodos_index = this.nextAvailableKathysterhmenoKefalaioPeriodosIndex;
        let lastKathysterhmenoItem = this.kathysterhmenoKefalaio[this.kathysterhmenoKefalaio.length - 1];
        if (this.kathysterhmenoKefalaio.length) {
            if (dosh.date <= DateTime.now().toISODate()) {
                let days = DateTime.fromISO(dosh.date).diff(DateTime.fromISO(lastKathysterhmenoItem.startDate), 'days').days;
                lastKathysterhmenoItem.dedouleymenoi_tokoi = (lastKathysterhmenoItem.amount * days * (lastKathysterhmenoItem.interest / 100 + lastKathysterhmenoItem.perithorio / 100 + lastKathysterhmenoItem.eisfora / 100 + lastKathysterhmenoItem.pososto_tokwn_yperhmerias / 100) / 360);
                // console.log('endDate APO:' + lastKathysterhmenoItem.endDate)
                lastKathysterhmenoItem.endDate = dosh.date;
                lastKathysterhmenoItem.valeurDate = dosh.date;
                // console.log('endDate SE:' + lastKathysterhmenoItem.endDate)
            }
        }

        let days = DateTime.fromISO(newEntry.endDate).diff(DateTime.fromISO(newEntry.startDate), 'days').days;
        newEntry.dedouleymenoi_tokoi = newEntry.amount * days * (newEntry.interest / 100 + newEntry.perithorio / 100 + newEntry.eisfora / 100 + newEntry.pososto_tokwn_yperhmerias / 100) / 360;
        newEntry.dummytext = 'dosh ' + newEntry.endDate;

        if (dosh.date <= DateTime.now().toISODate()) {

            if (this.calculateArgies) {
                let epomenhErgasimh = this.EpomenhErgasimh(dosh.date);
                let sameMonth = true;

                if (DateTime.fromISO(epomenhErgasimh).month !== DateTime.fromISO(dosh.date).month || DateTime.fromISO(epomenhErgasimh).year !== DateTime.fromISO(dosh.date).year) {
                    sameMonth = false;
                }

                if (!this.isOmologiako || (this.isOmologiako && sameMonth)) {
                    console.log('hello1')
                    if (this.isArgia(dosh.date)) {
                        console.log('hello11-' + dosh.date)
                        let epomenhErgasimh = this.EpomenhErgasimh(dosh.date);

                        let synolikhOfeilh = this.currentKathTokXreos + this.currentKathKefXreos + this.currentExoda + dosh.amount;
                        let eventsPistwsewn = this.eventsList.filter((evt: LoanAccountEventEntry) => evt.date >= dosh.date && evt.date <= epomenhErgasimh && evt.type === LoanAccountEventType.Pistwsh);
                        let eventsExoda = this.eventsList.filter((evt: LoanAccountEventEntry) => evt.date >= dosh.date && evt.date <= epomenhErgasimh && evt.type === LoanAccountEventType.Exodo);

                        for (let exodo of eventsExoda) {
                            synolikhOfeilh += exodo.amount;
                        }

                        for (let pistwsh of eventsPistwsewn) {
                            synolikhOfeilh -= pistwsh.amount;
                        }

                        console.log('hello111-')
                        if (synolikhOfeilh <= 0) {
                            console.log('hello1111-')
                            let newEntry0 = new AmountEntryExtra(this.perithorio, this.eisfora, this.bash_ektokismou, this.pososto_tokwn_yperhmerias);
                            newEntry0.startDate = dosh.date;
                            newEntry0.endDate = epomenhErgasimh;
                            newEntry0.interest = this.GetInterestRateForDate(dosh.date);
                            newEntry0.dedouleymenoi_tokoi = 0;
                            newEntry0.amount = newEntry.amount;
                            newEntry0.periodos_index = newEntry.periodos_index;
                            newEntry0.ignore = true;

                            newEntry.startDate = epomenhErgasimh;
                            this.seiraKathKef++;
                            newEntry0.dummytext += ' apo 0 ** ' + this.seiraKathKef + ' ';
                            this.kathysterhmenoKefalaio.push(newEntry0);
                        }
                    }
                    //     if( argia && pistwsh_ftanei) {
                    //         let newEntry0;
                    //         newEntry0.dedouleymenoi_tokoi=0;
                    //         this.kathysterhmenoKefalaio.push(newEntry0);
                    //         newEntry.startDate=newEntry0.endDate;
                    //     }
                } else { // omologiako

                }

            }
            this.seiraKathKef++;
            newEntry.dummytext += ' ' + this.seiraKathKef + ' ';

            if (this.isOmologiako && this.calculateArgies) {
                if (this.isArgia(newEntry.endDate)) {
                    let epomenhErgasimh = this.EpomenhErgasimh(newEntry.endDate);

                    if (DateTime.fromISO(epomenhErgasimh).month !== DateTime.fromISO(newEntry.endDate).month || DateTime.fromISO(epomenhErgasimh).year !== DateTime.fromISO(newEntry.endDate).year) {
                        newEntry.endDate = this.ProhgoumenhErgasimh(newEntry.endDate);
                    }
                }
            }

            this.kathysterhmenoKefalaio.push(newEntry);

            // // this.currentKathKefXreos+=dosh.amount;
            this.currentKathKefXreos = newEntry.amount;
            console.log('currentKathKefXreos handleDosh 1 ' + this.currentKathKefXreos)
        }
        if (this.eventsList.find((entry: LoanAccountEventEntry) => entry.date === newEntry.endDate && entry.periodos_index === newEntry.periodos_index && !entry.processed && entry.type === LoanAccountEventType.KathysterhmenoKefalaio) === undefined) {
            this.eventsList.push({
                type: LoanAccountEventType.KathysterhmenoKefalaio,
                date: newEntry.endDate,
                amount: 0,
                processed: false,
                periodos_index: newEntry.periodos_index,
                perigrafi: ""
            });
        }



        // this.RecalculateTokoiPediodouKathysterhmenouKefalaiou();
    }

    private HandleInterestChange(interestChange: LoanAccountEventEntry) {
        let currentEnhmero = this.enhmeroKefalaioList[this.enhmeroKefalaioList.length - 1];

        // console.log('yikes added interest for', interestChange.date, 'currentEnhmero.startDate', currentEnhmero.startDate);

        if (interestChange.date === currentEnhmero.startDate) {
            currentEnhmero.interest = this.GetInterestRateForDate(interestChange.date);
        } else if (interestChange.date <= (currentEnhmero.endDate as string)) {
            currentEnhmero.endDate = interestChange.date;
            currentEnhmero.valeurDate = interestChange.date;
            currentEnhmero.CalculateDedouleymenousTokous(false);
            // currentEnhmero.tokoi_periodou = 0;

            let newEntry = new AmountEntryExtra(this.perithorio, this.eisfora, this.bash_ektokismou, this.pososto_tokwn_yperhmerias);
            newEntry.interest = this.GetInterestRateForDate(interestChange.date);
            newEntry.startDate = interestChange.date;
            newEntry.amount = currentEnhmero.amount;
            this.enhmeroKefalaioList.push(newEntry);

        }

        // if (interestChange.date !== currentEnhmero.startDate) {
        //     currentEnhmero.endDate = interestChange.date;
        //     currentEnhmero.CalculateDedouleymenousTokous(0);
        //     currentEnhmero.tokoi_periodou = 0;

        //     let newEntry = new AmountEntryExtra(this.perithorio, this.eisfora, this.bash_ektokismou, this.pososto_tokwn_yperhmerias);
        //     newEntry.interest = this.GetInterestRateForDate(interestChange.date);
        //     newEntry.startDate = interestChange.date;
        //     newEntry.amount = currentEnhmero.amount;
        //     this.enhmeroKefalaioList.push(newEntry);
        // } else {
        //     console.log('yikes nope')
        // }

        for (let i = 0; i < this.kathysterhmenoKefalaio.length; ++i) {
            let item = this.kathysterhmenoKefalaio[i];

            if (item.startDate < interestChange.date && (item.endDate as string) > interestChange.date) {
                let newEntry = new AmountEntryExtra(this.perithorio, this.eisfora, this.bash_ektokismou, this.pososto_tokwn_yperhmerias);
                newEntry.interest = this.GetInterestRateForDate(interestChange.date);
                newEntry.startDate = interestChange.date;
                newEntry.amount = item.amount;
                newEntry.endDate = this.FixDate(item.endDate as string);
                newEntry.valeurDate = this.FixDate(item.endDate as string);
                newEntry.type = item.type;
                newEntry.periodos_index = item.periodos_index;
                newEntry.ofeilhIndex = item.ofeilhIndex;
                newEntry.CalculateDedouleymenousTokous(true);

                if (newEntry.startDate === newEntry.endDate) {
                    continue;
                }

                item.endDate = newEntry.startDate;
                item.valeurDate = newEntry.startDate;
                item.CalculateDedouleymenousTokous(true);

                // console.log('injecting', JSON.stringify(newEntry))
                this.kathysterhmenoKefalaio.splice(i + 1, 0, newEntry);
                // break;
            }
        }

        // this.RecalculateTokoiPediodouKathysterhmenouKefalaiou();

        for (let i = 0; i < this.kathysterhmenoiTokoi.length; ++i) {
            let item = this.kathysterhmenoiTokoi[i];

            if (item.startDate < interestChange.date && (item.endDate as string) > interestChange.date) {
                let newEntry = new AmountEntryExtra(this.perithorio, this.eisfora, this.bash_ektokismou, this.pososto_tokwn_yperhmerias);
                newEntry.interest = this.GetInterestRateForDate(interestChange.date);
                newEntry.startDate = interestChange.date;
                newEntry.amount = item.amount;
                newEntry.endDate = this.FixDate(item.endDate as string);
                newEntry.valeurDate = this.FixDate(item.endDate as string);
                newEntry.type = item.type;
                newEntry.periodos_index = item.periodos_index;
                newEntry.ofeilhIndex = item.ofeilhIndex;
                newEntry.CalculateDedouleymenousTokous(true);

                if (newEntry.startDate === newEntry.endDate) {
                    continue;
                }
                item.endDate = newEntry.startDate;
                item.valeurDate = newEntry.startDate;
                if (!item.ignoreDedoul) {
                    item.CalculateDedouleymenousTokous(true);
                }
                // item.ignore = true;
                this.kathysterhmenoiTokoi.splice(i + 1, 0, newEntry);
                // break;
            }
        }

        // this.RecalculateTokoiPediodouKathysterhmenwnTokwn();
    }

    private HandlePistwsh(pistwsh: LoanAccountEventEntry) {
        console.log('gogo pistwsh', JSON.stringify(pistwsh));
        if (pistwsh.amount <= 0) {
            return;
        }
        if (this.currentExoda > 0) {
            if (pistwsh.amount > this.currentExoda) {
                pistwsh.amount -= this.currentExoda;
                this.currentExoda = 0;
            }
            else if (pistwsh.amount < this.currentExoda) {
                this.currentExoda -= pistwsh.amount;
                pistwsh.amount = 0;
            }
            else if (pistwsh.amount === this.currentExoda) {
                pistwsh.amount -= this.currentExoda;
                this.currentExoda = 0;
                return;
            }
        }

        // Xrhsh pistwshs se kathysterhmenous tokous

        for (let i = 0; i < this.kathysterhmenoiTokoi.length; ++i) {
            console.log('gogo1')
            let item = this.kathysterhmenoiTokoi[i];

            if (pistwsh.amount <= 0)
                break;

            console.log('gogo1.5 ' + JSON.stringify(item))
            if (item.amount <= 0 || item.ignore) {
                continue;
            }
            console.log('gogo1.6')
            if (((item.endDate as string) < pistwsh.date || item.startDate > pistwsh.date)) {
                continue
            }

            console.log('gogo2');

            item.dummytext = 'pistwsh kath tok' + JSON.stringify(item) + ' --- ';
            item.dummytext = 'pistwsh kath tok' + item.endDate + " " + pistwsh.amount + " " + item.ignore;
            let oldEntry = new AmountEntryExtra(this.perithorio, this.eisfora, this.bash_ektokismou, this.pososto_tokwn_yperhmerias);
            oldEntry = JSON.parse(JSON.stringify(item));
            let newEntry = new AmountEntryExtra(this.perithorio, this.eisfora, this.bash_ektokismou, this.pososto_tokwn_yperhmerias);
            newEntry.interest = item.interest; // this.GetInterestRateForDate(pistwsh.date);
            newEntry.startDate = pistwsh.date;
            newEntry.endDate = item.endDate;
            newEntry.valeurDate = item.endDate;
            newEntry.type = item.type;
            newEntry.periodos_index = item.periodos_index;
            newEntry.ofeilhIndex = item.ofeilhIndex;

            item.endDate = pistwsh.date;
            item.valeurDate = pistwsh.date;

            if (pistwsh.amount >= item.amount) {
                newEntry.usedPistwsh = item.amount;
                pistwsh.amount -= item.amount;
                newEntry.amount = 0;
                newEntry.ignore = true;
                this.currentKathTokXreos -= item.amount;
                console.log('currentKathTokXreos handlePistwsh1 ' + this.currentKathTokXreos);
            } else {
                newEntry.usedPistwsh = pistwsh.amount;
                newEntry.amount = item.amount - pistwsh.amount;
                this.currentKathTokXreos -= pistwsh.amount;
                console.log('currentKathTokXreos handlePistwsh2 ' + this.currentKathTokXreos);
                pistwsh.amount = 0;
            }

            let allArgies = true;
            if (this.calculateArgies) {
                let start = item.startDate;
                let end = item.endDate;

                if (start === end && !this.isArgia(start)) {
                    allArgies = false;
                }

                while (start !== end) {
                    if (!this.isArgia(start)) {
                        allArgies = false;
                        break;
                    }
                    start = DateTime.fromISO(start).plus({ days: 1 }).toISODate() as string;
                }
            }

            if (((this.calculateArgies && (!allArgies || newEntry.amount > 0)) || !this.calculateArgies)) {
                item.CalculateDedouleymenousTokous(true);
                newEntry.CalculateDedouleymenousTokous(true);
            }

            newEntry.dummytext = '666 pistwsh ' + JSON.stringify(pistwsh) + ' === ' + JSON.stringify(oldEntry);
            // newEntry.dummytext = '666 pistwsh ' ;

            if (newEntry.amount >= 0.005) {
                this.hasKathysterhmenousTokous = true;
                if (this.kathysterhmenoiTokoiFirstDate === '1970-01-01') { this.kathysterhmenoiTokoiFirstDate = newEntry.startDate; }
            }

            // console.log('666 pushing3', JSON.stringify(newEntry))
            this.kathysterhmenoiTokoi.splice(i + 1, 0, newEntry);

            if (i + 2 < this.kathysterhmenoiTokoi.length) {
                for (let j = i + 2; j < this.kathysterhmenoiTokoi.length; ++j) {
                    if (this.kathysterhmenoiTokoi[j].ofeilhIndex === newEntry.ofeilhIndex) {
                        this.kathysterhmenoiTokoi[j].amount = 0;
                        this.kathysterhmenoiTokoi[j].dedouleymenoi_tokoi = 0;
                    }
                }
            }
            // this.kathysterhmenoiTokoi.push(newEntry);

            // let firstKathysterhmeno = this.kathysterhmenoKefalaio[0];

            // if (firstKathysterhmeno.endDate === firstKathysterhmeno.date) {
            //     this.kathysterhmenoKefalaio.splice(0, 1);
            // }
        }

        // this.RecalculateTokoiPediodouKathysterhmenwnTokwn();

        let hasNonZeroOfeilh = false;

        for (let k = 0; k < this.kathysterhmenoiTokoi.length; k++) {
            let foundX = this.kathysterhmenoiTokoi.find((x: AmountEntryExtra) => x.ofeilhIndex === this.kathysterhmenoiTokoi[k].ofeilhIndex && x.amount > 0);

            if (foundX) {
                hasNonZeroOfeilh = true;
                break;
            }
        }

        if (!hasNonZeroOfeilh) {
            this.hasKathysterhmenousTokous = false;
            this.kathysterhmenoiTokoiFirstDate = '1970-01-01';
        }
        if (pistwsh.amount === 0) {

            return;
        }

        this.hasKathysterhmenousTokous = false;
        this.kathysterhmenoiTokoiFirstDate = '1970-01-01';

        // Xrhsh pistwshs se kathysterhmeno kefalaio
        let splicePosition = -1;
        for (let i = this.kathysterhmenoKefalaio.length - 1; i >= 0; --i) {
            let item = this.kathysterhmenoKefalaio[i];

            if ((item.startDate <= pistwsh.date && (item.endDate as string) >= pistwsh.date && item.amount > 0)) {
                splicePosition = i;
                break;
            }
        }

        let lastKathysterhmeno = undefined;

        if (splicePosition !== -1)
            lastKathysterhmeno = this.kathysterhmenoKefalaio[splicePosition]; // this.kathysterhmenoKefalaio[this.kathysterhmenoKefalaio.length - 1];

        if (lastKathysterhmeno !== undefined && pistwsh.amount > 0 &&
            ((!this.calculateArgies) ||
                ((this.calculateArgies && (
                    !this.isArgia(pistwsh.date) ||
                    (this.isArgia(pistwsh.date) && (this.EpomenhErgasimh(pistwsh.date) >= lastKathysterhmeno.startDate)))
                )
                ))) {
            let newEntry = new AmountEntryExtra(this.perithorio, this.eisfora, this.bash_ektokismou, this.pososto_tokwn_yperhmerias);
            newEntry.interest = lastKathysterhmeno.interest; // this.GetInterestRateForDate(pistwsh.date);
            newEntry.startDate = pistwsh.date;
            newEntry.endDate = lastKathysterhmeno.endDate;
            newEntry.valeurDate = lastKathysterhmeno.endDate;
            if (this.calculateArgies && this.isArgia(newEntry.valeurDate as string)) { newEntry.valeurDate = this.EpomenhErgasimh(newEntry.valeurDate as string); }
            newEntry.type = lastKathysterhmeno.type;
            newEntry.periodos_index = lastKathysterhmeno.periodos_index;

            lastKathysterhmeno.endDate = pistwsh.date;
            lastKathysterhmeno.valeurDate = pistwsh.date;
            if (this.calculateArgies && this.isArgia(lastKathysterhmeno.valeurDate)) { lastKathysterhmeno.valeurDate = this.EpomenhErgasimh(lastKathysterhmeno.valeurDate); }

            console.log('hello1-pistwsh-' + pistwsh.date);

            if (pistwsh.amount >= lastKathysterhmeno.amount) {
                newEntry.usedPistwsh = lastKathysterhmeno.amount;
                pistwsh.amount -= lastKathysterhmeno.amount;
                this.currentKathKefXreos -= lastKathysterhmeno.amount;
                newEntry.amount = 0;
            } else {
                newEntry.usedPistwsh = pistwsh.amount;
                newEntry.amount = lastKathysterhmeno.amount - pistwsh.amount;
                this.currentKathKefXreos -= pistwsh.amount;
                pistwsh.amount = 0;
            }

            let allArgies = true;
            if (this.calculateArgies) {
                let start = lastKathysterhmeno.startDate;
                let end = lastKathysterhmeno.endDate;

                if (start === end && !this.isArgia(start)) {
                    allArgies = false;
                }

                while (start !== end) {
                    if (!this.isArgia(start)) {
                        allArgies = false;
                        break;
                    }
                    start = DateTime.fromISO(start).plus({ days: 1 }).toISODate() as string;
                }
            }

            if ((lastKathysterhmeno !== undefined && this.calculateArgies && (!allArgies || newEntry.amount > 0)) ||
                (lastKathysterhmeno !== undefined && !this.calculateArgies)) {
                lastKathysterhmeno.CalculateDedouleymenousTokous(true);
            }
            newEntry.CalculateDedouleymenousTokous(true);
            let days = DateTime.fromISO(newEntry.endDate as string).diff(DateTime.fromISO(newEntry.startDate), 'days').days;

            if (days >= 0) {
                this.seiraKathKef++;
                newEntry.dummytext = 'pistwsh ' + this.seiraKathKef + ' ' + newEntry.endDate;

                if (splicePosition !== -1) {
                    this.kathysterhmenoKefalaio.splice(splicePosition + 1, 0, newEntry);

                    if (splicePosition + 2 <= this.kathysterhmenoKefalaio.length) {
                        for (let i = splicePosition + 2; i < this.kathysterhmenoKefalaio.length; ++i) {
                            this.kathysterhmenoKefalaio[i].amount = this.kathysterhmenoKefalaio[i - 1].amount;
                        }
                    }
                }
                // this.kathysterhmenoKefalaio.push(newEntry);
            }
        }

        // Xrhsh pistwshs se enhmero kefalaio

        if (pistwsh.amount === 0)
            return;

        let enhmeroLast = this.enhmeroKefalaioList[this.enhmeroKefalaioList.length - 1];
        let currentEnhmero = enhmeroLast.amount;

        let usedPistwsh = 0;
        if (pistwsh.amount > currentEnhmero) {
            usedPistwsh = currentEnhmero;
            pistwsh.amount -= currentEnhmero;
            this.currentEnhmXreos -= currentEnhmero;
            console.log('currentEnhmXreos handlePistwsh 1: ' + this.currentEnhmXreos);
            currentEnhmero = 0;
        } else {
            usedPistwsh = pistwsh.amount;
            currentEnhmero -= pistwsh.amount;
            this.currentEnhmXreos -= pistwsh.amount;
            console.log('currentEnhmXreos handlePistwsh 2: ' + this.currentEnhmXreos);
            pistwsh.amount = 0;
        }

        if (this.loanCapitalRepaymentType === LoanRepaymentType.Tokoxreolytiko) {
            let rate = this.GetInterestRateForDate(DateTime.fromISO(pistwsh.date).startOf('year').toISODate() as string);
            let nper = DateTime.fromISO(this.loanEndingDate).diff(DateTime.fromISO(pistwsh.date), 'months').months / this.interestRepaymentFrequency;
            let pv = currentEnhmero;

            if (this.interestRepaymentFrequency === Frequency.Monthly)
                rate = rate / 12;
            else if (this.interestRepaymentFrequency === Frequency.Quarterly)
                rate = rate / 4;
            else if (this.interestRepaymentFrequency === Frequency.SemiAnnually)
                rate = rate / 2;
            // console.log('rate', rate, 'nper', nper, 'pv', pv, 'current enhm', currentEnhmero);

            this.pmtInitial = -LoanAccount.PMT(rate, this.pmtPeriods, pv);
        }

        let days = DateTime.fromISO(pistwsh.date).diff(DateTime.fromISO(enhmeroLast.startDate), 'days').days;
        enhmeroLast.dedouleymenoi_tokoi = (enhmeroLast.amount * days * (enhmeroLast.interest / 100 + enhmeroLast.perithorio / 100 + enhmeroLast.eisfora / 100) / 360);

        enhmeroLast.endDate = pistwsh.date;
        enhmeroLast.valeurDate = pistwsh.date;

        let newEntry = new AmountEntryExtra(this.perithorio, this.eisfora, this.bash_ektokismou, this.pososto_tokwn_yperhmerias);
        newEntry.interest = enhmeroLast.interest; // this.GetInterestRateForDate(pistwsh.date);
        newEntry.startDate = pistwsh.date;
        newEntry.amount = currentEnhmero;
        newEntry.usedPistwsh = usedPistwsh;
        // newEntry.ofeilhIndex = enhmeroLast.ofeilhIndex;

        this.enhmeroKefalaioList.push(newEntry);
    }

    public GetTokoiPeriodouForKathysterhmenoKefalaioAtDate(date: string): number {
        for (let i = 0; i < this.kathysterhmenoKefalaio.length; ++i) {
            let item = this.kathysterhmenoKefalaio[i];

            if (item.endDate === date) {
                return item.tokoi_periodou;
            }
        }

        return 0;
    }

    public HandleTokoiKathysterhmenwnTokwn(tokoi: LoanAccountEventEntry) {
        // console.log('yikes4 pushing doing handle tokoi kathysterhmenwn tokwn event', JSON.stringify(tokoi));

        if (tokoi.date > DateTime.now().toISODate()) {
            // console.log('777x RETURN' + JSON.stringify(tokoi))
            // console.log('Stopping processing, found future date' + tokoi.date);
            return;
        }

        let eggrafesKathysterhmenou = this.kathysterhmenoiTokoi.filter((entry: AmountEntryExtra) => entry.periodos_index === tokoi.periodos_index);

        let tokoiPeriodou = 0;

        for (let eggrafh of eggrafesKathysterhmenou) {
            // console.log('777x' + JSON.stringify(tokoi))

            if (!eggrafh.ignoreDedoul) {
                eggrafh.CalculateDedouleymenousTokous(true);
            }

            tokoiPeriodou += eggrafh.dedouleymenoi_tokoi;
            eggrafh.ignore = true;
        }
        // console.log('777x LAST' + JSON.stringify(eggrafesKathysterhmenou[eggrafesKathysterhmenou.length - 1]))

        let kathtokoidays = DateTime.fromISO(tokoi.date as string).diff(DateTime.fromISO(this.kathysterhmenoiTokoiFirstDate), 'days').days;
        if (tokoiPeriodou >= 0.01) {
            this.AddKinhsh(tokoi.date, tokoiPeriodou, 0, (this.hasKathysterhmenousTokous &&
                kathtokoidays > 90 && this.kathysterhmenoiTokoiFirstDate !== '1970-01-01' ? 'Μη λογιστικοποιημένοι ' : '') + 'Τόκοι επί Τόκων');
        }

        console.log('currentKathTokXreos handletokoiKathTokwn1 ' + this.currentKathTokXreos)
        eggrafesKathysterhmenou[eggrafesKathysterhmenou.length - 1].tokoi_periodou = tokoiPeriodou;

        let newEntry = new AmountEntryExtra(this.perithorio, this.eisfora, this.bash_ektokismou, this.pososto_tokwn_yperhmerias);
        newEntry.interest = this.GetInterestRateForDate(tokoi.date);
        newEntry.startDate = tokoi.date;
        newEntry.endDate = this.FixDate(AmountEntryExtra.getNextFrequencyDate(DateTime.fromISO(tokoi.date), Frequency.SemiAnnually, true).toISODate() as string);
        newEntry.valeurDate = this.FixDate(AmountEntryExtra.getNextFrequencyDate(DateTime.fromISO(tokoi.date), Frequency.SemiAnnually, true).toISODate() as string);

        // Move 30/12 to 31/12
                if (!this.interestRepaymentEpeteiako && newEntry.endDate.endsWith('12-30')) {
                    newEntry.endDate = newEntry.endDate.replace('12-30', '12-31');
                }
                if (!this.interestRepaymentEpeteiako && newEntry.valeurDate.endsWith('12-30')) {
                    newEntry.valeurDate = newEntry.valeurDate.replace('12-30', '12-31');
                }

                if (newEntry.endDate >= DateTime.now().toISODate()) {
                    newEntry.endDate = DateTime.now().toISODate() as string;
                }
                if (newEntry.valeurDate >= DateTime.now().toISODate()) {
                    newEntry.valeurDate = DateTime.now().toISODate() as string;
                }

        if (this.isOmologiako && this.calculateArgies && this.isArgia(newEntry.endDate)) {
            let epom = this.EpomenhErgasimh(newEntry.endDate);
            if (DateTime.fromISO(epom).month !== DateTime.fromISO(newEntry.endDate).month || DateTime.fromISO(epom).year !== DateTime.fromISO(newEntry.endDate).year) {
                epom = this.ProhgoumenhErgasimh(newEntry.endDate);
            }
            newEntry.endDate = epom;
        }

        newEntry.amount = tokoiPeriodou + eggrafesKathysterhmenou[eggrafesKathysterhmenou.length - 1].amount;
        newEntry.periodos_index = this.nextAvailableTokoiTokwnPeriodosIndex++;
        newEntry.CalculateDedouleymenousTokous(true);

        newEntry.dummytext = 'kath tokoi ' + JSON.stringify(tokoi);

        if (newEntry.amount >= 0.005) {
            this.hasKathysterhmenousTokous = true;
            if (this.kathysterhmenoiTokoiFirstDate === '1970-01-01') { this.kathysterhmenoiTokoiFirstDate = newEntry.startDate; }
        }

        newEntry.ofeilhIndex = eggrafesKathysterhmenou[eggrafesKathysterhmenou.length - 1].ofeilhIndex;

        // console.log('666 pushing4', JSON.stringify(newEntry))
        if (newEntry.endDate !== newEntry.startDate) {

            if (this.calculateArgies) {
                let epomenhErgasimh = this.EpomenhErgasimh(tokoi.date);
                let sameMonth = true;

                if (DateTime.fromISO(epomenhErgasimh).month !== DateTime.fromISO(tokoi.date).month && DateTime.fromISO(epomenhErgasimh).year !== DateTime.fromISO(tokoi.date).year) {
                    sameMonth = false;
                }

                if (!this.isOmologiako || (this.isOmologiako && sameMonth)) {
                    if (this.isArgia(tokoi.date)) {
                        let epomenhErgasimh = this.EpomenhErgasimh(tokoi.date);
                        let mellontikoiTokoi = 0;

                        let filteredEvents = this.eventsList.filter((evt: LoanAccountEventEntry) => !evt.processed && evt.date >= tokoi.date && evt.date < epomenhErgasimh && evt.type === LoanAccountEventType.TokoiKathysterhmenwnTokwn);

                        for (let evt of filteredEvents) {
                            let kathTokEggrafes = this.kathysterhmenoiTokoi.filter((entry: AmountEntryExtra) => entry.periodos_index === evt.periodos_index);
                            for (let kathTok of kathTokEggrafes) {
                                let days = DateTime.fromISO(kathTok.endDate as string).diff(DateTime.fromISO(kathTok.startDate), 'days').days;
                                mellontikoiTokoi += kathTok.amount * days * (kathTok.interest / 100 + kathTok.GetPerithorio() + kathTok.GetEisfora() + kathTok.pososto_tokwn_yperhmerias / 100) / kathTok.GetDivisibleDays();
                            }
                        }

                        let synolikhOfeilh = this.currentKathTokXreos + this.currentExoda + mellontikoiTokoi;
                        let eventsPistwsewn = this.eventsList.filter((evt: LoanAccountEventEntry) => evt.date >= tokoi.date && evt.date <= epomenhErgasimh && evt.type === LoanAccountEventType.Pistwsh);
                        let eventsExoda = this.eventsList.filter((evt: LoanAccountEventEntry) => evt.date >= tokoi.date && evt.date <= epomenhErgasimh && evt.type === LoanAccountEventType.Exodo);

                        for (let exodo of eventsExoda) {
                            synolikhOfeilh += exodo.amount;
                        }

                        for (let pistwsh of eventsPistwsewn) {
                            synolikhOfeilh -= pistwsh.amount;
                        }

                        if (synolikhOfeilh <= 0) {
                            let newEntry0 = new AmountEntryExtra(this.perithorio, this.eisfora, this.bash_ektokismou, this.pososto_tokwn_yperhmerias);
                            newEntry0.startDate = tokoi.date;
                            newEntry0.endDate = epomenhErgasimh;
                            newEntry0.interest = this.GetInterestRateForDate(tokoi.date);
                            newEntry0.dedouleymenoi_tokoi = 0;
                            newEntry0.amount = newEntry.amount;
                            newEntry0.ofeilhIndex = newEntry.ofeilhIndex;
                            newEntry0.periodos_index = newEntry.periodos_index;
                            // newEntry0.ignore = true;
                            newEntry0.ignoreDedoul = true;

                            newEntry.startDate = epomenhErgasimh;
                            this.seiraKathTok++;
                            newEntry0.dummytext += ' apo 0KATHTOKTOK ** ' + this.seiraKathTok + ' ';
                            this.kathysterhmenoiTokoi.push(newEntry0);
                        }
                    }
                } else {
                    //omologiako
                }
            }
            this.kathysterhmenoiTokoi.push(newEntry);
        }

        this.currentKathTokXreos += tokoiPeriodou;

        if (newEntry.endDate <= DateTime.now().toISODate()) {
            let nextDate = (AmountEntryExtra.getNextFrequencyDate(DateTime.fromISO(tokoi.date), Frequency.SemiAnnually, true).toISODate() as string);




            // Move 30/12 to 31/12
            if (!this.interestRepaymentEpeteiako && nextDate.endsWith('12-30')) {
                nextDate = nextDate.replace('12-30', '12-31');
            }

             if (this.isOmologiako && this.calculateArgies && this.isArgia(nextDate)) {
                    let epom = this.EpomenhErgasimh(nextDate);
                    if (DateTime.fromISO(epom).month !== DateTime.fromISO(nextDate).month || DateTime.fromISO(epom).year !== DateTime.fromISO(nextDate).year) {
                        epom = this.ProhgoumenhErgasimh(nextDate);
                    }
                    nextDate = epom;
                }

                
/*********EDW */
            this.eventsList.push({
                type: LoanAccountEventType.TokoiKathysterhmenwnTokwn,
                date: nextDate,
                amount: 0,
                processed: false,
                periodos_index: newEntry.periodos_index,
                perigrafi: ""
            });
        }
    }

    public HandleKathysterhmenoKefalaio(kathysterhmenoKefalaio: LoanAccountEventEntry) {
        // console.log('mpike rrr' + JSON.stringify(this.kathysterhmenoKefalaio))
        let eggrafes = this.kathysterhmenoKefalaio.filter((entry: AmountEntryExtra) => entry.periodos_index === kathysterhmenoKefalaio.periodos_index);

        if (eggrafes.length === 0) {
            // console.log('mpike - vgike 0')
            return;
        }

        this.nextAvailableKathysterhmenoKefalaioPeriodosIndex++;

        let dedouleymenoi_tokoi = 0;
        for (let eggrafh of eggrafes) {
            dedouleymenoi_tokoi += eggrafh.dedouleymenoi_tokoi;
        }

        let kathtokoidays = DateTime.fromISO(kathysterhmenoKefalaio.date as string).diff(DateTime.fromISO(this.kathysterhmenoiTokoiFirstDate), 'days').days;

        if (dedouleymenoi_tokoi > 0) {
            this.AddKinhsh(kathysterhmenoKefalaio.date, dedouleymenoi_tokoi, 0, (this.hasKathysterhmenousTokous &&
                kathtokoidays > 90 && this.kathysterhmenoiTokoiFirstDate !== '1970-01-01'
                ? 'Μη λογιστικοποιημένοι ' : '') + 'Τόκοι Καθυστερημένου Κεφαλαίου');
        }

        eggrafes[eggrafes.length - 1].tokoi_periodou = dedouleymenoi_tokoi;

        if (dedouleymenoi_tokoi > 0) {
            let newEntryTokoi = new AmountEntryExtra(this.perithorio, this.eisfora, this.bash_ektokismou, this.pososto_tokwn_yperhmerias);
            newEntryTokoi.interest = this.GetInterestRateForDate(kathysterhmenoKefalaio.date);
            newEntryTokoi.startDate = kathysterhmenoKefalaio.date;

            let checkShmeraArgia: boolean = false;
            if ((AmountEntryExtra.getNextFrequencyDate(DateTime.fromISO(kathysterhmenoKefalaio.date), Frequency.SemiAnnually, true).toISODate() as string) > DateTime.now().toISODate()) {
                checkShmeraArgia = true;
            }


            newEntryTokoi.endDate = this.FixDate(AmountEntryExtra.getNextFrequencyDate(DateTime.fromISO(kathysterhmenoKefalaio.date), Frequency.SemiAnnually, true).toISODate() as string);

            newEntryTokoi.valeurDate = this.FixDate(AmountEntryExtra.getNextFrequencyDate(DateTime.fromISO(kathysterhmenoKefalaio.date), Frequency.SemiAnnually, true).toISODate() as string);

            // 30/12 to 31/12
            if (!this.interestRepaymentEpeteiako && newEntryTokoi.endDate.endsWith('12-30')) {
                newEntryTokoi.endDate = newEntryTokoi.endDate.replace('12-30', '12-31');
            }
            if (!this.interestRepaymentEpeteiako && newEntryTokoi.valeurDate.endsWith('12-30')) {
                newEntryTokoi.valeurDate = newEntryTokoi.valeurDate.replace('12-30', '12-31');
            }

            if (this.isOmologiako && this.calculateArgies && this.isArgia(newEntryTokoi.endDate) && !checkShmeraArgia) {
                newEntryTokoi.endDate = this.ProhgoumenhErgasimh(newEntryTokoi.endDate);
            }            

            newEntryTokoi.amount = dedouleymenoi_tokoi;
            newEntryTokoi.periodos_index = this.nextAvailableTokoiTokwnPeriodosIndex++;
            newEntryTokoi.CalculateDedouleymenousTokous(true);
            newEntryTokoi.dummytext = 'kath kefalaio ' + JSON.stringify(kathysterhmenoKefalaio);
            if (newEntryTokoi.amount >= 0.005) {
                this.hasKathysterhmenousTokous = true;
                if (this.kathysterhmenoiTokoiFirstDate === '1970-01-01') { this.kathysterhmenoiTokoiFirstDate = newEntryTokoi.startDate; }
            }
            newEntryTokoi.ofeilhIndex = ++this.nextOfeilhIndex;
            this.currentKathTokXreos += newEntryTokoi.amount;
            console.log('currentKathTokXreos handlekathKef1 ' + this.currentKathTokXreos);
            eggrafes[eggrafes.length - 1].ofeilhIndex = newEntryTokoi.ofeilhIndex;


            if (this.calculateArgies) {
                let epomenhErgasimh = this.EpomenhErgasimh(kathysterhmenoKefalaio.date);
                let sameMonth = true;

                if (DateTime.fromISO(epomenhErgasimh).month !== DateTime.fromISO(kathysterhmenoKefalaio.date).month && DateTime.fromISO(epomenhErgasimh).year !== DateTime.fromISO(kathysterhmenoKefalaio.date).year) {
                    sameMonth = false;
                }

                if (!this.isOmologiako || (this.isOmologiako && sameMonth)) {
                    if (this.isArgia(kathysterhmenoKefalaio.date)) {
                        let epomenhErgasimh = this.EpomenhErgasimh(kathysterhmenoKefalaio.date);
                        let mellontikoiTokoi = 0;

                        let filteredEvents = this.eventsList.filter((evt: LoanAccountEventEntry) => !evt.processed && evt.date >= kathysterhmenoKefalaio.date && evt.date < epomenhErgasimh && evt.type === LoanAccountEventType.TokoiKathysterhmenwnTokwn);

                        for (let evt of filteredEvents) {
                            let kathTokEggrafes = this.kathysterhmenoiTokoi.filter((entry: AmountEntryExtra) => entry.periodos_index === evt.periodos_index);
                            for (let kathTok of kathTokEggrafes) {
                                let days = DateTime.fromISO(kathTok.endDate as string).diff(DateTime.fromISO(kathTok.startDate), 'days').days;
                                mellontikoiTokoi += kathTok.amount * days * (kathTok.interest / 100 + kathTok.GetPerithorio() + kathTok.GetEisfora() + kathTok.pososto_tokwn_yperhmerias / 100) / kathTok.GetDivisibleDays();
                            }
                        }

                        let synolikhOfeilh = this.currentKathTokXreos + this.currentExoda + mellontikoiTokoi;
                        let eventsPistwsewn = this.eventsList.filter((evt: LoanAccountEventEntry) => evt.date >= kathysterhmenoKefalaio.date && evt.date <= epomenhErgasimh && evt.type === LoanAccountEventType.Pistwsh);
                        let eventsExoda = this.eventsList.filter((evt: LoanAccountEventEntry) => evt.date >= kathysterhmenoKefalaio.date && evt.date <= epomenhErgasimh && evt.type === LoanAccountEventType.Exodo);

                        for (let exodo of eventsExoda) {
                            synolikhOfeilh += exodo.amount;
                        }

                        for (let pistwsh of eventsPistwsewn) {
                            synolikhOfeilh -= pistwsh.amount;
                        }

                        if (synolikhOfeilh <= 0) {
                            let newEntry0 = new AmountEntryExtra(this.perithorio, this.eisfora, this.bash_ektokismou, this.pososto_tokwn_yperhmerias);
                            newEntry0.startDate = kathysterhmenoKefalaio.date;
                            newEntry0.endDate = epomenhErgasimh;
                            newEntry0.interest = this.GetInterestRateForDate(kathysterhmenoKefalaio.date);
                            newEntry0.dedouleymenoi_tokoi = 0;
                            newEntry0.amount = newEntryTokoi.amount;
                            newEntry0.ofeilhIndex = newEntryTokoi.ofeilhIndex;
                            newEntry0.periodos_index = newEntryTokoi.periodos_index;
                            // newEntry0.ignore = true;
                            newEntry0.ignoreDedoul = true;

                            newEntryTokoi.startDate = epomenhErgasimh;
                            this.seiraKathTok++;
                            newEntry0.dummytext += ' apo 0KATHKEFTOK ** ' + this.seiraKathTok + ' ';
                            this.kathysterhmenoiTokoi.push(newEntry0);
                        }
                    }
                } else {
                    //omologiako
                }
            }

            this.kathysterhmenoiTokoi.push(newEntryTokoi);
            // }
            // }

            if (newEntryTokoi.endDate >= DateTime.now().toISODate()) {
                // console.log('mpike - vgike 1')
                //   return 
                ;
            }
            else {
                this.eventsList.push({
                    type: LoanAccountEventType.TokoiKathysterhmenwnTokwn,
                    date: newEntryTokoi.endDate,
                    amount: 0,
                    processed: false,
                    periodos_index: newEntryTokoi.periodos_index,
                    perigrafi: ""
                });
            }

        }

        let lastItem = eggrafes[eggrafes.length - 1];

        let newEntry = new AmountEntryExtra(this.perithorio, this.eisfora, this.bash_ektokismou, this.pososto_tokwn_yperhmerias);
        newEntry.interest = this.GetInterestRateForDate(kathysterhmenoKefalaio.date);
        newEntry.startDate = kathysterhmenoKefalaio.date;

        if ((AmountEntryExtra.getNextFrequencyDate(DateTime.fromISO(kathysterhmenoKefalaio.date), Frequency.SemiAnnually, false).toISODate() as string) === DateTime.now().toISODate()) {
            this.isTodayEventKathKef = true;
        }
        // console.log('endDate APO:' + newEntry.endDate);

        let checkShmeraArgia: boolean = false;
        if ((AmountEntryExtra.getNextFrequencyDate(DateTime.fromISO(kathysterhmenoKefalaio.date), Frequency.SemiAnnually, true).toISODate() as string) > DateTime.now().toISODate()) {
            checkShmeraArgia = true;
        }

        newEntry.endDate = this.FixDate(AmountEntryExtra.getNextFrequencyDate(DateTime.fromISO(kathysterhmenoKefalaio.date), Frequency.SemiAnnually, false).toISODate() as string);

        if (this.EpomenhErgasimh(kathysterhmenoKefalaio.date) > (newEntry.endDate as string)) {
            newEntry.endDate = this.FixDate(AmountEntryExtra.getNextFrequencyDate(DateTime.fromISO(newEntry.endDate), Frequency.SemiAnnually, false).toISODate() as string);
        }

        if (this.isOmologiako && this.calculateArgies && this.isArgia(newEntry.endDate) && !checkShmeraArgia) {
            newEntry.endDate = this.FixDate(AmountEntryExtra.getNextFrequencyDate(DateTime.fromISO(this.EpomenhErgasimh(kathysterhmenoKefalaio.date)), Frequency.SemiAnnually, false).toISODate() as string);

            if (this.isArgia(newEntry.endDate)) {
                newEntry.endDate = this.ProhgoumenhErgasimh(newEntry.endDate);
            }
        }

        newEntry.valeurDate = this.FixDate(AmountEntryExtra.getNextFrequencyDate(DateTime.fromISO(kathysterhmenoKefalaio.date), Frequency.SemiAnnually, false).toISODate() as string);
        // console.log('endDate SE:' + newEntry.endDate);
        newEntry.amount = lastItem.amount;
        newEntry.periodos_index = this.nextAvailableKathysterhmenoKefalaioPeriodosIndex;
        newEntry.CalculateDedouleymenousTokous(true);
        newEntry.dummytext = 'kathkef ' + JSON.stringify(kathysterhmenoKefalaio);

        newEntry.ofeilhIndex = lastItem.ofeilhIndex;

        this.kathysterhmenoKefalaio.push(newEntry);
        // console.log('555x' + lastItem.amount + ' ' + JSON.stringify(newEntry))
        if (lastItem.amount <= 0 || newEntry.endDate >= DateTime.now().toISODate()) {
            // console.log('555x mpike 1 ' + newEntry.endDate)
            if (newEntry.endDate < DateTime.now().toISODate()) {
                // console.log('555x mpike 2 ')

                this.eventsList.push({
                    type: LoanAccountEventType.KathysterhmenoKefalaio,
                    date: newEntry.endDate,
                    amount: 0,
                    processed: false,
                    periodos_index: newEntry.periodos_index,
                    perigrafi: ""
                });
            }
            // console.log('mpike - vgike 2')
            return;
        }

        this.eventsList.push({
            type: LoanAccountEventType.KathysterhmenoKefalaio,
            date: newEntry.endDate,
            amount: 0,
            processed: false,
            periodos_index: newEntry.periodos_index,
            perigrafi: ""
        });
        // console.log('mpike rrr' + JSON.stringify(this.kathysterhmenoKefalaio))

    }

    public ProcessEvents() {
        this.SortEvents();

        for (let event of this.eventsList) {
            if (event.processed) {
                continue;
            }

            // console.log(JSON.stringify(event));
            console.log('EVENTSSSS' + JSON.stringify(event));
            if (event.type === LoanAccountEventType.Dosh) {
                this.HandleDosh(event);
            } else if (event.type === LoanAccountEventType.Tokos) {
                this.HandleTokos(event);
            } else if (event.type === LoanAccountEventType.InterestChange) {
                this.HandleInterestChange(event);
            } else if (event.type === LoanAccountEventType.Pistwsh) {
                if (event.perigrafi === null || event.perigrafi === '') { event.perigrafi = 'Πληρωμή'; }
                this.AddKinhsh(event.date, 0, event.amount, event.perigrafi);
                this.HandlePistwsh(event);
            } else if (event.type === LoanAccountEventType.TokoiKathysterhmenwnTokwn) {
                this.HandleTokoiKathysterhmenwnTokwn(event);
            } else if (event.type === LoanAccountEventType.KathysterhmenoKefalaio) {
                this.HandleKathysterhmenoKefalaio(event);
            } else if (event.type === LoanAccountEventType.Exodo) {
                this.HandleExodo(event);
            } else if (event.type === LoanAccountEventType.Xrewsh) {
                this.HandleXrewsh(event);
            }

            event.processed = true;

            this.SortEvents();
        }

        this.CalculateLatestDedouleymenous();
        this.AddKinhsh(this.finalDate, this.finalYpoloipo, 0, 'Υπόλοιπο οφειλής')
    }

    public CalculateLatestDedouleymenous() {
        this.latestDedoul = 0;

        let trexontesDedoulEnhmero = 0;
        let trexontesDedoulKathKef = 0;

        if (!this.isTodayEventEnhmero) {
            for (let i = 0; i < this.listaKinhsewn.length; i++) {
                let item = this.listaKinhsewn[i];
                if (((DateTime.now().toISODate() as string) === item.date) &&
                    item.perigrafh.includes('Τόκοι Ενήμερου Κεφαλαίου')) {
                    trexontesDedoulEnhmero += this.listaKinhsewn[i].xrewsh;
                    this.finalYpoloipo -= this.listaKinhsewn[i].xrewsh;
                    this.finalDate = this.listaKinhsewn[i - 1].date;
                    this.listaKinhsewn.splice(i, 1);

                }
            }
        }

        // console.log('latest dedoul apo enhmero -> ', trexontesDedoulEnhmero);
        this.latestDedoul += trexontesDedoulEnhmero;
        // console.log('latest dedoul 1 -> ', trexontesDedoulKathKef);
        if (!this.isTodayEventKathKef) {
            // console.log('latest dedoul 2 -> ', trexontesDedoulKathKef);

            for (let i = 0; i < this.listaKinhsewn.length; i++) {
                // console.log('latest dedoul 3 -> ', trexontesDedoulKathKef);

                let item = this.listaKinhsewn[i];
                if (((DateTime.now().toISODate() as string) === item.date) &&
                    item.perigrafh.includes('Τόκοι Καθυστερημένου Κεφαλαίου')) {
                    // console.log('latest dedoul 4 -> ', trexontesDedoulKathKef);

                    trexontesDedoulKathKef += this.listaKinhsewn[i].xrewsh;
                    this.finalYpoloipo -= this.listaKinhsewn[i].xrewsh;
                    this.finalDate = this.listaKinhsewn[i - 1].date;
                    this.listaKinhsewn.splice(i, 1);
                }
            }

            if (this.kathysterhmenoKefalaio[this.kathysterhmenoKefalaio.length - 1].tokoi_periodou === 0) {
                this.latestDedoul += this.kathysterhmenoKefalaio[this.kathysterhmenoKefalaio.length - 1].dedouleymenoi_tokoi;
                // console.log('latest dedoul apo kath last' + this.latestDedoul)
            }

        }

        // console.log('latest dedoul apo kath kef -> ', trexontesDedoulKathKef);

        this.latestDedoul += trexontesDedoulKathKef;
        let temp = 0;
        for (let i = 0; i < this.kathysterhmenoiTokoi.length; ++i) {
            if (!this.kathysterhmenoiTokoi[i].ignore) {
                // console.log('latest dedoul temp ' + JSON.stringify(this.kathysterhmenoiTokoi[i]));
                temp += this.kathysterhmenoiTokoi[i].dedouleymenoi_tokoi;
            }
        }
        // console.log('latest dedoul apo kath tokous -> ', temp);
        this.latestDedoul += temp;

    }

    public AddEnhmeroKefalaioEntry(date: string, amount: number) {
        let newEntry = new AmountEntryExtra(this.perithorio, this.eisfora, this.bash_ektokismou, this.pososto_tokwn_yperhmerias);
        newEntry.interest = this.GetInterestRateForDate(date);
        newEntry.startDate = date;
        newEntry.amount = amount;

        // if (this.enhmeroKefalaioList.length === 0) {
        //     newEntry.endDate = date;
        // }
        this.enhmeroKefalaioList.push(newEntry);
    }

    public Initialize() {
        this.enhmeroKefalaioList = [];

        // if (!this.kathisterimenoARXIKA) {
        // if (this.isAllhloxreo) {
        //     this.AddEnhmeroKefalaioEntry(this.loanStartingDate, 0);
        // } else {
        if (this.kathisterimenoARXIKA) { this.loanCapitalAmount = this.kathisterimeno_enim_arx; }
        this.AddEnhmeroKefalaioEntry(this.loanStartingDate, this.loanCapitalAmount);
        this.currentEnhmXreos = this.loanCapitalAmount;
        console.log('currentEnhmXreos Initialize: ' + this.currentEnhmXreos);
        // }
        if (this.loanCapitalAmount >= 0.01) {
            this.AddKinhsh(this.loanStartingDate, this.loanCapitalAmount, 0, 'Λογιστικό υπόλοιπο');
        }
        // }

        this.SetupTokous();
    }

    public AddKinhsh(date: string, xrewsh: number, pistwsh: number, perigrafh: string) {
        let existingItem = this.listaKinhsewn.find((entry: Kinhsh) => entry.date === date && entry.perigrafh.includes(perigrafh.replace('Μη λογιστικοποιημένοι ', '')));

        if (existingItem && pistwsh === 0) {
            existingItem.xrewsh += xrewsh;
            this.finalYpoloipo += xrewsh;
        } else {
            let existingItemLogistikopoihmeno = this.listaKinhsewn.find((entry: Kinhsh) => entry.date === date && entry.perigrafh === "Τόκοι Ενήμερου Κεφαλαίου" && perigrafh.startsWith('Μη λογιστικοποιημένοι '));

            if (existingItemLogistikopoihmeno) {
                perigrafh = perigrafh.replace('Μη λογιστικοποιημένοι ', '');
            }
            this.listaKinhsewn.push({ date, xrewsh, pistwsh, perigrafh });
            this.finalDate = date;
            if (xrewsh > 0) { this.finalYpoloipo += xrewsh; }
            else if (pistwsh > 0) { this.finalYpoloipo -= pistwsh; }
        }
    }

    public SetupTokous() {
        // console.log('Setting up tokous events - Start');
        let checkMe = false;
        let nextDate = this.loanStartingDate;
        if (this.kathisterimenoARXIKA && !checkMe) {

            let newEntry = new AmountEntryExtra(this.perithorio, this.eisfora, this.bash_ektokismou, this.pososto_tokwn_yperhmerias);
            newEntry.interest = this.GetInterestRateForDate(this.kathisterimenodate);
            newEntry.startDate = this.kathisterimenodate;
            newEntry.endDate = this.FixDate(AmountEntryExtra.getNextFrequencyDate(DateTime.fromISO(this.kathisterimenodate), Frequency.SemiAnnually, false).toISODate() as string);
            newEntry.valeurDate = this.FixDate(AmountEntryExtra.getNextFrequencyDate(DateTime.fromISO(this.kathisterimenodate), Frequency.SemiAnnually, false).toISODate() as string);
            newEntry.amount = this.kathisterimenokef;
            newEntry.periodos_index = this.nextAvailableKathysterhmenoKefalaioPeriodosIndex;
            newEntry.CalculateDedouleymenousTokous(true);
            newEntry.dummytext = 'kathkef ARX';
            this.kathysterhmenoKefalaio.push(newEntry);

            this.eventsList.push({
                type: LoanAccountEventType.KathysterhmenoKefalaio,
                date: newEntry.endDate,
                amount: 0,
                processed: false,
                periodos_index: newEntry.periodos_index,
                perigrafi: "Αρχ. Καθ. Κ."
            });
            this.AddKinhsh(this.kathisterimenodate, newEntry.amount, 0, 'Αρχ. Καθ. Κ.');
            /********************************************************************************************** */
            /********************************************************************************************** */
            for (let kt of this.kathisterimenotokoiArxika) {
                console.log('44444tokoi: ' + JSON.stringify(kt));
                let newEntry2 = new AmountEntryExtra(this.perithorio, this.eisfora, this.bash_ektokismou, this.pososto_tokwn_yperhmerias);
                newEntry2.interest = this.GetInterestRateForDate(kt.date);
                newEntry2.startDate = kt.date;
                newEntry2.endDate = this.FixDate(AmountEntryExtra.getNextFrequencyDate(DateTime.fromISO(kt.date), Frequency.SemiAnnually, true).toISODate() as string);
                newEntry2.valeurDate = this.FixDate(AmountEntryExtra.getNextFrequencyDate(DateTime.fromISO(kt.date), Frequency.SemiAnnually, true).toISODate() as string);
                // console.log('999', this.interestRepaymentEpeteiako, newEntry2.endDate)
                // if (!this.interestRepaymentEpeteiako && newEntry2.endDate.endsWith('12-30')) {
                //     // console.log('999', this.interestRepaymentEpeteiako)
                //     newEntry2.endDate = newEntry2.endDate.replace('12-30', '12-31');
                // }
                // if (!this.interestRepaymentEpeteiako && newEntry2.valeurDate.endsWith('12-30')) {
                //     // console.log('999', this.interestRepaymentEpeteiako)
                //     newEntry2.valeurDate = newEntry2.valeurDate.replace('12-30', '12-31');
                // }

                newEntry2.amount = kt.amount;
                newEntry2.periodos_index = this.nextAvailableTokoiTokwnPeriodosIndex++;
                newEntry2.CalculateDedouleymenousTokous(true);
                newEntry2.dummytext = 'kathtok ARX' + kt.perigrafi;
                newEntry2.ofeilhIndex = ++this.nextOfeilhIndex;
                this.kathysterhmenoiTokoi.push(newEntry2);
                this.currentKathTokXreos += newEntry2.amount;
                console.log('currentKathTokXreos SetupTokoi1 ' + this.currentKathTokXreos);

                this.eventsList.push({
                    type: LoanAccountEventType.TokoiKathysterhmenwnTokwn,
                    date: newEntry2.endDate,
                    amount: 0,
                    processed: false,
                    periodos_index: newEntry2.periodos_index,
                    perigrafi: "Αρχ. Καθ. Τ. " + kt.perigrafi
                });
                checkMe = true;
                this.AddKinhsh(kt.date, kt.amount, 0, 'Αρχ. Καθ. Τ. ' + kt.perigrafi);
            }
        }

        /********************************************************************************************** */
        /********************************************************************************************** */
        let usedForEvent = '';
        do {
            // console.log('Setting up tokous events - Loop', nextDate)
            if ((AmountEntryExtra.getNextFrequencyDate(DateTime.fromISO(nextDate), this.interestRepaymentFrequency, this.interestRepaymentEpeteiako).toISODate() as string) === DateTime.now().toISODate()) {
                this.isTodayEventEnhmero = true;
            }

            nextDate = this.FixDate(AmountEntryExtra.getNextFrequencyDate(DateTime.fromISO(nextDate), this.interestRepaymentFrequency, this.interestRepaymentEpeteiako).toISODate() as string);
            usedForEvent = nextDate;

            if (this.isArgia(nextDate) && this.isOmologiako && this.calculateArgies) {
                usedForEvent = this.EpomenhErgasimh(nextDate);
                if (DateTime.fromISO(usedForEvent).month !== DateTime.fromISO(nextDate).month || DateTime.fromISO(usedForEvent).year !== DateTime.fromISO(nextDate).year) {
                    usedForEvent = this.ProhgoumenhErgasimh(nextDate);
                }
            }

            this.eventsList.push({ type: LoanAccountEventType.Tokos, date: usedForEvent, amount: 0, processed: false, perigrafi: "" });
        } while (nextDate < DateTime.now().toISODate());

        // for (let i = 0; i < this.eventsList.length; i++) {
        //     if (this.eventsList[i].type === LoanAccountEventType.Tokos) {
        //         this.eventsList[i].hiddenDate = this.eventsList[i].date;

        //         this.eventsList[i].date = this.FixDateExtra(this.eventsList[i].date, false)[0];
        //     }
        // }

        // console.log('Setting up tokous events - End');
    }

    public GetInterestRateForDate(date: string): number {
        let interestRate = 0;

        let pickedDate = '';

        for (let interest of this.interestRateList) {
            if (interest.date <= date) {
                interestRate = interest.interest;
                pickedDate = interest.date;
            } else { // Reached closest interest date
                break;
            }
        }

        // console.log('interest rate for date ', date, ' is ', interestRate, ' picked date ', pickedDate);
        if (this.arnitika_epitokia) {
            return Number(interestRate.toFixed(4));
        } else {
            if (interestRate > 0) {
                return Number(interestRate.toFixed(4));
            }
            else { return 0; }
        }
    }

    public static PMT(ir: number, np: number, pv: number, fv: number = 0, type: number = 0): number {
        /*
         * ir   - interest rate per month
         * np   - number of periods (months)
         * pv   - present value
         * fv   - future value
         * type - when the payments are due:
         *        0: end of the period, e.g. end of month (default)
         *        1: beginning of period
         */
        let pmt, pvif;

        fv || (fv = 0);
        type || (type = 0);

        if (ir === 0)
            return -(pv + fv) / np;

        pvif = Math.pow(1 + ir, np);
        pmt = - ir * (pv * pvif + fv) / (pvif - 1);

        if (type === 1)
            pmt /= (1 + ir);

        return pmt;
    }
}

export default LoanAccount;