<script lang="ts" setup>
import { ref, watch, onMounted, onBeforeUnmount } from "vue";
import { usePGRESTAPIStore } from "../store/pgrest_api";
import PGRESTResult from "../classes/PGRESTResult";
import { Avatar, Bell, Edit, Delete, Document, House, Plus, RefreshLeft, CircleCheck, CircleClose, View, Watch } from "@element-plus/icons-vue";
import { storeToRefs } from "pinia";
import { useMainStore } from "../store/main";
import { useRouter } from "vue-router";
import UserType from "../enums/UserType";
import { useGenericMethodsVariables } from "../composables/genericMethodsVariables";
import { useViewOptions } from "../composables/viewOptions";
import Auction from "../classes/DB_Entities/Auction";

const { isLoading, entityApprove, showError, showSuccess } = useGenericMethodsVariables();

const { filters, onSortChange, sortColumn, sortOrder } = useViewOptions(
  Auction.getTableNameStatic(),
  {
    auction_id: "",
    debtor_cid: "",
    property_number: "",
    vat_number: "",
    pending: false,
    pending_new: false,
    hide_rejected: true,
  }
);

const pgrestapi = usePGRESTAPIStore();
const mainStore = useMainStore();
const router = useRouter();

const DeleteDialogVisible = ref(false);
const { globalPortfolioID } = storeToRefs(mainStore);
const { globalDebtorID } = storeToRefs(mainStore);
const { globalLoanAgreementID } = storeToRefs(mainStore);
let DeleteSelectedRow = ref(0);
let dataList = ref([]);
let dataCount = ref(0);
let lastPage = ref(0);

let deleteVisible = ref(false);
let timer = ref<any>(null);

watch(globalPortfolioID, async () => {
  if (mainStore.loggedUser.username !== '') // Do not load if user logged out (changes the value of globalLoanAgreementID to "")
    await loadPage(1);
});

watch(globalDebtorID, async () => {
  if (mainStore.loggedUser.username !== '') // Do not load if user logged out (changes the value of globalLoanAgreementID to "")
    await loadPage(1);
});

watch(globalLoanAgreementID, async () => {
  if (mainStore.loggedUser.username !== '') // Do not load if user logged out (changes the value of globalLoanAgreementID to "")
    await loadPage(1);
});

const handleClick = (row: any) => {
  let view_only = (row.debtor_approve_status !== 1 || row.real_estate_approve_status !== 1);
  router.push(`/auctions/${row.id}/${view_only}`);
};

onMounted(async () => {
  loadPage(1);
});

const loadPage = async (page: number) => {
  isLoading.value = true;

  page = page - 1;
  lastPage.value = page;

  let filterUrl = "";

  if (filters.value.auction_id !== "") {
    filterUrl += `&id=eq.${filters.value.auction_d}`;
  }

  if (filters.value.pending) {
    filterUrl += "&pending_approvals=gt.0";
  }

  if (filters.value.debtor_cid !== "") {
    filterUrl += `&cid=ilike.*${filters.value.debtor_cid}*`;
  }

  if (filters.value.property_number !== "") {
    filterUrl += `&arithmos_akinitou_diax=eq.${filters.value.property_number}`;
  }

  if (filters.value.vat_number !== "") {
    filterUrl += `&vat_number=eq.${filters.value.vat_number}`;
  }

  if (filters.value.pending_new) {
    filterUrl += "&approve_status=eq.0";
  }

  if (filters.value.hide_rejected) {
    filterUrl += "&approve_status=neq.2";
  }

  if (sortOrder.value !== "") {
    filterUrl += `&order=${sortColumn.value}.${sortOrder.value}`;
  }

  let addedFilter = "";

  if (mainStore.globalPortfolioID !== "") {
    addedFilter += "&portfolio=eq." + mainStore.globalPortfolioID;
  }

  if (mainStore.globalDebtorID !== "") {
    addedFilter += "&debtor_id=eq." + mainStore.globalDebtorID;
  }

  if (mainStore.globalLoanAgreementID !== "") {
    addedFilter += "&loan_agreement_id=cs.{" + mainStore.globalLoanAgreementID + "}";
  }

  if (mainStore.loggedUser.user_type == UserType.Agent) {
    addedFilter += `&allowed_users=cs.{${mainStore.loggedUser.id}}`;
  } else if (mainStore.loggedUser.user_type == UserType.Supervisor) {
    addedFilter += `&portfolio_allowed_users=cs.{${mainStore.loggedUser.id}}`;
  }

  let result: PGRESTResult = await pgrestapi.getPaginated(
    "view_mosauctions_view1?select=*" + addedFilter + filterUrl,
    page
  );

  if (result.error) {
    showError(result.error.message);
    return;
  }

  if (result.headers)
    dataCount.value = parseInt(result.headers["content-range"].split("/")[1]);

  dataList.value = result.data;

  isLoading.value = false;
};

const filterChanged = async (value: string) => {
  if (timer.value !== 0) {
    clearTimeout(timer.value);
  }

  timer.value = setTimeout(() => {
    loadPage(1);

    timer.value = 0;
  }, 500);
};

const deleteHandleClick = (row_id: number) => {
  DeleteSelectedRow.value = row_id;
  deleteVisible.value = true;
  DeleteGRGuarantee(row_id);
};

const DeleteGRGuarantee = async (row_id: number) => {
  let result: PGRESTResult = new PGRESTResult();

  let id = JSON.stringify(row_id);

  result = await pgrestapi.delete_data("auctions?id=eq." + id);

  if (result.error) {
    showError(result.error.message);
    DeleteDialogVisible.value = false;
    return;
  }

  onDelete();
};

const onDelete = async () => {
  showSuccess("Auction Deleted Successfully");
  DeleteDialogVisible.value = false;
  loadPage(lastPage.value);
};

const localSortChange = async (sortObj: any) => {
  onSortChange(sortObj);
  await loadPage(lastPage.value);
};
const pendingFilterChange = async () => {
  await loadPage(1);
};

const approve = async (entity_id: number, state: boolean) => {
  let fv = await entityApprove(Auction.getTableNameStatic(), entity_id, state);

  if (fv) {
    showSuccess(`Auction updated`);
  } else {
    showError(`Auction update failed`);
  }

  await loadPage(lastPage.value);
};

const handleClickAdd = () => {
  router.push("/auctions/new");
};

const escapePressed = () => {
  console.log("escape");
};

const isEditable = (row: any) => {
  return (row.debtor_approve_status === 1 && row.real_estate_approve_status === 1);
}
</script>

<template>
  <div class="content-wrapper" v-loading="isLoading" @keydown.esc="escapePressed">
    <el-row :gutter="10">
      <el-col :span="12">
        <h3 class="heading-page">{{ $t("AuctionsView.auctions") }} ({{ dataCount }})</h3>
      </el-col>
    </el-row>

    <div style="margin: 30px">
      <el-row :gutter="20">
        <el-col :span="6">
          <el-input v-model="filters.debtor_cid" class="client-input" :placeholder="$t('AuctionsView.debtor_cid')"
            @input="filterChanged" />
        </el-col>
        <el-col :span="6">
          <el-input v-model="filters.property_number" class="client-input"
            :placeholder="$t('AuctionsView.real_estate_nbr')" @input="filterChanged" />
        </el-col>
        <el-col :span="6">
          <el-input v-model="filters.vat_number" class="client-input"
            :placeholder="$t('AuctionsView.tax_registration_nbr')" @input="filterChanged" />
        </el-col>
      </el-row>
    </div>

    <div>
      <el-row :gutter="10" style="margin: 10px; margin-top: 15px">
        <el-col :span="12" class="realestate-add-new-btn">
          <el-switch v-model="filters.pending" size="small" :active-text="$t('global.pending_changes')" inactive-text=""
            @change="pendingFilterChange()" style="float: left" />
          <el-switch v-model="filters.pending_new" size="small" :active-text="$t('global.pending_changes_new_entities')"
            inactive-text="" @change="pendingFilterChange()" style="float: left" />
          <el-switch v-model="filters.hide_rejected" size="small" :active-text="$t('global.hide_rejected_entities')"
            inactive-text="" @change="pendingFilterChange()" style="float: left" />
        </el-col>
        <el-col :span="12" class="realestate-add-new-btn">
          <el-button link type="primary" size="small" class="btn-add" @click="loadPage(lastPage)"
            style="float: right; margin: 2.5px" :icon="RefreshLeft" />
          <el-button link type="primary" size="small" class="btn-add" style="float: right; margin: 2.5px"
            @click="handleClickAdd()" :icon="Plus" />
        </el-col>
      </el-row>
    </div>

    <el-table v-loading="isLoading" :data="dataList" stripe border table-layout="fixed" sortable="custom"
      @sort-change="localSortChange" size="small">
      <el-table-column sortable fixed prop="cid" :label="$t('AuctionsView.debtor_cid')" />
      <el-table-column sortable fixed prop="arithmos_akinitou_diax" :label="$t('AuctionsView.real_estate_nbr')" />
      <el-table-column sortable fixed prop="vat_number" :label="$t('AuctionsView.tax_registration_nbr')" />
      <el-table-column sortable fixed prop="pending_approvals" :label="$t('global.pending_approvals')" />
      <el-table-column fixed="right" :label="$t('global.operations')" width="280">
        <template #default="scope">
          <el-button link type="primary" size="small" class="btn-detail" @click="handleClick(scope.row)"
            :icon="isEditable(scope.row) ? Edit : View" />
          <el-tooltip v-if="scope.row.approve_status == 0 && mainStore.loggedUser.user_type === UserType.Agent"
            effect="dark" content="Pending Supervisor approval" placement="top">
            <el-button size="small" type="primary" class="btn-warn" :icon="Watch" circle />
          </el-tooltip>
          <el-button v-if="mainStore.loggedUser.user_type === UserType.Administrator" link type="primary" size="small"
            class="btn-delete" @click="DeleteDialogVisible = true; DeleteSelectedRow = scope.row.id;" :icon="Delete" />
          <span v-if="mainStore.loggedUser.user_type < UserType.Agent && scope.row.approve_status === 0">
            <el-button class="btn-accept_square" @click="approve(scope.row.id, true)" :icon="CircleCheck" />
            <el-button class="btn-reject_square" @click="approve(scope.row.id, false)" :icon="CircleClose" />
          </span>
          <el-popover v-if="scope.row.pending_approvals > 0" placement="top-start" title="" :width="155" trigger="hover"
            :content="$t('global.pending_changes')">
            <template #reference>
              <el-button :icon="Bell" circle size="small" style="background-color: coral; border: 0px; color: white" />
            </template>
          </el-popover>
          <el-popover v-if="scope.row.debtor_approve_status !== 1" placement="top-start" title="" :width="205"
            trigger="hover" :content="$t('global.debtor_not_approved')">
            <template #reference>
              <el-button :icon="Avatar" circle size="small"
                style="background-color: blueviolet; border: 0px; color: white" />
            </template>
          </el-popover>
          <el-popover v-if="scope.row.real_estate_approve_status !== 1" placement="top-start" title="" :width="265"
            trigger="hover" :content="$t('global.real_estate_not_approved')">
            <template #reference>
              <el-button :icon="House" circle size="small" style="background-color: coral; border: 0px; color: white" />
            </template>
          </el-popover>
        </template>
      </el-table-column>
    </el-table>

    <div>
      <el-pagination small layout="prev, pager, next" :page-size="10" :total="dataCount" @current-change="loadPage" />
    </div>

    <el-dialog v-model="DeleteDialogVisible" title="Auction Delete" width="50%">
      <span>Are you sure you want to delete this Property?</span>
      <template #footer #default="scope">
        <span class="dialog-footer">
          <el-button @click="DeleteDialogVisible = false">{{ $t('generic.cancel') }}</el-button>
          <el-button type="primary" @click="deleteHandleClick(DeleteSelectedRow)">{{ $t('generic.delete') }}
          </el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<style scoped></style>
