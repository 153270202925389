<script lang="ts" setup>
import { ref, onMounted, watch } from "vue";
import { usePGRESTAPIStore } from "../store/pgrest_api";
import PGRESTResult from "../classes/PGRESTResult";
import { Bell, Edit, Delete, Document, User, Plus, RefreshLeft, CircleCheck, CircleClose, Watch } from "@element-plus/icons-vue";
import { useMainStore } from "../store/main";
import { useGenericMethodsVariables } from "../composables/genericMethodsVariables";
import { storeToRefs } from "pinia";
import UserType from "../enums/UserType";
import Option from "../interfaces/Option";
import AddEdit_Debtor from "../components/AddEdit_Debtor.vue";
import { useSort } from "../composables/sort";
import Debtor from "../classes/DB_Entities/Debtor";
import DocumentsList from "../components/DocumentsList.vue";
import EntityType from "../enums/EntityType";
import DocumentFolders from "../enums/DocumentFolders";

const users = ref<any>([]);
const mainStore = useMainStore();
const pgrestapi = usePGRESTAPIStore();
const { showError, showSuccess, isLoading, entityApprove } = useGenericMethodsVariables();
let filterPending = ref(false);
let filterPendingNew = ref(false);
let filterHideRejected = ref(true);
let dataList = ref([]);
let dataCount = ref(0);
let lastPage = ref(0);
let detailsAgentsVisible = ref(false);
let selectedRowForAgents = ref(0);
let selectedRow = ref(-1);
const deleteDialogVisible = ref(false);

let availableAgents = ref<Option[]>([]);
let assignedAgents = ref([]);
let filterFullname = ref<string>("");
let filterCID = ref<string>("");
let filterMosOfficer = ref<string>("");
let filterVat = ref<string>("");
let timer = ref<any>(null);
let addEditVisible = ref(false);

let showDocumentsList = ref(false);
let selectedDocumentEntity = ref<any>(undefined);

const { onSortChange, sortColumn, sortOrder } = useSort();
const { globalPortfolioID } = storeToRefs(mainStore);
const { globalDebtorID } = storeToRefs(mainStore);
const { globalLoanAgreementID } = storeToRefs(mainStore);

watch(globalPortfolioID, async () => {
  if (mainStore.loggedUser.username !== '') // Do not load if user logged out (changes the value of globalLoanAgreementID to "")
    await loadPage(1);
});

watch(globalDebtorID, async () => {
  if (mainStore.loggedUser.username !== '') // Do not load if user logged out (changes the value of globalLoanAgreementID to "")
    await loadPage(1);
});

watch(globalLoanAgreementID, async () => {
  if (mainStore.loggedUser.username !== '') // Do not load if user logged out (changes the value of globalLoanAgreementID to "")
    await loadPage(1);
});

const handleClickAdd = () => {
  selectedRow.value = -1;
  addEditVisible.value = true;
};

const loadUsers = async () => {
  let result = await pgrestapi.get("users?select=id,username&order=username");

  if (result.error) {
    showError(result.error.message);
    return;
  }

  users.value = result.data;
};

const loadAgents = async (row_id: number) => {
  let result = await pgrestapi.get("users?select=id,username&active=eq.true&deleted=eq.false&user_type=eq." + UserType.Agent); // active = true, deleted = false, user_type = 2 (Agent)

  availableAgents.value = [];

  for (let agent of result.data) {
    availableAgents.value.push({
      key: agent.id,
      label: agent.username,
      disabled: false,
    });
  }

  result = await pgrestapi.get("debtor?id=eq." + row_id);
  assignedAgents.value = result.data[0].allowed_users;
};

const closeAgentPicking = async () => {
  detailsAgentsVisible.value = false;

  let result = pgrestapi.patch("debtor?id=eq." + selectedRowForAgents.value, {
    allowed_users: assignedAgents.value,
  });
  await loadUsers();
  loadPage(lastPage.value);
};

const handleAgents = (row_id: number) => {
  isLoading.value = true;
  selectedRowForAgents.value = row_id;
  detailsAgentsVisible.value = true;
  loadAgents(row_id);
  isLoading.value = false;
};

const handleClickEdit = (row_id: number) => {
  selectedRow.value = row_id;
  addEditVisible.value = true;
};

const deleteHandleClick = (row_id: number) => {
  deleteDialogVisible.value = true;
  selectedRow.value = row_id;
};

onMounted(async () => {
  await loadUsers();
  loadPage(1);
});

const DeleteDebtor = async () => {
  let result = await pgrestapi.delete_data("debtor?id=eq." + selectedRow.value);

  if (result.error) {
    showError(result.error.message);
    return;
  }

  showSuccess("Debtor Deleted Successfully");
  deleteDialogVisible.value = false;
  loadPage(lastPage.value);
};

const loadPage = async (page: number) => {
  isLoading.value = true;

  lastPage.value = page;
  page = page - 1;

  let filterUrl = "";

  if (filterFullname.value !== "") {
    filterUrl += `&fullname=ilike.*${filterFullname.value}*`;
  }

  if (filterPending.value) {
    filterUrl += "&pending_approvals=gt.0";
  }

  if (filterPendingNew.value) {
    filterUrl += "&approve_status=eq.0";
  }

  if (filterHideRejected.value) {
    filterUrl += "&approve_status=neq.2";
  }

  if (filterCID.value !== "") {
    filterUrl += `&cid=ilike.*${filterCID.value}*`;
  }

  if (filterMosOfficer.value !== "") {
    filterUrl += `&allowed_users_usernames=ilike.*${filterMosOfficer.value}*`;
  }

  if (filterVat.value !== "") {
    filterUrl += `&vat_number=ilike.*${filterVat.value}*`;
  }

  if (sortOrder.value !== "") {
    if (filterUrl === "") {
      filterUrl = "?";
    }
    filterUrl += `&order=${sortColumn.value}.${sortOrder.value},id.asc`;
  } else {
    filterUrl += `&order=fullname.asc,id.asc`;
  }

  let addedFilter = "";
  let addedFilter2 = "";

  if (mainStore.globalPortfolioID !== "") {
    addedFilter += "&portfolio=eq." + mainStore.globalPortfolioID;
  }

  if (mainStore.globalDebtorID !== "") {
    addedFilter += "&id=eq." + mainStore.globalDebtorID;
  }

  if (mainStore.globalLoanAgreementID !== "") {
    addedFilter2 +=
      ",loan_agreement!inner(*)&loan_agreement.id=eq." + mainStore.globalLoanAgreementID;
  }

  let result: PGRESTResult = new PGRESTResult();

  if (mainStore.loggedUser.user_type == UserType.Agent) {
    addedFilter += `&allowed_users=cs.{${mainStore.loggedUser.id}}`;
  } else if (mainStore.loggedUser.user_type == UserType.Supervisor) {
    addedFilter += `&portfolio_allowed_users=cs.{${mainStore.loggedUser.id}}`;
  }

  result = await pgrestapi.getPaginated(
    "view_mosdebtors_view1?select=*" +
    addedFilter2 +
    filterUrl +
    addedFilter,
    page
  );

  if (result.error) {
    showError(result.error.message);
    return;
  }

  if (result.headers)
    dataCount.value = parseInt(result.headers["content-range"].split("/")[1]);

  dataList.value = result.data;

  isLoading.value = false;
};

const onSave = async () => {
  selectedRow.value = -1;
  showSuccess("Debtor updated");
  addEditVisible.value = false;

  loadPage(lastPage.value);
};

const onAdd = async () => {
  showSuccess("Debtor Added Successfully");
  addEditVisible.value = false;
  loadPage(lastPage.value);
};

const onFailSave = async () => {
  selectedRow.value = -1;
  showError("Debtor failed to update");
  addEditVisible.value = false;
};

const filterChanged = async (value: string) => {
  if (timer.value !== 0) {
    clearTimeout(timer.value);
  }

  timer.value = setTimeout(() => {
    loadPage(1);

    timer.value = 0;
  }, 500);
};

const pendingFilterChange = async () => {
  await loadPage(1);
};

const localSortChange = async (sortObj: any) => {
  onSortChange(sortObj);
  await loadPage(lastPage.value);
};

const approve = async (entity_id: number, state: boolean) => {
  let fv = await entityApprove(Debtor.getTableNameStatic(), entity_id, state);

  if (fv) {
    showSuccess(`Debtor updated`);
  } else {
    showError(`Debtor update failed`);
  }

  await loadPage(lastPage.value);
};

const onCloseAddEdit = () => {
  addEditVisible.value = false;
  selectedRow.value = -1;
};

const handleDocumentsListClick = (row: any) => {
  selectedRow.value = row.id;
  selectedDocumentEntity.value = row;
  showDocumentsList.value = true;
}
</script>

<template>
  <div class="content-wrapper" v-loading="isLoading">
    <el-row :gutter="10">
      <el-col :span="12">
        <h3 class="heading-page">{{ $t("DebtorView.debtors") }} ({{ dataCount }})</h3>
      </el-col>
    </el-row>

    <div style="margin: 30px">
      <el-row :gutter="20">
        <el-col :span="6">
          <el-input v-model="filterCID" class="client-input" :placeholder="$t('DebtorDetailsView.cid')"
            @input="filterChanged" />
        </el-col>
        <el-col :span="6">
          <el-input v-model="filterFullname" class="client-input" :placeholder="$t('DebtorView.company_name')"
            @input="filterChanged" />
        </el-col>
        <el-col :span="6">
          <el-select clearable v-model="filterMosOfficer" class="m-2 client-input" placeholder="MOS Officer"
            value-key="id" @change="filterChanged" style="width: 100%">
            <el-option v-for="item in users" :key="item.id" :label="item.username" :value="item.username" />
          </el-select>
        </el-col>
        <el-col :span="6">
          <el-input v-model="filterVat" class="client-input" :placeholder="$t('DebtorView.tax_registration_nbr')"
            @input="filterChanged" />
        </el-col>
      </el-row>
    </div>

    <div>
      <el-row :gutter="10" style="margin: 10px; margin-top: 15px">
        <el-col :span="12" class="realestate-add-new-btn">
          <el-switch v-model="filterPending" size="small" :active-text="$t('global.pending_changes')" inactive-text=""
            @change="pendingFilterChange()" style="float: left" />
          <el-switch v-model="filterPendingNew" size="small" :active-text="$t('global.pending_changes_new_entities')"
            inactive-text="" @change="pendingFilterChange()" style="float: left" />
          <el-switch v-model="filterHideRejected" size="small" :active-text="$t('global.hide_rejected_entities')"
            inactive-text="" @change="pendingFilterChange()" style="float: left" />
        </el-col>
        <el-col :span="12" class="realestate-add-new-btn">
          <el-button link type="primary" size="small" class="btn-add" @click="loadPage(lastPage)"
            style="float: right; margin: 2.5px" :icon="RefreshLeft" />
          <el-button link type="primary" size="small" class="btn-add" style="float: right; margin: 2.5px"
            @click="handleClickAdd()" :icon="Plus" />
        </el-col>
      </el-row>
    </div>

    <el-table :data="dataList" stripe border table-layout="fixed" sortable="custom" @sort-change="localSortChange"
      size="small">
      <el-table-column fixed sortable prop="cid" :label="$t('DebtorDetailsView.cid')" />
      <el-table-column fixed sortable prop="fullname" :label="$t('DebtorView.company_name')" />
      <el-table-column fixed sortable prop="vat_number" :label="$t('DebtorView.tax_registration_nbr')" />
      <el-table-column fixed sortable prop="portf_name" :label="$t('DebtorDetailsView.portfolio')" />
      <el-table-column fixed sortable prop="allowed_users_usernames" :label="$t('DebtorDetailsView.mos_officer')" />
      <el-table-column fixed sortable prop="pending_approvals" :label="$t('global.pending_approvals')" width="100">
        <template #default="scope">
          {{ scope.row.pending_approvals }}
        </template>
      </el-table-column>
      <el-table-column fixed="right" :label="$t('global.operations')" width="280">
        <template #default="scope">
          <el-button v-if="mainStore.loggedUser.user_type < 2" link type="primary" size="small" class="btn-portfolios"
            @click="handleAgents(scope.row.id)" :icon="User" />
          <el-tooltip class="box-item" effect="dark" content="Documents List" placement="top">
            <el-button link type="primary" size="small" class="btn-detail" @click="handleDocumentsListClick(scope.row)"
              :icon="Document" />
          </el-tooltip>
          <el-button link type="primary" size="small" class="btn-detail" @click="handleClickEdit(scope.row.id)"
            :icon="Edit" />
          <el-tooltip v-if="scope.row.approve_status == 0 && mainStore.loggedUser.user_type === UserType.Agent"
            effect="dark" content="Pending Supervisor approval" placement="top">
            <el-button size="small" type="primary" class="btn-warn" :icon="Watch" circle />
          </el-tooltip>
          <el-button v-if="mainStore.loggedUser.user_type === UserType.Administrator" link type="primary"
            class="btn-delete" @click="deleteHandleClick(scope.row.id)" :icon="Delete" />
          <span v-if="mainStore.loggedUser.user_type < UserType.Agent &&
            scope.row.approve_status === 0
            ">
            <el-button class="btn-accept_square" @click="approve(scope.row.id, true)" :icon="CircleCheck" />
            <el-button class="btn-reject_square" @click="approve(scope.row.id, false)" :icon="CircleClose" />
          </span>
          <el-popover v-if="scope.row.pending_approvals > 0" placement="top-start" title="" :width="155" trigger="hover"
            :content="$t('global.pending_changes')">
            <template #reference>
              <el-button :icon="Bell" circle size="small" style="background-color: coral; border: 0px; color: white" />
            </template>
          </el-popover>
        </template>
      </el-table-column>
    </el-table>

    <el-dialog v-if="addEditVisible" v-model="addEditVisible" @update:model-value="onCloseAddEdit"
      :title="selectedRow === -1 ? 'Add Debtor' : 'Debtor Details'" width="50%">
      <AddEdit_Debtor @close="onCloseAddEdit" @save="onSave" @fail-save="onFailSave" :row_id="selectedRow" />
    </el-dialog>

    <el-dialog v-model="deleteDialogVisible" title="Debtor Delete" width="50%">
      <span>Are you sure you want to delete this debtor data ?</span>
      <template #footer #default="scope">
        <span class="dialog-footer">
          <el-button @click="deleteDialogVisible = false">{{ $t('generic.cancel') }}</el-button>
          <el-button type="primary" @click="DeleteDebtor()">{{ $t('generic.delete') }}
          </el-button>
        </span>
      </template>
    </el-dialog>

    <el-dialog v-model="detailsAgentsVisible" title="Agent assignment" width="60%">
      <el-transfer v-model="assignedAgents" :data="availableAgents" :titles="['Available', 'Assigned']" />

      <template #footer #default="scope">
        <span class="dialog-footer">
          <el-button @click="closeAgentPicking">{{ $t('generic.close') }}</el-button>
        </span>
      </template>
    </el-dialog>

    <!-- TODO: Translations -->
    <el-dialog v-if="showDocumentsList" v-model="showDocumentsList"
      :title="`Debtor / ${selectedDocumentEntity.cid} - Documents List`" width="700">
      <DocumentsList v-if="showDocumentsList" :entity_type="EntityType.Debtor" :entity_id="selectedDocumentEntity.id"
        :row_id="selectedRow" :allowed_folders="[DocumentFolders.LegalizationDocuments, DocumentFolders.MiscDocuments]" />
    </el-dialog>

    <div>
      <el-pagination small layout=" prev, pager, next" :page-size="10" :total="dataCount" @current-change="loadPage" />
    </div>
  </div>
</template>

<style scoped></style>
