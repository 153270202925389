<script lang="ts" setup>
import { ref, onMounted } from "vue";
import { useMainStore } from "../store/main";
import { usePGRESTAPIStore } from "../store/pgrest_api";
import { useI18n } from "vue-i18n";
import { useGenericMethodsVariables } from "../composables/genericMethodsVariables";
import PGRESTResult from "../classes/PGRESTResult";
import DebitCredit from "../classes/DB_Entities/DebitCredit";
import UserType from "../enums/UserType";
import DebitCreditType from "../enums/DebitCreditType";

const props = defineProps<{
    loan_account_id: number;
    row_id: number;
    view_only: boolean;
    type: DebitCreditType;
}>();

const emits = defineEmits(["save", "close", "save_and_approve"]);

const mainStore = useMainStore();
const pgrestapi = usePGRESTAPIStore();
const { t } = useI18n({ useScope: "global" });

const { showError, showSuccess } = useGenericMethodsVariables();

let dbEntry = ref(new DebitCredit());
let originalAmount = -1;
let originalDate = "";
let originalPerigrafi = "";
let isLoading = ref(false);

onMounted(async () => {
    isLoading.value = true;

    console.log("debit_credit id", props.row_id);

    if (props.row_id === 0) {
        isLoading.value = false;

        return;
    }

    let result = await pgrestapi.get("debits_credits?id=eq." + props.row_id);

    if (result.error) {
        showError(result.error.code + ' - ' + result.error.message);
        isLoading.value = false;

        return;
    }

    originalAmount = result.data[0].amount;
    originalDate = result.data[0].date;
    originalPerigrafi = result.data[0].perigrafi
    dbEntry.value = result.data[0];

    isLoading.value = false;
});

// TODO add user type checks
const onSave = async () => {

    // New entry
    if (props.row_id === 0) {
        let newDebitCredit = new DebitCredit();
        newDebitCredit.loan_account_id = props.loan_account_id.toString();
        newDebitCredit.type = props.type;
        newDebitCredit.date = dbEntry.value.date;
        newDebitCredit.amount = dbEntry.value.amount;
        newDebitCredit.perigrafi = dbEntry.value.perigrafi;

        // Auto-approved for Admin/Supervisor
        if (mainStore.loggedUser.user_type === UserType.Administrator || mainStore.loggedUser.user_type === UserType.Supervisor) {
            newDebitCredit.status = 1;
        } else {
            newDebitCredit.status = 0;
        }

        console.log(newDebitCredit)

        let obj: any = JSON.parse(JSON.stringify(newDebitCredit));

        delete obj.id;

        let result = await pgrestapi.post_data("debits_credits", obj);

        // Add a new approval request for the new item
        let entityObj: any = {};
        entityObj.entity_id = result.data[0].id;
        entityObj.editor_user_id = mainStore.loggedUser.id;
        entityObj.field = 'status';
        entityObj.value = '1';

        // Auto-approved for Admin/Supervisor
        // if (mainStore.loggedUser.user_type === UserType.Administrator || mainStore.loggedUser.user_type === UserType.Supervisor) {
        //     entityObj.status = 1;
        // }

        if (result.error) {
            showError(result.error.code + ' - ' + result.error.message);
            return;
        }

        let result2 = await pgrestapi.post_data("entity_debits_credits_changes", entityObj);

        // In case the entity change request fails, delete the new debit_credit item
        if (result2.error) {
            showError(result2.error.code + ' - ' + result2.error.message);
            await pgrestapi.delete_data("debits_credits?id=eq." + result.data[0].id);
            return;
        }

        showSuccess("Entry added successfully");

        if (mainStore.loggedUser.user_type === UserType.Administrator || mainStore.loggedUser.user_type === UserType.Supervisor) {
            emits("save_and_approve", [{ id:result2.data[0].id, entity_id: result2.data[0].id, field: 'status', value: '1' }]);
        } else {
            emits("save");
        }

        return;
    }

    // Edit existing entry

    if (originalAmount === dbEntry.value.amount && originalDate === dbEntry.value.date && originalPerigrafi === dbEntry.value.perigrafi) {
        emits('close');
        return;
    }

    let ids_to_approve: any[] = [];

    console.log("originalAmount", originalAmount, "dbEntry.value.amount", dbEntry.value.amount, 'isEqual', originalAmount === dbEntry.value.amount);

    if (originalAmount !== dbEntry.value.amount) {
        let entityObj: any = {};
        entityObj.entity_id = dbEntry.value.id;
        entityObj.editor_user_id = mainStore.loggedUser.id;
        entityObj.field = 'amount';
        entityObj.value = dbEntry.value.amount;

        console.log('POSTing entity_debits_credits_changes', entityObj)
        let result = await pgrestapi.post_data("entity_debits_credits_changes", entityObj);

        if (result.error) {
            showError(result.error.code + ' - ' + result.error.message);
            return;
        }

        ids_to_approve.push({ id: result.data[0].id, entity_id: dbEntry.value.id, field: 'amount', value: dbEntry.value.amount });
    }

    console.log("originalDate", originalDate, "dbEntry.value.date", dbEntry.value.date, 'isEqual', originalDate === dbEntry.value.date);

    if (originalDate !== dbEntry.value.date) {
        let entityObj: any = {};
        entityObj.entity_id = dbEntry.value.id;
        entityObj.editor_user_id = mainStore.loggedUser.id;
        entityObj.field = 'date';
        entityObj.value = dbEntry.value.date;

        console.log('POSTing entity_debits_credits_changes', entityObj)
        let result = await pgrestapi.post_data("entity_debits_credits_changes", entityObj);

        if (result.error) {
            showError(result.error.code + ' - ' + result.error.message);
            return;
        }

        ids_to_approve.push({ id: result.data[0].id, entity_id: dbEntry.value.id, field: 'date', value: dbEntry.value.date });
    }

    if (originalPerigrafi !== dbEntry.value.perigrafi) {
        let entityObj: any = {};
        entityObj.entity_id = dbEntry.value.id;
        entityObj.editor_user_id = mainStore.loggedUser.id;
        entityObj.field = 'perigrafi';
        entityObj.value = dbEntry.value.perigrafi;

        console.log('POSTing entity_debits_credits_changes', entityObj)
        let result = await pgrestapi.post_data("entity_debits_credits_changes", entityObj);

        if (result.error) {
            showError(result.error.code + ' - ' + result.error.message);
            return;
        }

        ids_to_approve.push({ id: result.data[0].id, entity_id: dbEntry.value.id, field: 'perigrafi', value: dbEntry.value.perigrafi });
    }

    if (mainStore.loggedUser.user_type === UserType.Administrator || mainStore.loggedUser.user_type === UserType.Supervisor) {
        emits("save_and_approve", ids_to_approve);
    } else {
        emits("save");
    }

    showSuccess("Credit edited successfully");
};

const close = () => {
    emits("close");
}

</script>

<template>
  <div v-loading="isLoading">
    <el-form>
      <el-row :gutter="10">
        <el-col :span="24">
            <b>{{ $t('DebitCreditList.date') }}</b>
            <el-date-picker
                v-model="dbEntry.date"
                type="date"
                placeholder="Pick a Date"
                format="YYYY-MM-DD"
                value-format="YYYY-MM-DD"
            />
        </el-col>
        <el-col :span="24">
            <p/>
            <b>{{ $t('DebitCreditList.amount') }}</b>
            <el-input v-model="dbEntry.amount" type="number" step="0.01" placeholder="Amount" />
        </el-col>
        <el-col :span="24">
            <p/>
            <b>{{ $t('DebitCreditList.perigrafi') }}</b>
            <el-input v-model="dbEntry.perigrafi" type="string" />
        </el-col>
        
      </el-row>

      <el-row :gutter="10">
        <el-col :span="24">
          <el-form-item class="btn-wrapper-components">
            <el-button v-if="!props.view_only" type="primary" @click="onSave">{{ $t('generic.save') }}</el-button>
            <el-button @click="close">{{ $t('generic.close') }}</el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<style scoped>

</style>
