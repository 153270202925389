{
  "documents_folders": {
    "collaterals": "Εξασφαλίσεις",
    "conventional_documents": "Συμβατικά έγγραφα",
    "legal_actions": "Νομικές ενέργειες",
    "legalization_documents": "Νομιμοποιητικά έγγραφα",
    "misc_documents": "Λοιπά έγγραφα",
    "rollovers": "Rollovers",
    "technical_evaluations": "Τεχνικές αξιολογήσεις"
  },
  "menu": {
    "MOS": "MOS",
    "Dashboard": "Πίνακας ελέγχου",
    "Summary Check": "Summary Check",
    "Approvals": "Εγκρίσεις",
    "Clients": "Πελάτες",
    "Portfolios": "Portfolios",
    "Debtors": "Οφειλέτες",
    "Loan Agreements": "Συμβάσεις",
    "Register of Bonds": "Μητρώο Ομολογιών",
    "Loan Accounts": "Δανειακοί Λογαριασμοί",
    "Real Estates": "Ακίνητα",
    "RE Collaterals": "RE Collaterals",
    "Guarantees": "Εγγυήσεις",
    "Greek State Guarantees": "Εγγυήσεις Ελλ. Δημ.",
    "Auctions": "Πλειστηριασμοί",
    "NRE Collaterals": "NRE Collaterals",
    "NRE Collaterals 2": "NRE Collaterals",
    "Eκtokismos": "Εκτοκισμός",
    "Entity Changes": "Αλλαγές",
    "File Upload": "Ανέβασμα αρχείου",
    "Configuration": "Ρυθμίσεις",
    "Platform Settings": "Ρυθμίσεις εφαρμογής",
    "Monitoring": "Monitoring",
    "Diary": "Ληξιάριο",
    "Users": "Χρήστες",
    "Import Data": "Φόρτωση Αρχείου",
    "Reports": "Αναφορές",
    "ReportPreview": "Προεπισκόπηση Report",
    "Test": "Test",
    "Stats": "Stats",
    "Metabase-Agents": "Στατιστικά - Agents",
    "Metabase-SupervisorsEdits": "Στατιστικά - Supervisors (Τροποποιήσεις)",
    "Metabase-SupervisorsApprovals": "Στατιστικά - Supervisors (Εγκρίσεις)",
    "Metabase-DLAs": "Στατιστικά - Digitized LAs",
    "Digitized LA": "Απογραφή φυσικού αρχείου",
    "TestEktok": "TestEktokismos",
    "Privileges": "Δικαιώματα",
    "Workflow":"Workflow",
    "Projects":"Projects",
    "TaskTemplate":"Task Template",
    "ProjectTemplate":"Project Template",
    "Task templates":"Task Templates",
    "Project templates":"Project Templates",
    "Custom Fields":"Custom Fields",
    "Indexing":"Indexing",
    "Frontier":"Frontier Indexing"
  },
  "generic": {
    "name": "Όνομα",
    "surname": "Επίθετο",
    "fullname": "Πλήρες όνομα",
    "userdetails": "Πληροφορίες χρήστη",
    "cancel": "Ακύρωση",
    "delete": "Διαγραφή",
    "close": "Κλείσιμο",
    "save": "Αποθήκευση"
  },
  "questions": {
    "enter_username": "Εισάγετε όνομα χρήστη"
  },
  "components": {
    "switch": {
      "yes": "Ναι",
      "no": "Όχι"
    }
  },
  "loan_agreement": {
    "in_progress": "Εν εξελίξει",
    "unchecked": "Μη ελεγμένο ",
    "completed": "Ολοκληρωμένο",
    "performing": "Performing",
    "non_performing": "Non Performing",
    "denounced": "Denounced"
  },
  "LiksiarioView": {
    "id": "Εσωτερικό ID Βάσης Δεδομένων",
    "diary": "Ληξιάριο",
    "remaining_balanc": "Εναπομείναν υπόλοιπο (σύνολο)",
    "repayment_date": "Ημ/νία επόμενης πληρωμής",
    "margin": "Περιθώριο",
    "cert_sent": "Αποστολή επιστολής",
    "total_remaining_balance": "Εναπομείναν υπόλοιπο (σύνολο)",
    "our_participation": "Υπόλοιπο συμμετοχής μας",
    "interest_calc_start_date": "Έναρξη περιόδου εκτοκισμού",
    "interest_calc_end_date": "Λήξη περιόδου εκτοκισμού",
    "interest_rate": "Επιτόκιο",
    "interest_amount": "Τόκοι 100%",
    "withholdind_tax": "Φόρος 15%",
    "interest_amount_due": "Οφειλόμενοι τόκοι",
    "comments": "Παρατηρήσεις",
    "tax_certificate": "Επιστολή φόρου 15%",
    "days_overdue": "Ληξιπροθεσμία",
    "alarm_date": "Ημ/νία υπενθύμισης",
    "due_date": "Καταληκτική ημ/νία",
    "from": "από",
    "to": "έως",
    "ektokismos": "Εκτοκιστική περίοδος",
    "loan_agreement_id": "Αριθμός σύμβασης"
  },
  "validation": {
    "check_fields": "Παρακαλώ ελέγξτε τα προβληματικά πεδία",
    "max_20chars": "Πρέπει να είναι μέχρι 20 χαρακτήρες το πολύ",
    "only_numbers": "Πρέπει να περιέχει μόνο αριθμούς",
    "insert_cid": "Παρακαλώ εισάγετε CID",
    "insert_portfolio": "Παρακαλώ εισάγετε Portfolio",
    "insert_tax_registration_nbr": "Παρακαλώ εισάγετε ΑΦΜ",
    "insert_client_name": "Παρακαλώ εισάγετε όνομα πελάτη",
    "select_debtor": "Παρακαλώ εισάγετε οφειλέτη",
    "select_real_estate": "Παρακαλώ εισάγετε ακίνητο",
    "select_re_collateral": "Παρακαλώ εισάγετε real estate collateral",
    "tax_registration_nbr": "Παρακαλώ εισάγετε ΑΦΜ",
    "select_third_part_id": "Παρακαλώ εισάγετε Third Party ID",
    "select_ar_prog_pl": "Παρακαλώ εισάγετε αρ. προγράμματος Πλειστηριασμού",
    "select_hmer_pl": "Παρακαλώ εισάγετε ημερομηνία πλειστηριασμού",
    "select_anag_apetisis": "Παρακαλώ εισάγετε αναγγελία απαίτησης",
    "select_epispeudon": "Παρακαλώ εισάγετε επισπεύδων",
    "select_timh_pl": "Παρακαλώ εισάγετε τιμή πλειστηριασμού",
    "select_timh_katak": "Παρακαλώ εισάγετε τιμή κατακύρωσης",
    "select_pleiodotis": "Παρακαλώ εισάγετε πλειοδότη",
    "select_hmer_ekd": "Παρακαλώ εισάγετε ημερομηνία προηγούμενου πλειστηριασμού",
    "realestate_nbr": "Real Estate Nbr is required and must be numeric."
  },
  "summary_check": {
    "full_ownership": "Πλήρης",
    "bare_ownership": "Ψιλή",
    "usufruct": "Επικαρπία",
    "right_of_residence_for_life": "Δικαίωμα ισόβιας οίκησης",
    "air_right": "Δικαίωμα Υψούν",
    "null": " "
  },
  "errors": {
    "access_denied": "Απαγορεύεται η πρόσβαση",
    "missing_bank_name": "Λείπει το όνομα τράπεζας",
    "missing_client_name": "Λείπει το όνομα πελάτη",
    "missing_contact_details": "Λείπουν τα στοιχεία επικοινωνίας",
    "missing_participation_percent": "Λείπει το ποσοστό συμμετοχής",
    "missing_title_name": "Λείπει το όνομα τίτλου",
    "another_loan_no_bonds": "The loan type can't have a register of bonds",
    "select_portfolio": "Παρακαλώ επιλέξτε Portfolio",
    "aplo_omologiako_mia_trapeza": "The loan type can't have more than one participating banks",
    "aplo_omologiako_mia_trapeza_pososto": "Το ποσοστό της τράπεζας πρέπει να είναι 100.",
    "debtor_already_exists": "Debtor already exists (CID & VAT combination)",
    "missing_construction_date": "Missing construction date",
    "missing_debtor": "Missing Debtor",
    "missing_debtor_cid": "Missing Debtor CID",
    "missing_lhksh_asfalistiriou": "Λείπει η ημερομηνία λήξης του ασφαλιστηρίου",
    "missing_real_estate_id": "Λείπει το real estate ID",
    "missing_loan_agreement": "Missing Loan Agreement",
    "missing_start_bond_number": "Missing Starting Bond Number",
    "missing_end_bond_number": "Missing End Bond Number",
    "missing_nominal_value": "Missing Nominal Value",
    "missing_issue_date": "Missing Issue Date",
    "missing_end_date": "Missing End Date",
    "missing_bond_status": "Missing Bond Status",
    "missing_valuation_date": "Missing valuation date",
    "percentage_issue": "The percentages are wrong",
    "no_debtor_with_this_cid": "There is no debtor with this CID",
    "please_insert_kad": "Λείπει το ΚΑΔ",
    "denounce_amounts_wrong": "To Ποσό καταγγελίας δεν ισούται με το άθροισμα των ακόλουθων 5 αριθμητικών πεδίων.",
    "ypovoli_paratiriseon": "Η ημερομηνία υποβολής παρατηρήσεων δεν είναι έως 5 ημέρες από τη λήξη της αναγγελίας απαίτησης.",
    "suntaxi_pinaka_kat": "Η ημερομηνία σύνταξης πίνακα κατάταξης δεν είναι εντός 10 ημερών από την υποβολή παρατηρήσεων.",
    "hmer_anakophs": "Η ημερομηνία ανακοπής δεν είναι εντός 15 ημερών από την σύνταξης πίνακα κατάταξης .",
    "loan_accounts_balance_error": "Το άθροισμα των ποσών είναι λάθος",
    "missing_field_hmer": "Η ημερομηνία πληρωμής απαιτείται.",
    "missing_field_poso": "Το ποσό πληρωμής απαιτείται.",
    "portfolio_account_count_error": "Περισσότερες της μίας Τράπεζας έχουν επιλεγεί σαν λογαριασμός του Portfolio",
    "loan_accounts_balance_error_mh_logistik": "Το άθροισμα των μη λογιστικοποιημένων τόκων δεν ταιριάζει.",
    "loan_accounts_balance_error_logistik": "Το άθροισμα των λογιστικοποιημένων τόκων δεν ταιριάζει."
  },
  "notifications": {
    "pending_changes_register": "Το μητρώο ομολογιών έχει εκκρεμείς αλλαγές"
  },
  "loan_account": {
    "approval": "Εγκριση",
    "approval_status_null": "-",
    "approval_status_rejected": "Απορρίφθηκε",
    "approval_status_accepted": "Εγκρίθηκε",
    "pliromes": "Πληρωμές"
  },
  "payments": {
    "hmer": "Ημερομηνία πληρωμής",
    "poso": "Ποσό πληρωμής",
    "kefalaio": "Κεφάλαιο",
    "tokoi": "Τόκοι",
    "exoda": "Έξοδα",
    "proskomisi": "Προσκόμιση βεβαίωσης",
    "hmer_prosk": "Ημερομηνία προσκόμισης",
    "loan_account": "Loan Account"
  },
  "LoanAccountEktokismosView": {
    "hmer_epomenis_dosis": "Ημερομηνία επόμενης δόσης",
    "loan_account_id": "Loan Account",
    "kefalaio": "Κεφάλαιο επόμενης δόσης",
    "tokoi": "Τόκος επόμενης δόσης"
  },
  "LoanAgreementEktokismosView": {
    "bank_percent": "Ποσοστό Τράπεζας",
    "base_rate_value": "Επιτόκιο",
    "margin": "Περιθώριο",
    "total_due_rate": "Τελικό ενήμερο επιτόκιο",
    "overdue_rate": "Υπερημέρια",
    "total_rate": "Συνολικό επιτόκιο",
    "participating_banks": "Συμμετέχουσες Τράπεζες",
    "due_principal": "Ενήμερο Κεφάλαιο",
    "interest_on_due_principal": "Τόκοι ενήμερου κεφαλαίου",
    "overdue_principal": "Καθυστερημένο Κεφάλαιο",
    "overdue_interest": "Καθυστερημένοι Τόκοι",
    "interest_on_overdue_amount": "Τόκοι καθυστερημένης οφειλής",
    "start_date": "Ημερομηνία έναρξης",
    "end_date": "Ημερομηνία λήξης",
    "imeres": "Ημέρες"
  },
  "ektokismos": {
    "ektokismos": "Εκτοκισμός",
    "hmer_enarxis_ekt_periodou": "Ημερομηνία Έναρξης Εκτοκιστικής Περιόδου",
    "hmer_lixis_ekt_periodou": "Ημερομηνία Λήξης Εκτοκιστικής Περιόδου",
    "enimero_kef": "Ενήμερο Κεφάλαιο",
    "kathisterimeno_kef": "Καθυστερημένο Κεφάλαιο",
    "kathisterimenoi_tokoi": "Καθυστερημένοι Τόκοι",
    "epitokio": "Επιτόκιο περιόδου",
    "tokoi_enimeris": "Τόκοι ενήμερης οφειλής",
    "tokoi_kath": "Τόκοι καθυστερημένης οφειλής",
    "apodate": "Από",
    "todate": "Έως"
  },
  "global": {
    "hide_rejected_entities": "Απόκρυψη απορριφθέντων",
    "operations": "Ενέργειες",
    "pending_approvals": "Εκκρεμείς Εγκρίσεις",
    "pending_changes": "Εκκρεμείς Αλλαγές",
    "pending_changes_new_entities": "Εκκρεμείς Νέες εγγραφές",
    "true": "ΝΑΙ",
    "false": "ΟΧΙ",
    "active": "Ενεργό",
    "inactive": "Αδρανή",
    "date": "Ημερομηνία",
    "back": "Πίσω",
    "save": "Αποθήκευση",
    "next_step": "Επόμενο Βήμα",
    "select": "Επιλογή",
    "data_entry": "Εισαγωγή Δεδομένων",
    "approve_status": "Approve Status",
    "saved_dates": "Αποθηκευμένες Ημερομηνίες",
    "summary_check_error": "Δεν ήταν δυνατή η εύρεση όλων των απαιτούμενων δεδομένων για τη σύνοψη της δανειακής σύμβασης.",
    "ChangedToDenounced": "Το legal status της σύμβασης άλλαξε σε denounced. Προτείνεται από ",
    "debtor_not_approved": "Debtor requires approval(s)",
    "loan_agreement_not_approved": "Loan Agreement requires approval(s)",
    "real_estate_not_approved": "Real Estate requires approval(s)",
    "null": " ",
    "ignore_inactive_users": "Αγνόηση αδρανών χρηστών",
    "lockedrow": "Ένας άλλος χρήστης επεξεργάζεται αυτήν τη εγγραφή. Κάντε κλικ για να ξεκλειδώσετε τη εγγραφή.",
    "unlockalllockedrow": "Ξεκλειδώστε όλες τις κλειδωμένες εγγραφές",
    "ImportError": "Προβλήματα σε εγγραφές - Import",
    "import_errors": "Σφάλματα - Imports",
    "import_error_field": "Σφάλματα Import",
    "import_error_info": "Διαγράψτε το περιεχόμενο του πεδιου για να σβηστεί η ειδοποίηση σφάλματος"
  },
  "legal_status_list": {
    "performing": "Performing",
    "non_performing": "Non performing",
    "denounced": "Denounced"
  },
  "payment_type_list": {
    "xreolitiko": "ΧΡΕΩΛΥΤΙΚΟ ΙΣΟΠΟΣΩΝ ΔΟΣΕΩΝ",
    "tokoxreolitiko": "ΤΟΚΟΧΡΕΩΛΥΤΙΚΟ",
    "balloon": "ΧΡΕΩΛΥΤΙΚΟ ΑΝΙΣΟΠΟΣΩΝ ΔΟΣΕΩΝ"
  },
  "SummaryCheckView": {
    "loan_agreement": "Αριθμός σύμβασης",
    "loan_agreement_fao": "Αριθμός σύμβασης FAO",
    "tax_registration_nbr": "ΑΦΜ",
    "product": "Προϊόν",
    "status": "Κατάσταση",
    "pending_approvals": "Εκκρεμείς Αλλαγές",
    "summary_check": "Συνοπτικός Έλεγχος"
  },
  "ApprovalView": {
    "loan_agreement": "Αριθμός Σύμβασης",
    "mos_client": "Mos client",
    "approvals": "Εγκρίσεις",
    "middle_officer": "Middle Officer"
  },
  "ClientsView": {
    "clients": "Πελάτες",
    "company_name": "Επωνυμία πελάτη"
  },
  "PortfolioDetails": {
    "name": "Name",
    "asset_owner": "Asset Owner",
    "digitization": "Ψηφιοποίηση",
    "digital_code": "Digital Code"
  },
  "PortfolioView": {
    "portfolios": "Portfolios"
  },
  "DebtorView": {
    "debtors": "Debtors",
    "company_name": "Ονοματεπώνυμο / Επωνυμία",
    "phone": "Τηλέφωνο",
    "tax_registration_nbr": "ΑΦΜ",
    "debtor_company_name": "Ονοματεπώνυμο / Επωνυμία Οφειλέτη",
    "debor_tax_registration_nbr": "ΑΦΜ Οφειλέτη"
  },
  "DigitizedView": {
    "loan_agreements": "Απογραφή φυσικού αρχείου"
  },
  "LoanAgreementView": {
    "loan_agreements": "Loan Agreements",
    "loan_agreement": "Αριθμός Σύμβασης",
    "loan_agreement_fao": "Αριθμός σύμβασης FAO",
    "tax_registration_nbr": "ΑΦΜ",
    "product": "Προϊόν",
    "status": "Κατάσταση",
    "legal_status": "Legal Status",
    "ektokismos": "Εκτοκισμός",
    "add_loan_agreement": "Προσθήκη loan agreement",
    "delete_la": "Διαγραφή Loan Agreement",
    "delete_la_check": "Είστε σίγουροι ότι θέλετε να διαγράψετε αυτό το Loan Agreement;",
    "la_details": "Στοιχεία Loan Agreement"
  },
  "LoanAccountsView": {
    "loan_accounts": "Loan Accounts",
    "loanaccount": "Αριθμός Τρέχοντος Λογαριασμού",
    "original_loan_account": "Αριθμός αρχικού λογαριασμού",
    "loan_agreement": "Αριθμός σύμβασης",
    "installments": "Επόμενες Δόσεις",
    "loan_calculations": "Υπολογισμοί Δανείου"
  },
  "RealEstateView": {
    "real_estate": "Real Estate",
    "real_estate_nbr": "Αριθμός Ακινήτου Διαχειριστή",
    "city": "Πόλη",
    "debtor_cid": "Debtor CID",
    "type_of_property": "Είδος ακινήτου"
  },
  "RealEstateCollateralsView": {
    "RealEstateCollaterals": "Real Estate Collaterals",
    "collateral_nbr": "Αριθμός Καλύμματος",
    "loan_agreement": "Αριθμός Σύμβασης",
    "real_estate_nbr": "Αριθμός Ακινήτου"
  },
  "GuaranteeView": {
    "guarantees": "Guarantees",
    "loan_agreement": "Αριθμός Σύμβασης",
    "guaranteed_amount": "Ποσό Εγγύησης",
    "full_agreement_guarantee": "Εγγύηση του συνόλου της συμβάσεως",
    "guarantee_full_name": "Ονοματεπώνυμο / Επωνυμία Εγγυητή",
    "guarantee_vat": "ΑΦΜ Εγγυητή"
  },
  "GreekStateGuaranteesView": {
    "greek_state_guarantees": "Greek State Guarantees",
    "kya_of_greek_state_guarantee_provision": "ΚΥΑ παροχής ΕΕΔ",
    "loan_agreement": "Αριθμός Σύμβασης",
    "guaranteed_amount": "Ποσό Εγγύησης"
  },
  "AuctionsView": {
    "auctions": "Auctions",
    "debtor_cid": "Debtor CID",
    "real_estate_nbr": "Αριθμός Ακινήτου Διαχειριστή",
    "tax_registration_nbr": "ΑΦΜ"
  },
  "NRECollateralsView": {
    "nre_collaterals": "NRE Collaterals",
    "loan_agreement": "Αριθμός Σύμβασης",
    "checks": "Επιταγές",
    "listed_shares": "Εισηγμένες Μετοχές",
    "unlisted_shares": "Μη εισηγμένες μετοχές"
  },
  "UsersView": {
    "users": "Χρήστες",
    "username": "Όνομα Χρήστη",
    "name": "Όνομα",
    "company": "Εταιρεία",
    "user_type": "Τύπος Χρήστη",
    "deactive_user_question": "Είστε σίγουροι πως θέλετε να απενεργοποιήσετε αυτό το χρήστη;"
  },
  "RegisterOfBondsView": {
    "register_of_bonds": "Register Of Bonds",
    "loan_agreement": "Αριθμός Σύμβασης",
    "bondholder": "Ομολογιούχος",
    "participating_banks": "Συμμετέχουσες Τράπεζες",
    "participating_percent": "Ποσοστό Συμμετοχής",
    "register": "Εγγραφές",
    "banks": "Τράπεζες",
    "titles": "Τίτλοι",
    "bonds": "Ομολογίες",
    "payment_representative": "Πληρεξούσιος Καταβολών / Έκπρόσωπος των Ομολογιούχων",
    "replacement_mechanism": "Μηχανισμός αντικατάστασης εκπροσώπου των Ομολογιούχων",
    "information": "Πληροφορίες",
    "Participating_banks_servicers": "Συμμετέχουσες Τράπεζες",
    "contact_details": "Στοιχεία Επικοινωνίας",
    "nominal_value": "Ονομαστική αξία τίτλου",
    "issue_date": "Ημερομηνία έκδοσης",
    "end_date": "Ημερομηνία λήξης",
    "status": "Κατάσταση",
    "from": "Από",
    "to": "Έως",
    "portfolio_account": "Portfolio Account",
    "start_bond_number": "Από",
    "end_bond_number": "Έως",
    "ar_sumbashs": "Αριθμός Σύμβασης",
    "bank_name": "Τράπεζα",
    "participation_percent": "Ποσοστό Συμμετοχής %",
    "title_name": "Τίτλος"
  },
  "EntityChangesView": {
    "editor": "Editor",
    "fieldname": "Όνομα Πεδίου",
    "approver": "Υπεύθυνος Έγκρισης",
    "approval_status": "Κατάσταση Έγκρισης",
    "entity": "Οντότητα",
    "field": "Πεδίο",
    "value": "Τιμή",
    "inserted_at": "Ημερομηνία/Ώρα Εισαγωγής",
    "decision_at": "Ημερομηνία/Ώρα Απόφασης",
    "select_entity_type": "Επιλέξτε Τύπο Οντότητας"
  },
  "global_validation": {
    "portfolio_is_required": "Το πεδίο Portfolio είναι υποχρεωτικό",
    "asset_owner_is_required": "Το πεδίο Asset Owner είναι υποχρεωτικό",
    "mos_client_is_required": "Το πεδίο MOS Client είναι υποχρεωτικό",
    "debtor_is_required": "Το πεδίο Debtor είναι υποχρεωτικό",
    "loan_agreement_is_required": "Το πεδίο Loan Agreement είναι υποχρεωτικό",
    "real_estate_is_required": "Το πεδίο Real Estate είναι υποχρεωτικό",
    "nre_collateral_is_required": "Το πεδίο NRE Collateral είναι υποχρεωτικό",
    "product_is_required": "Το πεδίο Product είναι υποχρεωτικό",
    "collateral_number": "Το πεδίο Αριθμός Καλύμματος είναι υποχρεωτικό",
    "loan_agreement_number": "Το πεδίο Αριθμός Σύμβασης είναι υποχρεωτικό",
    "type": "Το πεδίο Είδος Collateral είναι υποχρεωτικό"
  },
  "ApprovalDetailsView": {
    "loan_agreement": "Αριθμός Σύμβασης",
    "approval_decision_send_date": "Ημερομηνία αποστολής έγκρισης",
    "account_manager": "Account Manager",
    "approval_decision_subject": "Θέμα έγκρισης",
    "mos_assignment_date": "Ημερομηνία ανάθεσης εντός της MOS",
    "mos_estimated_imlementation_date": "Εκτιμώμενη ημερομηνία υλοποίησης",
    "middle_officer": "Middle Officer",
    "end_date": "Ημερομηνία ολοκλήρωσης",
    "lawyer": "Δικηγόρος",
    "comments": "Σχόλια",
    "choose_client": "Επιλέξτε MOS Client",
    "choose_debtor": "Επιλέξτε Debtor",
    "loan_agreement_id": "Αριθμός Σύμβασης",
    "hmer_apost_egkrishs": "Ημερομηνία αποστολής έγκρισης",
    "thema_egkrishs": "Θέμα έγκρισης",
    "hmer_anatheshs_mos": "Ημερομηνία ανάθεσης εντός της MOS",
    "ektim_hmer_ylop": "Εκτιμώμενη ημερομηνία υλοποίησης",
    "hmer_olokl": "Ημερομηνία ολοκλήρωσης"
  },
  "DDebtorDetailsView": {
    "details": "Στοιχεία από Πελάτη",
    "extra_fields": "Στοιχεία απογραφής",
    "cid": "CID",
    "portfolio": "Portfolio",
    "pelatis": "Πελάτης",
    "cor_contractcode": "COR Contract Code",
    "cor_sdocontractid": "COR SdoContract Id",
    "physical_contract_id": "Physical Contract Id",
    "cor_importdate": "COR Import Date",
    "securitized_perimeter": "Securitized perimeter",
    "analyt_thesi_fakel_ana_cid": "Αναλυτική θέση φακέλων ανά CID",
    "checked_not_included_incairo": "Checked Not included in Cairo",
    "fak_pistoux_cairo": "Φακέλλοι πιστούχων cairo",
    "denounced_flag": "Denounced Flag",
    "stoixeia_xeiristi": "Στοιχεία χειριστή",
    "anoigma": "Άνοιγμα",
    "elegxos": "Έλεγχος",
    "apografh": "Απογραφή",
    "elegxos_so1": "Έλεγχος - S.0.1",
    "stoixeia_pshfiop": "Στοιχεία Ψηφιοποίησης",
    "shmansh_barcode": "Σήμανση Barcode",
    "elegxos_so2_1": "Έλεγχος - S.O.2",
    "pshfiopoihsh": "Ψηφιοποίηση",
    "suskeuasia": "Συσκευασία",
    "elegxos_pmo": "Έλεγχος PMO",
    "stby_apostolhs": "STBY Αποστολής",
    "apostolh": "Αποστολή",
    "end_stand_by_apograf": "Ένδειξη Stand By Απογραφής",
    "sxolio": "Σχόλιο",
    "folders": "Στοιχεία ψηφοποίησης",
    "downloadexportprot": "Εξαγωγή Πρωτοκόλλου Π/Π",
    "downloadexportdt": "Εξαγωγή Data Tape",
    "permissions": "Δεν έχετε πρόσβαση σε αυτή τη σελίδα.",
    "Please_select": "Παρακαλώ επιλέξτε portfolio για εξαγωγή",
    "cor_center": "COR Center",
    "physical_contract_id_copy": "Physical Contract Id COPY",
    "apografi": "Απογραφή",
    "sumvatika_eggrafa": "Συμβατικά έγγραφα - 01",
    "sumvasi": "Σύμβαση",
    "symv_arithmos_zelatinon": "Αριθμός ζελατίνων",
    "symv_symv_prot": "Πλήθος πρωτοτύπων Συμβ.",
    "symv_symv_antig": "Πλήθος αντιγράφων Συμβ",
    "prosthetes_praxeis": "Πρόσθετες Πράξεις",
    "symv_prosth_prot": "Πλήθος πρωτοτύπων Πρ. Πράξης",
    "symv_prosth_antigr": "Πλήθος αντιγράφων Πρ. Πράξης",
    "symv_prosth_sxolio": "Σχόλιο",
    "nomimopoihtika": "Νομιμοποιητικά (Μόνο για ΟΔ) - 02",
    "praktikaGS_DS": "Πρακτικά ΓΣ/ΔΣ",
    "nomim_antig": "Πλήθος αντιγράφων Νομιμοποιητικών",
    "nomim_prot": "Πλήθος πρωτοτύπων Νομιμοποιητικών",
    "axiografa": "Αξιόγραφα (Μόνο για ΟΔ) - 03",
    "axiogr_arithmos_zelatinon": "Αριθμός ζελατίνων αξιογράφων",
    "axiogr_prot": "Πλήθος πρωτοτύπων αξιογράφων",
    "axiogr_antig": "Πλήθος αντιγράφων αξιογράφων",
    "exasfalistika": "Εξασφαλιστικά έγγραφα - 04",
    "arithmos_zelatinon": "Αριθμός ζελατίνων",
    "prosim_upoth": "Προσημειώσεις / Υποθήκες",
    "prosim_plithos": "Πλήθος",
    "plithos_eggr": "Πλήθος εγγράφων",
    "enexiriasis_ekx": "Ενεχυριάσεις / Εκχωρήσεις",
    "enex_plithos_prot": "Πλήθος πρωτοτύπων Ενεχ. / Εκχ.",
    "enex_plithos_antig": "Πλήθος αντιγράφων Ενεχ. / Εκχ.",
    "epid_enexiriasis_ekx": "Επιδόσεις ενεχυριάσεων/εκχωρήσεων",
    "epid_enex_plithos_prot": "Πλήθος πρωτοτύπων Επιδόσεων Ενεχ. / Εκχ.",
    "epid_enex_plithos_antig": "Πλήθος αντιγράφων Επιδόσεων Ενεχ. / Εκχ.",
    "nomikes_energeies": "Νομικές ενέργειες - 05",
    "nom_epist_kataggelias": "Επιστολές καταγγελίας",
    "nom_epist_kataggelias_prot": "Πλήθος πρωτοτύπων Επιστ. Καταγγ.",
    "nom_epist_kataggelias_antig": "Πλήθος αντιγράφων Επιστ. Καταγγ.",
    "nom_ekth_epid": "Εκθέσεις επίδοσης",
    "nom_ekthes_epid_prot": "Πλήθος πρωτοτύπων Εκθ. Επιδ.",
    "nom_ekthes_epid_antig": "Πλήθος αντιγράφων Εκθ. Επιδ.",
    "nom_diat_plir": "Διαταγές πληρωμής",
    "nom_diat_plir_prot": "Πλήθος πρωτοτύπων Διατ. Πληρ.",
    "nom_diat_plir_antig": "Πλήθος αντιγράφων Διατ. Πληρ.",
    "nom_epid_dp": "Επιδόσεις Δ.Π.",
    "nom_epid_dp_prot": "Πλήθος πρωτοτύπων Επίδ. Δ.Π.",
    "nom_epid_dp_antig": "Πλήθος αντιγράφων Επίδ. Δ.Π.",
    "nom_apogr": "Απόγραφο",
    "nom_apogr_prot": "Πλήθος πρωτοτύπων Απόγρ.",
    "nom_apogr_antig": "Πλήθος αντιγράφων Απόγρ.",
    "loipa": "Λοιπά - 06",
    "eggrafa_ektos_DT": "'Εγγραφα εκτός DT",
    "sxolio_apogr": "Σχόλιο",
    "folder_barcode": "Barcode φακέλου",
    "container_barcode": "Barcode κιβωτίου",
    "ccy": "Νόμισμα",
    "gelatin_barcode": "Barcode ζελατίνης",
    "portfolio_id": "Portfolio"
  },
  "DebtorDetailsView": {
    "individual": "ΦΥΣΙΚΟ ΠΡΟΣΩΠΟ",
    "legal_entity": "ΝΟΜΙΚΟ ΠΡΟΣΩΠΟ ΙΔΙΩΤΙΚΟΥ ΔΙΚΑΙΟΥ",
    "legal_entity_public": "ΝΟΜΙΚΟ ΠΡΟΣΩΠΟ ΔΗΜΟΣΙΟΥ ΔΙΚΑΙΟΥ",
    "details": "Λεπτομέρειες",
    "legal_details": "Νομικές Λεπτομέρειες",
    "legal_address": "Νόμιμη Διεύθυνση",
    "postal_address": "Διεύθυνση Αλληλογραφίας",
    "rest_of_legal_details": "Υπόλοιπα Νομικά Στοιχεία",
    "cid": "CID",
    "mos_client": "Mos client",
    "portfolio": "Portfolio",
    "former_asset_owner_fao": "Former Asset Owner (FAO)",
    "cid_fao": "CID FAO",
    "customer_category": "Κατηγορία πελάτη",
    "customer_type": "Κατηγορία πελάτη",
    "legal_status": "Legal Status",
    "company_fullname": "Ονοματεπώνυμο / Επωνυμία",
    "fullname": "Ονοματεπώνυμο / Επωνυμία",
    "id_card_type": "ΑΔΤ",
    "id_card": "ID card",
    "tax_registration_nbr": "ΑΦΜ",
    "vat_number": "ΑΦΜ",
    "gemi": "ΓΕΜΗ",
    "gemh": "ΓΕΜΗ",
    "industrial_sector": "Κλάδος",
    "klados": "Κλάδος",
    "kad": "ΚΑΔ",
    "street": "Οδός",
    "address": "Οδός",
    "street_number": "Αριθμός",
    "address_number": "Αριθμός",
    "region": "Περιοχή",
    "city": "Πόλη",
    "prefecture": "Νομός",
    "district": "Νομός",
    "zip_code": "ΤΚ",
    "postcode": "ΤΚ",
    "country": "Χώρα",
    "phone": "Τηλέφωνο",
    "phone_number": "Τηλέφωνο",
    "email": "Email",
    "postal_street": "Οδός Αλληλογραφίας",
    "postal_nbr": "Αριθμός Αλληλογραφίας",
    "postal_address_number": "Αριθμός Αλληλογραφίας",
    "postal_region": "Περιοχή Αλληλογραφίας",
    "postal_city": "Πόλη Αλληλογραφίας",
    "postal_prefecture": "Νομός Αλληλογραφίας",
    "postal_district": "Νομός Αλληλογραφίας",
    "postal_zip_code": "ΤΚ Αλληλογραφίας",
    "postal_postcode": "ΤΚ Αλληλογραφίας",
    "postal_country": "Χώρα Αλληλογραφίας",
    "legal_start_date": "Ημ/νία έναρξης ισχύος νομιμοποίησης",
    "hmer_enar_isx_nomim": "Ημ/νία έναρξης ισχύος νομιμοποίησης",
    "legal_end_date": "Ημ/νία λήξης ισχύος νομιμοποίησης",
    "hmer_liks_isx_nomim": "Ημ/νία λήξης ισχύος νομιμοποίησης",
    "social_security_clearance_issue_date": "Ημ/νία έκδοσης Ασφαλιστικής Ενημερότητας",
    "hmer_ekd_asfa_enim": "Ημ/νία έκδοσης Ασφαλιστικής Ενημερότητας",
    "social_security_clearance_end_date": "Ημ/νία λήξης Ασφαλιστικής Ενημερότητας",
    "hmer_liks_asfa_enim": "Ημ/νία λήξης Ασφαλιστικής Ενημερότητας",
    "mos_client_account_officer": "MOS Clients Account Officer",
    "mos_clients_acc_off": "MOS Clients Account Officer",
    "allowed_users": "",
    "approve_status": "Κατάσταση Έγκρισης",
    "group_id": "Κωδικός Ομίλου",
    "loan_agreement": "Αριθμός σύμβασης",
    "group_name": "Επωνυμία Ομίλου",
    "mos_officer": "MOS Officer",
    "supervisors": "Supervisors",
    "fao": "FAO"
  },
  "LoanAgreementDetails": {
    "status": "Κατάσταση",
    "loan_agreement": "Αριθμός σύμβασης",
    "loan_agreement_fao": "Αριθμός σύμβασης FAO",
    "legal_status": "Legal Status",
    "product": "Προϊόν",
    "currency": "Νόμισμα",
    "total_amount": "Συνολικό ποσό σύμβασης",
    "date_of_agreement": "Ημερομηνία σύμβασης",
    "date_of_additional_arrangement": "Τελευταία Ημερομηνία Τροποποίησης Σύμβασης",
    "agent": "Agent",
    "choose_agent": "Επιλέξτε Agent",
    "comments": "Σχόλια",
    "initiation_of_legal_actions": "Denouncement Date",
    "serving_of_legal_actions": "Ημερομηνία επίδοσης δικαστικών ενεργειών",
    "serving_fees": "Έξοδα επίδοσης",
    "payment_order": "Έκδοση διαταγής πληρωμής",
    "payment_order_details": "Έκδοση διαταγής πληρωμής - Λεπτομέρειες",
    "process_interruption_margin": "Περιθώριο Ανακοπής",
    "suspension_request": "Αίτηση Αναστολής",
    "suspension_request_margin": "Περιθώριο Αίτησης Αναστολής",
    "appraisal_by_a_certified_appraiser": "Εκτίμηση από πιστοποιημένο εκτιμητή",
    "appraisal_fees": "Έξοδα εκτίμησης",
    "date_of_serving_of_confiscation_report": "Ημερομηνία επίδοση έκθεσης κατάσχεσης",
    "confiscation_amount": "Ποσό κατάσχεσης",
    "confiscation": "Κατάσχεση",
    "in_the_hands_of_a_third_party": "Εις χείρας Τρίτου",
    "date_of_upcoming_auction": "Ημερομηνία επερχόμενου πλειστηριασμού",
    "suspension_request_from": "Περιθώριο αναστολής Από",
    "suspension_request_to": "Περιθώριο αναστολής Έως",
    "auction": "Δημοπρασία",
    "paymentorders": "Προσθήκες Διαταγών Πληρωμής",
    "percentage_issue": "Τα ποσοστά είναι λάθος",
    "kefaleo": "Κεφάλαιο",
    "log_tokoi": "Λογ. Τόκοι",
    "mh_log_tokoi": "Μη Λογ. Τόκοι",
    "promithies_exoda": "Προμήθειες/Έξοδα",
    "tokoi_promithion_exodon": "Τόκοι Προμηθειών/Εξόδων",
    "denouncedamount": "Ποσό καταγγελίας: ",
    "hmer_kai_poso_ofeilhs": "Ημερομηνίες / Ποσά Οφειλής",
    "arxikh_hmer_lukshs": "Αρχική ημερομηνία λήξης",
    "nea_hmer_lukshs": "Νέα ημερομηνία λήξης",
    "vasi_ektokismou": "Βάση εκτοκισμού",
    "kathgor_epitok_bashs": "Κατηγορία επιτοκίου βάσης",
    "perithorio": "Περιθώριο",
    "epitok_uperhm": "Επιτόκιο υπερημερίας",
    "eisfora_n128_1975": "Εισφορά Ν.128/1975",
    "parakra_forou": "Παρακράτηση φόρου",
    "oros_propliromis": "Όρος προπληρωμής",
    "new_amendment_date": "Νέα Ημερομηνία Τροποποίησης",
    "date_amount_repayment": "Νέα Ημερομηνία/Ποσό Οφειλής",
    "debtor_id": "Οφειλέτης",
    "ar_sumbashs": "Αριθμός σύμβασης",
    "ar_sumbashs_fao": "Αριθμός σύμβασης FAO",
    "katast_sumbashs": "Legal Status",
    "synol_poso_sumbashs": "Συνολικό ποσό σύμβασης",
    "hmer_sumbashs": "Ημερομηνία σύμβασης",
    "hmer_tropop_sumbashs": "Τελευταία Ημερομηνία Τροποποίησης Σύμβασης",
    "hmer_enarx_dikast_energ": "Denouncement Date",
    "hmer_epidosis_dikast_energ": "Ημερομηνία επίδοσης δικαστικών ενεργειών",
    "exoda_epidosis": "Έξοδα επίδοσης",
    "ekdosi_diatagis": "Έκδοση διαταγής πληρωμής",
    "diatagi_pl_hmek_hmep_ex_per": "Διαταγή πληρωμής",
    "aitisi_anastolis": "Αίτηση Αναστολής",
    "katasxesi": "Κατάσχεση",
    "ektimisi": "Εκτίμηση από πιστοποιημένο εκτιμητή",
    "exoda_ektimisis": "Έξοδα εκτίμησης",
    "hmer_epid_ekth_katasx": "Ημερομηνία επίδοση έκθεσης κατάσχεσης",
    "exoda_epidosis_ekth_katasx": "Έξοδα επίδοσης",
    "poso_katasxesis": "Ποσό κατάσχεσης",
    "eis_xeiras_tritou": "Εις χείρας Τρίτου",
    "hmer_eperx_pleist": "Ημερομηνία επερχόμενου πλειστηριασμού",
    "perithorio_anakopis": "Περιθώριο Ανακοπής",
    "perithorio_anastolis_apo": "Περιθώριο αναστολής Από",
    "perithorio_anastolis_eos": "Περιθώριο αναστολής Έως",
    "denouncement_amount": "Ποσό καταγγελίας",
    "tropos_apopl": "Τρόπος αποπληρωμής",
    "hmer_enark_period_xaritos": "Ημερομηνία έναρξης περιόδου χάριτος",
    "hmer_liksis_period_xaritos": "Ημερομηνία λήξης περιόδου χάριτος",
    "program_apopl_kefal": "Συχνότητα αποπληρωμής κεφαλαίου",
    "hmer_prwths_doshs_kefal": "Ημερομηνία πρώτης δόσης κεφαλαίου",
    "program_apopl_tokon": "Συχνότητα αποπληρωμής τόκων",
    "hmer_protis_doshs_tokon": "Ημερομηνία πρώτης δόσης τόκων",
    "plithos_doseon_kefal": "Πλήθος δόσεων κεφαλαίου",
    "dikaioma_propl": "Δικαίωμα προπληρωμής",
    "hmer_poso_ofeilhs": "Ημερομηνίες / Ποσά Οφειλής",
    "poso_diatagis_plhr": "Ποσό διαταγής πληρωμής",
    "empty": "Κενό",
    "issue_date": "Ημερομηνία έκδοσης",
    "first_serving_date": "1η ημερομηνία επίδοσης",
    "first_serving_fees": "Έξοδα αποστολής",
    "second_serving_date": "2η ημερομηνία επίδοσης",
    "new_payment_order": "Νεα πληρωμή διαταγής",
    "pros_diatg_ektel_plhr": "Προσωρινή διαταγή για μη εκτέλεση της διαταγής πληρωμής",
    "telesidikh_apof": "Τελεσίδικη απόφαση"
  },
  "LoanAccountDetails": {
    "loan_account_details": "Πληροφορίες Σύμβασης",
    "repayment_plan": "Σχέδιο Αποπληρωμής",
    "balance": "Υπόλοιπο",
    "transactions": "Συναλλαγές",
    "loan_agreement": "Αριθμός σύμβασης",
    "loan_account": "Αριθμός τρέχοντος λογαριασμού",
    "original_loan_accound": "Αριθμός αρχικού λογαριασμού",
    "previous_loan_accound": "Παλαιός λογαριασμός",
    "currency": "Νόμισμα",
    "product": "Προϊόν",
    "start_date": "Ημερομηνία έναρξης",
    "original_end_date": "Αρχική ημερομηνία λήξης",
    "end_date": "Νέα ημερομηνία λήξης",
    "disbursment_date": "Ημερομηνία εκταμίευσης",
    "disbursment_amount": "Ποσό εκταμίευσης",
    "payment_type": "Τρόπος αποπληρωμής",
    "start_date_of_grace_period": "Ημερομηνία έναρξης περιόδου χάριτος",
    "end_date_of_grace_period": "Ημερομηνία λήξης περιόδου χάριτος",
    "principal_repayment_schedule": "Συχνότητα αποπληρωμής κεφαλαίου",
    "1st_principal_payment_date": "Ημερομηνία πρώτης δόσης κεφαλαίου",
    "interest_repayment_schedule": "Συχνότητα αποπληρωμής τόκων",
    "1st_interest_payment_date": "Ημερομηνία πρώτης δόσης τόκων",
    "number_of_principal_payments": "Πλήθος δόσεων κεφαλαίου",
    "date_of_principal_payment": "Ημερομηνία οφειλής",
    "amount_of_principal_payment": "Ποσό οφειλής",
    "prepayment_right": "Δικαίωμα προπληρωμής",
    "current_balance": "Τρέχον υπόλοιπο",
    "due_principal": "Ενήμερο κεφάλαιο",
    "overdue_principal": "Καθυστερημένο κεφάλαιο",
    "on_balance_sheet_interest": "Λογιστικοποιημένοι τόκοι",
    "interest_on_due_balance": "Συμβατικοί",
    "interest_on_overdue_balance": "Υπερημερίας",
    "interest_on_interest": "Ν 2601/1998",
    "off_balance_sheet_interest": "Μη λογιστικοποιημένοι τόκοι",
    "commissions_expenses": "Έξοδα",
    "conditional_write_off_amount": "Διεγραμμένο ποσό",
    "hmer_tropop": "Ημερομηνία Τροποποίησης",
    "accruals": "Δεδουλευμένοι τόκοι",
    "1st_delay_date": "Ημερομηνία πρώτης καθυστέρησης",
    "days_of_delay": "Ημέρες καθυστέρησης",
    "legal_status": "Legal Status",
    "base_rate": "Κατηγορία επιτοκίου βάσης",
    "base_rate_value": "Επιτόκιο βάσης",
    "margin": "Περιθώριο",
    "overude_rate": "Επιτόκιο υπερημερίας",
    "levy_N128/75": "Εισφορά Ν.128/1975",
    "tax_withholding": "Παρακράτηση φόρου",
    "presentation_of_certificate": "Προσκόμιση βεβαίωσης",
    "payment_date": "Ημερομηνία πληρωμής",
    "payment_amount": "Ποσό πληρωμής",
    "principal_paid": "Κεφάλαιο που εξοφλήθηκε",
    "interest_paid": "Τόκοι που εξοφλήθηκαν",
    "expenses_paid": "Έξοδα που εξοφλήθηκαν",
    "payment_components": "Components οφειλής",
    "next_installment_date": "Ημερομηνία επόμενης δόσης",
    "next_installment_principal_due": "Κεφάλαιο επόμενης δόσης",
    "next_installment_interest_due": "Τόκος επόμενης δόσης",
    "status": "Status",
    "hmer_proskom": "Ημερομηνία προσκόμισης",
    "date_of_current_balance": "Ημερομηνία τρέχοντος υπολοίπου",
    "loan_agreement_id": "Αριθμός Σύμβασης",
    "ar_trexontos_logariasmou": "Αριθμός τρέχοντος λογαριασμού",
    "ar_arxikou_logariasmou": "Αριθμός αρχικού λογαριασμού",
    "palaios_logariasmos": "Παλαιός λογαριασμός",
    "hmer_enarkshs": "Ημερομηνία έναρξης",
    "arxikh_hmer_lukshs": "Αρχική ημερομηνία λήξης",
    "nea_hmer_lukshs": "Νέα ημερομηνία λήξης",
    "hmer_ektam": "Ημερομηνία εκταμίευσης",
    "poso_ektam": "Ποσό εκταμίευσης",
    "trexon_upol": "Τρέχον υπόλοιπο",
    "enhm_kefal": "Ενήμερο Κεφάλαιο",
    "kathister_kefal": "Καθυστερημένο Κεφάλαιο",
    "logistikop_tokoi": "Λογιστικοποιημένοι τόκοι",
    "sumbatikoi_log_tokoi": "Συμβατικοί",
    "yperhmerias_log_tokoi": "Υπερημερίας",
    "n2601_1998_log_tokoi": "Ν 2601/1998",
    "mh_logistikop_tokoi": "Μη λογιστικοποιημένοι τόκοι",
    "eksoda": "Έξοδα",
    "diegram_poso": "Διεγραμμένο ποσό",
    "dedoul_tokoi": "Δεδουλευμένοι τόκοι",
    "hmer_prwths_kathister": "Ημερομηνία πρώτης καθυστέρησης",
    "hmeres_kathister": "Ημέρες καθυστέρησης",
    "kathgor_epitok_bashs": "Κατηγορία επιτοκίου βάσης",
    "epitok_bashs": "Επιτόκιο βάσης",
    "perithorio": "Περιθώριο",
    "epitok_uperhm": "Επιτόκιο υπερημερίας",
    "eisfora_n128_1975": "Εισφορά Ν.128/1975",
    "parakra_forou": "Παρακράτηση φόρου",
    "proskom_bebeos": "Προσκόμιση βεβαίωσης",
    "logistikopoihmenoi": "Λογιστικοποιημένοι τόκοι",
    "mhlogistikopoihmenoi": "Μη Λογιστικοποιημένοι τόκοι",
    "pistwseis": "Πιστώσεις",
    "xrewseis": "Χρεώσεις",
    "doseis": "Δόσεις",
    "tokoi": "Ημ/νίες Τοκισμού",
    "exoda": "Έξοδα",
    "synoliko_poso": "Συνολικό Ποσό",
    "loan_capital_repayment_type": "Loan Capital Repayment Type",
    "loan_capital_repayment_frequency": "Loan Capital Repayment Frequency",
    "loan_capital_repayment_epeteiako": "Loan Capital Repayment Epeteiako",
    "loan_interest_repayment_frequency": "Loan Interest Repayment Frequency",
    "loan_interest_repayment_epeteiako": "Loan Interest Repayment Epeteiako",
    "loan_interest_change_frequency": "Interest Change Frequency",
    "loan_interest_change_epeteiako": "Interest Change Anniversary",
    "hmer_enarxis_xaritos": "Ημερομηνία έναρξης περ. χάριτος",
    "hmer_lixis_xaritos": "Ημερομηνία λήξης περ. χάριτος",
    "hmer_1is_dosis_tokon": "Ημερομηνία πρώτης δόσης τόκων",
    "kathisterimeno": "Καθυστερημένο εξαρχής",
    "kathisterimenoKEF": "Καθυστερημένο αρχικό κεφάλαιο",
    "kathisterimenoTOK": "Καθυστερημένοι αρχικοί τόκοι",
    "kathisterimenodate": "Καθυστερημένο αρχική ημερομηνία",
    "kathisterimeno_enim_arx": "Ενήμερο κεφάλαιο σε καθυστερημένο",
    "tokoitokwn": "Αρχικοί τόκοι τόκων καθυστερημένου",
    "arnitika_epitokia": "Αρνητικά επιτόκια",
    "pedia_upologismou": "Στοιχεία δανείου - Εκτοκισμός"
  },
  "Monitoring": {
    "id": "Εσωτερικό ID Βάσης Δεδομένων",
    "short_desc": "Συνοπτική περιγραφή",
    "due_date": "Καταληκτική ημ/νία",
    "description": "Αναλυτική περιγραφή",
    "actions_comments_clarifications": "Ενέργειες/ Παρατηρήσεις/ Διευκρινίσεις",
    "alarm_date": "Ημ/νία υπενθύμισης",
    "end_date": "Ημ/νία ολοκλήρωσης",
    "mos_officer": "MOS Officer",
    "account_manager": "Account Manager",
    "obligations": "Υποχρεώσεις",
    "recruit_final_stat": "Απαιτήσεις οικονομικών καταστάσεων",
    "comments": "Παρατηρήσεις",
    "account_manager_financials": "Account Manager",
    "insurance_company": "Ασφαλιστική εταιρεία",
    "insurance_policy": "Ασφαλιστήριο συμβόλαιο",
    "start_date": "Ημ/νία έναρξης",
    "end_date_as": "Καταληκτική ημ/νία",
    "alarm_date_as": "Ημ/νία υπενθύμισης",
    "object_of_insurance": "Αντικείμενο ασφάλισης",
    "insurance_risk": "Ασφαλιστικός κίνδυνος",
    "insured_capital": "Ασφαλιστική αξία",
    "cession_of_insurance_policy": "Νομότυπη εκχώρηση",
    "actions_comments_clarifications_as": "Ενέργειες/ Παρατηρήσεις/ Διευκρινίσεις",
    "type_of_agency_fee": "Είδος προμήθειας",
    "agency_fee_amount": "Ποσό προμήθειας",
    "agency_fee_percentage_first": "Ποσοστό προμήθειας",
    "agency_fee_percentage_second": "Βάση προμήθειας",
    "fee_payment_freq": "Συχνότητα πληρωμής προμήθειας",
    "due_date_p": "Καταληκτική ημ/νία",
    "alarm_date_p": "Ημ/νία υπενθύμισης",
    "payment_date": "Ημ/νία είσπραξης",
    "comments_p": "Παρατηρήσεις",
    "pending_issues": "Pending Issues",
    "financials": "Financials Ενήμερων και ρυθμισμένων",
    "asfalistiria": "Ασφαλιστήρια Συμβόλαια",
    "promitheies": "Προμήθειες",
    "monitoring_type": "Monitoring type",
    "loan_agreement_id": "Αριθμός Σύμβασης"
  },
  "RealEstateDetails": {
    "id": "Εσωτερικό ID Βάσης Δεδομένων",
    "information": "Πληροφορίες ακινήτου",
    "apartment_information": "Πληροφορίες Διαμερίσματος",
    "arithmos_akinitou_diax": "Αριθμός ακινήτου διαχειριστή",
    "eidos": "Είδος ακινήτου",
    "address": "Οδός",
    "address_number": "Αριθμός Οδού",
    "region": "Περιοχή",
    "city": "Πόλη",
    "district": "Νομός",
    "postalcode": "ΤΚ",
    "country": "Χώρα",
    "google_map_link": "Google map link",
    "description": "Περιγραφή",
    "constr_date": "Ημερομηνία κατασκευής",
    "eidos_idioktisias": "Είδος ιδιοκτησίας",
    "percentage_property": "Ποσοστό ιδιοκτησίας",
    "antikeim_aksia_akin_rev": "Αντικειμενική αξία ακινήτου (REV)",
    "ektim_aksias_akin": "Εκτίμηση αξίας ακινήτου",
    "eidos_ektim": "Είδος εκτίμησης",
    "hmer_ektim": "Ημερομηνία εκτίμησης",
    "ektimiths": "Εκτιμητής",
    "dikaiouxos": "Δικαιούχος",
    "kthmatolog_graf": "Κτηματολογικό γραφείο",
    "kaek": "KAEK",
    "ypothikof": "Υποθηκοφυλάκειο",
    "tomos": "Τόμος",
    "fullo": "Φύλλο",
    "number": "Αριθμός",
    "poleod_parabaseis": "Πολεοδομικές παραβάσεις",
    "ektim_kostos_taktop": "Εκτιμώμενο κόστος τακτοποίησης",
    "tm_oikopedou": "ΤΜ οικοπέδου",
    "tm_ktismatos": "ΤΜ κτίσματος",
    "tm_apothikhs": "ΤΜ Αποθήκης",
    "tm_thesis_stathmeushs": "ΤΜ Θέσης Στάθμευσης",
    "orofoi": "Σε πόσους όροφους εκτείνεται",
    "type_of_prenotation": "Είδος προσημείωσης",
    "construction_status": "Στάδιο κατασκευής",
    "liquidation_value": "Liquidation Value",
    "approve_status": "Κατάσταση Έγκρισης",
    "debtor": "Debtor CID",
    "type_of_property": "Είδος ακινήτου"
  },
  "re_ownershiptype": {
    "full_ownership": "Πλήρης",
    "bare_ownership": "Ψιλή",
    "usufruct": "Επικαρπία",
    "right_of_residence_for_life": "Δικαίωμα ισόβιας οίκησης",
    "air_right": "Δικαίωμα Υψούν"
  },
  "re_typeofproperty": {
    "field": "Αγρός",
    "commercial": "Εμπορικό ακίνητο",
    "residential": "Aστικό ακίνητο",
    "office": "Γραφείο",
    "landplot": "Οικόπεδο",
    "industrial": "Βιομηχανική Περιοχή",
    "hotel": "Ξενοδοχείο",
    "null": " "
  },
  "real_estate_collateral": {
    "loan_agreement_number": "Αριθμός σύμβασης",
    "collateral_number": "Αριθμός καλύμματος",
    "original_collateral_nbr": "Αρχικός Αριθμός καλύμματος",
    "real_estate_nbr": "Αριθμός ακινήτου",
    "type_of_prenotation": "Είδος προσημείωσης",
    "lien": "Σειρά προσημείωσης",
    "prenotation_amount": "Ποσό προσημείωσης",
    "total_prenotation_amount": "Συνολικό ποσό προσημείωσης",
    "perc_of_participation": "Ποσοστό συμμετοχής",
    "prenotation_summary": "Περίληψη εγγραφής προσημείωσης",
    "transfer_of_prenot_to_cadastral": "Μετεγγραφή σε Υποθηκοφυλακείο / Κτηματολογικό Γραφείο",
    "volume": "Τόμος",
    "page": "Φύλλο",
    "number": "Αριθμός",
    "insurer": "Ασφαλιστικός φορέας",
    "insurance_policy_nbr": "Αριθμός ασφαλιστηρίου",
    "cession_of_insurance_policy": "Εκχώρηση ασφαλιστηρίου",
    "legal_status": "Legal Status",
    "comments": "Σχόλια",
    "lhksh_asfalistiriou": "Λήξη Ασφαλιστηρίου",
    "loan_agreement_id": "Αριθμός Σύμβασης",
    "ar_kalummatos": "Αριθμός καλύμματος",
    "arxikos_arith_kalummatos": "Αρχικός Αριθμός καλύμματος",
    "real_estate_id": "Αριθμός ακινήτου",
    "eidos_prosim": "Είδος προσημείωσης",
    "seira_prosim": "Σειρά προσημείωσης",
    "poso_prosim": "Ποσό προσημείωσης",
    "sunoliko_poso_prosim": "Συνολικό ποσό προσημείωσης",
    "pososto_summetoxhs": "Ποσοστό συμμετοχής",
    "perilhps_eggrafhs_prosim": "Περίληψη εγγραφής προσημείωσης",
    "meteggrafh_se_ypothikof_ktimat_graf": "Μετεγγραφή σε Υποθηκοφυλακείο / Κτηματολογικό Γραφείο",
    "tomos": "Τόμος",
    "fullo": "Φύλλο",
    "asfal_foreas": "Ασφαλιστικός φορέας",
    "ar_asfal": "Αριθμός ασφαλιστηρίου",
    "ekxor_asfalist": "Εκχώρηση ασφαλιστηρίου",
    "pistop_ypoth_ktimatol": "Πιστοποιητικά Υποθηκοφυλακείου ή Κτηματολογικού Γραφείου",
    "kalimaNRE": "Κάλυμμα NRE",
    "synol_poso_sumbashs": "Ποσό σύμβασης"
  },
  "TypeofPrenotation": {
    "sunenetikh": "ΣΥΝΑΙΝΕΤΙΚΗ ΠΡΟΣΗΜΕΙΩΣΗ",
    "anagkastikh": "ΑΝΑΓΚΑΣΤΙΚΗ ΠΡΟΣΗΜΕΙΩΣΗ",
    "sunenetikh_ypothiki": "ΣΥΝΑΙΝΕΤΙΚΗ ΥΠΟΘΗΚΗ",
    "anagkastikh_ypothiki": "ΑΝΑΓΚΑΣΤΙΚΗ ΥΠΟΘΗΚΗ",
    "null": " "
  },
  "GuaranteesDetails": {
    "loan_agreement_number": "Αριθμός σύμβασης",
    "debtor_cid": "Debtor CID",
    "full_agreement_guarantee": "Εγγύηση του συνόλου της συμβάσεως",
    "guaranteed_amount": "Ποσό εγγύησης",
    "comments": "Σχόλια",
    "loan_agreement_id": "Αριθμός Σύμβασης",
    "egguhsh_sunolou_sumbas": "Εγγύηση του συνόλου της συμβάσεως",
    "poso_egg": "Ποσό εγγύησης",
    "tautopoihsh": "Ταυτοποίηση",
    "epipleonnomeggrafa": "Επιπλέον Έγγραφα"
  },
  "GreekStateGuarantees": {
    "loan_agreement": "Αριθμός Σύμβασης",
    "kya_of_greek_state": "ΚΥΑ παροχής ΕΕΔ",
    "guaranteed_amount": "Ποσό Εγγυήσεως",
    "contractual_document": "Συμβατικό έγγραφο",
    "contract_servicing": "Επίδοση σύμβασης σε Δημόσιους Φορείς",
    "monitoring_of_debt_25th": "Παρακολούθηση των οφειλών και ενημέρωσης της 25ης Δ/νσης",
    "delivery_of_comm_25th": "Απόδοση προμηθειών στην 25η Δ/νση",
    "claim_limitation_period": "Προθεσμία παραγραφής απαίτησης",
    "forfeiture_actions": "Ενέργειες κατάπτωσης",
    "kua_paroxhs_eed": "ΚΥΑ παροχής ΕΕΔ",
    "poso_eggyhseos": "Ποσό Εγγυήσεως",
    "sumbatiko_eggrafo": "Συμβατικό έγγραφο",
    "epidosh_sumbas_se_dhmos_foreis": "Επίδοση σύμβασης σε Δημόσιους Φορείς",
    "parakolouth_ofilon": "Παρακολούθηση των οφειλών και ενημέρωσης της 25ης Δ/νσης",
    "apodosh_prom": "Απόδοση προμηθειών στην 25η Δ/νση",
    "prothesmia_paragraf_apet": "Προθεσμία παραγραφής απαίτησης",
    "energies_katapt": "Ενέργειες κατάπτωσης",
    "loan_agreement_id": "Αριθμός Σύμβασης"
  },
  "AuctionsDetails": {
    "auction_program_nbr": "Αρ. προγράμματος Πλειστηριασμού",
    "auction_date": "Ημερομηνία πλειστηριασμού",
    "declaration_of_claim": "Αναγγελία απαίτησης",
    "claimant": "Επισπεύδων",
    "auction_initial_price": "Τιμή πλειστηριασμού",
    "auction_status": "Κατάσταση",
    "auction_price": "Τιμή κατακύρωσης",
    "bidder": "Πλειοδότης",
    "sufficient_auction_amount": "Επαρκές πλειστηρίασμα",
    "submission_of_comments": "Υποβολή παρατηρήσεων",
    "compiling_of_ranking_table": "Σύνταξη Πίνακα Κατάταξης",
    "date_of_suspension": "Ημερομηνία ανακοπής",
    "amount_attributed_to_client": "Ποσό που αναλογεί σε Client",
    "collection_by_Letter_of_Guarantee": "Είσπραξη με εγγυητική (LF)",
    "issuing_bank": "Εκδότρια τράπεζα (Issuing Bank)",
    "issue_date": "Ημερομηνία έκδοσης",
    "lg_amount": "LG amount",
    "lg_commission": "LG comission",
    "beneficiary": "Δικαιούχος (beneficiary)",
    "maturity": "Προθεσμία λήξης (Maturity)",
    "attorney_fees": "Έξοδα δικηγόρου",
    "certified_appraisers_fee": "Έξοδα πιστοποιημένου εκτιμητή",
    "date_of_previous_auction": "Ημερομηνία προηγούμενου πλειστηριασμού",
    "price_of_previous_auction": "Τιμή προηγούμενου πλειστηριασμού",
    "status_of_previous_auction": "Κατάσταση προηγούμενου πλειστηριασμού",
    "third_part_id": "Third Party ID",
    "ar_prog_pl": "Αρ. προγράμματος Πλειστηριασμού",
    "hmer_pl": "Ημερομηνία πλειστηριασμού",
    "anag_apetisis": "Αναγγελία απαίτησης",
    "epispeudon": "Επισπεύδων",
    "timh_pl": "Τιμή πλειστηριασμού",
    "katastash": "Κατάσταση",
    "timh_katak": "Τιμή κατακύρωσης",
    "pleiodotis": "Πλειοδότης",
    "eparkes_pl": "Επαρκές πλειστηρίασμα",
    "yp_parat": "Υποβολή παρατηρήσεων",
    "suntak_pinaka_katat": "Σύνταξη Πίνακα Κατάταξης",
    "hmer_anakophs": "Ημερομηνία ανακοπής",
    "poso_ana_client": "Ποσό που αναλογεί σε Client",
    "eisp_me_egg_lf": "Είσπραξη με εγγυητική (LF)",
    "ekd_trapeza_ib": "Εκδότρια τράπεζα (Issuing Bank)",
    "hmer_ekd": "Ημερομηνία έκδοσης",
    "dikaiouxos_text": "Δικαιούχος (beneficiary)",
    "prothesmia_liksis": "Προθεσμία λήξης (Maturity)",
    "eksoda_dikhgorou": "Έξοδα δικηγόρου",
    "eksoda_pistop_ektim": "Έξοδα πιστοποιημένου εκτιμητή",
    "hmer_prohg_pl": "Ημερομηνία προηγούμενου πλειστηριασμού",
    "timh_prohg_pl": "Τιμή προηγούμενου πλειστηριασμού",
    "katastash_prohg_pl": "Κατάσταση προηγούμενου πλειστηριασμού",
    "lg_comission": "LG comission",
    "debtor_id": "CID Οφειλέτη",
    "real_estate_id": "Αριθμός ακινήτου διαχειριστή",
    "ar_kalummatos": "Αριθμός καλύμματος",
    "re_collateral_id": "Αριθμός καλύμματος"
  },
  "AuctionsStatusList": {
    "successful": "Επιτυχής",
    "fruitless": "Άκαρπος",
    "suspended": "Σε αναστολή",
    "frustrated": "Ματαίωση",
    "cancelled": "Ακύρωση"
  },
  "NRECollateralsDetails": {
    "loan_agreement": "Αριθμός σύμβασης",
    "collateral_number": "Αριθμός καλύμματος",
    "id": "Id",
    "ar_kalimatos": "Αριθμός καλύμματος",
    "em_ekd_etair_metox": "Εκδότρια εταιρεία μετοχών",
    "em_katox_metox": "Κάτοχος μετοχών",
    "em_sumbash_enexurou": "Σύμβαση ενεχύρου",
    "em_plithos_metoxon": "Πλήθος μετοχών",
    "em_onomastikh_timh": "Ονομαστική τιμή",
    "em_dhlosh_protheshs_metabib": "Δήλωση πρόθεσης μεταβίβασης ή σύστασης βάρους",
    "em_hmer_epidoshs": "Ημερομηνία επίδοσης",
    "mem_ekdo_eteria_metox": "Εκδότρια εταιρεία μετοχών",
    "mem_katoxos_metox": "Κάτοχος μετοχών",
    "mem_sumbash_enexurou": "Σύμβαση ενεχύρου",
    "mem_plithos_metoxon": "Πλήθος μετοχών",
    "mem_onom_aksia_metox": "Ονομαστική αξία μετοχής",
    "mem_sunol_onomas_aksia_metox": "Συνολική ονομαστική αξία μετοχών",
    "mem_epishm_metox_biblio_metox": "Επισημειώσεις μετοχών και βιβλίου μετόχων",
    "mem_hmer_epidoshs": "Ημερομηνία επίδοσης",
    "k_dikaiouxos_katath": "Δικαιούχος κατάθεσης",
    "k_sumbash_enex": "Σύμβαση ενεχύρου",
    "k_sumbatika_eggrafa": "Συμβατικά έγγραφα",
    "k_trapeza": "Τράπεζα",
    "k_iban": "IBAN",
    "k_ypol": "Υπόλοιπο",
    "k_hmer_ypol": "Ημερομηνία υπολοίπου",
    "ea_sumbash_enex": "Σύμβαση ενεχύρου",
    "ea_dikaiol_enexur_apait": "Δικαιολογητικά ενεχυριασμένης απαίτησης",
    "ea_gnost_sust_enex": "Γνωστοποίηση σύστασης ενεχύρου επί άυλων κινητών αξιών",
    "ea_hmer_epidoshs": "Ημερομηνία επίδοσης",
    "tp_sumbash_enex": "Σύμβαση ενεχύρου",
    "tp_hmer_epidoshs": "Ημερομηνία επίδοσης",
    "tp_sxetikh_praksh": "Σχετική πράξη",
    "tp_ypogr_titl_paegae": "Υπογραφές τίτλων ΠΑΕΓΑΕ",
    "tp_sumbatika_eggrafa": "Συμβατικά έγγραφα",
    "tp_sumbas_enex_apait_": "Σύμβαση ενεχύρου απαίτησης εκ των ασφαλιστηρίων",
    "e_sumbatika_eggrafa": "Συμβατικά έγγραφα",
    "e_pinakio_epitagon": "Πινάκιο Επιταγών",
    "e_sxetika_dikaiol": "Σχετικά δικαιολογητικά",
    "e_log_takseos": "Λογαριασμός τάξεως",
    "e_xor_log": "Χορηγητικός λογαριασμός που θα συνδεθεί ο τάξεως",
    "e_log_xre_eks_prom": "Λογαριασμός χρέωσης εξόδων και προμηθειών",
    "e_egkekr_orio_diasp_ana_ekdoth": "Εγκεκριμένο όριο διασποράς ανά εκδότη",
    "e_nomotipo_ekdos_epitaghs": "Νομότυπο έκδοσης επιταγής",
    "e_sumf_poson_epitagis": "Συμφωνία ποσών επιταγής",
    "e_diaspora_ana_ekdoth": "Διασπορά ανά εκδότη",
    "e_ypar_kartel_xreost_ypol": "Ύπαρξη καρτέλας και χρεωστικού υπολοίπου",
    "e_hmer_ekd_epitaghs": "Ημερομηνία έκδοσης επιταγής",
    "e_end_sfragishs_epit": "Ένδειξη σφράγισης επιταγής",
    "e_loipa_stoixeia_epitaghs": "Λοιπά στοιχεία επιταγής",
    "s_eborikotita": "Εμπορικότητα",
    "s_pinakio_sunal": "Πινάκιο συναλλαγματικών",
    "s_sxetika_dikaol": "Σχετικά δικαιολογητικά",
    "s_ypo_ait_pa_meta_sunal": "Υπογεγραμμένη αίτηση παραλαβής μεταχρονολογημένων / συναλλαγματικών επιταγών",
    "s_log_takseos": "Λογαριασμός τάξεως",
    "s_xor_log_sund_takseos": "Χορηγητικός λογαριασμός που θα συνδεθεί ο τάξεως",
    "s_log_xre_eks_prom": "Λογαριασμός χρέωσης εξόδων και προμηθειών",
    "s_egkek_orio_diasporas_ekd": "Εγκεκριμένο όριο διασποράς ανά εκδότη",
    "s_nom_ekd_epit": "Νομότυπο έκδοσης επιταγής",
    "s_sumf_poson_epit": "Συμφωνία ποσών επιταγής",
    "s_hmer_ekd_epit": "Ημερομηνία έκδοσης επιταγής",
    "s_ypar_kart_xreost_ypol": "Ύπαρξη καρτέλας και χρεωστικού υπολοίπου",
    "s_diasp_ana_ekd": "Διασπορά ανά εκδότη",
    "s_ypar_dusmenon_stoix": "Ύπαρξη δυσμενων στοιχείων",
    "s_ypog_sumbash": "Υπογεγραμμένη σύμβαση",
    "kel_eidos": "Είδος",
    "kel_idioktisia": "Ιδιοκτησία",
    "kel_poso": "Ποσό",
    "loan_agreement_id": "Αριθμός Σύμβασης",
    "type": "Είδος Collateral",
    "listed_shares": {
      "issuing_stock_company": "Εκδότρια εταιρεία μετοχών",
      "stockholder": "Κάτοχος μετοχών",
      "pledge_agreement": "Σύμβαση ενεχύρου",
      "nbr_of_shares": "Πλήθος μετοχών",
      "nominal_value": "Ονομαστική τιμή",
      "declaration_of_intent": "Δήλωση πρόθεσης μεταβίβασης ή σύστασης βάρους",
      "servicing_date": "Ημερομηνία επίδοσης"
    },
    "unlisted_shares": {
      "issuing_stock_company": "Εκδότρια εταιρεία μετοχών",
      "stockholder": "Κάτοχος μετοχών",
      "pledge_agreement": "Σύμβαση ενεχύρου",
      "nbr_of_shares": "Πλήθος μετοχών",
      "nominal_value": "Ονομαστική αξία μετοχής",
      "total_nominal_value": "Συνολική ονομαστική αξία μετοχών",
      "share_and_shareholder_book_annotations": "Επισημειώσεις μετοχών και βιβλίου μετόχων",
      "servicing_date": "Ημερομηνία επίδοσης"
    },
    "deposits": {
      "beneficiary": "Δικαιούχος κατάθεσης",
      "pledge_agreement": "Σύμβαση ενεχύρου",
      "contractual_document_agreement": "Συμβατικά έγγραφα",
      "bank": "Τράπεζα",
      "iban": "IBAN",
      "balance": "Υπόλοιπο",
      "date_of_balance": "Ημερομηνία υπολοίπου"
    },
    "pledged_claims": {
      "pledged_agreement": "Σύμβαση ενεχύρου",
      "evidence_of_the_pledged_claim": "Δικαιολογητικά ενεχυριασμένης απαίτησης",
      "notice_of_pledge": "Γνωστοποίηση σύστασης ενεχύρου επί άυλων κινητών αξιών",
      "servicing_date": "Ημερομηνία επίδοσης"
    },
    "Warehouse_receipt_(PAEGAE)": {
      "pledge_agreement": "Σύμβαση ενεχύρου",
      "servicing_date": "Ημερομηνία επίδοσης",
      "relevant_act": "Σχετική πράξη",
      "sign_of_titles": "Υπογραφές τίτλων ΠΑΕΓΑΕ",
      "contractual_document": "Συμβατικά έγγραφα",
      "pledge_agreement_of_insurance_policy": "Σύμβαση ενεχύρου απαίτησης εκ των ασφαλιστηρίων"
    },
    "checks": {
      "contractual_document": "Συμβατικά έγγραφα",
      "checks_table": "Πινάκιο Επιταγών",
      "other_documents": "Σχετικά δικαιολογητικά",
      "off_balance_account": "Λογαριασμός τάξεως",
      "loan_account": "Χορηγητικός λογαριασμός που θα συνδεθεί ο τάξεως",
      "commission_account": "Λογαριασμός χρέωσης εξόδων και προμηθειών",
      "approved_spread_limit_per_issuer": "Εγκεκριμένο όριο διασποράς ανά εκδότη",
      "legal_issuing_of_check": "Νομότυπο έκδοσης επιταγής",
      "amount_agreement": "Συμφωνία ποσών επιταγής",
      "spread_per_issuer": "Διασπορά ανά εκδότη",
      "existence_of_account_and_debit_balance": "Ύπαρξη καρτέλας και χρεωστικού υπολοίπου",
      "issuing_date": "Ημερομηνία έκδοσης επιταγής",
      "stamped_check": "Ένδειξη σφράγισης επιταγής",
      "check_details": "Λοιπά στοιχεία επιταγής"
    },
    "bill_of_exchange": {
      "commerciality": "Εμπορικότητα",
      "bill_of_exchange_table": "Πινάκιο συναλλαγματικών",
      "related_documents": "Σχετικά δικαιολογητικά",
      "signed_request_for_the_receipt": "Υπογεγραμμένη αίτηση παραλαβής μεταχρονολογημένων / συναλλαγματικών επιταγών",
      "off_balance_account": "Λογαριασμός τάξεως",
      "loan_account": "Χορηγητικός λογαριασμός που θα συνδεθεί ο τάξεως",
      "commission_account": "Λογαριασμός χρέωσης εξόδων και προμηθειών",
      "spread_limit_per_issuer": "Εγκεκριμένο όριο διασποράς ανά εκδότη",
      "legal_issuing_of_check": "Νομότυπο έκδοσης επιταγής",
      "amount_agreement": "Συμφωνία ποσών επιταγής",
      "issue_date": "Ημερομηνία έκδοσης επιταγής",
      "issue_date_prepei": "Ημερομηνία έκδοσης επιταγής (Πρέπει Να'ναι Τουλάχιστον Μεταγενέστερη Κατά 20 Μέρες Από Ημ/νια Παραλαβής)",
      "existence_of_account_and_ebit_balance": "Ύπαρξη καρτέλας και χρεωστικού υπολοίπου",
      "spread_per_issuer": "Διασπορά ανά εκδότη",
      "default_credit_behavior": "Ύπαρξη δυσμενων στοιχείων",
      "signed_contract": "Υπογεγραμμένη σύμβαση"
    },
    "movable_collaterals": {
      "type": "Είδος",
      "property": "Ιδιοκτησία",
      "amount": "Ποσό"
    }
  },
  "nre_collaterals_category": {
    "listed_shares": "Εισηγμένες Μετοχές",
    "unlisted_shares": "Μη εισηγμένες μετοχές",
    "deposits": "Καταθέσεις",
    "pledged_claims": "Εκχώρηση απαιτήσεων",
    "warehouse_receipt": "Τίτλοι ΠΑΕΓΑΕ",
    "checks": "Επιταγές",
    "bill_of_exchange": "Συναλλαγματικές",
    "movable_collaterals": "Κινητές εξασφαλίσεις",
    "collateral_type": "Είδος Collateral"
  },
  "ListedSharesView": {
    "issuing_stock_company": "Εκδότρια εταιρεία μετοχών",
    "stockholder": "Κάτοχος μετοχών",
    "nominal_value": "Ονομαστική τιμή",
    "servicing_date": "Ημερομηνία επίδοσης"
  },
  "UnlistedSharesView": {
    "issuing_stock_company": "Εκδότρια εταιρεία μετοχών",
    "stockholder": "Κάτοχος μετοχών",
    "total_nominal_value": "Συνολική ονομαστική αξία μετοχών",
    "servicing_date": "Ημερομηνία επίδοσης"
  },
  "DepositsView": {
    "beneficiary": "Δικαιούχος κατάθεσης",
    "pledge_agreement": "Σύμβαση ενεχύρου",
    "bank": "Τράπεζα",
    "date_of_balance": "Ημερομηνία υπολοίπου"
  },
  "PledgedClaimsView": {
    "pledge_agreement": "Σύμβαση ενεχύρου",
    "evidence_of_the_pledged_claim": "Δικαιολογητικά ενεχυριασμένης απαίτησης",
    "servicing_date": "Ημερομηνία επίδοσης"
  },
  "WarehouseReceiptView": {
    "pledge_agreement": "Σύμβαση ενεχύρου",
    "servicing_date": "Ημερομηνία επίδοσης",
    "sign_of_titles": "Υπογραφές τίτλων ΠΑΕΓΑΕ",
    "contractual_document": "Συμβατικά έγγραφα"
  },
  "ChecksView": {
    "contractual_document": "Συμβατικά έγγραφα",
    "checks_table": "Πινάκιο Επιταγών",
    "off_balance_account": "Λογαριασμός τάξεως",
    "issue_date": "Ημερομηνία έκδοσης επιταγής"
  },
  "BillOfExchangeView": {
    "commerciality": "Εμπορικότητα",
    "bill_of_exchange_table": "Πινάκιο συναλλαγματικών",
    "off_balance_account": "Λογαριασμός τάξεως",
    "issue_date": "Ημερομηνία έκδοσης επιταγής"
  },
  "MovableCollateralsView": {
    "type": "Είδος",
    "property": "Ιδιοκτησία",
    "amount": "Ποσό"
  },
  "SummaryChecks": {
    "basic_data": "Βασικά Στοιχεία",
    "cid": "Κωδικός Πελάτη",
    "summary_check_date": "Ημερομηνία Summary Check",
    "basic_check_note": "Στοιχεία Χρηματοδότησης",
    "details_of_contract": "Στοιχεία Σύμβασης",
    "type_of_financing": "Είδος Χρηματοδότησης",
    "guarantees": "Εγγυητής/ες",
    "orth_plirot_ypografon": "Ορθότητα - Πληρότητα Σύμβασης – Υπογραφές",
    "payment_representative": "Πληρεξούσιος Καταβολών / Έκπρόσωπος των Ομολογιούχων",
    "replacement_mechanism": "Μηχανισμός αντικατάστασης εκπροσώπου των Ομολογιούχων",
    "summet_trapezes": "Ομολογιούχοι",
    "contact_information": "Στοιχεία Επικοινωνίας",
    "bank_representative": "Έκπρ. Τράπεζας",
    "creditor_signatures": "Υπογραφές Πιστούχου",
    "signatures_of_guarantors": "Υπογραφές Εγγυητών",
    "skopos_ekdosis": "Σκοπός Έκδοσης",
    "extension_of_duration": "Παράταση Διαρκείας",
    "account_details": "Στοιχεία Λογαριασμών",
    "product_type": "Είδος Δανείου",
    "loan_account_number": "Αριθμός Δανειακού Λογ/μού",
    "repayment": "Αποπληρωμή (Τρέχον Πλάνο)",
    "interests": "Τόκοι",
    "capital": "Κεφάλαιο",
    "total_amount": "Συνολικό Ποσό",
    "collaterals_control": "Εξασφαλίσεις - Έλεγχος",
    "movable_collaterals": "Κινητές εξασφαλίσεις",
    "property_type": "Είδος Ακινήτου",
    "address": "Διεύθυνση",
    "individual_guarantees": "Ατομικές Εγγυήσεις",
    "legal_entities_guarantees": "Εταιρικές Εγγυήσεις",
    "greek_state_guarantees": "Εγγυήσεις Δημοσίου",
    "shares": "Μετοχές",
    "portfolio": "Portfolio",
    "name": "Ονοματεπώνυμο/Επωνυμία Πιστούχου",
    "afm": "ΑΦΜ",
    "gemi": "ΓΕΜΗ",
    "klados": "Κλάδος",
    "lixi_nom": "Λήξη Νομιμοποίησης",
    "street": "Οδός",
    "street_number": "Αριθμός",
    "region": "Περιοχή",
    "city": "Πόλη",
    "prefecture": "Νομός",
    "zip_code": "ΤΚ",
    "country": "Χώρα",
    "phone": "Τηλέφωνο",
    "postal_street": "Οδός Αλληλογραφίας",
    "postal_nbr": "Αριθμός Αλληλογραφίας",
    "postal_region": "Περιοχή Αλληλογραφίας",
    "postal_city": "Πόλη Αλληλογραφίας",
    "postal_prefecture": "Νομός Αλληλογραφίας",
    "postal_zip_code": "ΤΚ Αλληλογραφίας",
    "postal_country": "Χώρα Αλληλογραφίας",
    "email": "Email",
    "account_manager": "Account Manager (FL)",
    "loan_agreement": "Αριθμός Σύμβασης",
    "bank": "Ομολογιούχος",
    "pososto": "Ποσοστό Συμμετοχής",
    "contact_details": "Στοιχεία Επικοινωνίας",
    "total_amount_la": "Συνολικό ποσό σύμβασης",
    "tropopoihseis": "Τροποποιήσεις (Ημερομηνίες)",
    "paratasi_diarkeias": "Ημερομηνία Λήξης",
    "bonds": "Ομολογίες",
    "imer_kalipsis_ektam": "Ημερομηνία Κάλυψης / Εκταμίευσης",
    "ektam_poso": "Εκταμιευθέν Ποσό",
    "propliromi": "Δικαίωμα Προπληρωμής",
    "empragmates": "Εμπράγματες Εξασφαλίσεις",
    "texniki_ektimisi": "Τεχνική Εκτίμηση",
    "enoxikes": "Ενοχικές Εξασφαλίσεις",
    "ekxoriseis": "Εκχωρήσεις Απαιτήσεων",
    "uparxi_ekx_ap": "Ύπαρξη Εκχώρησης Απαιτήσεων",
    "lixi_ekx_asf": "Λήξη Εκχωρημένων Ασφαλιστηρίων",
    "ekxorisi_xreogr": "Εκχώρηση Χρεογράφων",
    "checks": "Επιταγές",
    "promitheies": "Προμήθειες",
    "idle": "Αδράνειας",
    "anadoxis": "Ανάδοχης – Οργάνωσης",
    "diaxeirisis": "Διαχείρισης",
    "tropopoihshs": "Τροποποίησης",
    "waiver": "Waiver Fee για τον Εκπρόσωπο των Ομολογιούχων",
    "waiver_gia_kathe": "Waiver Fee για κάθε Ομολογιούχο",
    "upoxr_plirof_clauses": "Υποχρεώσεις Πληροφόρησης - Οικονομικά Στοιχεία (clauses)",
    "gegonota": "Γεγονότα Καταγγελίας (clauses)",
    "limitation_period": "Χρόνος Παραγραφής (Limitation Period)",
    "ekdosi_diatagis_pliromis": "Έκδοση Διαταγής Πληρωμής Ημερ/νια και Χρονικό Περιθώριο για τροπή προσημείωσης σε Υποθήκη",
    "paratiriseis": "Παρατηρήσεις - Εκκρεμότητες",
    "elegxos_sumvasis": "Έλεγχος Σύμβασης",
    "elegxos_epidosis": "Έλεγχος Επίδοσης",
    "eispraxi_gegenimenon": "Είσπραξη Γεγενημένων Απαιτήσεων",
    "onomateponimo": "Ονοματεπώνυμο / Επωνυμία",
    "personal_corp_guar": "Προσωπική/Eταιρική Εγγύηση",
    "guar_name": "Επωνυμία / Όνομα Εγγυητή",
    "tautopoihsh": "Ταυτοποίηση",
    "epipleon_nom_egg": "Επιπλέον Νομιμοποιητικά Έγγραφα",
    "upothikes_pros": "Υποθήκες Προσημείωσης",
    "katoxos_akinitou": "Κάτοχος Ακινήτου",
    "pistopoihtika_upoth": "Πιστοποιητικά Υποθηκοφυλακείου ή Κτηματολογικού Γραφείου",
    "asfalistiria_sumv": "Ασφαλιστήρια Συμβόλαια",
    "ekxorisi_asf": "Εκχώρηση Ασφαλιστήριου",
    "lixi_asf": "Λήξη Ασφαλιστήριου",
    "ekxorisi_apait_tritou": "Εκχώρηση Απαιτήσεων Τρίτου",
    "sumvasi_enex": "Σύμβαση Ενέχυρου",
    "episimiosis_met": "Επισημειώσεις Μετοχών και Βιβλίου Μετοχών",
    "egguhsh_ell_dimosiou": "Εγγύηση Ελληνικού Δημοσίου",
    "epipleon_eggrafa": "Επιπλέον Έγγραφα",
    "null": " "
  },
  "CollateralTypes": {
    "listed_shares": "Εισηγμένες Μετοχές",
    "unlisted_shares": "Μη εισηγμένες μετοχές",
    "deposits": "Καταθέσεις",
    "pledged_claims": "Εκχώρηση απαιτήσεων",
    "warehouse_receipt": "Τίτλοι ΠΑΕΓΑΕ",
    "checks": "Επιταγές",
    "bill_of_exchange": "Συναλλαγματικές",
    "movable_collaterals": "Κινητές εξασφαλίσεις"
  },
  "PlatformSettingsView": {
    "agents": "Bondholders",
    "agents_details": "Bondholder",
    "currencies": "Νομίσματα",
    "currencies_details": "Νόμισμα",
    "loan_agreement_product": "Προϊόντα Συμβάσεων",
    "interests": "Επιτόκια",
    "kad": "Κωδικοί Αριθμοί Δραστηριότητας",
    "klados": "Κλάδοι Δραστηριότητας",
    "loan_account_product": "Προϊόντα Δανειακών Λογαριασμών",
    "short": "Συντομογραφίες",
    "short_details": "Συντομογραφία",
    "new_agent": "Νέος bondholder",
    "agent_name": "Όνομα bondholder",
    "agent_delete_message": "Είστε βέβαιοι ότι θέλετε να διαγράψετε αυτόν τον bondholder;",
    "currency_code": "Κωδικοί",
    "currency_code_details": "Κωδικός",
    "symbol": "Σύμβολα",
    "symbol_details": "Σύμβολο",
    "new_currency": "Νέο Νόμισμα",
    "currency_delete_message": "Είστε βέβαιοι ότι θέλετε να διαγράψετε αυτό το νόμισμα;",
    "summary_check_type": "Τύποι Summary Check",
    "la_product_details": "Προϊόν Σύμβασης",
    "la_summarycheck_details": "Τύπος Summary Check",
    "la_greek_trans": "Ελληνική Μετάφραση",
    "la_english_trans": "Αγγλική Μετάφραση",
    "la_new_product": "Νέο Προϊόν Σύμβασης",
    "la_delete_message": "Είστε βέβαιοι ότι θέλετε να διαγράψετε αυτό το προϊόν σύμβασης;",
    "interest_categories": "Κατηγορίες Τόκων",
    "interest_categories_details": "Κατηγορία Επιτοκίου",
    "new_interest_category": "Νέα Κατηγορία Επιτοκίου",
    "interest_rate": "Τιμή Επιτοκίου",
    "interest_value_date": "Ημερομηνία Ισχύος Επιτοκίου",
    "description": "Περιγραφές",
    "description_details": "Περιγραφή",
    "kad_details": "Αριθμός Δραστηριότητας",
    "new_kad_code": "Νέος Αριθμός Δραστηριότητας",
    "kad_delete_message": "Είστε βέβαιοι ότι θέλετε να διαγράψετε αυτόν τον αριθμό δραστηριότητας;",
    "klados_code": "Κωδικοί Αριθμοί Δραστηριότητας",
    "klados_code_details": "Κωδικός Αριθμός Δραστηριότητας",
    "new_klados_code": "Νέος Κωδικός Δραστηριότητας",
    "loan_account_product_details": "Προϊόν Δανειακού Λογαριασμού",
    "new_loan_account_product": "Νέο Προϊόν",
    "prefectures": "Νομοί",
    "prefecture_name": "Νομός",
    "new_prefecture": "Νέος Νομός",
    "prefecture_delete_message": "Είστε βέβαιοι ότι θέλετε να διαγράψετε αυτόν τον νομό;",
    "postal_codes": "Ταχυδρομικοί Κώδικες",
    "new_postalcode": "Νέος Ταχυδρομικός Κώδικας",
    "postalcode_name": "Ταχυδρομικός Κώδικας",
    "postalcode_delete_message": "Είστε βέβαιοι ότι θέλετε να διαγράψετε αυτόν τον ΤΚ;",
    "countries": "Χώρες",
    "new_country": "Νέα Χώρα",
    "country_name": "Χώρα",
    "country_delete_message": "Είστε βέβαιοι ότι θέλετε να διαγράψετε αυτή την χώρα;",
    "argies": "Αργίες",
    "new_holiday": "Νέα Αργία",
    "argia_name": "Αργία",
    "argia_delete_message": "Είστε βέβαιοι ότι θέλετε να διαγράψετε αυτή τήν αργία;",
    "short_descriptions": "Συνοπτικές Περιγραφές",
    "short_desc_delete_message": "Είστε βέβαιοι ότι θέλετε να διαγράψετε αυτή τήν συνοπτική περιγραφή;",
    "new_short_desc": "Νέα συνοπτική περιγραφή",
    "short_desc": "Συνοπτική περιγραφή",
    "ins_comps": "Ασφαλιστικές Εταιρείες",
    "inscomp_delete_message": "Είστε βέβαιοι ότι θέλετε να διαγράψετε αυτή τήν ασφαλιστική εταιρεία;",
    "ins_comp": "Ασφαλιστική εταιρεία",
    "new_ins_comp": "Νέα ασφαλιστική εταιρεία",
    "obj_of_ins": "Αντικείμενο Ασφάλισης",
    "obj_of_inss": "Αντικείμενα Ασφάλισης",
    "obj_of_ins_delete_message": "Είστε βέβαιοι ότι θέλετε να διαγράψετε αυτό το αντικείμενο ασφάλισης;",
    "new_obj_of_ins": "Νέο αντικείμενο ασφάλισης",
    "insurance_risk": "Ασφαλιστικός Κίνδυνος",
    "ins_risks": "Ασφαλιστικοι Κίνδυνοι",
    "ins_risk": "Ασφαλιστικός κίνδυνος",
    "inst_risk_delete_message": "Είστε βέβαιοι ότι θέλετε να διαγράψετε αυτόν τον ασφαλιστικό κίνδυνο;",
    "new_ins_risk": "Νέος ασφαλιστικός κίνδυνος",
    "type_of_agency_fee": "Είδος Προμήθειας",
    "config_type_of_agency_fees": "Είδη Προμήθειας",
    "type_of_agency_fees_delete_message": "Είστε βέβαιοι ότι θέλετε να διαγράψετε αυτό το είδος προμήθειας;",
    "new_type_of_agency_fee": "Νέο είδος προμήθειας",
    "fee_payment_freq": "Συχνότητα πληρωμής προμήθειας",
    "config_fee_payment_freqs": "Συχνότητα πληρωμής προμήθειας",
    "fee_delete_message": "Είστε βέβαιοι ότι θέλετε να διαγράψετε αυτή την συχνότητα πληρωμής;",
    "new_fee": "Νέα συχνότητα πληρωμής",
    "groups": "Όμιλοι",
    "group_id": "Κωδικός Ομίλου",
    "group_name": "Επωνυμία Ομίλου",
    "new_group": "Νέος όμιλος",
    "group_details": "'Ομιλος",
    "group_delete_message": "Είστε βέβαιοι ότι θέλετε να διαγράψετε αυτόν τον όμιλο;",
    "min_tk": "Ελάχιστο Postalcode",
    "max_tk": "Μέγιστο Postalcode",
    "new_printer": "Νέος Printer",
    "printer_details": "Printer",
    "printer_delete_message": "Είστε βέβαιοι ότι θέλετε να διαγράψετε αυτόν τον printer;",
    "automato_epitokio": "Αυτόματο επιτόκιο",
    "calculateArgies": "Υπολογισμός Αργιών",
    "allhloxreo": "Αλληλόχρεο"
  },
  "DigitizedGelatin": {
    "Conventional": "Συμβατικά (01)",
    "Legalization": "Νομιμοποιητικά (02)",
    "Securities": "Αξιόγραφα (03)",
    "Surety": "Εξασφαλιστικά (04)",
    "LegalAction": "Νομικές Ενέργειες (05)",
    "Miscellaneous": "Λοιπά (06)"
  },
  "DebitCreditList": {
    "date": "Ημερομηνία",
    "amount": "Ποσό",
    "newamount": "Νέο Ποσό",
    "newdate": "Νέα Ημερομηνία",
    "newstatus": "Νέα Κατάσταση",
    "addition": "Προσθήκη",
    "removal": "Αφαίρεση",
    "perigrafi": "Περιγραφή",
    "newperigrafi": "Νέα Περιγραφή"
  },
  "ProjectsList": {
    "name": "Projects",
    "arithmos_aitisis":"Αριθμός αίτησης",
    "customer_code":"Κωδικός Πελάτη",
    "hmer_anatheshs": "Ημερομηνία Ανάθεσης",
    "customer":"Customer",
    "projectTemplate":"Project Template"
  },
  "ProjectsTemplatesList": {
    "name": "Project Templates",
    "name_el": "Ονομασία Πρότυπου Έργου στα Αγγλικά",
    "name_en": "Ονομασία Πρότυπου Έργου στα Ελληνικά"
  },
  "TaskTemplatesList": {
    "name": "Task Templates",
    "name_el": "Ονομασία Προτύπου Διαδικασίας στα Αγγλικά",
    "name_en": "Ονομασία Προτύπου Διαδικασίας στα Ελληνικά"
  },
  "CustomField": {
    "name_el":"Όνομα πεδίου Ελληνικά",
    "name_en":"Όνομα πεδίου Αγγλικά",
    "type":"Τύπος",
    "status":"Status",
    "CustomField":"Custom Fields",
    "text":"Text",
    "date":"Date",
    "number":"Number",
    "list":"Dropdown List",
    "boolean":"Yes/No",
    "status_list": "Λίστα Καταστάσεων",
    "money": "Χρηματικό ποσό"
  },
  "ProjectTaskHistory": {
    "project_task": "Διαδικασία Έργου",
    "field": "Πεδίο",
    "value": "Τιμή",
    "timestamp": "Χρονική στιμγή"
  },
  "Apografi_Frontier":{
    "borrower_id":"Borrower ID 30_6_2020",
    "borrower_name":"Borrower Name",
    "asset_class":"Asset Class",
    "contract_id":"Contract ID 30_6_2020",
    "loan_id_31_12_2021":"Loan ID 31_12_2021",
    "origination_date":"Origination_Date of Loan ID 31_12_2021",
    "loan_type_31_12_2021":"Loan Type (as Loan ID 31_12_2021)",
    "loan_id_open_date_31_12_2021":"Loan ID 31_12_2021 Open Date",
    "last_restructured_cut_off":"Last Restructured <> Cut-off",
    "loan_id_30_06_2020":"Loan ID 30_6_2020",
    "origination_date_loan_id_30_06_2020":"Origination_Date of Loan ID 30_6_2020",
    "looking_for_initial":"Looking for Initial?",
    "looking_for_last":"Looking for Last?",
    "looking_for_collateral":"Looking for Collateral?",
    "breach_category":"Breach Category",
    "management_branch_31_12_2021_meta_609":"Management Branch 31_12_2021 μετά από αντικατάσταση τουν 609",
    "priority":"Priority",
    "activation_branch":"Activation Branch",
    "management_branch_30_06_2020":"Management Branch 30_6_2020",
    "management_branch_31_12_2021_me_609_pro_antik":"Management Branch 31_12_2021 with 609 προ αντικατάστασης",
    "dms_status":"DMS Status",
    "iron_mountain_deliv_status":"Iron Mountain Delivery Status",
    "dovlaue_delivery_status":"DoValue Delivery Status physical file",
    "kwd":"Κωδ",
    "katastasi":"Κατάσταση",
    "sxolio":"Σχολιο",
    "anathesi_mo_officer":"Ανάθεση MO Officer",
    "hmerominia_enarxis_epexergasias":"Ημ/νία έναρξης επεξεργασίας",
    "imerominia_olokl_epexerg":"Ημ/νία ολοκλήρωσης επεξεργασίας",
    "mo_supervisor":"MO Supervisor",
    "imeromhnia_elegxou":"Ημ/νία ελέγχου",
    "anartimeno_psif_iliko_public_dms":"Αναρτημένο Ψηφιακό Υλικό στο Public DMS",
    "arxiki_sumvasi_pliris":"ΑΡΧΙΚΗ ΣΥΜΒΑΣΗ ΠΛΗΡΗΣ",
    "teleutaia_pp_pliris":"ΤΕΛΕΥΤΑΙΑ ΠΠ ΠΛΗΡΗΣ ",
    "eggrafa_prosimiosis":"ΕΓΓΡΑΦΑ ΠΡΟΣΗΜΕΙΩΣΗΣ",
    "initial_loan_id":"Loan ID",
    "initial_contract_id":"Contract ID",
    "initial_digitization_date":"Digitization Date",
    "initial_subfolder":"Subfolder",
    "initial_pages":"Pages",
    "last_amend_loan_id":"Loan ID",
    "last_amend_contract_id":"Contract ID",
    "last_amend_digitization_date":"Digitization Date",
    "last_amend_subfolder":"Subfolder",
    "last_amend_pages":"Pages",
    "re_collateral_loan_id":"Loan ID",
    "re_collateral_contract_id":"Contract ID",
    "re_collateral_digitization_date":"Digitization Date",
    "re_collateral_subfolder":"Subfolder",
    "re_collateral_pages":"Pages",
    "elegxos_dms":"Έλεγχος στο DMS ΕΤΕ",
    "fakelos_rc":"Φάκελος RC",
    "mitos":"Mitos",
    "arxiki_sumv_pliris_se_allo_sustima":"ΑΡΧΙΚΗ ΣΥΜΒΑΣΗ ΠΛΗΡΗΣ σε άλλο σύστημα",
    "teleutaia_pp_pliris_se_allo_sustima":"ΤΕΛΕΥΤΑΙΑ ΠΠ ΠΛΗΡΗΣ σε άλλο σύστημα ",
    "eggrafa_pros_se_allo_sustima":"ΕΓΓΡΑΦΑ ΠΡΟΣΗΜΕΙΩΣΗΣ σε άλλο σύστημα",
    "entopismos_se_sustima":"Εντοπισμός σε σύστημα",
    "remedy":"Remedy",
    "apothikeusi_psifiakou_ulikou":"Αποθήκευση ψηφιακού υλικού (πολλαπλά ονόματα)",
    "project_name":"Frontier Indexing",
    "downloadexportprot":"Download Protocol",
    "DataTape":"Row Data",
    "EpivevaiosiClaim":"Επιβεβαίωση Claim",
    "LoanTypeOther":"Other",
    "L3869":"L3869",
    "L4605":"L4605",
    "Mortgage":"Mortgage",
    "SBL":"SBL",
    "Consumer":"Consumer",
    "deZhteitai":"Δεν ζητείται",
    "deZhteitaiRC":"Δεν ζητείται (ενέργειες RC)",
    "Y":"Y",
    "denApaiteitaiLogwMirythmisis":"Δεν απαιτείται λόγω μη ρύθμισης",
    "ApofN3869":"Αποφ Ν.3869",
    "DenApaiteitai_L4605":"Δεν απαιτείται (4605)",
    "DenZiteitai_energeies_RC":"Δεν ζητείται (ενέργειες RC)",
    "Delivered":"Delivered",
    "Uploaded":"Uploaded",
    "Apostoli":"Αποστολή",
    "ProsPsifiopoihsh":"Προς Ψηφιοποίηση",
    "Pending":"Pending",
    "Completed":"Completed",
    "N":"N",
    "portfolio_id":"Portfolio",
    "vrethikan_eggrafa3869":"Βρέθηκαν έγγραφα Ν.3869",
    "den_vrethikan_eggrafa3869":"Δεν βρέθηκαν έγγραφα Ν.3869",
    "ektimiseis_texn_fakelos":"Εκτιμήσεις / Τεχνικός Φάκελος",
    "aitisi_egkrisi_oikonomika_stoixeia":"Αίτηση / Εγκριση / Οικονομικά Στοιχεία",
    "sumvatika_eggrafa":"Συμβατικά Έγγραφα",
    "prosim_upoth_dikogr":"Προσημειώσεις / Υποθήκες / Δικόγραφα",
    "eggrafa_epidik_kai_anagk_ektel":"Έγγραφα Επιδίκασης  και Αναγκαστικής Εκτέλεσης",
    "dikografa_3869":"Δικόγραφα Ν.3869",
    "entopistike_public_DMS":"Εντοπίστηκε σε public DMS",
    "rc":"RC",
    "dms_ete":"DMS ETE",
    "PartiallyCompleted":"Partially Completed",
    "NoRemedy":"No Remedy",
    "ektiposi":"Εκτύπωση",
    "ektiposi_date":"Ημερομηνία",
    "epivevaiosi_kataxorisis":"Επιβεβαίωση Καταχώρισης Ευρημάτων στο DMS Public",
    "epivevaiosi_date":"Ημερομηνία",
    "xristis":"Χρήστης",
    "arithmos_kivotiou":"Αριθμός Κιβωτίου",
    "Please_select":"Επιλέξτε",
    "portfolio":"Portfolio",
    "remedy_actions_comment": "Σχόλια"
  }
}